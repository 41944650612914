import React, { useState, useEffect } from "react";
import { FormControlLabel, MuiThemeProvider, Switch, Tooltip } from "@material-ui/core";
import MaterialTable from "material-table";
import { Modal } from "react-bootstrap";
import util from "../../../utils/miniUtils";
import ImageComponent from "../../../utils/ImageComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paper from "@material-ui/core/Paper";
import { NavLink } from "react-router-dom";
import { faEnvelope, faExclamationTriangle, faPhone,  } from "@fortawesome/free-solid-svg-icons";

const tableRef = React.createRef();

const ModalRecommendedTalents = ( { positionId, refreshTalentTable } ) => {
    const [show, setShow] = useState( false );
    const [talents, setTalents] = useState( [] );
    const [loading, setLoading] = useState( false );
    const [state, setState] = useState( {
        country: false,
        state: false,
        city: false,
        relocation: false
    } );

    const handleClose = () => {
        setShow( false );
        refreshTalentTable( positionId );
    };
    const handleShow = () => setShow( true );

    const handleQueryParams = ( params ) => {
        let paramsString = '?';
        if( params.country ) {
            paramsString += `country=${params.country}`;
        }
        if( params.state ) {
            paramsString += `&state=${params.state}`;
        }
        if( params.city ) {
            paramsString += `&city=${params.city}`;
        }
        if( params.relocation ) {
            paramsString += `&relocation=${params.relocation}`;
        }

        return paramsString;
    };

    const getRecommendedTalents = async ( position_id, params ) => {
        try {
            const request = await util.axios.get( `/recommended-talents/${position_id}${handleQueryParams( params )}` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setTalents( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    async function addCandidateToJob( position_id, candidate_id ) {
        // Select a candidate from the list and added to the position
        try {
            setLoading( true );
            const request = await util.axios.post( `requirements/add_candidate_new_method/${position_id}`, { data: { id: candidate_id }, sqResponse: [] } );
            const { error, msg } = request.data;
            setLoading( false );
            if ( error ) return util.toast().error( msg );
            util.toast().success( "Talent added to the Job Position" );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    }

    function validateSkills( data ) {
        return data["skill_name"] === "";
    }

    useEffect( () => {
        if( show ) {
            getRecommendedTalents( positionId, state );
        }
    },[show, state] );

    return (
        <>
            <button className={"btn btn-secondary activeBtn btn-sm"} onClick={handleShow}>
        Recommended Talents
            </button> 

            <Modal show={show} size="xl" onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Recommended Talents</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <MuiThemeProvider theme={util.defaultTableTheme}>
                        <MaterialTable
                            title="Recommended Talents"
                            tableRef={tableRef}
                            columns={[
                                {
                                    title: 'Name', field: 'name', searchable: true,
                                    render: rowData => {
                                        return <NavLink to={`/candidates/${rowData.id}?jp=true&job_id=${positionId}`}>{rowData.name}
                                        </NavLink>;
                                    }
                                },
                                {
                                    title: 'Skills', field: 'skill_name', searchable: true,
                                    width: "10%",
                                    render: rowData => {
                                        return util.suspenseDots( rowData["skill_name"] );
                                    },
                                },
                                {
                                    title: 'Last Job', field: 'wh_position', searchable: true,
                                    width: "10%",
                                    render: rowData => {
                                        return util.suspenseDots( util.extractOnePositionTitle( rowData["wh_position"] ) );
                                    },
                                },
                                {
                                    title: 'Location', field: 'location',
                                    render: ( data ) => {
                                        const countryFlagSrc = util.countryFlag( data.country );
                                        const locationInfo = util.location( data.country, data.state, data.city );
                                        const tooltipContent = `${data.city}, ${data.state}, ${data.country}`;

                                        return (
                                            <Tooltip title={tooltipContent} arrow>
                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img
                                                        width={30}
                                                        src={countryFlagSrc}
                                                        alt={data.country}
                                                        className="mr-1"
                                                    />
                                                    {locationInfo}
                                                </span>
                                            </Tooltip>
                                        );
                                    },
                                },
                                {
                                    title: 'Work Authorization', field: 'working_status',
                                    render: rowData => {
                                        if ( rowData.working_status == null ) {
                                            return <p>Currently Not Working</p>;
                                        } else {
                                            return rowData.working_status;
                                        }
                                    }
                                },
                                {
                                    title: 'Email', field: 'email',
                                    render: rowData => {
                                        return <Tooltip title="Send it an email">
                                            <a href={`mailto:${rowData.email}`}>
                                                <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                                            </a>
                                        </Tooltip>;
                                    }
                                },
                                {
                                    title: 'Phone', field: 'phone_mobile',
                                    render: rowData => {
                                        return <Tooltip title="Call him"><a href={`phone:${rowData.phone_mobile}`}>
                                            <FontAwesomeIcon icon={faPhone} className="mr-2" />
                                        </a></Tooltip>;
                                    }
                                },
                                {
                                    title: 'Available',
                                    editable: "onUpdate",
                                    render: rowData => {
                                        if ( validateSkills( rowData ) === false ) {
                                            return <div onClick={() => addCandidateToJob( positionId, rowData.id )} title="Select Talent" className="btn btn-outline-primary btn-sm">
                                                <div className="text-center">Select</div>
                                            </div>;
                                        }
                                        if ( validateSkills( rowData ) === true ) {
                                            return <Tooltip title="To use this talent, please add at least one skill">
                                                <div className="icon-link"><div className="text-center"><FontAwesomeIcon icon={faExclamationTriangle} /></div></div>
                                            </Tooltip>;
                                        }
                                    }
                                }
                            ]}
                            components={{
                                Container: props => <Paper {...props} elevation={0} />
                            }}
                            data={talents}
                            options={{
                                debounceInterval: 600,
                                pageSize: 10,
                                emptyRowsWhenPaging: false,
                                sorting: true,
                                padding: "dense",
                                showTitle: false,
                                loading: loading,
                                toolbarButtonAlignment: "left"
                            }}
                            localization={{
                                toolbar: {
                                    searchPlaceholder: "Search for talent..."
                                }
                            }}
                            actions={[
                                {
                                    icon: () => <FormControlLabel control={<Switch 
                                        size="small" 
                                        checked={state.country} 
                                        onChange={( e ) => setState( prev => ( { ...prev, country: e.target.checked } ) )} />} 
                                    label="Country" />,
                                    isFreeAction: true,
                                },
                                {
                                    icon: () => <FormControlLabel control={<Switch 
                                        size="small" 
                                        checked={state["state"]} 
                                        onChange={( e ) => setState( prev => ( { ...prev, state: e.target.checked } ) )} />}
                                    label="State" />,
                                    isFreeAction: true,
                                },
                                {
                                    icon: () => <FormControlLabel control={<Switch 
                                        size="small" 
                                        checked={state.city} 
                                        onChange={( e ) => setState( prev => ( { ...prev, city: e.target.checked } ) )} />} 
                                    label="City" />,
                                    isFreeAction: true,
                                },
                                {
                                    icon: () =>
                                        <FormControlLabel control={<Switch 
                                            size="small"
                                            checked={state.relocation} 
                                            onChange={( e ) => setState( prev => ( { ...prev, relocation: e.target.checked } ) )} />} 
                                        label="Relocation" />,
                                    isFreeAction: true,
                                },
                                
                            ]}
                        />
                    </MuiThemeProvider>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalRecommendedTalents;