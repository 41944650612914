import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import MemberList from './MemberList';
import { Paper } from '@material-ui/core';
import EmployerList from './EmployerList';
import EmployerDetails from './EmployerDetails';


const Employer = () => {
    const [showForm, setShowForm] = useState( false );
    const [selectedEmployer, setSelectedEmployer] = useState( null );
    // const handleClose = () => {
    //     setShowForm( false );
    //     setSelectedEmployer( null );
    // };
    const onBackClick = () => {
        setShowForm( false );    
    };

    const handleNewEmployer = () => {
        setSelectedEmployer( null );
        setShowForm( true );
    };

    const handleEmployerClick = ( value ) => {
        setSelectedEmployer( value );
        setShowForm( true );
    };

    return (
        <>
            <div className="d-flex mt-3">
                {!showForm ? <button className="btn btn-primary" onClick={handleNewEmployer}>
                    <FontAwesomeIcon icon={faPlus} />
                    <span className="ml-1">Add Employer</span>
                </button> : ""}
            </div>
            {!showForm ? <EmployerList handleEmployerClick={handleEmployerClick} /> : 
                <EmployerDetails onBackClick={onBackClick} selectedEmployer={selectedEmployer} />}
            {/* {showForm && <Paper className="vendorDetails-section" style={{ height: "500px" }}>
                <VendorForm handleClose={handleClose} selectedVendor={selectedVendor} handleVenderSelect={handleVenderSelect} />
                <MemberList selectedVendor={selectedVendor} />
            </Paper>} */}
        </>
    );
};

export default Employer;