import React, { useEffect, useState } from "react";
import { faFilter, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import selector from "../../../utils/selector";
import util from "../../../utils/miniUtils";
import { Autocomplete } from "@material-ui/lab";
import { TextField, Switch, FormControlLabel } from "@material-ui/core";

const AdvancedFilter = ( { filterData, searchData, closeAdvanceSearch } ) => {
    const [skillsList, setSkillsList] = useState( [] );
    const [statesList, setStatesList] = useState( [] );
    const [state, setState] = useState( filterData.state );

    function handleChanges( e ) {
        const { name, value } = e.target;
        if ( name === "skill_name" ) {
            searchSkill( value ).then( null );
        }
        setState( { ...state, [name]: value } );
    }

    async function searchSkill( sk ) {
        try {
            let query = `skill=${sk}`;
            const request = await util.axios.get( `${util.url}/generic_data/search_skills?${query}` );
            const { error, msg, skills } = request.data;
            if ( error ) throw new Error( msg );
            setSkillsList( skills );
        } catch ( e ) {
            util.handleError( e );
        }
    }

    useEffect( () => {
        if ( state.country && state.country.length === 0 ) {
            setStatesList( selector.statesByCountry( [] ) );
        } else {
            setStatesList( selector.statesByCountry( state.country ) );
        }
    }, [state.country] );

    return (
        <div className="advancedFilterBox">
            <div className="row">
                <div className="col-md-8">
                    <h5>Advanced Filter</h5>
                </div>
                <div className="col-md-4 align-self-end d-flex justify-content-end">
                    <FormControlLabel
                        control={
                            <Switch
                                checked={state.rec_skill}
                                onChange={( e ) => setState( { ...state, rec_skill: e.target.checked } )}
                                name="rec_skill"
                                color="primary"
                            />
                        }
                        label="Recommended by skills"
                        fullWidth
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={state.rec_cert}
                                onChange={( e ) => setState( { ...state, rec_cert: e.target.checked } )}
                                name="rec_cert"
                                color="primary"
                            />
                        }
                        label="Recommended by cert"
                        fullWidth
                    />
                </div>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center w-100">
                <div className="d-flex justify-content-center w-100">
                    <div className="col-md-4 p-1 mt-2 align-self-end">
                        <TextField
                            label="Name"
                            name="name"
                            value={state.name}
                            onChange={handleChanges}
                            fullWidth
                            variant="outlined"
                            size="small"
                        />
                    </div>
                    <div className="col-md-4 p-1 mt-2 align-self-end">
                        <Autocomplete
                            freeSolo
                            fullWidth
                            disableClearable
                            onChange={() => null}
                            options={skillsList.map( ( option ) => option.skill_name )}
                            renderInput={( params ) => (
                                <TextField
                                    {...params}
                                    label="Skill"
                                    name="skill_name"
                                    value={state.skill_name}
                                    onChange={handleChanges}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            )}
                        />
                    </div>
                    <div className="col-md-4 p-1 mt-2 align-self-end">
                        <TextField
                            label="Last job"
                            name="job_title"
                            value={state.job_title}
                            onChange={handleChanges}
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-center w-100">
                    <util.RowComponent
                        c={4}
                        t={"Country"}
                        n={"country"}
                        m={handleChanges}
                        v={state.country}
                        type={1}
                        d={selector.country()}
                    />
                    <util.RowComponent
                        c={4}
                        t={"State"}
                        n={"state"}
                        m={handleChanges}
                        v={state.state}
                        type={1}
                        d={statesList}
                    />
                    <div className="col-md-4 p-1 mt-3">
                        <button className="btn btn-sm btn-secondary activeBtn sizeBtn" onClick={() => searchData( state )}>
                            <FontAwesomeIcon icon={faFilter} className="mr-2" />
                            Search
                        </button>
                        <button className="btn btn-sm" title="Close advanced search" onClick={closeAdvanceSearch}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                </div>
                <div className="d-flex justify-content-center w-100">

                </div>
            </div>
        </div>
    );
};

export default AdvancedFilter;
