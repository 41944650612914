import React, { useEffect, useState } from "react";
import util from "../../utils/miniUtils";
import { Card } from "react-bootstrap";
import { CardContent, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
const EmployeeSkills = () => {
    const [employeeSkill, setEmployeeSkill] = useState( [] );
    const [filterList, setFilterList] = useState( {
        skill: "",
        year: "",
        country: "",
        state: "",
        name: "",
        city: "",
        
    } );
    const getEmployeeSkillDetails = async () => {
        try{
            const response = await util.axios.post( `/new_dashboard/get_employee_skill`,
                { filterList: filterList } );
            const { error, data } = response.data;
            setEmployeeSkill( data );
            if( error ) throw error;
        }catch ( e ) {
            util.handleError( e );
        }
    };
    const handleChange = ( e ) => {
        const { value, name } = e.target;
        setFilterList( { ...filterList, [name]:value } );
    };
    
    
    useEffect( () => {
        const timeOut = setTimeout( () => {
            getEmployeeSkillDetails();

        }, 500 );
        return () => clearTimeout( timeOut );
    },[filterList] );
    const exportToCsv = () => {
        const headings = [
            "Employee Name",
            "Employee Email",
            "Employee Phone",
            "Employee Id",
            "Country",
            "State",
            "City",
            "Job Title",            
            "Skills With Experience",
            "Skill Ratings",
            "ej Ratings",
            "Certifications",
            "Previous Job Positions",
        ];
      
        const csvContent =
          "data:text/csv;charset=utf-8," +
          [headings.join( ',' ), ...employeeSkill.map( row =>
              [
                  row.employee_name,
                  row.employee_email,
                  row.employee_phone,
                  row.employee_id,
                  row.country,
                  row.state,
                  row.city,
                  row.job_title,
                  
                  row.skills_with_experience,
                  row.skill_ratings,
                  row.ej_ratings,
                  row.certifications,
                  row.positions,
              ].join( ',' )
          )].join( '\n' );
      
        const encodedUri = encodeURI( csvContent );
        const link = document.createElement( "a" );
        link.setAttribute( "href", encodedUri );
        link.setAttribute( "download", "table-export.csv" );
        document.body.appendChild( link );
        link.click();
    };
    return(
        <Card style={{ width: "100%", height: "90%", overflow: 'hidden' }}>
            <CardContent style={{ height: "100%" }} >
                <div style={{ height: "100%", overflow: "auto" }}>                     
                    <div className="recruiter-filter">
                        <input 
                            value={filterList.name} 
                            className="mr-2 non-draggable dashboard-select" 
                            name="name"
                            placeholder="Search Name" 
                            onChange={handleChange}/>

                        <input 
                            value={filterList.skill} 
                            className="mr-2 non-draggable dashboard-select"
                            name="skill"
                            placeholder="Search Skill" 
                            onChange={handleChange}/>
                        <input 
                            className="mr-2 non-draggable dashboard-select"
                            name="year"
                            value={filterList.year} 
                            placeholder="Search Skill Experience" 
                            onChange={handleChange}/>
                        <input 
                            className="mr-2 non-draggable dashboard-select"
                            value={filterList.country} 
                            name="country"
                            placeholder="Search Country"
                            onChange={handleChange}/>
                        <input 
                            className="mr-2 non-draggable dashboard-select"
                            value={filterList.city} 
                            placeholder="Search City" 
                            name="city"
                            onChange={handleChange}/>
                        <input 
                            className="mr-2 non-draggable dashboard-select"
                            value={filterList.state} 
                            placeholder="Search State" 
                            name="state"
                            onChange={handleChange}/>
                        <div className={"btn btn-secondary jobButton activeBtn btn-sm export-onboarded non-draggable"} onClick={exportToCsv}>
                            <FontAwesomeIcon icon={faFileExport} className="mr-2" />
                                Export All
                        </div>
                    </div>
                    <table className="employee-skill-dashboard">
                        
                        <thead >
                            <tr>
                                <th>Employee Name</th>
                                <th>Employee Email</th>
                                <th>Employee Phone</th>
                                <th>Employee Id</th>
                                <th>Country</th>
                                <th>State</th>
                                <th>City</th>
                                <th>Job Title</th>
                                <th>Skills With Experience</th>
                                <th>Skill Ratings</th>
                                <th>ej Ratings</th>
                                <th>Certifications</th>
                                <th>Previous Job Positions</th>
                            </tr>

                        </thead>
                        <tbody className="recruiter-report-body">
                            {
                                employeeSkill.map( ( item, index ) => (
                                    <tr key={index} >
                                        <td>
                                            <Tooltip title={item.employee_name} arrow>
                                                <span className="truncate">{item.employee_name}</span>
                                            </Tooltip>
                                        </td>
                                        <td>
                                            <Tooltip title={item.employee_email} arrow>
                                                <span className="truncate">{item.employee_email}</span>
                                            </Tooltip>
                                        </td>
                                        
                                        {/* <td>{item.employee_email}</td> */}
                                        <td>{item.employee_phone}</td>
                                        <td>{item.employee_id}</td>
                                        <td>{item.country}</td>
                                        <td>
                                            {/* <Tooltip title={item.state} arrow> */}
                                            {item.state}
                                            {/* </Tooltip> */}
                                        </td>
                                        <td>
                                            <Tooltip title={item.city} arrow>
                                                <span className="truncate">{item.city}</span>
                                            </Tooltip>
                                        </td>
                                        <td>
                                            <Tooltip title={item.job_title} arrow>
                                                <span className="truncate">{item.job_title}</span>
                                            </Tooltip>
                                        </td>
                                        
                                        
                                        <td>
                                            <Tooltip title={item.skills_with_experience} arrow>
                                                <span className="truncate">{item.skills_with_experience}</span>
                                            </Tooltip>
                                        </td>
                                        
                                        <td>{item.skill_ratings}</td>
                                        <td>{item.ej_ratings}</td>
                                        <td>
                                            <Tooltip title={item.certifications} arrow>
                                                <span className="truncate">{item.certifications}</span>
                                            </Tooltip>
                                        </td>
                                        <td>
                                            <Tooltip title={item.positions} arrow>
                                                <span className="truncate">{item.positions}</span>
                                            </Tooltip>
                                        </td>
                                        
                                    </tr>
                                ) )
                            }
                            

                        </tbody>
                    </table>
            
                </div>
            </CardContent>
        </Card>
    );

};
export default EmployeeSkills;