import React, { useEffect, useState } from "react";
import {
    Card,   
    CardContent,
} from "@material-ui/core";
import util from "../../utils/miniUtils";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../../redux/actions/datefilter";

const CandidateByJobPosition = () => {
    const [totalApplicants, setTotalApplicants] = useState( [] );

    let { from, to, applyFilter } = useSelector( ( state ) => state.filter ) || {};
    const dispatch = useDispatch();

    useEffect( () =>{
        if( applyFilter ) {
            getCandidatesAddedByJobPosition();
        }
    },[applyFilter] );

    const getCandidatesAddedByJobPosition = async (  ) => {
        try {
            if( from === undefined ){
                const currentDate = new Date();
                from = currentDate.getFullYear() + '-' +
                  ( currentDate.getMonth() + 1 ) + '-' +
                  currentDate.getDate();
            }
            if( to === undefined ){
                const currentDate = new Date();
                to = currentDate.getFullYear() + '-' +
                ( currentDate.getMonth() + 1 ) + '-' +
                currentDate.getDate();
            }
            from = from ? util.convertToUTC( from ) : '';
            to = to ? util.convertToUTC( to ) : '';
            const response = await util.axios.get(
                `new_dashboard/get_candidates_by_job_position?from=${from}&to=${to}`
            );

            const { data, error, msg } = response.data;
            setTotalApplicants( data );
            dispatch( setFilter( false ) );
            if ( error ) throw msg;
        } catch ( e ) {
            util.handleError( e );
        }
    };
  
    return (
        <Card style={{ width: "100%", height: "90%", overflow: 'hidden' }}>
            <CardContent style={{ height: "100% " }} >
                <div style={{ height: "100%", overflow: "auto" }}>
                    
                    {/* <table className="table table-bordered"> */}
                    <table className='job-position-table'>
                        <thead>
                            <tr>
                                <th >Job Title</th>
                                <th >Talents </th>
                            </tr>
                        </thead>
                        <tbody className="recruiter-report-body">
                            {totalApplicants.map( ( item,index ) => (
                                <tr key={index} className="recruiter-report-rows">
                                    <td>{item.job_title}</td>
                                    <td>{item.candidate_count}</td>
                                </tr>
                            ) )}
                        </tbody>
                    </table>
                    
                </div>
            </CardContent>
        </Card>
    );
};

export default CandidateByJobPosition;

