import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const StarComponent = ( { value } ) => {
    if ( value === null || value === undefined ) {
        return null;
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <div style={{ position: 'relative', fontSize: '24px', height: '24px', width: '24px', lineHeight: '24px', textAlign: 'center' }}>
                <FontAwesomeIcon icon={faStar} style={{ color: '#FFD700', fontSize: 'inherit' }} />
                <span style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'black',
                    fontWeight: 'bold',
                    fontSize: '12px'
                }}>
                    {value}
                </span>
            </div>
        </div>
    );
};

export default StarComponent;
