import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FileManagementTable from '../../../shared/profile_component/employee/EmployeeFileManagementTable';
import CandidateSelector from './CandidateSelector';

const UploadDocumentModal = ( { show, handleClose, candidates, updateCandidates } ) => {
    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Upload Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CandidateSelector candidates={candidates} updateCandidates={updateCandidates} />
                <FileManagementTable />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
            Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UploadDocumentModal;