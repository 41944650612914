import React, { Fragment, useEffect, useState } from "react";
import ItemMember from "./ItemMember";
import ItemWithLead from "./ItemWithLead";
import util from '../../utils/miniUtils';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import CreationModal from "./CreationModal";

const EDIT_ITEM = 2;
const NEW_ITEM = 1;

const BigColumn = ( { settings } ) => {
    const { title, subTitle, getListPath, getEmployeeList, assignTeamMemberPath, getAssignedTeamMemberPath, deleteTeamMemberPath, deletePath } = settings;
    const [employeeList, setEmployeeList] = useState( [] );
    const [newMember, setNewMember] = useState( null );

    const [state, setState] = useState( {
        showExtraColumn: false,
        childData: [],
        parentData: [],
        showModal: false,
        showMemberSelector: false,
        id: null
    } );

    const reloadData = () => {
        getParentData();
        setState( { ...state, showModal: false } );
    };

    const getParentData = async () => {
        try{
            const request = await util.axios.get( getListPath );
            const { msg, error, data } = request.data;
            if( error ) throw( msg );
            setState( ( prev ) => {
                return { ...prev, parentData: data, showExtraColumn: true };
            } );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const getAssignedTeamMembers = async ( id ) => {
        try{
            const request = await util.axios.get( `${getAssignedTeamMemberPath}/${id}` );
            const { msg, error, data } = request.data;
            if( error ) throw( msg );
            setState( { ...state, childData: data, showExtraColumn: true, id: id } );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const assignTeamMember = async () => {
        try{
            const request = await util.axios.post( `${assignTeamMemberPath}/${state.id}/${newMember}` );
            const { msg, error, data } = request.data;
            if( error ) throw( msg );
            util.toast().success( msg );
            setState( ( prev ) => {
                return { ...prev, showMemberSelector: false, childData: data };
            } );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const onDeleteMember = async ( id ) => {
        try {
            const request = await util.axios.delete( `${deleteTeamMemberPath}/${state.id}/${id}` );
            const { msg, error, data } = request.data;
            if( error ) throw( msg );
            setState( ( prev ) => {
                return { ...prev, showMemberSelector: false, childData: data };
            } );
            util.toast().success( msg );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const getEmployeesList = async () => {
        try {
            const request = await util.axios.get( getEmployeeList );
            const { msg, error, data } = request.data;
            if( error ) throw( msg );
            setEmployeeList( data );
        } catch ( error ) {
            util.handleError( error ); 
        }
    };

    const editParentData = ( id ) => {
        // open modal, modal will do the request
        setState( { ...state, showModal: true, actionType: EDIT_ITEM, id: id } );
    };

    const onSelect = ( e, id ) => {
        e.stopPropagation();
        getAssignedTeamMembers( id );
    };

    const onDelete = async ( e, id ) => {
        e.stopPropagation();
        try {
            const request = await util.axios.delete( `${deletePath}/${id}` );
            const { msg, error } = request.data;
            if( error ) throw( msg );
            util.toast().success( msg );
            getParentData();
        } catch ( error ) {
            util.handleError( error ); 
        }
    };
    const onRemoveMember = ( e, id ) => {
        e.stopPropagation();
        onDeleteMember( id );
    };
    const onEdit = ( e, id ) => {
        e.stopPropagation();
        editParentData( id );
    };

    const onChange = ( e ) => setNewMember( e.target.value );

    useEffect( () => {
        getEmployeesList();
        getParentData();
    }, [] );

    return (
        <Fragment>
            <div className="col-md-4 col-sm-6 bg-white">
                <div className="d-flex justify-content-between align-items-center mt-1 mb-2">
                    <b>{title}</b>
                    <FontAwesomeIcon                        
                        className="isCursor text-primary"
                        onClick={() => setState( { ...state, showModal: true, actionType: NEW_ITEM } )}
                        icon={faPlus} />
                </div>
                <div className="listContainer">
                    {state.parentData.map( ( d, i ) => {
                        return <ItemWithLead active={state.id} key={i} data={d} onSelect={onSelect} onDelete={onDelete} onEdit={onEdit} />;
                    } )}
                </div>
            </div>

            {state.id !== null ? (
                <div className="col-md-4 col-sm-6 bg-white ml-1">
                    <div className="d-flex justify-content-between align-items-center mt-1 mb-2">
                        <b>{subTitle}</b>
                        <FontAwesomeIcon className="isCursor text-primary" onClick={() => setState( { ...state, showMemberSelector: true, actionType: NEW_ITEM } )} icon={faPlus} />
                    </div>
                    {state.showMemberSelector && (
                        <div className="">
                            <div className="d-flex justify-content-center">
                                <b>Add Member</b>
                            </div>

                            <div className="d-flex justify-content-between align-items-center">
                                <select className="form-control" name="member" onChange={onChange}>
                                    <option value={null}>Select Employee</option>
                                    {employeeList.map( ( emp, i ) => {
                                        return (
                                            <option key={i} value={emp.employee_id}>
                                                {emp.name}
                                            </option>
                                        );
                                    } )}
                                </select>
                                <div className="d-flex">
                                    <span className="p-1"><FontAwesomeIcon onClick={() => setState( { ...state, showMemberSelector: false } )} icon={faTimes} className="isCursor text-danger" /></span>
                                    <span className="p-1"><FontAwesomeIcon onClick={assignTeamMember} icon={faCheck} className="isCursor text-primary" /></span>
                                </div>
                            </div>
                        </div>
                    )}
                    
                    {state.childData && state.childData.map( ( d, i ) => {
                        return <ItemMember key={i} data={d} onSelect={null} onDelete={onRemoveMember} />;
                    } )}
                </div>
            ) : null}
            {state.showModal && <CreationModal show={state.showModal} handleClose={() => setState( { ...state, showModal: false } )} settings={settings} reloadData={reloadData} actionType={state.actionType} id={state.id} />}
        </Fragment>
    );
};

export default BigColumn;
