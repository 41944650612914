import React, { Fragment } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import util from "../../../../utils/miniUtils";

export default function ExportEmployee( { filterByStatus } ) {

    const retreiveInfo = async () => {
        try {
            const request = await util.axios.post( `${util.url}/export/employees`, { status: filterByStatus } );
            let { error, msg, info } = request.data;
            const blob = new Blob( [info], { type: "text/csv;charset=utf-8;" } );
            const link = document.createElement( "a" );
            const url = URL.createObjectURL( blob );
    
            link.setAttribute( "href", url );
            link.setAttribute( "download", "selected_rows.csv" );
            link.style.display = "none";
    
            document.body.appendChild( link );
            link.click();
            document.body.removeChild( link );
    
            URL.revokeObjectURL( url );
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };


    return (
        <Fragment>
            <div className={"btn btn-secondary sizeBtn activeBtn"} onClick={()=>retreiveInfo()} >
                <FontAwesomeIcon icon={faFileExport} className="mr-2" />
                Ex. Employee
            </div>
        </Fragment>
    );
}
