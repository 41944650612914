import React from 'react';

const CandidateSelector = ( { candidates } ) => {
    console.table( candidates );
    return ( <>
        <h5>Selected Candidate</h5>
        <ol className="mx-3 mb-0">
            {candidates.map( el => <li key={el.id}
                // className="bg-light mr-1 mb-1 rounded"
            >
                {el.name}
                {/* <input 
                    type="checkbox"
                    className="form-check-input" id={el.name.trim() + 'checkbox'} 
                    checked={el.checked}
                    onChange={( e ) => updateCandidates( e, el.id )}
                /> */}
            </li> )}
        </ol>
    </>
    );
};

export default CandidateSelector;