import React, { useEffect, useRef } from "react";
import d3Funnel from "d3-funnel";

const FunnelChart = ( { parentStatusList, data } ) => {
    const chartRef = useRef( null );

    useEffect( () => {
       
        const chart = new d3Funnel( chartRef.current );
        const colors = [
            "#B2DAF7", "#78B7EF", "#3B93F4", "#0170FF", "#0038FF", "#0425CC", "#000D6B" ,
            "#B8BACC", "#9193A8", "#75788F", "#5A5C76",
            "#404259", "#23273C", "#1A1B2F", "#0A0D1C",
            "#EFF0F9", "#DFE1F0", "#B8BACC", "#9193A8", 
            "#75788F", "#5A5C76", "#404259", "#23273C", 
            "#1A1B2F", "#0A0D1C",
        ];

        // Prepare the data for the funnel chart
        const funnelData = parentStatusList?.map( ( status,index ) => {
            
            return {
                label: status.name || "Unknown Label",
                value: data[status.jts_id] || 0,
                backgroundColor: colors[index],
                
            };
        } );        
          

        chart.draw( funnelData, {
            block: {
                dynamicHeight: false,
                minHeight: 20,
                highlight: true,
            },
            chart: {
                animate: false,
                bottomPinch: 0,
                inverted: true,
                curve: {
                    enabled: true,
                },
                height: 600,
            },
            label: {
                format: "{l}\n{f}",
                fontSize: 15,
            },
            
        } );
    }, [data, parentStatusList] );

    return <div className="funnel-chart" ref={chartRef}></div>;
};

export default FunnelChart;
