import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import util from "../../../utils/miniUtils";

const SaveSocialmediaCredentials = ( { statedata }  ) => {
    const [formData, setFormData] = useState( {
        twitter_api_key: '',
        twitter_api_secret_key: '',
        twitter_access_token: '',
        twitter_access_token_secret: '',
        instagram_access_token: '',
        instagram_business_account_id: '',
        instagram_client_secret: '',
        instagram_client_id: '',
        instagram_short_lived_access_token: '',
        linkedin_client_id: '',
        linkedin_client_secret: '',
        linkedin_access_token:'',
        AWS_EMPLOYEE_BUCKET:'',
        AWS_ACCESS_KEY:'',
        AWS_SECRET_ACCESS_KEY:'',
        GOOGLE_CLIENT_ID:'',
        GOOGLE_CLIENT_SECRET:'',
        GOOGLE_REDIRECT_URI:''
    } );

    const [isEdit, setIsEdit] = useState( false );
    const handleChange = ( e ) => {
        const { name, value } = e.target;
        setFormData( prevState => ( {
            ...prevState,
            [name]: value
        } ) );

    };

    const filterFormData = ( data ) => {
        return Object.keys( data )
            .filter( key => data[key] !== '' )
            .reduce( ( obj, key ) => {
                obj[key] = data[key];
                return obj;
            }, {} );
    };

    useEffect( () => {
        const url = statedata.credentialType ? `/tokens/${statedata.credentialType}` : '/tokens/linkedIn';
        util.axios.get( url )
            .then( response => {
                if ( response.data.length > 0 ) {
                   
                    setIsEdit( true );
                }
                else{
                   
                    setIsEdit( false );
                }
            } )
            .catch( error => console.error( 'Error fetching tokens:', error ) );
    }, [statedata.credentialType] );

    const handleSubmit = async ( e ) => {
        e.preventDefault();
        console.log( isEdit );
        // Assuming you have a way to identify the platform
        const filteredData = filterFormData( formData );
        const url = isEdit ? `/tokens/${statedata.credentialType}` : '/tokens';
       
        try {
           
            const request = await ( isEdit
                ? util.axios.put( url, { data: { platform: statedata.credentialType,token_data:filteredData } } )
                : util.axios.post( url, { data: { platform: statedata.credentialType, token_data:filteredData  } } ) );
            const { msg, error } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
            setFormData( prevState => ( {
                ...prevState,
                email_username:'',
                email_password:'',
                twitter_api_key: '',
                twitter_api_secret_key: '',
                twitter_access_token: '',
                twitter_access_token_secret: '',
                instagram_access_token: '',
                instagram_business_account_id: '',
                instagram_client_secret: '',
                instagram_client_id: '',
                instagram_short_lived_access_token: '',
                linkedin_client_id: '',
                linkedin_client_secret: '',
                linkedin_access_token:'',
                aws_bucket:'',
                aws_access_key:'',
                aws_secret_access_key:'',
                google_client_id:'',
                google_client_secret:'',
                google_redirect_url:''
            } ) );
        } catch ( error ) {
            util.handleError( error );
        }
    };
    // const saveInformation = async () => {
    //     try {
    //         const request = await util.axios.post( createPath+type, { item:item.name } );
    //         const { msg, error } = request.data;
    //         if( error ) throw( msg );
    //         util.toast().success( msg );
    //         reloadData( type );
    //     } catch ( error ) {
    //         util.handleError( error );
    //     }
    //     handleClose();
    // };

   

    // const getRegionList = () => {
    //     let regionList = {};
    //     countryList.forEach( item => {
    //         if( regionList[item.region] ) {
    //             regionList[item.region].push( item.name );
    //         } else {
    //             regionList[item.region] = [item.name];
    //         }
    //     } );
    //     return Object.keys( regionList );
    // };
   
    return (

        <div >
            <form onSubmit={handleSubmit}>
                {statedata.id == '1' || statedata.id == '' ?
                    <div>
                        <div className="social-media-tokens-form">

                            <h2>LinkedIn</h2>
                            <div className="form-group">
                                <label>LinkedIn Access Token:</label>
                                <input type="password" className="form-control" name="linkedin_access_token" value={formData.linkedin_access_token} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>LinkedIn Client ID:</label>
                                <input type="password" className="form-control" name="linkedin_client_id" value={formData.linkedin_client_id} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>LinkedIn Client Secret:</label>
                                <input type="password" className="form-control" name="linkedin_client_secret" value={formData.linkedin_client_secret} onChange={handleChange} />
                            </div>
                            <div className="w-100 text-right">
                                <button type="submit" className="btn btn-secondary sizeBtn activeBtn ">{isEdit ? 'Update' : 'Save'} Tokens</button>
                            </div>
                        </div>
                    </div>
                    : ''}
                {
                    statedata.id == '2' ? <div className="social-media-tokens-form"> <h2>Instagram</h2>
                        <div className="form-group">
                            <label>Instagram Access Token:</label>
                            <input type="password" className="form-control" name="instagram_access_token" value={formData.instagram_access_token} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Instagram Business Account ID:</label>
                            <input type="password" className="form-control" name="instagram_business_account_id" value={formData.instagram_business_account_id} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Instagram Client Secret:</label>
                            <input type="password" className="form-control" name="instagram_client_secret" value={formData.instagram_client_secret} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Instagram Client ID:</label>
                            <input type="password" className="form-control" name="instagram_client_id" value={formData.instagram_client_id} onChange={handleChange} />
                        </div>
                        {/* <div className="form-group">
                            <label>Instagram Short-Lived Access Token:</label>
                            <input type="text" className="form-control" name="instagram_short_lived_access_token" value={formData.instagram_short_lived_access_token} onChange={handleChange} />
                        </div> */}
                        <div className="w-100 text-right">
                            <button type="submit" className="btn btn-secondary sizeBtn activeBtn ">{isEdit ? 'Update' : 'Save'} Tokens</button>
                        </div>
                    </div> : ''
                }
                {
                    statedata.id == '4' ? <div className="social-media-tokens-form">
                        <h2>Twitter</h2>
                        <div className="form-group">
                            <label>Twitter API Key:</label>
                            <input type="password" className="form-control" name="twitter_api_key" value={formData.twitter_api_key} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Twitter API Secret Key:</label>
                            <input type="password"  className="form-control" name="twitter_api_secret_key" value={formData.twitter_api_secret_key} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Twitter Access Token:</label>
                            <input type="password" className="form-control" name="twitter_access_token" value={formData.twitter_access_token} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Twitter Access Token Secret:</label>
                            <input type="password" className="form-control" name="twitter_access_token_secret" value={formData.twitter_access_token_secret} onChange={handleChange} />
                        </div>
                        <div className="w-100 text-right">
                            <button type="submit" className="btn btn-secondary sizeBtn activeBtn ">{isEdit ? 'Update' : 'Save'} Tokens</button>
                        </div>
                    </div> : ''
                }
                {
                    statedata.id == '3' ? <div className="social-media-tokens-form">
                        <h2>Facebook</h2>
                        <div className="form-group">
                            <label>Facebook API Key:</label>
                            <input type="password" className="form-control" name="twitter_api_key" value={formData.twitter_api_key} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Facebook API Secret Key:</label>
                            <input type="password" className="form-control" name="twitter_api_secret_key" value={formData.twitter_api_secret_key} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Facebook Access Token:</label>
                            <input type="password" className="form-control" name="twitter_access_token" value={formData.twitter_access_token} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Facebook Access Token Secret:</label>
                            <input type="password" className="form-control" name="twitter_access_token_secret" value={formData.twitter_access_token_secret} onChange={handleChange} />
                        </div>
                        <div className="w-100 text-right">
                            <button type="submit" className="btn btn-secondary sizeBtn activeBtn ">{isEdit ? 'Update' : 'Save'} Tokens</button>
                        </div>
                    </div> : ''
                }
                {
                    statedata.id == '6' ? <div className="social-media-tokens-form">
                        <h2>AWS Bucket</h2>
                        <div className="form-group">
                            <label>AWS BUCKET :</label>
                            <input type="text" className="form-control" name="aws_bucket" value={formData.aws_bucket} onChange={handleChange} />
                        </div>
                     
                        <div className="form-group">
                            <label>AWS Access Token:</label>
                            <input type="password" className="form-control" name="aws_access_key" value={formData.aws_access_key} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>AWS Access Token Secret:</label>
                            <input type="password" className="form-control" name="aws_secret_access_key" value={formData.aws_secret_access_key} onChange={handleChange} />
                        </div>
                        <div className="w-100 text-right">
                            <button type="submit" className="btn btn-secondary sizeBtn activeBtn ">{isEdit ? 'Update' : 'Save'} Tokens</button>
                        </div>
                    </div> : ''
                }
                {
                    statedata.id == '7' ? <div className="social-media-tokens-form">
                        <h2>Google</h2>
                        <div className="form-group">
                            <label>Google Client ID:</label>
                            <input type="password" className="form-control" name="google_client_id" value={formData.google_client_id} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Google Client Secret:</label>
                            <input type="password" className="form-control" name="google_client_secret" value={formData.google_client_secret} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Google Redirect URL:</label>
                            <input type="text" className="form-control" name="google_redirect_url" value={formData.google_redirect_url} onChange={handleChange} />
                        </div>
                        <div className="w-100 text-right">
                            <button type="submit" className="btn btn-secondary sizeBtn activeBtn ">{isEdit ? 'Update' : 'Save'} Tokens</button>
                        </div>
                    </div> : ''
                }
                {
                    statedata.id == '5' ? <div className="social-media-tokens-form">
                        <h2>Email</h2>
                        <div className="form-group">
                            <label>Email</label>
                            <input type="text" className="form-control" name="email_username" value={formData.email_username} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Password:</label>
                            <input type="password" className="form-control" name="email_password" value={formData.email_password} onChange={handleChange} />
                        </div>
                       
                        <div className="w-100 text-right">
                            <button type="submit" className="btn btn-secondary sizeBtn activeBtn ">{isEdit ? 'Update' : 'Save'} Tokens</button>
                        </div>
                    </div> : ''
                }
            </form>
        </div>

    );
};

export default SaveSocialmediaCredentials;
