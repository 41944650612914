import React, { useEffect } from 'react';
import StatusList from './StatusList';
import useJobTalentStatus from './useJobTalentStatus';

const JobStatus = () => {
    const { 
        parentStatusList,
        childStatusList, 
        getParentSatusList, 
        createParentStatus, 
        createChildStatus, 
        editParentStatus,
        editChildStatus,
        selectedStatus,
        handleParentStatusClick,
        deleteParentStatus,
        deleteChildStatus,
        updateParentOrder,
        updateChildOrder } = useJobTalentStatus();
    
    useEffect( () => {
        getParentSatusList();
    },[] );

    return <>
        <div className='m-1 row'>
            <StatusList title="Status"
                statusList={parentStatusList} 
                selectedStatus={selectedStatus} 
                clickable={true}
                handleClick={handleParentStatusClick}
                saveNewStatus={createParentStatus}
                updateStatus={editParentStatus}
                deleteStatus={deleteParentStatus}
                updateOrder={updateParentOrder} />
            <StatusList title="Status Options" 
                statusList={childStatusList} 
                selectedStatus={selectedStatus}
                saveNewStatus={createChildStatus}
                updateStatus={editChildStatus}
                deleteStatus={deleteChildStatus}
                updateOrder={updateChildOrder} />
        </div>
        
    </>;
};

export default JobStatus;