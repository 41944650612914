import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom"; // useHistory for navigation
import util from "../utils/miniUtils";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";

const EmployerVerification = () => {
    const queryParams = new URLSearchParams( window.location.search );
    const [isVerifying, setIsVerifying] = useState( true );
    const token = queryParams.get( 'token' );
    const history = useHistory(); 

    useEffect( () => {
        
        if ( token ) {
            

            verifyEmployerToken();
        } else {
            util.toast().error( "Invalid or missing token." );
            history.push( "/login" );
        }
    }, [token] ); 

    const verifyEmployerToken = async () => {

        try {
            const response = await util.axios.put( `/employer_verification_first/new`, { token } );
            
            const { error, msg } = response.data;  
    
            if ( error ) {
                
                throw new Error( msg );
            }
    
            
            toast.success( msg );
            setIsVerifying( false );
    
            setTimeout( () => history.push( "/" ), 4000 );
    
        } catch ( error ) {
            util.handleError( error );
            history.push( "/" );
        }
    };
    
    
    

    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#f9fafc",
                color: "#333",
                fontFamily: "'Roboto', sans-serif",
                textAlign: "center",
            }}
        >
            <CircularProgress
                style={{ color: "#007bff", marginBottom: "1.5rem" }}
                size={50}
            />
            <h1
                style={{
                    fontSize: "1.8rem",
                    fontWeight: "600",
                    margin: "0 1rem",
                }}
            >
                {isVerifying
                    ? "We're verifying your account..."
                    : "Redirecting you to the login page..."}
            </h1>
            {isVerifying && (
                <p
                    style={{
                        fontSize: "1rem",
                        color: "#555",
                        marginTop: "1rem",
                        maxWidth: "500px",
                        lineHeight: "1.5",
                    }}
                >
                This process may take a few moments. Please wait while we confirm
                your account details.
                </p>
            )}
        </div>
    );
};

export default EmployerVerification;
