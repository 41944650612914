import React, { useEffect, useReducer, useState } from 'react';
import { Modal } from 'react-bootstrap';
import util from '../../../utils/miniUtils';
import { CircularProgress } from '@material-ui/core';

const formReducer = ( state, action ) => {
    switch ( action.type ) {
        case 'UPDATE_STATE':
            return { ...action.payload };
        case 'SET_FIELD':
            return { ...state, [action.field]: action.value };
        case 'RESET_FORM':
            return { name: "", department: "", description: "" };
        default:
            return state;
    }
};

const initialState = {
    name: "",
    gl_department: "",
    description: ""
};

const CreateEditServiceCatalogModal = ( { isEdit, show, handleClose, scId = null } ) => {
    const [departmentList, setDepartmentList] = useState( [] );
    const [loading, setLoading] = useState( false );
    const [state, dispatch] = useReducer( formReducer, initialState );

    const handleChange = ( e ) => {
        const { name, value } = e.target;
        dispatch( { type: 'SET_FIELD', field: name, value } );
    };

    const getDepartmentList = async () => {
        try {
            setLoading( true );
            const request = await util.axios.get( "/department/simple-list" );
            const { data, error, msg } = request.data;
            if ( error ) throw msg;
            setDepartmentList( data );
        } catch ( error ) {
            util.handleError( error );
        } finally {
            setLoading( false );
        }
    };

    const getServiceCatalogDetails = async ( id ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/workload_service_catalog/${id}` );
            const { data, error, msg } = request.data;
            if( error ) throw msg;
            dispatch( { type: 'UPDATE_STATE', payload: data } );
        } catch ( error ) {
            util.handleError( error );
        } finally {
            setLoading( false );
        }
    };

    const saveCatalog = async ( data ) => {
        try {
            setLoading( true );
            const request = await util.axios.post( "/workload_service_catalog", data );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            dispatch( { type: 'RESET_FORM' } );
            util.toast().success( msg );
            handleClose();
        } catch ( error ) {
            util.handleError( error );
        } finally {
            setLoading( false );
        }
    };

    const updateCatalog = async ( data ) => {
        try {
            setLoading( true );
            const request = await util.axios.patch( `/workload_service_catalog/${scId}`, data );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            dispatch( { type: 'RESET_FORM' } );
            util.toast().success( msg );
            handleClose();
        } catch ( error ) {
            util.handleError( error );
        } finally {
            setLoading( false );
        }
    };

    const handleSubmitButton = () => {
        const payload = { ...state };
        if ( isEdit ) {
            updateCatalog( payload );
        } else {
            saveCatalog( { ...payload, created_by: util.user.id, workloads: [] } );
        }
    };

    useEffect( () => {
        if ( show ) {
            getDepartmentList();
            if( isEdit && scId ) {
                getServiceCatalogDetails( scId );
            }
        }
    }, [show] );

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? "Edit Workload Service Catalog" : "Create Workload Service Catalog"}</Modal.Title>
            </Modal.Header>
            {loading ? (
                <Modal.Body>
                    <div className="d-flex justify-content-center align-items-center">
                        <CircularProgress />
                    </div>
                </Modal.Body>
            ) : (
                <Modal.Body>
                    <div className='form-group'>
                        <label htmlFor='catalogDepartment'>Department</label>
                        <select
                            className='form-control'
                            id="catalogDepartment"
                            name="gl_department"
                            value={state.gl_department}
                            onChange={handleChange}
                        >
                            <option value=""></option>
                            {departmentList.map( el => (
                                <option key={el.id} value={el.id}>{el.itemName}</option>
                            ) )}
                        </select>
                    </div>

                    <div className='form-group'>
                        <label htmlFor='catalogTitle'>Title</label>
                        <input
                            className='form-control'
                            id="catalogTitle"
                            name="name"
                            value={state.name}
                            onChange={handleChange}
                        />
                    </div>

                    <div className='form-group'>
                        <label htmlFor='catalogDescription'>Description</label>
                        <input
                            className='form-control'
                            id="catalogDescription"
                            name="description"
                            value={state.description}
                            onChange={handleChange}
                        />
                    </div>
                </Modal.Body>
            )}
            <Modal.Footer>
                <button className='btn btn-secondary sizeBtn' onClick={handleClose}>Cancel</button>
                <button className='btn btn-secondary sizeBtn activeBtn' onClick={handleSubmitButton}>Save</button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateEditServiceCatalogModal;
