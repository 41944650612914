import React, { useEffect, useState } from "react";
import { ClientTalentStatusChart } from "./ChartList";
import util from "../../utils/miniUtils";
import { useDispatch } from "react-redux";
import { setFilter } from "../../../redux/actions/datefilter";
const ClientTalentStatus  = () =>{
    const [statusList, setStatusList] = useState( [] );
    const [selectedStatus, setSelectedStatus] = useState( 4 );
    let dispatch = useDispatch();
    const statusToShow = ['Accepted', 'Submitted' ,'Onboarded'];
    const getStatusList = async () => {
        try{
            const request = await util.axios.get( 'job_talent_status/list' );
            const { data, error, msg } = request.data;
            if( error ) throw msg;    

            let d = [];   
            for( let i=0;i<data.length;i++ ){
                for( let char of statusToShow ){
                    if( char === data[i].name ){
                        d.push( data[i] );
                    }
                }
            }
            
            setStatusList( d );
        } catch ( e ) {
            util.handleError( e );
        }
    };
    useEffect( () =>{
        getStatusList();
    },[] );
    const handleSelectChange = ( e ) => {
        
        setSelectedStatus( e.target.value );
        dispatch( setFilter( true ) );
    };
    return (
        <>
            <div>
                <div className="d-flex justify-content-around bg-white">
                    <div>
                    Choose Status:
                    </div>
                    
                    <select onChange={handleSelectChange}>
                        {
                            statusList.map( ( item,index ) => {
                                return ( <option value={item.jts_id} key={index}>{item.name}</option> );
                            } )
                        }
                    </select>
                </div>
                 
                
            
                <ClientTalentStatusChart selectedStatus={selectedStatus}/>
            </div>
        </>
    );
};

export default ClientTalentStatus;