import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import util from "../../utils/miniUtils";
import countryList from '../../utils/location/country.json';

const CreationModal = ( { show, handleClose, actionType = 1, data = {}, settings, reloadData, id } ) => {
    const { title, getEmployeeList, createPath, getDetailPath, updatePath } = settings;
    const [employeeList, setEmployeeList] = useState( [] );
    const [item, setItemInformation] = useState( data );
    const [companyList, setCompanyList] = useState( [] );

    const handleChange = ( e ) => setItemInformation( { ...item, [e.target.name]: e.target.value } );

    const getCustomerCompanyList = async () => {
        try {
            const request = await util.axios.get( `/customer_companies?all=true&status=Active` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setCompanyList( data );
        } catch ( error ) {
            util.handleError( error );
        }       
    };

    const getEmployeesList = async () => {
        try {
            const request = await util.axios.get( getEmployeeList );
            const { msg, error, data } = request.data;
            if( error ) throw( msg );
            setEmployeeList( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const getItemDetail = async () => {
        try {
            const request = await util.axios.get( getDetailPath + "/" + id );
            const { msg, error, data } = request.data;
            if( error ) throw( msg );
            setItemInformation( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const saveInformation = async () => {
        try {
            const request = await util.axios.post( createPath, item );
            const { msg, error } = request.data;
            if( error ) throw( msg );
            util.toast().success( msg );
            reloadData();
        } catch ( error ) {
            util.handleError( error );
        }
        handleClose();
    };

    const updateInformation = async () => {
        try {
            const request = await util.axios.put( updatePath + "/" + id, item );
            const { msg, error } = request.data;
            if( error ) throw( msg );
            util.toast().success( msg );
            reloadData();
        } catch ( error ) {
            util.handleError( error );
        }
        handleClose();
    };

    // const getRegionList = () => {
    //     let regionList = {};
    //     countryList.forEach( item => {
    //         if( regionList[item.region] ) {
    //             regionList[item.region].push( item.name );
    //         } else {
    //             regionList[item.region] = [item.name];
    //         }
    //     } );
    //     return Object.keys( regionList );
    // };

    const triggerSaveUpdate = () => {
        if ( actionType === 1 ) {
            return saveInformation();
        }
        return updateInformation();
    };

    useEffect( () => {
        getEmployeesList();
        getCustomerCompanyList();
        if ( actionType === 2 ) {
            getItemDetail( id );
        }
    }, [] );

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {actionType === 1 ? "Create new " : "Update "} {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mt-2">
                    <label className="form-label">Title</label>
                    <input className="form-control" name="name" value={item.name} onChange={handleChange} />
                </div>

                {title === "Groups" && <div className="mt-2 d-flex justify-content-between">
                    <div>
                        <label className="form-label">Country</label>
                        <select className="form-control" onChange={handleChange} value={item.country} name={"country"}>
                            <option></option>
                            <option value="Global">Global</option>
                            {countryList.map( ( country ) => (
                                <option key={country.code3} value={country.name}>
                                    {country.name}
                                </option>
                            ) )}
                        </select>
                    </div>

                    <div>
                        <label className="form-label">Region</label>
                        <select className="form-control" onChange={handleChange} value={item.region} name={"region"}>
                            <option></option>
                            <option value="Global">Global</option>
                            {util.regionList.map( ( item, index ) => (
                                <option key={item + index} value={item}>
                                    {item}
                                </option>
                            ) )}
                        </select>
                    </div>
                </div>}

                <div className="mt-2">
                    <label className="form-label">Description</label>
                    <input className="form-control" name="description" onChange={handleChange} value={item.description} />
                </div>
                <div className="mt-2">
                    <label className="form-label">{title + " Lead"}</label>
                    <select className="form-control" onChange={handleChange} value={item.lead} name={"lead"}>
                        <option></option>
                        {employeeList.map( ( emp ) => (
                            <option key={emp.employee_id} value={emp.employee_id}>
                                {emp.name}
                            </option>
                        ) )}
                    </select>
                </div>
                {title === "Groups" && <div className="mt-2">
                    <label className="form-label">{title + " Customer Company"}</label>
                    <select className="form-control" onChange={handleChange} value={item.customer_company_id} name={"customer_company_id"}>
                        <option></option>
                        {companyList.map( ( company ) => (
                            <option key={company.customer_company_id} value={company.customer_company_id}>
                                {company.customer_name}
                            </option>
                        ) )}
                    </select>
                </div>}
                <div className="d-flex justify-content-end m-2">
                    <button className="btn btn-sm btn-primary" onClick={triggerSaveUpdate}>
						Save
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CreationModal;
