import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import util from '../../utils/miniUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import AccountListModal from './AccountListModal';
import { MuiThemeProvider } from '@material-ui/core';

const MemberList = ( { selectedVendor } ) => {
    const [memberList, setMemberList] = useState( [] );
    const [showTalentTable, setShowTalentTable] = useState( false );
    const [selectedMember, setSelectedMember] = useState( [] );

    const handleCloseTalentTable = () => {
        setShowTalentTable( false );
        getVendorMembers( selectedVendor );
    };

    const getVendorMembers = async ( id ) => {
        try {
            const request = await util.axios.get( `/vendor/members/${id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setMemberList( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const deleteMember = async () => {
        try {
            const request = await util.axios.put( `/vendor/member`, {
                members: selectedMember.map( el => ( { 
                    vendor_id: el.vendor_id, 
                    account_id: el.account_id  
                } ) )
            } );
            const { error, msg } = request.data;
            if( error ) throw msg;
            getVendorMembers( selectedVendor );
            util.toast().success( msg );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    useEffect( () => {
        getVendorMembers( selectedVendor.vendor_company_id );
    },[] );

    if( !selectedVendor ) {
        return "";
    }

    return (
        <>
            <div className="w-100 mt-2">
                {memberList.length > 0 ? <MuiThemeProvider theme={util.defaultTableThemeSlim}>
                    <MaterialTable
                        title="Members"
                        columns={[
                            { title: "ID", field: "id" },
                            { title: "Name", field: "name" },
                            { title: "Email", field: "email" },
                            { title: "Phone", field: "phone1" },
                        ]}
                        data={memberList}
                        options={{
                            searchFieldAlignment: "left",
                            search: true,
                            selection: true,
                            exportButton: false,
                            paging: true, 
                            sorting: true,
                            pageSizeOptions: [20, 50, 100], 
                            actionsColumnIndex: -1,
                            // maxBodyHeight: 'calc(100vh - 320px)',
                        }}
                        onSelectionChange={query => {
                            setSelectedMember( query );
                        }}
                        // actions={[
                        //     {
                        //         icon: () => <span><FontAwesomeIcon className='text-primary' icon={faPlusCircle} /></span>,
                        //         isFreeAction: true,
                        //         onClick: ( ) => setShowTalentTable( true ),
                        //     },
                        //     {
                        //         icon: () => <span><FontAwesomeIcon className='text-danger' icon={faTrash} /></span>,
                        //         isFreeAction: false,
                        //         onClick: ( ) => deleteMember(),
                        //     }
                        // ]}
                    />
                </MuiThemeProvider> : <div>
                    No Members available
                </div>}
            </div>
        </>
    );
};

export default MemberList;