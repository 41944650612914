import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import util from '../../../utils/miniUtils';

const ReasonModal = ({ show, handleCloseModal, reason, getDroppedReasons }) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (reason) {
            setTitle(reason.title || '');
            setDescription(reason.tooltip || '');
        } else {
            setTitle('');
            setDescription('');
        }
    }, [reason]);

    const handleSaveChanges = async () => {
        try {
            const value = { title, tooltip: description };
            const catalog = 'dropped_reasons';
            const isJSON = true;
            const endpoint = reason ? '/catalog/data/update' : '/catalog/data/create';
            const method = reason ? 'put' : 'post';
            const payload = reason ? { value, catalog, isJSON, id: reason.id } : { value, catalog, isJSON };

            const response = await util.axios[method](endpoint, payload);

            const { error, msg } = response.data;
            if (error) throw new Error(msg);

            util.toast().success(msg);
            getDroppedReasons();
            handleCloseModal();
        } catch (error) {
            util.handleError(error);
            // console.error(`Error ${reason ? 'updating' : 'creating'} reason: ${error.message}`);  
        }
    };

    return (
        <Modal show={show} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>{reason ? 'Edit' : 'Add'} Reason</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div className="form-group">
                        <label htmlFor="reasonTitle">Title</label>
                        <input
                            type="text"
                            className="form-control"
                            id="reasonTitle"
                            placeholder="Enter title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="reasonDescription">Description</label>
                        <input
                            type="text"
                            className="form-control"
                            id="reasonDescription"
                            placeholder="Enter description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSaveChanges}>
                    {reason ? "Edit Reason" : "Add Reason"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ReasonModal;
