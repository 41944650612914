import React, { useState, useEffect } from 'react';
import { Button, Form, Col, Row, Container, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faUsers } from '@fortawesome/free-solid-svg-icons';
import image from '../../../assets/images/star.webp';
import DrawerComponent from './customerGptAssistant';
import CustomerLogo from './customerLogo';
import util from "../../utils/miniUtils";
import CustomerMembersTable from "./customerMembersTable";


const CustomerDetail = ( { customer, onBackClick, onSave } ) => {
    // Set initial customer details state

    const [isDrawerOpen, setIsDrawerOpen] = useState( false );
    const [loading,setLoading]= useState( false );
    const [customerList,setCustomerList]=useState( [] );
    const [membersOfGroup,setMembersOfGroup]=useState( [] );
    const [groupsList,setGroupsList]=useState( [] );
    const [categoriesByGroup,setCategoriesByGroup]=useState( [] );
    const [showMembersTable, setShowMembersTable] = useState( false );

    // Other state variables and functions...

    // Toggle function to switch views
    const toggleMembersView = () => {
        setShowMembersTable( !showMembersTable );
    };
    

    const getAssignedMembersToGroup = async ( group_id ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/groups/get_assigned_members/${group_id}` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setMembersOfGroup( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };
    const getCategories = async ( group_id ) => {
        try{
            setLoading( true );
            const request = await util.axios.get( `/groups/get_categories/${group_id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setCategoriesByGroup( data.filter( el => el.active === 1 ) );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    const getInitialRequest = async ( ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/customer_ticket/workload/get_create_initial_request/` );
            const { error, msg, customerList, grpList } = request.data;
            if ( error ) throw msg;
            setLoading( false );
            setCustomerList( customerList );
            setGroupsList( grpList );
            // setDepartmentList( depList );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    };

    const toggleDrawer = () => {
        setIsDrawerOpen( !isDrawerOpen );
    };
    const [customerDetails, setCustomerDetails] = useState( customer || {
        customer_name: "",
        customer_HQ_Address: "",
        customer_Longitude: "",
        customer_Latitude: "",
        customer_domain: "",
        customer_Email: "",
        customer_Phone: "",
        customer_Industry: "",
        revenue_Size: "",
        employee_Count: "",
        customer_Founded_Year: "",
        customer_Description: "",
        customer_Status: "",
        country_Code: "",
        time_Zone: "",
        customer_Website: "",
        social_Media_Links: "",
        customer_group_id:"",
        customer_default_category:"",
        customer_default_resolver: ""
    } );

    const badgeTypes = [
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'danger',
        'warning',
        'danger',
        'success',
        'danger'
    ];

    const badgeTexts = [
        'Active',
        'Inactive',
        'Prospective',
        'Onboarding',
        'Suspended',
        'Closed',
        'At Risk',
        'Churned',
        'Renewed',
        'Blacklisted'
    ];
    
    const getBadgeText = ( number ) => {
        // Check if the input is "Active"
        const isActive = number?.toLowerCase() === 'active';
    
        // Filter the badgeTexts array based on the condition
        return badgeTexts.filter( text => {
            const lowercaseText = text.toLowerCase();
            const lowercaseNumber = number?.toLowerCase();
    
            // If the input is "Active", exclude "Inactive" from the result
            if ( isActive && lowercaseText.includes( 'inactive' ) ) {
                return false;
            }
    
            // Otherwise, include text that matches the input number
            return lowercaseText.includes( lowercaseNumber );
        } );
    };
    
   
   
    const getBadgeMarkup = ( badgeNumber ) => {
        // Determine the badge type based on the integer using modulus to cycle through array
        const index = badgeTexts.findIndex( text => text.toLowerCase().includes( badgeNumber?.toLowerCase() ) );

        const badgeType = badgeTypes[index];
      
        return (
            <div className="d-flex justify-content-end ">
                <span className={`badge badge-${badgeType}`}>
                    {getBadgeText( badgeNumber )}
                </span>
            </div>
        );
    };
      
    // Handle input changes
    const handleChange = ( e ) => {
        const { name, value } = e.target;
        setCustomerDetails( ( prevDetails ) => ( {
            ...prevDetails,
            [name]: value,
        } ) );
    };

    // Handle save button click
    const handleSave = () => {
        onSave( customerDetails );
       
    };
    useEffect( () => {
        getInitialRequest();
    }, [] );

    useEffect( ()=>{
        // Every time group id changes, need to retrieve new information for status and categories
        if ( customerDetails.customer_group_id != null ){
            
            getAssignedMembersToGroup( customerDetails.customer_group_id );
            getCategories( customerDetails.customer_group_id );

        }
    },[customerDetails.customer_group_id] );

    // Render form with react-bootstrap components
    return (
        <>
            <div className="d-flex align-items-center mb-3">
                <Button variant="link" onClick={onBackClick} className="d-flex align-items-center mr-2">
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" /> Back
                </Button>
                <Button className='btn activeBtn sizeBtn btn-secondary mr-2' onClick={toggleDrawer}>
        Train AI
                </Button>
                <Button className='btn activeBtn sizeBtn btn-secondary mr-2' onClick={toggleMembersView}>
                    {showMembersTable ? 'Info' : 'Members'}
                </Button>
            </div>
            <DrawerComponent open={isDrawerOpen} onClose={toggleDrawer} customer={customer}/>


            {showMembersTable ? (
                <CustomerMembersTable id={customer?.customer_company_id} />
            ) : (
                <><Row style={{ marginLeft: '3%', marginTop: '20px' }}>
                    <Col xs={6} className="logo-placeholder">
                        <CustomerLogo customerDetails={customerDetails} />
                    </Col>

                </Row><Container>
                    <Card className='ml-4'>
                        <Card.Header as="h5">
                            <div className="d-flex justify-content-between align-items-center">
                                <span>Customer Details</span>
                                <span>{getBadgeMarkup( customerDetails.customer_Status )}</span>
                            </div>
                        </Card.Header>
                        <Card.Body className='ml-4'>
                            <Form>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={6}>
                                                        Customer Name
                                            </Form.Label>
                                            <Col sm={10}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="ID"
                                                    name="customer_name"
                                                    value={customerDetails.customer_name}
                                                    onChange={handleChange} />
                                            </Col>
                                        </Form.Group>
                                        {/* Assignment Group */}
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={6}>
                                                        Assignment Group
                                            </Form.Label>
                                            <Col sm={10}>
                                                <Form.Control as="select"
                                                    value={customerDetails.customer_group_id ? customerDetails.customer_group_id : ""}
                                                    name="customer_group_id" onChange={handleChange}>
                                                    <option value=""> -- </option>
                                                    {groupsList.map( ( item ) => (
                                                        <option key={item.id} value={item.id}>
                                                            {item.itemName}
                                                        </option>
                                                    ) )}
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>

                                        {/* Category */}
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={6}>
                                                        Category
                                            </Form.Label>
                                            <Col sm={10}>
                                                <Form.Control as="select"
                                                    value={customerDetails.customer_default_category}
                                                    name="customer_default_category" onChange={handleChange}>
                                                    <option value=""> -- </option>
                                                    {categoriesByGroup.map( ( item ) => (
                                                        <option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    ) )}
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>

                                        {/* Resolver */}
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={6}>
                                                        Resolver
                                            </Form.Label>
                                            <Col sm={10}>
                                                <Form.Control as="select"
                                                    value={customerDetails.customer_default_resolver}
                                                    name="customer_default_resolver" onChange={handleChange}>
                                                    <option value=""> -- </option>
                                                    {membersOfGroup.map( ( item ) => (
                                                        <option key={item.employee_id} value={item.employee_id}>
                                                            {item.name}
                                                        </option>
                                                    ) )}
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={6}>
                                                        HQ Address
                                            </Form.Label>
                                            <Col sm={10}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="HQ Address"
                                                    name="customer_HQ_Address"
                                                    value={customerDetails.customer_HQ_Address}
                                                    onChange={handleChange} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={6}>
                                                        Email Domain
                                            </Form.Label>
                                            <Col sm={10}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Customer Email Domain"
                                                    name="customer_domain"
                                                    value={customerDetails.customer_domain}
                                                    onChange={handleChange} />
                                            </Col>
                                        </Form.Group>


                                        <Form.Group as={Row}>
                                            <Form.Label column sm={6}>
                                                        Customer Phone
                                            </Form.Label>
                                            <Col sm={10}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Customer Phone"
                                                    name="customer_Phone"
                                                    value={customerDetails.customer_Phone}
                                                    onChange={handleChange} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={6}>
                                                        Revenue Size
                                            </Form.Label>
                                            <Col sm={10}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Revenue Size"
                                                    name="revenue_Size"
                                                    value={customerDetails.revenue_Size}
                                                    onChange={handleChange} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={6}>
                                                        Customer Status
                                            </Form.Label>

                                            <Col sm={10}>
                                                <select
                                                    className="form-control"
                                                    style={{ width: '100%' }}
                                                    name="customer_Status"
                                                    value={customerDetails.customer_Status}
                                                    onChange={handleChange}
                                                >

                                                    <option value="Active">Active</option>
                                                    <option value="At Risk">At Risk</option>
                                                    <option value="Blacklisted">Blacklisted</option>
                                                    <option value="Churned">Churned</option>
                                                    <option value="Closed">Closed</option>
                                                    <option value="Inactive">InActive</option>
                                                    <option value="Onboarding">Onboarding</option>
                                                    <option value="Prospective">Prospective</option>
                                                    <option value="Renewed">Renewed</option>
                                                    <option value="Suspended">Suspended</option>
                                                </select>
                                            </Col>

                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={6}>
                                                        social_Media_Links
                                            </Form.Label>
                                            <Col sm={10}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="social_Media_Links"
                                                    name="social_Media_Links"
                                                    value={customerDetails.social_Media_Links}
                                                    onChange={handleChange} />
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>

                                        <Form.Group as={Row}>
                                            <Form.Label column sm={6}>
                                                        Customer Email
                                            </Form.Label>
                                            <Col sm={10}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Customer Email"
                                                    name="customer_Email"
                                                    value={customerDetails.customer_Email}
                                                    onChange={handleChange} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={6}>
                                                        Employee Count
                                            </Form.Label>
                                            <Col sm={10}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Employee Count"
                                                    name="employee_Count"
                                                    value={customerDetails.employee_Count}
                                                    onChange={handleChange} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={6}>
                                                        Customer Founded Year
                                            </Form.Label>
                                            <Col sm={10}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Customer Founded Year"
                                                    name="customer_Founded_Year"
                                                    value={customerDetails.customer_Founded_Year}
                                                    onChange={handleChange} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={6}>
                                                        Customer Website
                                            </Form.Label>
                                            <Col sm={10}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Customer Website"
                                                    name="customer_Website"
                                                    value={customerDetails.customer_Website}
                                                    onChange={handleChange} />
                                            </Col>
                                        </Form.Group>


                                        <Form.Group as={Row}>
                                            <Form.Label column sm={6}>
                                                        Customer Industry
                                            </Form.Label>
                                            <Col sm={10}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Customer Industry"
                                                    name="customer_Description"
                                                    value={customerDetails.customer_Description}
                                                    onChange={handleChange} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={6}>
                                                        Time Zone
                                            </Form.Label>
                                            <Col sm={10}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Time Zone"
                                                    name="time_Zone"
                                                    value={customerDetails.time_Zone}
                                                    onChange={handleChange} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row}>
                                            <Form.Label column sm={6}>
                                                        Country Code
                                            </Form.Label>
                                            <Col sm={10}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Country Code"
                                                    name="country_Code"
                                                    value={customerDetails.country_Code}
                                                    onChange={handleChange} />
                                            </Col>
                                        </Form.Group>

                                    </Col>
                                </Row>


                                <Form.Group as={Row}>
                                    <Col sm={{ span: 5, offset: 0.5 }}>
                                        <Button type="submit" className='mt-3' onClick={handleSave}>
                                                    Save Customer
                                        </Button>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container></>
            )}
        </>

    );
};

export default CustomerDetail;
