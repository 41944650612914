import React, { Fragment, useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import util from "../../../utils/miniUtils";
import ImageComponent from "../../../utils/ImageComponent";
import selector from "../../../utils/selector";
import { Editor } from "@tinymce/tinymce-react";
import "../../../../assets/css/main.css";
import SAlert from "../../../microComponents/AlertSA";
import { Facebook, Twitter, LinkedIn, LinkRounded } from "@material-ui/icons";
import ModalSelectPartnerClient from "./ModalSelectPartnerClient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faSave, faCopy } from "@fortawesome/free-solid-svg-icons";
import { Chip, Paper, TextField, Tooltip } from "@material-ui/core";
import LocationCX from "../../../shared/LocationCX";
import SkillSelector from "../../../shared/selector/SkillSelector";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ModalScreeningQuestions from "./ModalScreeningQuestions";
import useDropDownOptions from "../../../shared/jobs/useDropDownOptions";
const classLabelsMUI = "MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled";

function JobPositionForm( props ) {
    const [job, setJobData] = useState( props.job );
    const [backUpJob, setBackUp] = useState( job );
    const [isDisabled, setIsDisabled] = useState( props.job.status === 3 );
    const [marginShow, setMarginShow] = useState( false );
    const [modalVisible, setModalVisible] = useState( false );
    const [modalType, setModalType] = useState( false );
    const [marginLower, setMarginLower] = useState( false );
    const [isVisible, setIsVisible] = useState( true );
    const [recruiters, setRecruiters] = useState( [] );
    const [catalogJobDesc, setCatalogJobDesc] = useState( [] );
    const [disabledByOnboarding, setDisabledByOnboarding] = useState( false );
    const [skillsList, setSkillsList] = useState( [] );
    const [open, setOpen] = useState( false );
    const [showScreeningQuestions, setShowScreeningQuestions] = useState( false );
    const [vendors, setVendors] = useState( [] );
    const { getDroppedReasons, reasons } = useDropDownOptions();
    const [tooltipText, setTooltipText] = useState( "Copy Job Link" );

    // const [skills,setSkills] = useState( props.skills );
    const PARTNER = 4;
    const CLIENT = 3;



    const [showFullDescription, setShowFullDescription] = useState(false);
    useEffect(() => {
        getDroppedReasons();
    }, []);
    const getReasonText = (reasonIds) => {
        const reasonTexts = reasonIds.map(id => {
            const reason = reasons.find(r => r.id === parseInt(id));
            return reason ? reason.title : 'Reason not found';
        });
        return reasonTexts.join(', ');
    };
    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    useEffect(() => {
        // Listen when update is pressed from accordion
        if (props.updateChanges !== null) {
            handleUpdateJobPosition();
        }
        // eslint-disable-next-line
    }, [props.updateChanges]);

    useEffect(() => {
        // Listen when cancel is pressed from accordion
        if (props.cancelChanges !== null) {
            setJobData(backUpJob);
        }
        // eslint-disable-next-line
    }, [props.cancelChanges]);
    useEffect(() => {
        setJobData(props.job);
    }, [props.job]);
    useEffect(() => {
        // After the job is loaded, we need to update the UI of the deal type and hide partner if is direct client
        // This occurs only one time after load the job information
        if (job.deal_type === "Direct Client") {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    }, [job]);

    useEffect(() => {
        getRecruiters().then(null);
        getCatalog().then(null);
        getVendors();
    }, []);

    useEffect(() => {
        let disable = false;
        props.talents.forEach(d => {
            if (parseInt(d.status) === 13) {
                disable = true;
            }
        });
        setDisabledByOnboarding(disable);
    }, [props.talents]);

    useEffect(() => {
        // Refresh al fields with the data of the selected job from the sidebar
        setIsDisabled(props.job.status === 3);
        return () => { };
    }, [props.job.status]);

    async function getRecruiters() {
        try {
         
            if( !util.user.employer_id ){
                const request = await util.axios.get( `${util.url}/app_resources/users_by_role/1` );
                const { error, msg, users } = request.data;
                if ( error ) throw msg;
                setRecruiters( users );
            }else{
                const request = await util.axios.get(
                                       `${util.url}/employers_subordinates/get_members/${util.user.employer_id}`
                );
                const { error, data } = request.data;
                                 
                if ( error ) throw error;
              
                setRecruiters( data );
              
            }
        } catch ( error ) {
            util.handleError( error );
        }
    }

    const getVendors = async () => {
        try {
            const request = await util.axios.get(`/vendor/job/${job.id}`);
            const { data, error, msg } = request.data;
            if (error) throw msg;
            setVendors(data);
        } catch (error) {
            util.handleError(error);
        }
    };

    // const getListofSkills = async ( search ) => {
    //     try {
    //         search.replace( /\//g, "" );
    //         const encodedSearch = encodeURIComponent( search );
    //         const response = await util.axios.get( `catalog/catalog_skills_search/${encodedSearch}` );
    //         const { error, msg, data } = response.data;
    //         if ( error ) return util.handleError( msg );
    //         setSkillsList( data );
    //     } catch ( e ) {
    //         util.handleError( e );
    //     }
    // };
    const getCatalog = async () => {
        // Make a request for catalog of job descriptions
        try {
            const response = await util.axios.get(`${util.url}/app_resources/job_descriptions`);
            const { error, msg, job_descriptions } = response["data"];
            if (error) return util.handleError(msg);
            setCatalogJobDesc(job_descriptions);
        } catch (error) {
            util.handleError(error);
        }
    };

    // function handleChange( e ) {
    //     let { name, value } = e.target;
    //     if ( name === "deal_type" ){
    //         if ( value === "Direct Client" ){
    //             setIsVisible( false );
    //         }else{
    //             setIsVisible( true );
    //         }
    //     }
    //     setJobData( { ...job, [name]: value } );
    // }

    // function handleRichTextChange( e ) {
    //     const value = e.toString( "html" );
    //     setJobData( { ...job, 'job_description': value } );
    // }

    function handleUpdateJobPosition() {
        if (marginLower && job.margin < 20) {
            util.toast().warn("You're using a lower margin tan 20%");
            return;
        }
        util
            .axios
            .patch(`${util.url}/client_requirements/update/${job.id}`, job)
            .then(response => {
                const { error, msg, job } = response.data;
                if (error) throw (msg);
                props.handleChange(job);
                util.toast().success(msg);
                setBackUp(job);
            })
            .catch(err => util.toast().error(err));
    }

    function onConfirmLowerMargin() {
        setMarginLower(true);
    }

    // function selectAction( modalOption ){
    //     setModalType( modalOption );
    //     setModalVisible( true );
    // }

    function hideModal() {
        setModalType(PARTNER);
        setModalVisible(false);
    }

    // Copy link to clipboard
    // const copyToClipboard = () => {
    //     const url = window.document.location.origin + `/careers?swe=${util.uuidv4()}&job=${job.id}&fs=true`;
    //     navigator.clipboard.writeText( url ).then( null );
    //     setOpen( true );
    // };
    // Copy link to clipboard
    const copyToClipboard = () => {
        const url = window.document.location.origin + `/careers?swe=${util.uuidv4()}&job=${job.id}&fs=true`;

        navigator.clipboard.writeText( url )
            .then( () => {
                setTooltipText( "Job Link is Copied!" ); // Set tooltip to confirm copy
                setOpen( true );
                setTimeout( () => setTooltipText( "Copy Job Link" ), 2000 ); // Reset tooltip after 2 seconds
            } )
            .catch( () => {
                setTooltipText( "Failed to Copy" );
            } );
    };
    function selectedData(opc, data) {
        let jobTemp = job;
        if (opc === PARTNER) {
            jobTemp.partner = data;
        } else {
            jobTemp.client = data;
        }
        setJobData(Object.assign({}, jobTemp));
    }

    // function handleChangeAutoComplete( value ) {
    // // let e ={target:{name: "job_title", value: value}}
    // // handleChange(e);
    // // must trigger job description
    //     const j = catalogJobDesc.filter( ( j ) => j.title === value )[0];
    //     // let e2 ={target:{name: "job_description", value: j.description}}
    //     // handleChange(e2);
    //     setJobData( { ...job, job_title: value, job_description: j.description } );
    // }

    // function handleSkillUpdate( name,value ) {
    //     // setJobData( { ...job, skills: [value] } );
    //     if( name === "skills" ) {
    //         setJobData( ( prevJob ) => ( { ...prevJob, skills: [...prevJob.skills, value] } ) );
    //     }
    //     else {
    //         setJobData( ( prev ) => ( { ...prev, skills: [...value] } ) );
    //     }
    // }

    const RowItem = ( { label, value, person = false } ) => (
        <div className="row mb-2">
            <span className="col-xl-4 col-lg-7 col-sm-5 Jobclient"><span>{label}:</span></span>
            {person ? <span className="col-xl-5 col-lg-5 col-sm-5 Jobclient">{recruiters?.find(el => el.id == value)?.name}</span> : <span className="col-xl-5 col-lg-5 col-sm-5 Jobclient">{value}</span>}
        </div>
    );
    return (
        <Fragment>

            <div elevation={3} className="bg-white p-4 container-class">

                <div className='row mt-2'>
                    <ul className="col-md-3">
                        <div>
                            {util.user.role !== 15 ? (<span
                                className={`btn btn-sm btn-secondary activeBtn ${!job.questions && 'inactiveBtn'}`}
                                onClick={() => setShowScreeningQuestions(true)}>
                                {job.questions ? "Screening Questions" : "Add Screening Questions"}
                            </span>) : null}
                        </div>
                        {job.client_requirement_id ? <li style={{ listStyle: 'none' }}>
                            <span className="text-danger">Client Reference:</span>
                            <span className="ml-2">{job.client_requirement_id}</span>
                        </li> : ""}
                        <li style={{ listStyle: 'none', marginTop: '5px' }}><Tooltip title={tooltipText} onClose={() => setTooltipText( "Copy Job Link" )}>

                            <span className="likeLink" onClick={copyToClipboard}>
                                <LinkRounded size={32} icon={faCopy} /><span className="pl-1">Link</span>
                            </span>
                        </Tooltip>
                        </li>
                        <li style={{ listStyle: 'none', marginTop: '5px' }}><Tooltip title="Job Role">
                            <div className="Jobclient"><span><img className='jobReadOnlyImg' src="/images/briefcase.webp" alt="job role" /></span><span className="pl-1">{job.role}</span>
                            </div></Tooltip>
                        </li>
                        <li style={{ listStyle: 'none', marginTop: '5px' }}><Tooltip title="Location">
                            <div className="Jobclient"><span><img className='jobReadOnlyImg' src="/images/map.webp" alt="location" /></span><span className="pl-1"> {util.location(job.country, job.state, job.city)}</span></div>
                        </Tooltip></li>
                        {/* <li style={{ listStyle: 'none', marginTop: '5px' }}><Tooltip title="Primary Skills">
                            <div className="Jobclient"><span><img className='jobReadOnlyImg' src="/images/certificate.webp" alt="primary skills" /></span><span className="pl-1">{job.certification}</span></div>
                        </Tooltip></li> */}
                        <li style={{ listStyle: 'none', marginTop: '5px' }}><Tooltip title="Salary">
                            <div className="Jobclient"><span><img className='jobReadOnlyImg' src="/images/salary.webp" alt="salary" /></span>
                                <span className="pl-1">{util.buyFromBuyTo(job.buy_from, job.buy_to, job.country)} {job.sell_rate_type}</span>
                            </div>
                        </Tooltip>
                        </li>
                        <li style={{ listStyle: 'none', marginTop: '5px' }}><Tooltip title="Education">
                            <div className="Jobclient"><span><img className='jobReadOnlyImg' src="/images/open-book.webp" alt="education" /></span><span className="ml-1">{job.education}</span></div>
                        </Tooltip></li>
                        <li style={{ listStyle: 'none', marginTop: '5px' }}><Tooltip title="Experience Level">
                            <div className="Jobclient"><span><img className='jobReadOnlyImg' src="/images/improve.webp" alt="experience level" /></span><span className="ml-1">{job.level}</span></div>
                        </Tooltip></li>
                        <li style={{ listStyle: 'none', marginTop: '5px' }}><Tooltip title="Scope">
                            <div className="Jobclient"><span><img className='jobReadOnlyImg' src="/images/target.webp" alt="scope" /></span><span className="ml-1">{job.scope}</span></div>
                        </Tooltip></li>

                        <li style={{ listStyle: 'none', marginTop: '5px' }}>
                            {job.level ? <span className="jobsCardView-chips">{job.level}</span> : ""}
                            {job.role ? <span className="jobsCardView-chips">{job.role}</span> : ""}
                            {/* {job.skills ? <span className="jobsCardView-chips">{job.skills}</span> : ""}
                        {job.secondary_skill ? <span className="jobsCardView-chips">{job.secondary_skill}</span> : ""} */}

                        </li>


                    </ul>
                    <div className="col-md-9">
                        <div>
                            <div>
                                {util.user.role !== 15 && !util.user.employer_id &&<strong>Client Information</strong>}
                                <div className='jobReadOnly'>
                                    {util.user.role !== 15 && !util.user.employer_id && <div className="col-md-8">
                                        <RowItem label="Deal Type" value={job.deal_type} />
                                        <RowItem label="Restricted To" value={job.restricted_to} />
                                        <div className="row mt-2">
                                            <span className="col-xl-4 col-lg-8 col-sm-5 Jobclient"><span>Partner Information:</span></span>
                                            <div className="col-xl-8 col-lg-12 col-sm-7 Jobclient">
                                                <div>{job.partner?.company}</div>
                                                <div>{job.partner?.name}
                                                    <a className="text-primary ml-1" href={`mailto:${job.partner?.email}`}>{job.partner?.email}</a>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="row mt-2">
                                            <span className="col-xl-4 col-lg-8 col-sm-5 Jobclient"><span>End Client Information:</span></span>
                                            <div className="col-xl-8 col-lg-12 col-sm-5 Jobclient">
                                                <div>{job.client?.company}</div>
                                                <div>{job.client?.name}
                                                    <a className="text-primary ml-1" href={`mailto:${job.client?.email}`}>{job.client?.email}</a>
                                                </div>

                                            </div>
                                        </div>

                                    </div>}
                                    <div className="col-md-8">
                                        <RowItem label="Priority" value={job.priority} />
                                        <RowItem label="Employment Type" value={job.employment_type} />
                                        {job.employment_type !== "Fulltime" ? <RowItem label="Duration in Months" value={job.duration} /> : ""}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div>
                            {util.user.role !== 15 && (<div className="mt-3">
                                <strong>Onboarding Contacts</strong>
                                <div className='jobReadOnly'>
                                    {!util.user.employer_id &&  <div className="col-md-8">
                                        {job.reporting_manager ? <RowItem label="Reporting Manager" value={job.reporting_manager} /> : ""}
                                        {job.sales_lead ? <RowItem label="Sales Lead" value={job.sales_lead} person={true} /> : ""}
                                        {job.reporting_manager_phone ? <RowItem label="Reporting Manager Phone" value={job.reporting_manager_phone} /> : ""}
                                    </div> }
                                    <div className="col-md-8">
                                        {job.lead_recruiter ? <RowItem label="Lead Recruiter" value={job.lead_recruiter} person={true} /> : ""}
                                        {job.reporting_manager_address ? <RowItem label="Reporting Manager Address" value={job.reporting_manager_address} /> : ""}
                                        {job.secondary_recruiter ? <RowItem label="Secondary Recruiter" value={job.secondary_recruiter} person={true} /> : ""}
                                    </div>
                                </div>
                            </div>)}
                            {/* {job.questions && <div>
                                <span className="btn btn-sm btn-light" onClick={() => setShowScreeningQuestions( true )}>Screening Questions</span>
                            </div>} */}

                            {vendors.length > 0 && util.user.role !== 15 ? <div className="mt-3">
                                <strong>Vendors</strong>
                                <div>{vendors.map(el => <div className="badge ml-1 mb-1" key={el.vendor_company_id}>{el.vendor_name}</div>)}</div>
                            </div> : ""}

                        </div>
                    </div>
                    <ul>
                        {job.skills.length > 0 ? <li style={{ listStyle: 'none', marginTop: '5px' }} >
                            <strong className="mt-1 ml-3 ">Skills:</strong>
                            <div className="row ml-4">
                                {job.skills && job.skills.length > 0 ? job.skills.map(el => <div size="small" key={el.id} value={el.name} className="jobsCardView-chips mt-1 mr-1 mb-1  "> {el.name} </div>) : ''}
                            </div>
                        </li> : ""}
                    </ul>
                    <ul>
                        {job.certification.length > 0 ? <li style={{ listStyle: 'none', marginTop: '5px' }} >
                            <strong className="mt-1 ml-3 ">Certifications:</strong>
                            <div className="row ml-4">
                                {job.certification && job.certification.length > 0 ? job.certification.map(el => <div size="small" key={el.id} value={el.name} className="jobsCardView-chips mt-1 mr-1 mb-1  "> {el.name} </div>) : ''}
                            </div>
                        </li> : ""}
                    </ul>
                </div>
                <div>
                    {job.status === 6 ? <p><strong className="ml-2">Dropped Reason:</strong><span className="jobsCardView-chips mt-1 mr-1 mb-1  ">  {getReasonText(job.dropped_reasons.split(','))}</span></p> : ""}
                </div>

                <div className="m-2">
                    <strong className="border-bottom mb-3">Job Description</strong>
                    <div
                        dangerouslySetInnerHTML={{ __html: showFullDescription ? job.job_description : job.job_description.substring(0, 200) }}
                        className="jobsReadOnly-desc"></div>
                    {job.job_description.length > 200 && (
                        <a onClick={toggleDescription} className="text-primary iscursor">
                            {showFullDescription ? 'Read less' : 'Read more'}
                        </a>
                    )}
                </div>
            </div>


            {marginShow ?
                <SAlert
                    show={marginShow}
                    confirmText="Yes"
                    typeButton="warning"
                    msg={"Do you want to use a lower margin in this Job?"}
                    hideAlert={setMarginShow(false)}
                    onConfirm={onConfirmLowerMargin}
                    opc={0}
                /> : ""}

            <ModalSelectPartnerClient
                selectedData={selectedData}
                hideModal={hideModal}
                modalVisible={modalVisible}
                modalType={modalType}
            />
            {showScreeningQuestions && <ModalScreeningQuestions
                show={showScreeningQuestions}
                hideModal={() => setShowScreeningQuestions(false)}
                questions={job.questions}
                job={job}
                reloadJob={props.refresh}
            />}

        </Fragment>
    );
}

export default JobPositionForm;
