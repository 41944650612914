import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, ListGroup, Spinner } from 'react-bootstrap';
import util from "../../../utils/miniUtils";

const RoleModal = ( { show, onClose, role, onSave, isEditing } ) => {
    // State to manage role details
    const [roleName, setRoleName] = useState( '' );
    const [roleDescription, setRoleDescription] = useState( '' );
    const [rolePermissions, setRolePermissions] = useState( [] );
    const [isLoading, setIsLoading] = useState( false ); // New state for loading

    const fetchPermissions = async ( id ) => {
        setIsLoading( true ); // Start loading
        try {
            const request = await util.axios.get( `/role_permissions/get_role_permissions/${id}` );
            const { data } = request.data;
            if ( request.data.error ) throw request.data.msg;
            setRolePermissions( data );
        } catch ( error ) {
            util.handleError( error );
        } finally {
            setIsLoading( false ); // Stop loading regardless of outcome
        }
    };

    // Load permissions when the modal is opened or when the role changes
    useEffect( () => {
        if ( show ) { // Only trigger the loading when the modal is shown
            if ( isEditing && role ) {
                setRoleName( role.name );
                setRoleDescription( role.description );
                fetchPermissions( role.role_id );
            } else {
                setRoleName( '' );
                setRoleDescription( '' );
                fetchPermissions( null );
            }
        }
    }, [show, role, isEditing] );

    // Handle role save
    const handleSave = () => {
        const data = {
            name: roleName,
            description: roleDescription,
            permissions: rolePermissions.filter( p => p.checked ), // Only send checked permissions
        };
        onSave( data, isEditing ? role.role_id : null );
    };

    // Toggle permission checkbox
    const togglePermission = ( permissionId ) => {
        setRolePermissions( prevState =>
            prevState.map( p =>
                p.permission_id === permissionId ? { ...p, checked: !p.checked } : p
            )
        );
    };

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{isEditing ? 'Edit Role' : 'Create Role'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading ? ( // Check if it's loading
                    <div className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : ( // If not loading, show the form
                    <Form>
                        {/* Name field */}
                        <Form.Group as={Row}>
                            <Form.Label column sm={4}>Name</Form.Label>
                            <Col sm={8}>
                                <Form.Control
                                    type="text"
                                    value={roleName}
                                    onChange={( e ) => setRoleName( e.target.value )}
                                />
                            </Col>
                        </Form.Group>

                        {/* Description field */}
                        <Form.Group as={Row}>
                            <Form.Label column sm={4}>Description</Form.Label>
                            <Col sm={8}>
                                <Form.Control
                                    type="text"
                                    value={roleDescription}
                                    onChange={( e ) => setRoleDescription( e.target.value )}
                                />
                            </Col>
                        </Form.Group>

                        <hr />

                        {/* Permissions list */}
                        <ListGroup variant="flush" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                            {rolePermissions && rolePermissions.map( ( permission ) => (
                                <ListGroup.Item key={permission.permission_id}>
                                    <Form.Check
                                        type="checkbox"
                                        label={`${permission.name} (${permission.module} - ${permission.action})`}
                                        checked={permission.checked}
                                        onChange={() => togglePermission( permission.permission_id )}
                                    />
                                </ListGroup.Item>
                            ) )}
                        </ListGroup>
                    </Form>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>Close</Button>
                <Button variant="primary" onClick={handleSave} disabled={isLoading}>Save Changes</Button> {/* Disable button when loading */}
            </Modal.Footer>
        </Modal>
    );
};

export default RoleModal;

