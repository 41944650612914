import React, { useEffect, useState } from "react";
import ScreeningQuestionnaire from "./ScreeningQuestionnaire";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import TestsContainer from "../../shared/profile_component/test_talent/TestsContainer";
import { TabContent } from "react-bootstrap";
import util from "../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";

export default function BasicTabs( { pcData } ) {
    const [state, setState] = useState( [] );

    const getActionLogs = async ( candidate_id, position_id ) => {
        try {
            const request = await util.axios.get( `/position_candidate_action/${candidate_id}/${position_id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setState( data );
        } catch( error ) {
            util.handleError( error );
        }
    };

    useEffect( () => {
        getActionLogs( pcData.candidate_id, pcData.position_id );
    },[] );

    return (
        <Tabs
            defaultActiveKey={pcData.response ? 
                "screening-questions" : 
                state.filter( el => el.type == "email" ).length > 0 ? 
                    "email" : 
                    state.filter( el => el.type == "schedule" ).length > 0 ? "schedule" : "assignTest"}
            id="justify-tab-example"
        >
            {pcData.response && <Tab eventKey="screening-questions" title="Screening Questions">
                <ScreeningQuestionnaire sqData={pcData.response} />
            </Tab>}
            { state.filter( el => el.type == "email" ).length > 0 && <Tab eventKey="email" title="Email">
                <TabContent  style={{ minHeight: "100px", padding: "3px" }}>
                    <table className="table table-sm table-bordered">
                        <thead className="bg-secondary text-white">
                            <tr>
                                <th>Id</th>
                                <th>Subject</th>
                                <th>cc</th>
                                <th>Attachment</th>
                                <th style={{ maxWidth: "400px" }}>Email</th>
                                <th>Created on</th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.filter( el => el.type =="email" ).map( el => <tr key={el.id}>
                                <td>{el.id}</td>
                                <td>{el.title}</td>
                                <td>{el.cc}</td>
                                <td>{el.attachment ? <a
                                    target={"_blank"}
                                    rel="noopener noreferrer"
                                    href={util.resumeUrl( { candidate_id: el.candidate_id, resume: el.attachment } )}
                                >
                                    <FontAwesomeIcon
                                        icon={faPaperclip}
                                        className="mr-2"
                                    />
                                </a> : ""}</td>
                                <td  style={{ maxWidth: "400px" }}><div dangerouslySetInnerHTML={{ __html: el.value }} /></td>
                                <td>{el.createdAt}</td>
                            </tr> )}
                        </tbody>
                    </table>
                </TabContent>
            </Tab>}
            { state.filter( el => el.type == "schedule" ).length > 0 && <Tab eventKey="schedule" title="Schedules">
                <TabContent style={{ minHeight: "100px", padding: "3px" }}>
                    <table className="table table-sm table-bordered">
                        <thead className="bg-secondary text-white">
                            <tr>
                                <th>Id</th>
                                <th>Title</th>
                                <th>cc</th>
                                <th>Starts at</th>
                                <th>Ends at</th>
                                <th>Location</th>
                                <th style={{ maxWidth: "400px" }}>Summary</th>
                                <th>Created on</th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.filter( el => el.type =="schedule" ).map( el => <tr key={el.id}>
                                <td>{el.id}</td>
                                <td>{el.title}</td>
                                <td>{el.cc}</td>
                                <td>{el.starts_at}</td>
                                <td>{el.ends_at}</td>
                                <td>{el.location}</td>
                                <td  style={{ maxWidth: "400px" }}><div dangerouslySetInnerHTML={{ __html: el.value }} /></td>
                                <td>{el.createdAt}</td>
                            </tr> )}
                        </tbody>
                    </table>
                </TabContent>
            </Tab>}
           {!util.user.employer_id && <Tab eventKey="assignTest" title="Assigned Tests">
                <TabContent style={{ minHeight: "100px" }}>
                    <TestsContainer talent_id={pcData.candidate_id} isEditing={false}/>
                </TabContent>
            </Tab> }
            {/* <Tab eventKey="docUpload" title="Documents" disabled>
                Documents Log
            </Tab> */}
        </Tabs>
    );
}
