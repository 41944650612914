import React from 'react';
import util from '../../../utils/miniUtils';
import ImageComponent from "../../../utils/ImageComponent";


const CollaborationSingle = ( { data, deleteCollectionSingle } )=> {

    const { desc, createdAt, status } = data;
    const { name, candidate_id, profile_picture } = data.account;


    
    return(
        <div className="col mt-1 mb-2">	
            { util.user.candidate_id === Number( candidate_id ) ? 
                ( <div className="row d-flex align-items-center">
                    <div className='col-md-2 d-flex align-items-center text-center'>
                        <small className='text-secondary'>{util.humanDateTime( createdAt ).split( "at" ).join( "-" )}</small>
                    </div>
                    <div className="collab-desc col-md-8">
                        <div className="py-1 px-2">
                            {status === 0 ? (
                                <span style={{ fontStyle: 'italic' }}>deleted</span> // Display "deleted" in italic if status is 0
                            ) : (
                                <span>{desc}</span> // Otherwise, display the original description
                            )}
                        </div>
                        <span className='btn collab-deleteButton' onClick={() => deleteCollectionSingle( data.id )}>x</span> {/* Updated to call delete function */}
                    </div>
                    <div className="col-md-2 d-flex flex-column text-center align-items-center">
                        <img className="rounded-circle mb-1" width={50} height={50} src="/images/avatar.webp" alt="emy" />
                        {/* {
                            profile_picture ?
                                <img className="rounded-circle mb-1" width={50} height={50} src={`${util.url}/candidate/${candidate_id}/${profile_picture}`} alt="emy" />
                                :<img className="rounded-circle mb-1" width={50} height={50} src="/images/avatar.webp" alt="emy" />
                        } */}
                    
                        <small>{name}</small>
                    </div>
                </div>
                ) : (
                    <div className="row d-flex align-items-center">
                        <div className="col-md-2 d-flex flex-column text-center align-items-center">
                            <img className="rounded-circle mb-1" width={50} height={50} src="/images/avatar.webp" alt="emy" />
                            {/* {
                                profile_picture ?
                                    <img className="rounded-circle mb-1" width={50} height={50} src={`${util.url}/candidate/${candidate_id}/${profile_picture}`} alt="emy" />
                                    :<img className="rounded-circle mb-1" width={50} height={50} src="/images/avatar.webp" alt="emy" />
                            } */}
                    
                            <small>{name}</small>
                        </div>
                        <div className="collab-desc col-md-8">
                            <div className="py-1 px-2">
                                {status === 0 ? (
                                    <span style={{ fontStyle: 'italic' }}>deleted</span> // Display "deleted" in italic if status is 0
                                ) : (
                                    <span>{desc}</span> // Otherwise, display the original description
                                )}
                            </div>
                            <span className='btn collab-deleteButton' onClick={() => deleteCollectionSingle( data.id )}>x</span> {/* Updated to call delete function */}
                        </div>
                        <div className='col-md-2 d-flex align-items-center text-center'>
                            <small className='text-secondary'>{util.humanDateTime( createdAt ).split( "at" ).join( "-" )}</small>
                        </div>
                    </div> )
            }
        </div>
    );
};

export default CollaborationSingle;