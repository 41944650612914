import React from 'react';
/***
 * Decide what section is going to show: By Sell Rate or By Margin
 *
 */
export default function SectionSelector( { mode, selectSection } ) {
    return (
        <div>
            
            <div className="d-flex align-content-center align-items-center">
                <b className="m-2 text-size-19">Calculate By:</b>
                <label className="m-2">
                    <input type="radio" value="1" checked={mode === 1} onChange={()=>null} onClick={() => selectSection( 1 )} name="mode" /> Sell rate
                </label>
                <label className="m-2">
                    <input type="radio" value="2" checked={mode === 2} onChange={()=>null} onClick={() => selectSection( 2 )} name="mode" /> By Margin
                </label>
            </div>
        </div>
    );
}
