import React, { useState } from 'react';
import VendorList from './VendorLIst';
import VendorForm from './VendorForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import MemberList from './MemberList';
import { Paper } from '@material-ui/core';
import VendorDetails from './VendorDetails';


const Vendor = () => {
    const [showForm, setShowForm] = useState( false );
    const [selectedVendor, setSelectedVendor] = useState( null );
    const handleClose = () => {
        setShowForm( false );
        setSelectedVendor( null );
    };
    const onBackClick = () => {
        setShowForm( false );    
    };

    const handleNewVendor = () => {
        setSelectedVendor( null );
        setShowForm( true );
    };

    const handleVendorClick = ( value ) => {
        setSelectedVendor( value );
        setShowForm( true );
    };

    return (
        <>
            <div className="d-flex mt-3">
                {!showForm ? <button className="btn btn-primary" onClick={handleNewVendor}>
                    <FontAwesomeIcon icon={faPlus} />
                    <span className="ml-1">Add Vendor</span>
                </button> : ""}
            </div>
            {!showForm ? <VendorList handleVendorClick={handleVendorClick} /> : 
                <VendorDetails onBackClick={onBackClick} selectedVendor={selectedVendor} />}
            {/* {showForm && <Paper className="vendorDetails-section" style={{ height: "500px" }}>
                <VendorForm handleClose={handleClose} selectedVendor={selectedVendor} handleVenderSelect={handleVenderSelect} />
                <MemberList selectedVendor={selectedVendor} />
            </Paper>} */}
        </>
    );
};

export default Vendor;