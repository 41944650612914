import React, { useEffect, useState } from 'react';
import FieldValue from "./components/FieldValue";
import Input from "./components/input";
import SelectorCTC from "./components/SelectorCTC";
import useCtcCalculation from "./useCtcCalculation";
import { OFFER_TYPE, TALENT_ENGAGEMENT_TYPE } from "./components/constants";
import BySellRate from "./components/BySellRate";
import ByMargin from "./components/ByMargin";
import JobSettings from "./components/JobSettings";
import SaveChangesButton from "./components/SaveChangesButton";
import { Drawer } from "@material-ui/core";
import SectionSelector from './components/sectionSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import util from '../../../../utils/miniUtils';
import { CtcComments } from './components/CtcComments';

export default function CtcDrawer({ showDrawer, closeDrawer, talent, jobTemp, reloadData }) {

    const { ctc, onChange, saveComments, onChangeSlider, currentJobSettings, showSaveButton, saveChangesOnMarginEstimate, selectSection } = useCtcCalculation(talent, jobTemp, reloadData);
    const { markUp, billableDaysInYear, workingHoursInDay, averageBillableDaysInMonth } = currentJobSettings(reloadData);
    const [member, setMember] = useState({});

    const getVendorMemberDetails = async () => {
        try {

            const request = await util.axios.get(
                `${util.url}/vendor/member/${talent.createdBy}`,
            );
            const { error, msg, data } = request.data;
            setMember(data[0]);
            if (error) throw msg;

        } catch (error) {

            util.handleError(error);
        }
    };
    useEffect(() => {
        if (talent.uploaded_vendor_id) {
            getVendorMemberDetails();
        }
    }, []);

    return (
        <Drawer anchor="right" open={showDrawer} onClose={closeDrawer}>
            <div style={{ maxWidth: window.innerWidth * .30, minWidth: window.innerWidth * .30 }}>
                <div className="drawerTitle_JP" >
                    <h4>{talent?.id} - {talent?.name}</h4>
                </div>
                <div>
                    <div className="container text-size-14">
                        {talent.uploaded_vendor_id && util.user.role !== 15 && (<div className='pt-1 pb-1'>
                            <div className="row d-flex align-content-center align-items-center">
                                <div className="col-md-6"><b>Vendor Company Name</b></div>
                                <div className="col-md-6"><b className="text-left">{member.vendor_name}</b>
                                </div>
                            </div>
                            <hr className="m-1" />
                            <div className="row d-flex align-content-center align-items-center">
                                <div className="col-md-6"><b>Vendor Name</b></div>
                                <div className="col-md-6"><b className="text-left">{member.name}</b>
                                </div>
                            </div>
                            <hr className="m-1" />
                            <div className="row d-flex align-content-center align-items-center">
                                <div className="col-md-6"><b>Vendor Email</b></div>
                                <div className="col-md-6"><b className="text-left"><a href={`mailto:${talent.email}`}>{member.email}</a></b>
                                </div>
                            </div>
                            <hr className="m-1" />
                            <div className="row d-flex align-content-center align-items-center">
                                <div className="col-md-6"><b>Vendor Phone</b></div>
                                <div className="col-md-6"><b className="text-left">{member.phone}</b>
                                </div>
                            </div>
                            <hr className="m-1" />
                        </div>)}
                        <div className="d-flex justify-content-between pt-1 pb-1">
                            {util.user.role !== 15 && (<b className="text-size-19">Margin Estimate</b>)}
                            <SaveChangesButton saveChangesOnMarginEstimate={saveChangesOnMarginEstimate} showSaveButton={showSaveButton} />
                        </div>

                        <SelectorCTC isVendor={talent.uploaded_vendor_id} typeSelector={TALENT_ENGAGEMENT_TYPE} title="Talent Engagement Type" name="employmentType" value={util.user.role === 15 ? "Corp to Corp" : ctc.employmentType} onChange={onChange} />
                        {
                            ctc.employmentType === 'Fulltime Employee' ?
                                <Input isCurrency={true} isVendor={talent.uploaded_vendor_id} job={jobTemp} title={(util.user.role === 15 || talent.uploaded_vendor_id) ? "Vendor Rate Per Year" : "eJ Offer Per Year"} name="ejOffer" value={ctc.ejOffer} onChange={onChange} />
                                :
                                <Input isCurrency={true} isVendor={talent.uploaded_vendor_id} job={jobTemp} title={(util.user.role === 15 || talent.uploaded_vendor_id) ? 'Vendor Rate' : "eJ Offer"} name="ejOffer" value={ctc.ejOffer} onChange={onChange} />
                        }
                        {ctc.employmentType !== "Fulltime Employee" ? <SelectorCTC isVendor={talent.uploaded_vendor_id} typeSelector={OFFER_TYPE} title={(util.user.role === 15 || talent.uploaded_vendor_id) ? "Vendor Rate Type" : "Offer Type"} name="offerType" value={ctc.offerType} onChange={onChange} /> : null}

                        {util.user.role !== 15 && (<Input isVendor={talent.uploaded_vendor_id} isCurrency={true} job={jobTemp}
                            title="Talent Expectation" name="talentExpectation" value={ctc.talentExpectation} onChange={onChange} />)}
                        <FieldValue country={jobTemp.country} name="Cost To Company Per Hour" value={ctc.ctcPerHour.toFixed(2)} />
                        <FieldValue country={jobTemp.country} name="Cost To Company Per Day" value={ctc.ctcPerDay.toFixed(2)} />
                        <FieldValue country={jobTemp.country} name="Cost To Company Per Month" value={ctc.ctcPerMonth.toFixed(2)} />
                        <FieldValue country={jobTemp.country} name="Cost To Company Per Annum" value={ctc.ctcPerAnnum.toFixed(2)} />
                        <br />
                        {/* <b className="text-size-19">Job Settings</b> */}
                        {util.user.role !== 15 && (<>
                            <div className="mt-1" />
                            <JobSettings onChange={onChange} ctc={ctc} markUp={markUp} additionalDays={ctc.additionalDays} billableDaysInYear={billableDaysInYear} workingHoursInDay={workingHoursInDay} averageBillableDaysInMonth={averageBillableDaysInMonth} job={jobTemp} />
                            <hr />

                            <SectionSelector mode={ctc.mode} selectSection={selectSection} />
                            {
                                ctc.mode === 1 ?
                                    <BySellRate job={jobTemp} ctc={ctc} onChange={onChange} />
                                    :
                                    <ByMargin job={jobTemp} ctc={ctc} onChange={onChangeSlider} />
                            }

                        </>)}
                        <br />
                        <div>
                            <CtcComments ctc={ctc} saveComments={saveComments} />
                        </div>

                    </div>
                </div>
            </div>
        </Drawer>

    );
}