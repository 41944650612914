import React, { useEffect, useState } from 'react';
import util from '../../../utils/miniUtils';

const CatalogSQuestionForm = ( { reloadData,actionType = 1, editId,handleClose } ) => {

    const [questionTitle, setQuestionTitle] = useState( '' );
    const [questionType, setQuestionType] = useState( '1' );
    const [options, setOptions] = useState( ['',''] ); // For Multiple Choice and Single Choice

    const handleOptionChange = ( index, value ) => {
     
        const newOptions = [...options];
        newOptions[index] = value;
        setOptions( newOptions );
    };

    const addOption = () => {
       
        if( questionType == '2' && options.length > 1 ){
            util.toast().error( 'Only two options are required' );
            return;
        }
        setOptions( [...options, ''] );
    };

    const removeOption = ( index ) => {
        const newOptions = [...options];
        newOptions.splice( index, 1 );
        setOptions( newOptions );
    };

    const getItemDetail = async () => {
        let type='job_sq';
        try {
            const request = await util.axios.get( `${util.url}/get_catalogs_list/itemDetials/${type}/${editId}` );
            const { msg, error, data } = request.data;
            if( error ) throw( msg );
            setQuestionTitle( data[0][0].question );
            setQuestionType( data[0][0].type );
            if( data[0][0].type == '1' ){
                options[0]=data[0][0].option_1;
                options[1]=( data[0][0].option_2 );
                options[3]=data[0][0].option_3;
                options[4]=( data[0][0].option_4 );
            }
            if( data[0][0].type == '2' ){
                options[0]=data[0][0].option_1;
                options[1]=( data[0][0].option_2 );
              
            }

        } catch ( error ) {
            util.handleError( error );
        }
    };

    const saveInformation = async() => {
    // Handle saving the question details (title, type, options) to your backend or state
        
        const data ={
            question:questionTitle,
            type:questionType,
            options,
        };
       
        try {
            const request = await util.axios.post( `${util.url}/addCatalogsSQ`, { data } );
            const { msg, error } = request.data;
            if( error ) throw( msg );
            util.toast().success( msg );
            reloadData( 'job_sq' );
          
        } catch ( error ) {
            util.handleError( error );
        }
        // Reset the form after saving
       
        handleClose();
       
    };

    const updateInformation = async() => {
        // Handle saving the question details (title, type, options) to your backend or state
            
        const data ={
            id:editId,
            question:questionTitle,
            type:questionType,
            options,
        };
        
        try {
            const request = await util.axios.patch( `${util.url}/editCatalogsSQ`, { data } );
            const { msg, error } = request.data;
            if( error ) throw( msg );
            util.toast().success( msg );
            reloadData( 'job_sq' );
        } catch ( error ) {
            util.handleError( error );
        }
        // Reset the form after saving
       
        handleClose();
    };

    const triggerSaveUpdate = () => {
      
        if ( actionType === 1 ) {
            return saveInformation();
        }
        return updateInformation();
    };

    useEffect( () => {
      
        if ( actionType === 2 ) {
            getItemDetail( editId );
          
        }
    }, [] );


    return (
        <div className="container mt-4">
            <div className='mb-3' >
                <div className='mb-3'>
                    <label className="form-label">
               Title:
                        <textarea
                            className="w-100 my-1 screening-inputbox" 
                            type="text"
                            placeholder='Question text'
                            value={questionTitle}
                            onChange={( e ) => setQuestionTitle( e.target.value )}
                        />
                    </label>
                </div>
                <div >
                    <label className="form-label">
               Type: 
                        <select 
                            className=" ml-3 form-select"
                            value={questionType}
                            onChange={( e ) =>{ setQuestionType( e.target.value );
                                setQuestionTitle( '' );}}
                        >
                            <option value='1'>Multiple Choice</option>
                            <option value='2'>Single Choice</option>
                            <option value='3'>Numeric</option>
                            <option value='4'>Custom Question</option>
                        </select>
                    </label>
                </div>
            </div>
  
            {questionType == '1' || questionType == '2' ? (
                <div>
                    <label className="form-label">Options:</label>
                    {options.map( ( option, index ) => (
                        <div key={index} className="input-group mb-3">
                            <input
                                type="text"
                                className="mx-1 screening-option"
                                placeholder='Option text'
                                value={option}
                                onChange={( e ) => handleOptionChange( index, e.target.value )}
                            />
                            <button
                                type="button"
                                className='m-1 btn bg-light'
                                onClick={() => removeOption( index )}
                            >
              X
                            </button>
                        </div>
                    ) )}
                    <button
                        className="border bg-primary text-light px-2 py-1"
                        onClick={() => addOption( )}>
            + Option
                    </button>
                </div>
            ) : null}
            <div className="d-flex justify-content-end m-2">
                <button type="button" className="btn btn-primary mt-3 " onClick={triggerSaveUpdate}>
          Save
                </button></div>
        </div>
    );
};

export default CatalogSQuestionForm;
