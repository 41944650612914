import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import util from '../../utils/miniUtils';


const WorkflowForm = ( { workflow = {}, show=false, category, handleClose, isNew=true, createWorkflow, updateWorkflow, groupList } ) => {
    const [state, setState] = useState( {
        name: workflow.name || "",
        wl_category_id: category,
        approver1: workflow.approver1 || "",
        approver2: workflow.approver2 || "",
        approver3: workflow.approver3 || null,
        approvals_required: workflow.approvals_required || ""
    } );

    const handleChange = ( e ) => {
        const { name, value } = e.target;
        if( name === 'name' ){
            setState( ( prev ) => ( {
                ...prev,
                [name]: value
            } ) );
        } else{
            setState( ( prev ) => ( {
                ...prev,
                [name]: Number( value )
            } ) );
        }
    };

    const handleSave = () => {
        const { error, msg } = validateForm( state );
        if( error ) {
            util.toast().error( msg );
            return;
        }
        if( isNew ) {
            createWorkflow( state );
        } else {
            updateWorkflow( workflow.id, state );
        }
        handleClose();
        setState( {
            name: "",
            wl_category_id: "",
            approver1: "",
            approver2: "",
            approver3: "",
            approvals_required: ""
        } );
    };

    const validateForm = ( form ) => {
        let errors = [];
    
        if ( !form.name ) {
            errors.push( "Name is required." );
        }
        if ( !form.approvals_required ) {
            errors.push( "The number of approvals required must be specified." );
        }

        if( form.approvals_required < 3 ) {
            if ( !form.approver1 || !form.approver2 ) {
                errors.push( "All three approvers (Approver 1, Approver 2) must be selected." );
            } else if ( new Set( [form.approver1, form.approver2] ).size !== 2 ) {
                // If all approvers are selected, check if they are different
                errors.push( "Approvers (Approver 1, Approver 2) must be different." );
            }
        }

        if( form.approvals_required > 2 ) {
            if ( !form.approver1 || !form.approver2 || !form.approver3 ) {
                errors.push( "All three approvers (Approver 1, Approver 2, Approver 3) must be selected." );
            } else if ( new Set( [form.approver1, form.approver2, form.approver3] ).size !== 3 ) {
                // If all approvers are selected, check if they are different
                errors.push( "Approvers (Approver 1, Approver 2, Approver 3) must be different." );
            }
        }

        
        return {
            error: errors.length > 0,
            msg: errors.join( " " )
        };
    };
    

    return (
        <Modal show={show} onHide={handleClose}  backdrop="static">
            <Modal.Header closeButton>
                <div className="d-flex justify-content-center">
                    <b>{isNew ? "Add Workflow" : "Edit Workflow"}</b>
                </div>
            </Modal.Header>
            <Modal.Body>
                <label htmlFor="workflowName">Name</label>
                <input id="workflowName" className="form-control"
                    value={state.name} name="name" onChange={handleChange}/>

                <label htmlFor="approvalRequired">Number of Approval Required</label>
                <select id="approvalRequired" className="form-control"
                    name="approvals_required" value={state.approvals_required} onChange={handleChange}>
                    <option value=""></option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                </select>

                <label htmlFor="approver1">Approver 1</label>
                <select id="approver1" className="form-control"
                    name="approver1" value={state.approver1} onChange={handleChange}>
                    <option value=""></option>
                    {groupList.map( el => <option key={"approver1" + el.id} value={el.id}>{el.itemName}</option> )}
                </select>

                <label htmlFor="approver2">Approver 2</label>
                <select id="approver2" className="form-control"
                    name="approver2" value={state.approver2} onChange={handleChange}>
                    <option value=""></option>
                    {groupList.map( el => <option key={"approver2" + el.id} value={el.id}>{el.itemName}</option> )}
                </select>

                {state.approvals_required > 2 ? <>
                    <label htmlFor="approver3">Approver 3</label>
                    <select id="approver3" className="form-control"
                        name="approver3" value={state.approver3} onChange={handleChange}>
                        <option value=""></option>
                        {groupList.map( el => <option key={"approver3" + el.id} value={el.id}>{el.itemName}</option> )}
                    </select>
                </> : ""}
            </Modal.Body>
            <Modal.Footer>
                <span className="btn btn-outline-danger" onClick={handleClose}>
                    <FontAwesomeIcon
                        icon={faTimes}
                    />
                </span>
                <span className="btn btn-outline-primary" onClick={() => handleSave( state )}>
                    <FontAwesomeIcon
                        icon={faCheck}
                    />
                </span>
            </Modal.Footer>
        </Modal>
    );
};

export default WorkflowForm;