import React from 'react';
import { Modal, Form } from 'react-bootstrap';

const IncreaseLimitsModal = ( { show, handleClose, employer, newJobLimit, setNewJobLimit, newEmployeeLimit, setNewEmployeeLimit, handleModalSave } ) => {
    const handleJobLimitChange = ( e ) => {
        const value = e.target.value;
        if ( value >= employer.job_limit ) {
            setNewJobLimit( value );
        }
    };

    const handleEmployeeLimitChange = ( e ) => {
        const value = e.target.value;
        if ( value >= employer.employee_limit ) {
            setNewEmployeeLimit( value );
        }
    };

    // Function to check if the Save Changes button should be displayed
    const shouldDisplaySaveButton = () => {
        return (
            newEmployeeLimit > employer.employee_limit ||
            newJobLimit > employer.job_limit
        );
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Increase Account Limits</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formJobLimit">
                        <Form.Label>Job Limit</Form.Label>
                        <Form.Control
                            type="number"
                            value={newJobLimit}
                            onChange={handleJobLimitChange}
                            placeholder="Enter new job limit"
                            min={employer.job_limit}
                        />
                        <small>Current Job Limit: {employer.job_limit}</small>
                    </Form.Group>
                    <Form.Group controlId="formEmployeeLimit">
                        <Form.Label>Employee Limit</Form.Label>
                        <Form.Control
                            type="number"
                            value={newEmployeeLimit}
                            onChange={handleEmployeeLimitChange}
                            placeholder="Enter new employee limit"
                            min={employer.job_limit}

                        />
                        <small>Current Employee Limit: {employer.employee_limit}</small>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={handleClose}>
                    Close
                </button>
                {shouldDisplaySaveButton() && ( // Conditionally render the Save Changes button
                    <button className="btn btn-primary" onClick={handleModalSave}>
                        Save Changes
                    </button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default IncreaseLimitsModal;