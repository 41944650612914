import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Form, Col, Row, Container, Card } from 'react-bootstrap';
import util from '../../utils/miniUtils';
import MemberList from './MemberList';
import CountryFillOne from '../EmployerSettings/CountryFillOne';


const EmployerDetails = ( { onBackClick,selectedEmployer } ) => {
    const industries = [
        "Agriculture",
        "Apparel & Fashion",
        "Automotive",
        "Aviation & Aerospace",
        "Banking & Financial Services",
        "Biotechnology",
        "Chemicals",
        "Construction",
        "Consumer Electronics",
        "Consumer Goods",
        "Defense & Space",
        "Education",
        "Electrical & Electronics",
        "Energy & Utilities",
        "Engineering Services",
        "Entertainment",
        "Environmental Services",
        "Food & Beverages",
        "Furniture",
        "Gaming",
        "Government",
        "Healthcare & Pharmaceuticals",
        "Hospitality",
        "Human Resources & Staffing",
        "Information Technology (IT)",
        "Insurance",
        "Investment & Private Equity",
        "Legal Services",
        "Logistics & Transportation",
        "Luxury Goods",
        "Manufacturing",
        "Marine",
        "Media & Publishing",
        "Mining",
        "Nonprofit & NGOs",
        "Oil & Gas",
        "Packaging",
        "Professional Services",
        "Public Safety",
        "Real Estate",
        "Renewable Energy",
        "Retail & E-commerce",
        "Security & Investigations",
        "Software Development",
        "Sports & Recreation",
        "Telecommunications",
        "Textiles & Apparel",
        "Travel & Tourism",
        "Veterinary Services",
        "Wholesale Trade",
    ];
    const isReadOnly = util.user.role === 18;
    const [showMembersTable, setShowMembersTable] = useState( false );
    const [employerDetails, setEmployerDetails] = useState( {} );
    // const [groupsList, setGroupsList] = useState( [] );
    const [loading, setLoading] = useState( false );
    const [attachmentFiles, setAttachmentFiles] = useState( [] );
    // const [categories, setCategories] = useState( [] );

    const handleChange = ( e ) => {
        const { name, value } = e.target;
        setEmployerDetails( prev => ( { ...prev, [name]: value } ) );
    };

    const toggleMembersView = () => {
        setShowMembersTable( !showMembersTable );
    };

    const handleSave = ( e ) => {
        e.preventDefault();
        if( employerDetails.employer_id ) {
            updateEmployer( employerDetails.employer_id, employerDetails );
        } else {
            addNewEmployer( employerDetails );
        }
    };

    // const badgeTypes = [
    //     'primary',
    //     'secondary',
    //     'info',
    //     'success',
    //     'warning',
    //     'danger',
    //     'warning',
    //     'danger',
    //     'success',
    //     'danger'
    // ];

    // const badgeTexts = [
    //     'Active',
    //     'Inactive',
    //     'Prospective',
    //     'Onboarding',
    //     'Suspended',
    //     'Closed',
    //     'At Risk',
    //     'Churned',
    //     'Renewed',
    //     'Blacklisted'
    // ];

    // const getBadgeText = ( number ) => {
    //     // Check if the input is "Active"
    //     const isActive = number?.toLowerCase() === 'active';
    
    //     // Filter the badgeTexts array based on the condition
    //     return badgeTexts.filter( text => {
    //         const lowercaseText = text.toLowerCase();
    //         const lowercaseNumber = number?.toLowerCase();
    
    //         // If the input is "Active", exclude "Inactive" from the result
    //         if ( isActive && lowercaseText.includes( 'inactive' ) ) {
    //             return false;
    //         }
    
    //         // Otherwise, include text that matches the input number
    //         return lowercaseText.includes( lowercaseNumber );
    //     } );
    // };

    // const getBadgeMarkup = ( badgeNumber ) => {
    //     // Determine the badge type based on the integer using modulus to cycle through array
    //     const index = badgeTexts.findIndex( text => text.toLowerCase().includes( badgeNumber?.toLowerCase() ) );

    //     const badgeType = badgeTypes[index];
      
    //     return (
    //         <div className="d-flex justify-content-end ">
    //             <span className={`badge badge-${badgeType}`}>
    //                 {getBadgeText( badgeNumber )}
    //             </span>
    //         </div>
    //     );
    // };
    // API Requests
    

    const getEmployerDetails = async ( id ) => {
        try {
            const request = await util.axios.get( `/employers/${id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setEmployerDetails( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    // const getCategories = async ( group_id ) => {
    //     try{
    //         setLoading( true );
    //         const request = await util.axios.get( `/groups/get_categories/${group_id}` );
    //         const { error, msg, data } = request.data;
    //         if( error ) throw msg;
    //         setCategories( data.filter( el => el.active === 1 ) );
    //     } catch ( error ) {
    //         util.handleError( error );
    //     }
    //     setLoading( false );
    // };

    const addNewEmployer = async ( employer ) => {
        try {
            const request = await util.axios.post( "/employers", { ...employer } );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const updateEmployer = async ( id, employer ) => {
        try {
            const request = await util.axios.put( `/employers/${id}`, { ...employer } );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    useEffect( () => {
     
        if( selectedEmployer && selectedEmployer.employer_id ) {
            getEmployerDetails( selectedEmployer.employer_id );
        }
    },[] );

    if( loading ) {
        return util.LOADING_SCREEN( loading );
    }

    return (
        <>
            <div className="d-flex align-items-center mb-3">
                <Button variant="link" onClick={onBackClick} className="d-flex align-items-center mr-2">
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" /> Back
                </Button>
                
                {!selectedEmployer ? "" : <Button className='btn activeBtn sizeBtn btn-secondary mr-2' onClick={toggleMembersView}>
                    {showMembersTable ? 'Info' : 'Members'}
                </Button>}
            </div>
            {showMembersTable ? <MemberList selectedEmployer={selectedEmployer} /> 
                : <div>
                    <Row style={{ marginLeft: '3%', marginTop: '20px' }}>
                        <Col xs={6} className="logo-placeholder">
                        </Col>

                    </Row>
                    <Container className="mt-3">
                        <Card>
                            <Card.Body>
                                <Card.Header className="text-center mb-4">Company Details</Card.Header>
                                <Form>
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group controlId="formCompanyName">
                                                <Form.Label>Company Legal Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="company_legal_name"
                                                    value={employerDetails.company_legal_name || ""}
                                                    placeholder="Enter your organization's name (e.g. ABC Corp.)"
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formEmployerEmail">
                                                <Form.Label>Employer Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    name="employer_email"
                                                    value={employerDetails.employer_email || ""}
                                                    placeholder="Enter your official company email (e.g. info@abc.com)"
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    {/* <Row className="mb-3"> */}
                                        {/* <Col md={6}>
                                            <Form.Group controlId="formCountryCode">
                                                <Form.Label>Country</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="country_code"
                                                    value={employerDetails.country || ""}
                                                    placeholder="Enter your company’s country code"
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formEmployerPhone">
                                                <Form.Label>Employer Phone</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="employer_phone"
                                                    value={employerDetails.employer_phone || ""}
                                                    placeholder="+1 123 456 7890"
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col> */}
                                        {/* <CountryFillOne
                                            newState={employerDetails}
                                            onChangeHandler={handleChange}
                                            isReadOnly={isReadOnly}
                                            attachmentFiles={attachmentFiles}
                                            setAttachmentFiles={setAttachmentFiles}
                                        />
                                    </Row> */}

                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group controlId="formCompanyWebsite">
                                                <Form.Label>Employer Website</Form.Label>
                                                <Form.Control
                                                    type="url"
                                                    name="employer_website"
                                                    value={employerDetails.employer_website || ""}
                                                    placeholder="https://www.yourcompany.com"
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formHQAddress">
                                                <Form.Label>HQ Address</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="address"
                                                    value={employerDetails.address || ""}
                                                    placeholder="Enter the main office address (e.g., 123 Main St)"
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group controlId="formYearFounded">
                                                <Form.Label>Year Founded</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="year_founded"
                                                    value={employerDetails.year_founded || ""}
                                                    placeholder="Enter the year your company was established"
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formEmailDomain">
                                                <Form.Label>Email Domain</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="email_domain"
                                                    value={employerDetails.email_domain || ""}
                                                    placeholder="Enter your company’s email domain (e.g., abc.co)"
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group controlId="formStatus">
                                                <Form.Label>Status</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="status"
                                                    value={employerDetails.status || ""}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Select Status</option>
                                                    <option value="Active">Active</option>
                                                    <option value="Inactive">Inactive</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formEmployeeCount">
                                                <Form.Label>Employee Count</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="employee_count"
                                                    value={employerDetails.employee_count || ""}
                                                    placeholder="Total number of employees"
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group controlId="formSocialMediaLinks">
                                                <Form.Label>Social Media Links</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="social_media"
                                                    value={employerDetails.social_media || ""}
                                                    placeholder="Add links to your social media profiles (e.g., LinkedIn)"
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formIndustry">
                                                <Form.Label>Industry</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="industry"
                                                    value={employerDetails.industry || ""}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Select Industry</option>
                                                    {industries.map( ( industry, index ) => (
                                                        <option key={index} value={industry}>
                                                            {industry}
                                                        </option>
                                                    ) )}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group controlId="formMaxJobLimit">
                                                <Form.Label>Maximum Job Limit</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="job_limit"
                                                    value={employerDetails.job_limit || ""}
                                                    placeholder="Max number of jobs this employee can post"
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formAvailableJobsToPost">
                                                <Form.Label>Available Jobs To Post</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="job_count"
                                                    value={employerDetails.job_count ||0}
                                                    placeholder="Available Jobs to Post"
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        
                                        <CountryFillOne
                                            newState={employerDetails}
                                            onChangeHandler={handleChange}
                                            isReadOnly={isReadOnly}
                                            attachmentFiles={attachmentFiles}
                                            setAttachmentFiles={setAttachmentFiles}
                                        />
                                    </Row>

                                    <div className="text-right">
                                        <Button
                                            type="button"
                                            className="btn btn-secondary activeBtn sizeBtn"
                                            onClick={handleSave}
                                        >
                        Save
                                        </Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>

                </div>}
        </>
    );
};

export default EmployerDetails;