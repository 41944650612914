import { faCaretDown, faCheck, faClock, faExclamationCircle, faLock, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import { Button } from 'react-bootstrap';
import Step6 from '../miniComponents/6_client_decision/Step6';
import RestartProcess from '../miniComponents/RestartProcess';
import Step1 from '../miniComponents/Step1';
import Step2 from '../miniComponents/Step2';
import Step3 from '../miniComponents/Step3';
import Step4 from '../miniComponents/Step4';
import Step5 from '../miniComponents/Step5';
import StopProcess from '../miniComponents/StopProcess';

export default function TimeLineCandidate( props ) {
    const c = props.data;
    const ids = [props.data.id];
    const candidateStatus = parseInt( c.status );
    const [candidate] = useState( props.data );
    const [firstContact] = useState( c["first_contact"] );
    const [internalReview] = useState( c["internal_review"] );
    const [clientInterviews] = useState( c["client_interviews"] );
    const [onBoardingDocs] = useState( c["onboarding_documents"] );
    const [backgroundCheck] = useState( c["background_check"] );
    const [finalDecision] = useState( c.final_decision );
    const [show, setShow] = useState( false );
    const [showRestart, setShowRestart] = useState( false );
    const [panel, setPanel] = useState( "panel1" );

    const LockedSection = ( { title } ) => {
        return (
            <p className="text-muted">
                <FontAwesomeIcon icon={faLock} /> {title} is not available
            </p>
        );
    };

    function hideModal() {
        setShow( false );
    }

    function hideModalRestart() {
        setShowRestart( false );
    }

    function changePanel( panelSelected ) {
        if ( panel === panelSelected ) {
            setPanel( "" );
        } else {
            setPanel( panelSelected );
        }
    }

    return (
        <Fragment>
            <div className="container-fluid mb-2">
                <div className="d-flex justify-content-between">
                    <h3 className="text-muted py-0">
                        <p className="p-1">Talent Process</p>
                        {candidate["status_note"] && candidateStatus === 19 ? (
                            <span className="text-danger">{candidate["status_note"]}</span>
                        ) : (
                            ""
                        )}
                    </h3>
                    {candidateStatus !== 13 ? ( // TODO pending to add resignation flow
                        candidateStatus === 19 ? (
                            <div className="text-right">
                                <Button
                                    onClick={() => setShowRestart( true )}
                                    size="sm"
                                    variant="success"
                                >
                                    <FontAwesomeIcon icon={faSyncAlt} className="mr-1" />
                                    Restart Process
                                </Button>
                            </div>
                        ) : (
                            <div className="text-right">
                                <Button
                                    onClick={() => setShow( true )}
                                    size="sm"
                                    variant="danger"
                                >
                                    <FontAwesomeIcon
                                        icon={faExclamationCircle}
                                        className="mr-1"
                                    />
                                    Stop Process
                                </Button>
                            </div>
                        )
                    ) : null}
                </div>
                {/*Validate what should be displayed according with status of the candidate*/}
                <Accordion
                    expanded={panel === "panel1"}
                    onChange={() => changePanel( "panel1" )}
                >
                    <AccordionSummary expandIcon={<FontAwesomeIcon icon={faCaretDown} />}>
                        <span className="mr-2">
                            {candidateStatus > 1 ? (
                                <FontAwesomeIcon className="text-success" icon={faCheck} />
                            ) : (
                                <FontAwesomeIcon icon={faClock} className="text-muted" />
                            )}
                        </span>
                        First Contact
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="container-fluid">
                            <Step1 data={firstContact} candidate={candidate} />
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    expanded={panel === "panel2"}
                    onChange={() => changePanel( "panel2" )}
                >
                    <AccordionSummary expandIcon={<FontAwesomeIcon icon={faCaretDown} />}>
                        <span className="mr-2">
                            {candidateStatus > 2 ? (
                                <FontAwesomeIcon className="text-success" icon={faCheck} />
                            ) : (
                                <FontAwesomeIcon icon={faClock} className="text-muted" />
                            )}
                        </span>
                        Internal Interview
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="container-fluid">
                            {candidateStatus > 1 ? (
                                <Step2 data={internalReview} candidate={candidate} />
                            ) : (
                                <LockedSection title="Internal review" />
                            )}
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    expanded={panel === "panel3"}
                    onChange={() => changePanel( "panel3" )}
                >
                    <AccordionSummary expandIcon={<FontAwesomeIcon icon={faCaretDown} />}>
                        <span className="mr-2">
                            {candidateStatus > 3 ? (
                                <FontAwesomeIcon className="text-success" icon={faCheck} />
                            ) : (
                                <FontAwesomeIcon icon={faClock} className="text-muted" />
                            )}
                        </span>
                        Client Evaluation
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="container-fluid">
                            {candidateStatus > 2 ? (
                                <Step3 data={clientInterviews} candidate={candidate} />
                            ) : (
                                <LockedSection title="Client Evaluation" />
                            )}
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    expanded={panel === "panel4"}
                    onChange={() => changePanel( "panel4" )}
                >
                    <AccordionSummary expandIcon={<FontAwesomeIcon icon={faCaretDown} />}>
                        <span className="mr-2">
                            {candidateStatus > 4 ? (
                                <FontAwesomeIcon className="text-success" icon={faCheck} />
                            ) : (
                                <FontAwesomeIcon icon={faClock} className="text-muted" />
                            )}
                        </span>
                        Onboarding Docs
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="container-fluid">
                            {candidateStatus > 3 ? (
                                <Step4 data={onBoardingDocs} candidate={candidate} />
                            ) : (
                                <LockedSection title="Onboarding documents" />
                            )}
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    expanded={panel === "panel5"}
                    onChange={() => changePanel( "panel5" )}
                >
                    <AccordionSummary expandIcon={<FontAwesomeIcon icon={faCaretDown} />}>
                        <span className="mr-2">
                            {candidateStatus > 5 ? (
                                <FontAwesomeIcon className="text-success" icon={faCheck} />
                            ) : (
                                <FontAwesomeIcon icon={faClock} className="text-muted" />
                            )}
                        </span>
                        Background Check
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="container-fluid">
                            {candidateStatus > 4 ? (
                                <Step5 data={backgroundCheck} candidate={candidate} />
                            ) : (
                                <LockedSection title="Background check" />
                            )}
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    expanded={panel === "panel6"}
                    onChange={() => changePanel( "panel6" )}
                >
                    <AccordionSummary expandIcon={<FontAwesomeIcon icon={faCaretDown} />}>
                        <span className="mr-2">
                            {candidateStatus > 9 ? (
                                <FontAwesomeIcon className="text-success" icon={faCheck} />
                            ) : (
                                <FontAwesomeIcon icon={faClock} className="text-muted" />
                            )}
                        </span>
                        Final Decision
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="container-fluid">
                            {candidateStatus > 5 ? (
                                <Step6 data={finalDecision} candidate={candidate} />
                            ) : (
                                <LockedSection title="Final decision" />
                            )}
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            {show ? (
                <StopProcess
                    modalVisible={show}
                    hideModal={hideModal}
                    ids={ids}
                    position_id={c.position_id}
                />
            ) : (
                ""
            )}
            {showRestart ? (
                <RestartProcess
                    modalVisible={showRestart}
                    hideModal={hideModalRestart}
                    ids={ids}
                    position_id={c.position_id}
                />
            ) : (
                ""
            )}
        </Fragment>
    );
}
