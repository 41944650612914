import { useState, useEffect } from "react";
import util from "../../../utils/miniUtils";
import axios from "axios";
import { bake_cookie } from "sfcookies";
import {
    RECRUITER_ROLE,
    TALENT_ROLE,
    CLIENT_ROLE,
    PARTNER_ROLE,
    RECRUITER_MANAGER,
    EMPLOYEE_ROLE,
    EMPLOYEE_MANAGER,
    EVALUATOR
} from "../../../../constants";

const useModalUsers = ( user, hideModal, tableRef ) => {
    // Primary state for the setting the user data in the Create User Modal
    let [modalUser, setModalUser] = useState( () => {
        let u = { ...user };
        if ( user.permissions === undefined ) {
            u.permissions = {
                account_id: 0,
                recruiter: 0,
                recruiter_manager: 0,
                evaluator: 0,
                employee_manager: 0,
                asset_manager: 0,
                talent: 0,
                employee: 0,
                client: 0,
                partner: 0,
            };
        }
        return u;
    } );

    let [isNew, setIsNew] = useState( false );
    let [loading, setLoading] = useState( false );
    let [needSkills, setNeedSkills] = useState( false );
    let [companies, setCompanies] = useState( [] );
    let [companyList, setCompanyList] = useState( [] );

    let [access, setAccess] = useState( false );

    let [skills, setSkills] = useState( [{
        skill_name: "",
        years_experience: 0,
        skill_type: 1
    }] );

    const [options, setOptions] = useState( [] );
    
    useEffect( () => {
        const fetchOptions = async () => {
            try {
                setLoading( true );
                const response = await util.axios.get( `/role_permissions/role_dropdown/${user.id}` );
                const { data } = response.data;
                setOptions( data );
            } catch ( error ) {
                console.error( 'Failed to fetch options', error );
            } finally {
                setLoading( false );
            }
        };

        fetchOptions();
    }, [user.id] );

    const toggleChecked = ( roleId, name ) => {
        setOptions( options.map( option => {
            if ( option.role_id === roleId ) {
                return { ...option, checked: !option.checked };
            }
            return option;
        } ) );
    };

    const handleSave = async () => {
        try {
            setLoading( true );
            const selectedRoleNames = options.filter( option => option.checked );
            const selectedRoles = options.filter( option => option.checked ).map( option => option.role_id );

            await util.axios.post( `/role_permissions/link_permissions/${user.id}`, { selectedRoles, selectedRoleNames } );

            // Add any additional logic after saving
        } catch ( error ) {
            console.error( 'Failed to save selections', error );
        } finally {
            setLoading( false );
        }
    };

    const sortedOptions = [...options].sort( ( a, b ) => b.checked - a.checked );

    useEffect( () => {
        let u = user;
        if ( user.id === 0 ) {
            if ( user.permissions === undefined ) {
                u.permissions = {
                    account_id: 0,
                    recruiter: 0,
                    recruiter_manager: 0,
                    evaluator: 0,
                    employee_manager: 0,
                    asset_manager: 0,
                    talent: 0,
                    employee: 0,
                    client: 0,
                    partner: 0,
                };
            }
            setIsNew( true );
            setModalUser( u );
        } else {
            u.id = user.id;
            setIsNew( false );
            setModalUser( u );
        }
    }, [user] );

    // Always get the list of companies
    useEffect( () => {
        getCompaniesList().then();
        getCustomerCompanyList().then();
    }, [] );

    useEffect( () => {
        if ( modalUser !== undefined && modalUser.account_id !== 0 ) {
            getAccess().then();
        }
    }, [] );
    // Method for getting lists of companies
    async function getCompaniesList() {
        try {
            const request = await util.axios.get( `generic_data/available_companies` );
            const { error, msg, data } = request.data;
            if ( error ) throw new Error( msg );
            setCompanies( data );
        } catch ( error ) {
            util.handleError( error );
        }
    }
    async function getCustomerCompanyList() {
        try {
            const request = await util.axios.get( `/customer_companies?all=true&status=Active` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setCompanyList( data );
        } catch ( error ) {
            util.handleError( error );
        }
    }
    async function getAccess() {
        try {
            const request = await util.axios.get( `/access` );
            const { error, data, msg } = request.data;
            if ( error ) throw new Error( msg );
            console.log( data );
            setAccess( data );
        } catch ( e ) {
            util.handleError( e );
        }

    }
    // Change handler for the ModalUsers form inputs
    function handleChanges( e ) {
        let { name, value } = e.target;
        let u = { ...modalUser };
        if ( name === "role" ) {
            value = parseInt( value );
            u[name] = value;

            // Validate if this roles needs talent profile
            if ( ( value === 2 || value === 7 || value === 8 ) && ( modalUser.candidate_id === 0 || modalUser.candidate_id === null ) ) {
                setNeedSkills( true );
            } else {
                setNeedSkills( false );
            }

            // Reset all permissions to 0
            u.permissions = {
                employee: value === EMPLOYEE_ROLE ? 1 : 0,
                employee_manager: value === EMPLOYEE_MANAGER ? 1 : 0,
                evaluator: value === EVALUATOR ? 1 : 0,
                client: value === CLIENT_ROLE ? 1 : 0,
                partner: value === PARTNER_ROLE ? 1 : 0,
                recruiter: value === RECRUITER_ROLE ? 1 : 0,
                recruiter_manager: value === RECRUITER_MANAGER ? 1 : 0,
                talent: value === TALENT_ROLE ? 1 : 0,
            };
        } else {
            u[name] = value;
        }
        setModalUser( u );
    }

    async function updateUser( data, opc ) {
        try {
            // setLoading(true)
            if ( !util.validateEmail( data.email ) ) throw new Error( "Invalid email " );
            if ( needSkills && skills.length === 0 ) throw new Error( "You need to add at least 1 skill" );
            if ( needSkills && skills.length > 0 ) {
                modalUser.skills = skills;
            }
            console.log( modalUser );
            const request = await util.axios.put( `${util.url}/users/update/${modalUser.id}`, modalUser );
            const { error, msg, updatedUser } = request.data;
            if ( error ) return util.toast().error( msg );
            util.toast().success( msg );
            tableRef.current && tableRef.current.onQueryChange();
            setLoading( false );
            // If the roles for the current user change, it will reload the app
            if ( opc === 1 && modalUser.id === util.user.id ) {
                const request = await axios.put( `${util.url}/auth/change_user_role`, { user: modalUser } );
                const { error, msg, accessToken } = request.data;
                if ( error ) throw msg;
                bake_cookie( "tokenServer", accessToken );
                window.history.replaceState( "", "", "/" );
                window.location.reload();

            } else {
                setModalUser( updatedUser );
                setNeedSkills( false );
            }
            hideModal();

        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    }

    async function createUser( data ) {
        try {
            let userTemp = { ...data };
            // Validate if is a new user with a role that requires skills
            if ( userTemp.role === 2 || userTemp.role === 7 || userTemp.role === 8 ) {
                userTemp.skills = skills;
            }

            setLoading( true );
            if ( !util.validateEmail( userTemp.email ) ) throw new Error( "Invalid email " );
            if ( userTemp.phone1.length < 11 ) throw new Error( "Phone 1 number is not valid" );
            // if (userTemp.phone2.length < 11 ) throw new Error("Phone 2 number is not valid")

            const request = await util.axios.post( `${util.url}/users/new`, userTemp );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
            tableRef.current && tableRef.current.onQueryChange();
            hideModal();
            setLoading( false );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    }

    function handleChangesPermission( e, role ) {
        const { checked } = e.target;
        let u = { ...modalUser };
        u.permissions[role] = ( checked ? 1 : 0 );
        if ( modalUser.candidate_id === 0 || modalUser.candidate_id === null ) {
            // This is when selected user doesn't have talent profile
            if ( ( role === "talent" && checked ) ||
                ( role === "employee" && checked ) ||
                ( role === "employee_manager" && checked ) ) {
                setNeedSkills( true );
            } else {
                if ( u.permissions.talent === 0 &&
                    u.permissions.employee === 0 &&
                    u.permissions.employee_manager === 0 ) {
                    setNeedSkills( false );
                } else {
                    setNeedSkills( true );
                }
            }
        }
        setModalUser( { ...u } );
    }

    function isDisabled( roleName ) {
        switch ( roleName ) {
            case "recruiter": return modalUser.role === RECRUITER_ROLE;
            case "talent": return modalUser.role === TALENT_ROLE;
            case "client": return modalUser.role === CLIENT_ROLE;
            case "partner": return modalUser.role === PARTNER_ROLE;
            case "evaluator": return modalUser.role === EVALUATOR;
            case "recruiter_manager": return modalUser.role === RECRUITER_MANAGER;
            case "employee": return modalUser.role === EMPLOYEE_ROLE;
            case "employee_manager": return modalUser.role === EMPLOYEE_MANAGER;
            default: break;
        }
    }

    const onSkillChange = ( e, index ) => {
        const { name, value } = e.target;
        let t = [...skills];
        t[index][name] = value;
        setSkills( t );
    };

    const addSkill = () => {
        let t = [...skills];
        t.push( { years_experience: "", skill_name: "" } );
        setSkills( t );
    };

    const removeSkill = ( index ) => {
        if ( skills.length === 1 ) {
            util.toast().warn( "You need to include at least 1 skill for the talent profile" );
        } else {
            let t = [...skills];
            t.splice( index, 1 );
            setSkills( t );
        }

    };
    // Creating a copy of the existing user
    async function impersonateUser( user ) {
        const request = await axios.post( `${util.url}/auth/impersonate_user`, user );
        const { error, msg, accessToken } = request.data;
        if ( error ) throw msg;
        bake_cookie( "tokenServer", accessToken );
        window.history.replaceState( "", "", "/" );
        window.location.reload();
    }

    const reviewPermissions = () => {
        const { employee, employee_manager, recruiter, recruiter_manager } = modalUser.permissions;
        if ( [employee, employee_manager, recruiter, recruiter_manager].includes( 1 ) ) return true;
        return false;
    };

    const handleSubmit = async () => {
        if ( isNew ) {
            await createUser( modalUser );
        } else {
            await updateUser( modalUser, 1 );
        }
        await handleSave();
    };

    return {
        modalUser, setModalUser,
        isNew, setIsNew,
        loading, setLoading,
        needSkills, setNeedSkills,
        companies, setCompanies,
        companyList, setCompanyList,
        access, setAccess,
        skills, setSkills,
        options, setOptions, toggleChecked, sortedOptions, handleSave,
        getCompaniesList,
        getAccess,
        handleChanges,
        updateUser,
        createUser,
        handleChangesPermission,
        isDisabled,
        onSkillChange,
        addSkill,
        removeSkill,
        impersonateUser,
        reviewPermissions,
        handleSubmit
    };
};

export default useModalUsers;
