import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function InformationBlock ( { icon, title, value } ) {

    return (
        <div className="col-md-4 mt-2">
            <div className="card">
                <div className="card-body">
                    <table className="w-100">
                        <thead/>
                        <tbody>
                            <tr>
                                <td/>
                                <td className="blockTitle">{title}</td>
                            </tr>
                            <tr>
                                <td className="blockIcon"><FontAwesomeIcon icon={icon} /></td>
                                <td className="blockValue">{value}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default InformationBlock;