import { Checkbox, FormControlLabel, IconButton, InputAdornment, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import util from '../../utils/miniUtils';
import countryList from "../../utils/location/country.json";

import { v4 as uuidv4 } from "uuid";

const LocationComponent = ({ setJob, job, remote, handleRemoteChange }) => {
    const handleCountryChange = (e) => {
        setJob(prev => ({
            ...prev,
            country: e.target.value
        })
        );

    };
    return <>
        <div className='col-md-5 mt-2'>
            <FormControlLabel
                control={<Checkbox checked={remote} onChange={handleRemoteChange} color="primary" />}
                label="Remote"
            />
        </div>
        <div className='col-md-5 p-1 mt-2 w-100'>
            <TextField
                select
                size="small"
                variant="outlined"
                name="country"
                label="Country"
                value={job.country}
                onChange={handleCountryChange}
                // error={Boolean( candidateErrors[index]?.country )}
                // helperText={candidateErrors[index]?.country || ""}
                // className='form-control'
                InputLabelProps={{ shrink: true }}
                SelectProps={{
                    native: true
                }}
            >
                <optgroup label="Most Active">
                    {/* <option value="">--</option> */}
                    <option value="United States">United States</option>
                    <option value="Canada">Canada</option>
                    <option value="India">India</option>
                    <option value="Australia">Australia</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Japan">Japan</option>
                    <option value="Germany">Germany</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="South Africa">South Africa</option>
                </optgroup>
                <optgroup label="All Countries">
                    <option value="" />
                    {countryList?.map((item) => {
                        return (
                            <option key={uuidv4()} value={item.name}>
                                {item.name}
                            </option>
                        );
                    })}
                </optgroup>
            </TextField>
        </div>
        <LocationInput name="city" label="City *" setJob={setJob} value={job.city} countrySelected={job.country} disabled={remote} />
        <LocationInput name="zipcode" label="Zipcode" setJob={setJob} value={job.zipCode} countrySelected={job.country} disabled={remote} />
    </>;
};

export default LocationComponent;

const LocationInput = ({ name, label, setJob, value, countrySelected, disabled }) => {
    const [searchText, setSearchText] = useState(value);
    const [result, setResult] = useState([]);
    const [startSearch, setStartSearch] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setSearchText(value);
    }, [value]);

    const handleInputChange = (e) => {
        setStartSearch(true);
        setSearchText(e.target.value);
    };

    const handleClear = () => {
        setSearchText('');
        setJob(prev => ({
            ...prev,
            country: "",
            state: "",
            city: "",
            zipCode: "",
            latitude: "",
            longitude: ""
        }));
    };
    const handleClick = (value) => {
        const { country, state, city, county, state_district, province } = value.address;
        console.log(country);
        const { lat, lon } = value;
        setStartSearch(false);
        if (name === 'city') {
            setJob(prev => ({
                ...prev,
                country: country,
                state: state,
                city: city ? city : province ? province : county ? county : state_district ? state_district : state,
                latitude: lat,
                longitude: lon
            }));
        } else {
            setJob(prev => ({
                ...prev,
                country: country,
                state: state,
                city: city ? city : province ? province : county ? county : state_district ? state_district : state,
                latitude: lat,
                longitude: lon,
                zipCode: value.name
            }));
        }
        setResult([]);
        setSearchText(value.name);

    };

    async function getLocationInfo(location, name) {
        if (location && startSearch) {
            setLoading(true);
            const type = name === 'zipcode' ? 'postalcode' : 'city';
            const url = `https://nominatim.openstreetmap.org/search?${type}=${encodeURIComponent(location)}&format=json&addressdetails=1&language=en-US&&country=${countrySelected}`;
            try {
                const response = await fetch(url, {
                    headers: {
                        'Accept-Language': 'en-US'
                    }
                });
                const data = await response.json();
                setResult(name === 'city' ? data.filter(el => el.type !== 'postcode') : data);
            } catch (error) {
                util.handleError(error);
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            // Replace this with your actual search logic
            getLocationInfo(searchText, name);
        }, 500); // Adjust the delay as needed (e.g., 500 milliseconds)

        return () => clearTimeout(delayDebounceFn);
    }, [searchText]);

    return <div className='col-md-5 p-1 mt-2 align-self-center position-relative'>
        <TextField
            className="w-100"
            size={"small"}
            variant="outlined"
            label={label}
            placeholder={label}
            InputLabelProps={{ shrink: true }}
            value={searchText}
            onChange={handleInputChange}
            disabled={disabled}
            InputProps={{
                endAdornment: searchText && (
                    <InputAdornment position="end">
                        <IconButton
                            edge="end"
                            onClick={handleClear}
                        >
                            x
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
        {result.length > 0 && !loading ? <div className="locationComponent-dropdown">
            {result.map((el) => (
                <div key={el.place_id} className="location-option" onClick={() => handleClick(el)}>
                    {el.display_name}
                </div>
            ))}
        </div> : loading ? <div className="location-option">
            loading...
        </div> : ""}
    </div>;

};