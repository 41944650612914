import React, { useRef, useEffect } from "react";
import D3Funnel from "d3-funnel";

export default function FunnelGraphJobs( { totals } ) {
    const chartRef = useRef( null );

    let data = [
        { label: "New", labelColor: "#000", value: totals.new, backgroundColor: "#ffd700" },
        { label: "Active", labelColor: "#000", value: totals.active, backgroundColor: "#008000" },
        { label: "Hot", labelColor: "#000", value: totals.hot, backgroundColor: "#ff6347" },
        { label: "Hold", labelColor: "#000", value: totals.hold, backgroundColor: "#d4cf05" },
        { label: "Closed", labelColor: "#000", value: totals.closed, backgroundColor: "#ff0000" },
        { label: "Filled", labelColor: "#000", value: totals.filled, backgroundColor: "#25a7bc" },
        { label: "Dropped", labelColor: "#000", value: totals.dropped, backgroundColor: "#898989" },
    ];

    let options = {
        chart: {
            animate: false,
            bottomPinch: 0,
            curve: {
                enabled: true
            },
        },
        block: {
            dynamicHeight: false,
            minHeight: 20
        },
        label: {
            format: "{l}\n{f}",
            fontSize: 13,
        }
    };

    useEffect( () => {
        const chart = new D3Funnel( chartRef.current );
        chart.draw( data, options );
        // eslint-disable-next-line
    }, [totals] );

    return <div className="funnelMarginTop" ref={chartRef}/>;
}
