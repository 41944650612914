import React from 'react';
import util from '../../../utils/miniUtils';
import selector from "../../../utils/selector";


export default function TitleExpansion( { position } ) {
    // Construct the title based on several fields, at the end adds the status of the job position
    let job_id = String( position.id ).padStart( 5, "0" );
    let partner = position.partner ? position.partner.company : "";
    let client = position.client ? position.client.company : "";
    let city = position.city ? position.city : "";
    let state = position.state ? position.state : "";
    let country = position.country ? position.country : "";
    let job_title = position.job_title;
    let title = job_id + " - ";

    if ( client !== "" && !util.user.employer_id  ) {
        title += client + " - ";
    }
    if ( partner !== "" && !util.user.employer_id  ) {
        title += partner + " - ";
    }
    if ( city !== ""  ) {
        title += city + ", ";
    }
    if ( state !== "" ) {
        title += selector.getStateAbbreviation( state ) + ", ";
    }
    if ( country !== "" ) {
        title += selector.getCountryAbbreviation( country ) + " - ";
    }
    if ( job_title !== "" ) {
        title += job_title;
    }
    title += ` -  Status: `;

    return (
        <b className={"titleExpandible"}>
            {title} {util.jobStatusOnlyIcons( position.status )}
        </b>
    );
}