import React, { useEffect, useState } from "react";
import {
    Card,   
    CardContent,
} from "@material-ui/core";
import util from "../../utils/miniUtils";
import ImageComponent from "../../utils/ImageComponent";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { setFilter } from "../../../redux/actions/datefilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";


const MonthlyReport = () => {
    const [monthlyOnboarded, setMonthlyOnboarded] = useState( [] );
    const [monthlyOnboardedSubcon, setMonthlyOnboardedSubcon] = useState( [] );
    const [otherOnboarding, setOtherOnboarding] = useState( [] );
    const [monthlyShortlisted, setMonthlyShortlisted] = useState( [] );
    const [monthlyShortlistedSubcon, setMonthlyShortlistedSubcon] = useState( [] );
    const [otherShortlisting, setOtherShortlisting] = useState( [] );
    const [countryList, setCountryList] = useState( [] );
    const [selectedCountries, setSelectedCountries] = useState( [] );
    const [initialShortlistedSub, setInitialShortlistedSub] = useState( [] );
    const [initialShortlistedNotSub, setInitialShortlistedNotSub] = useState( [] );
    const [initialShortlistedOthers, setInitialShortlistedOthers] = useState( [] );
    const [initialOnboardedSub, setInitialOnboardedSub] = useState( [] );
    const [initialOnboardedNotSub, setInitialOnboardedNotSub] = useState( [] );
    const [initialOnboardedOthers, setInitialOnboardedOthers] = useState( [] );
    
    let { from, to, applyFilter } = useSelector( ( state ) => state.filter ) || {};
    const dispatch = useDispatch();
    
    useEffect( () =>{
        if( applyFilter ) {
            getMonthlyOnboarded();
            getMonthlyShortlisted();
        }
        
    },[applyFilter ] );
    
    const getMonthlyOnboarded = async (  ) => {
        try {
            if( from === undefined ){
                const currentDate = new Date();
                from = currentDate.getFullYear() + '-' +
                  ( currentDate.getMonth() + 1 ) + '-' +
                  currentDate.getDate();
            }
            if( to === undefined ){
                const currentDate = new Date();
                to = currentDate.getFullYear() + '-' +
                ( currentDate.getMonth() + 1 ) + '-' +
                currentDate.getDate();
            }
            from = from ? util.convertToUTC( from ) : '';
            to = to ? util.convertToUTC( to ) : '';
            const response = await util.axios.get(
                `new_dashboard/get_monthly_onboarded_report?from=${from}&to=${to}`
            );

            const { data, error, msg } = response.data;
            const onboardedCountries = new Set( data.withoutSubcon.map( item => item.country ), data.withSubcon.map( item => item.country ), data.others.map( item => item.country ) );
            
            const list = new Set( [...countryList,...onboardedCountries] ) ;
            setCountryList( list );
            
            setMonthlyOnboarded( data.withoutSubcon );
            setMonthlyOnboardedSubcon( data.withSubcon );
            setOtherOnboarding( data.others );
            setInitialOnboardedSub( data.withSubcon );
            setInitialOnboardedNotSub( data.withoutSubcon );
            setInitialOnboardedOthers( data.others );
            dispatch( setFilter( false ) );
            if ( error ) throw msg;
        } catch ( e ) {
            util.handleError( e );
        }
    };
    const getMonthlyShortlisted = async (  ) => {
        try {
            if( from === undefined ){
                const currentDate = new Date();
                from = currentDate.getFullYear() + '-' +
                  ( currentDate.getMonth() + 1 ) + '-' +
                  currentDate.getDate();
            }
            if( to === undefined ){
                const currentDate = new Date();
                to = currentDate.getFullYear() + '-' +
                ( currentDate.getMonth() + 1 ) + '-' +
                currentDate.getDate();
            }
            from = from ? util.convertToUTC( from ) : '';
            to = to ? util.convertToUTC( to ) : '';
            const response = await util.axios.get(
                `new_dashboard/get_monthly_shortlisted_report?from=${from}&to=${to}`
            );

            const { data, error, msg } = response.data;
            const shortlistedCountries = new Set( data.lateralShortlisted.map( item => item.country ), data.subconShortlisted.map( item => item.country ) , data.otherShortlist.map( item => item.country ) );

            setCountryList( new Set( [...countryList, ...shortlistedCountries] ) );
            
            setMonthlyShortlisted( data.lateralShortlisted );
            setMonthlyShortlistedSubcon( data.subconShortlisted );
            setOtherShortlisting( data.otherShortlist );
            setInitialShortlistedSub( data.subconShortlisted );
            setInitialShortlistedNotSub( data.lateralShortlisted );
            setInitialShortlistedOthers( data.otherShortlist );
            dispatch( setFilter( false ) );
            if ( error ) throw msg;
        } catch ( e ) {
            util.handleError( e );
        }
    };


    const exportToCsv = () => {
        const headings = [
            "Country",
            "Client Name",
            "Name Of Candidate",
            "Candidate ID",
            "Sourced By",
            "Shortlisted/Onboarded",
            "Date Of Joining",
            "Buy Rate",
            "Sell Rate",
            "Margin"
        ];
    
        const csvRows = [
            headings.join( ',' ),
            ...monthlyOnboardedSubcon?.map( row =>
                [
                    row.country,
                    row.client_name,
                    row.Onboarded_Talent_Name,
                    row.candidate_id,
                    row.recruiter_name,
                    'onboarded',
                    row.joining_date ? row.joining_date : "To be determined",
                    row.buy_rate,
                    row.sell_rate,
                    row.sell_margin
                ].join( ',' )
            ),
        ];
    
        // Add a row for "SubCon Hire - Onboarded"
        csvRows.splice( 1, 0, 'SubCon Hire - Onboarded' );
    
        // Add data for "Lateral Hire - Onboarded"
        csvRows.push( 'Lateral Hire - Onboarded' );
        csvRows.push(
            ...monthlyOnboarded?.map( row =>
                [
                    row.country,
                    row.client_name,
                    row.Onboarded_Talent_Name,
                    row.candidate_id,
                    row.recruiter_name,
                    'onboarded',
                    row.joining_date ? row.joining_date : "To be determined",
                    row.buy_rate,
                    row.sell_rate,
                    row.sell_margin

                ].join( ',' )
            )
        );
        csvRows.push( 'Others - Onboarded' );
        csvRows.push(
            ...otherOnboarding?.map( row =>
                [
                    row.country,
                    row.client_name,
                    row.Onboarded_Talent_Name,
                    row.candidate_id,
                    row.recruiter_name,
                    'onboarded',
                    row.joining_date ? row.joining_date : "To be determined",
                    row.buy_rate,
                    row.sell_rate,
                    row.sell_margin

                ].join( ',' )
            )
        );
    
        // Add a row for "SubCon Hire - Shortlisted"
        csvRows.push( 'SubCon Hire - Shortlisted' );
    
        // Add data for "SubCon Hire - Shortlisted"
        csvRows.push(
            ...monthlyShortlistedSubcon?.map( row =>
                [
                    row.country,
                    row.client_name,
                    row.Onboarded_Talent_Name,
                    row.candidate_id,
                    row.recruiter_name,
                    'shortlisted',
                    'To be determined',
                    row.buy_rate,
                    row.sell_rate,
                    row.sell_margin

                ].join( ',' )
            )
        );
    
        // Add a row for "Lateral Hire - Shortlisted"
        csvRows.push( 'Lateral Hire - Shortlisted' );
    
        // Add data for "Lateral Hire - Shortlisted"
        csvRows.push(
            ...monthlyShortlisted?.map( row =>
                [
                    row.country,
                    row.client_name,
                    row.Onboarded_Talent_Name,
                    row.candidate_id,
                    row.recruiter_name,
                    'shortlisted',
                    'To be determined',
                    row.buy_rate,
                    row.sell_rate,
                    row.sell_margin

                ].join( ',' )
            )
        );
        csvRows.push( 'Others - Shortlisted' );
    
        // Add data for "Lateral Hire - Shortlisted"
        csvRows.push(
            ...otherShortlisting?.map( row =>
                [
                    row.country,
                    row.client_name,
                    row.Onboarded_Talent_Name,
                    row.candidate_id,
                    row.recruiter_name,
                    'shortlisted',
                    'To be determined',
                    row.buy_rate,
                    row.sell_rate,
                    row.sell_margin

                ].join( ',' )
            )
        );
    
        const csvContent =
            'data:text/csv;charset=utf-8,' + csvRows.join( '\n' );
    
        const encodedUri = encodeURI( csvContent );
        const link = document.createElement( 'a' );
        link.setAttribute( 'href', encodedUri );
        link.setAttribute( 'download', 'table-export.csv' );
        document.body.appendChild( link );
        link.click();
    };
    const handleCountryChange = ( selectedCountries ) => {
        setSelectedCountries( selectedCountries );
        
        const monthlyShortlistedNew = initialShortlistedSub.filter( item => selectedCountries?.length ===0 || selectedCountries?.some( el => el.value === item.country ) );
        setMonthlyShortlistedSubcon( monthlyShortlistedNew );

        const monthlyShortlistedNonSub = initialShortlistedNotSub.filter( item => selectedCountries?.length ===0 || selectedCountries?.some( el => el.value === item.country ) );
        setMonthlyShortlisted( monthlyShortlistedNonSub );
        const monthlyShortlistedOthers = initialShortlistedOthers.filter( item => selectedCountries?.length ===0 || selectedCountries?.some( el => el.value === item.country ) );
        setOtherShortlisting( monthlyShortlistedOthers );
        const monthlyOnboardedNew = initialOnboardedSub.filter( item => selectedCountries?.length === 0 || ( selectedCountries?.some( list => item.country === list.value ) ) );
        
        setMonthlyOnboardedSubcon( monthlyOnboardedNew );
        const monthlyOnboardedNew2 = initialOnboardedNotSub.filter( item => selectedCountries?.length === 0 || ( selectedCountries?.some( list => item.country === list.value ) ) );
        
        setMonthlyOnboarded( monthlyOnboardedNew2 );
        const monthlyOnboardedNew3 = initialOnboardedOthers.filter( item => selectedCountries?.length === 0 || ( selectedCountries?.some( list => item.country === list.value ) ) );
        
        setOtherOnboarding( monthlyOnboardedNew3 );
    };
     
    return (
        <Card style={{ width: "100%", height: "90%", overflow: 'hidden' }}>
            <CardContent style={{ height: "100%" }} >
                <div style={{ height: "100%", overflow: "auto" }}>
                    <div className="recruiter-filter">
                        
                        
                        <Select 
                            isMulti
                            onChange={handleCountryChange}
                            value={selectedCountries}
                            placeholder="Select Countries..."
                            options={Array.from( countryList ).map( country => ( { value: country, label: country } ) )}
                            className="mr-2 non-draggable dashboard-select"
                        />
                        
                        <div className={"btn btn-secondary jobButton activeBtn btn-sm export-onboarded non-draggable"} onClick={exportToCsv}>
                            <FontAwesomeIcon icon={faFileExport} className="mr-2 " />
                                Export All
                        </div>
                    </div>
                    
                    {/* <table className="table table-bordered"> */}
                    <table className="monthly-report">
                        <thead>
                            <tr>
                                <th>Country</th>
                                <th>Client Name</th>
                                <th>Name Of Candidate </th>
                                <th className="numeric-values">Candidate ID </th>
                                <th>Sourced By</th>
                                <th>Shortlisted/Onboarded</th>
                                <th>Date Of Joining</th>
                                <th className="numeric-values">Buy Rate</th>
                                <th className="numeric-values">Sell Rate</th>
                                <th className="numeric-values">Margin %</th>

                                {/* <th>Status</th> */}
                                
                            </tr>
                        </thead>
                        <tbody className="recruiter-report-body">
                            <tr className="centered-row">
                                <td colSpan="10">SubCons Hire - Onboarded</td>
                            </tr>
                            {monthlyOnboardedSubcon?.map( ( item,index ) => (
                                <tr key={index} className="recruiter-report-rows">
                                    <td>
                                        <img width={25} src={util.countryFlag( item?.country )} alt={item?.country} className="mr-1"/>
                                        {item?.country}</td>
                                    <td>{item?.client_name}</td>
                                    <td>{item.Onboarded_Talent_Name}</td>
                                    <td className="numeric-values">{item.candidate_id}</td>
                                    <td>{item?.recruiter_name}</td>
                                    <td>Onboarded</td>
                                    <td>{item?.joining_date ? item?.joining_date : 'To be determined'}</td>
                                    
                                    <td className="numeric-values">{item?.buy_rate}</td>
                                    <td className="numeric-values">{item?.sell_rate}</td>
                                    <td className="numeric-values">{item?.sell_margin}</td>

                                    {/* <td>{item.status}</td> */}
                                    
                                </tr>
                            ) )}
                            <tr className="centered-row">
                                <td colSpan="10">Lateral Hire - Onboarded</td>
                            </tr>
                            {monthlyOnboarded?.map( ( item,index ) => (
                                <tr key={index} className="recruiter-report-rows">
                                    <td>
                                        <img width={25} src={util.countryFlag( item?.country )} alt={item?.country} className="mr-1"/>
                                        {item?.country}</td>
                                    <td>{item?.client_name}</td>
                                    <td>{item.Onboarded_Talent_Name}</td>
                                    <td className="numeric-values">{item.candidate_id}</td>
                                    <td>{item?.recruiter_name}</td>
                                    <td>Onboarded</td>
                                    <td>{item?.joining_date ? item?.joining_date : 'To be determined'}</td>
                                    
                                    
                                    <td className="numeric-values">{item?.buy_rate}</td>
                                    <td className="numeric-values">{item?.sell_rate}</td>
                                    <td className="numeric-values">{item?.sell_margin}</td>

                                    {/* <td>{item.status}</td> */}
                                    
                                </tr>
                            ) )}
                            <tr className="centered-row">
                                <td colSpan="10">Others - Onboarded</td>
                            </tr>
                            {otherOnboarding?.map( ( item,index ) => (
                                <tr key={index} className="recruiter-report-rows">
                                    <td>
                                        <img width={25} src={util.countryFlag( item?.country )} alt={item?.country} className="mr-1"/>
                                        {item?.country}</td>
                                    <td>{item?.client_name}</td>
                                    <td>{item.Onboarded_Talent_Name}</td>
                                    <td className="numeric-values">{item.candidate_id}</td>
                                    <td>{item?.recruiter_name}</td>
                                    <td>Onboarded</td>
                                    <td>{item?.joining_date ? item?.joining_date : 'To be determined'}</td>

                                    
                                    <td className="numeric-values">{item?.buy_rate}</td>
                                    <td className="numeric-values">{item?.sell_rate}</td>
                                    <td className="numeric-values">{item?.sell_margin}</td>

                                    {/* <td>{item.status}</td> */}
                                    
                                </tr>
                            ) )}
                            
                            <tr className="centered-row">
                                <td colSpan="10">Subcons Hire - Shortlisted</td>
                            </tr>
                            {monthlyShortlistedSubcon?.map( ( item,index ) => (
                                <tr key={index} className="recruiter-report-rows">
                                    <td>
                                        <img width={25} src={util.countryFlag( item?.country )} alt={item?.country} className="mr-1"/>
                                        {item?.country}</td>
                                    <td>{item?.client_name}</td>
                                    <td>{item.Onboarded_Talent_Name}</td>
                                    <td className="numeric-values">{item.candidate_id}</td>
                                    <td>{item?.recruiter_name}</td>
                                    <td>Shortlisted</td>
                                    <td>To be determined</td>
                                    
                                    <td className="numeric-values">{item?.buy_rate}</td>
                                    <td className="numeric-values">{item?.sell_rate}</td>
                                    <td className="numeric-values">{item?.sell_margin}</td>

                                    {/* <td>{item.status}</td> */}
                                    
                                </tr>
                            ) )}
                            <tr className="centered-row">
                                <td colSpan="10">Lateral Hire - Shortlisted</td>
                            </tr>
                            {monthlyShortlisted?.map( ( item,index ) => (
                                <tr key={index} className="recruiter-report-rows">
                                    <td>
                                        <img width={25} src={util.countryFlag( item?.country )} alt={item?.country} className="mr-1"/>
                                        {item?.country}</td>
                                    <td>{item?.client_name}</td>
                                    <td>{item.Onboarded_Talent_Name}</td>
                                    <td className="numeric-values">{item.candidate_id}</td>
                                    <td>{item?.recruiter_name}</td>
                                    <td>Shortlisted</td>
                                    <td>To be determined</td>
                                    
                                    <td className="numeric-values">{item?.buy_rate}</td>
                                    <td className="numeric-values">{item?.sell_rate}</td>
                                    <td className="numeric-values">{item?.sell_margin}</td>

                                    {/* <td>{item.status}</td> */}
                                    
                                </tr>
                            ) )}
                            <tr className="centered-row">
                                <td colSpan="10">Others - Shortlisted</td>
                            </tr>
                            {otherShortlisting?.map( ( item,index ) => (
                                <tr key={index} className="recruiter-report-rows">
                                    <td>
                                        <img width={25} src={util.countryFlag( item?.country )} alt={item?.country} className="mr-1"/>
                                        {item?.country}</td>
                                    <td>{item?.client_name}</td>
                                    <td>{item.Onboarded_Talent_Name}</td>
                                    <td className="numeric-values">{item.candidate_id}</td>
                                    <td>{item?.recruiter_name}</td>
                                    <td>Shortlisted</td>
                                    <td>To be determined</td>
                                    
                                    <td className="numeric-values">{item?.buy_rate}</td>
                                    <td className="numeric-values">{item?.sell_rate}</td>
                                    <td className="numeric-values">{item?.sell_margin}</td>

                                    {/* <td>{item.status}</td> */}
                                    
                                </tr>
                            ) )}
                        </tbody>
                    </table>
                    
                </div>
            </CardContent>
        </Card>
    );
};

export default MonthlyReport;

