import React, { useEffect, useState } from "react";
import { faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import selector from "../../utils/selector";
import util from "../../utils/miniUtils";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import countryList from '../../utils/location/country.json';

const UserFilter = ( { filterData:{ role,company, new_role,  country,search }, searchData, closeAdvanceSearch ,companyData} ) => {

    const [countries] = useState( countryList );
    const [state, setState] = useState( {
        role: role,
        company: company,
        new_role:new_role,
        country: country,
        search: search
    } );


let companyList=[{ company: "All" }]
companyData.map((item)=>{
    companyList.push({company:`${item.customer_name}`})
})

    function handleChanges( e ) {
        const { name, value } = e.target;
        setState( { ...state,['new_role']:'All', [name]:value } );
    }
    
    function handleChangesRole( e ) {
        const { name, value } = e.target;
       
        setState( { ...state ,[name]:value} );
    }

    return (
        <div className="advancedFilterBox">
            <div className="row ">
                <div className="col-md-12 ml-3 mt-3">
                    <h5>Advanced Filter</h5>
                </div>
            </div>
            <div className="row mx-auto ml-3">
                <util.RowComponent
                    c={1}
                    t={"Role"}
                    n={"role"}
                    m={handleChanges}
                    v={state.role? state.role: ""}
                    type={1}
                    className="align-self-end p-2  "
                    d={[{ role: "All" }, { role: "Client" }, { role: "Employee" }, { role: "Employee Manager" }, { role: "Partner" } ]}
                />
                 <util.RowComponent
                    c={1}
                    t={"Company"}
                    n={"company"}
                    m={handleChanges}
                    v={state.company? state.company: ""}
                    type={1}
                    className="align-self-end p-2"
                    d={companyList}
                />
                 <util.RowComponent
                    c={1}
                    t={"New Role"}
                    n={"new_role"}
                    m={handleChangesRole}
                    v={state.new_role? state.new_role: ""}
                    type={1}
                    className="align-self-end p-2 "
                    d={[{ new_role: "All" }, { new_role: "Customer" }, { new_role: "Announcement Manager" },{ new_role: "Announcement Viewer" }, { new_role: "Customer Resolver" } ]}
                />
                {/* <TextField
					select
					size="small"
					name="country"
					className="w-5"
					label="Country"
					value={state.country? state.country: ""}
					onChange={handleChanges}
					InputLabelProps={{ shrink: true }}
					SelectProps={{
						native: true
					}}
				>
					<option value=""/>
					{countries?.map((option, i) => (
						<option key={i} value={option.name}>
							{option.name}
						</option>
					))}
				</TextField> */}
                <TextField
                    label="Search"
                    name="search"
                    value={state.name}
                    onChange={handleChanges}
                    variant="standard"
                    className="align-self-end  p-2 ml-2"
                />

                <div className="col-md-1   d-flex align-self-center justify-content-center" >
                    <button className="btn btn-sm btn-primary"
                        onClick={() => searchData( state )}
                    >
                        <FontAwesomeIcon icon={faFilter} className="mr-2"/>
                        Filter
                    </button>
                    <button className="btn btn-sm"
                        title="Close advanced search"
                        onClick={closeAdvanceSearch}
                    >
                        <FontAwesomeIcon icon={faTimes}/>
                    </button>
                </div>
                <hr />
            </div >
        </div>
    );
};

export default UserFilter;
