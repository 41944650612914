
export const setCandidates = ( data ) => ( {
    type: "SET_CANDIDATES",
    payload:data
} );

export const setCandidateStatus = ( data ) => ( {
    type: "SET_CANDIDATE_STATUS",
    payload:data
} );
