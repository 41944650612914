import React, { forwardRef, useState } from "react";
import {  Select } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {  setFilter, setFrom, setTo } from "../../../redux/actions/datefilter";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCalendarAlt, faFilter } from "@fortawesome/free-solid-svg-icons";


const ChartFilter = ( { filtering } ) => {
   
    const [selectedPeriod, setSelectedPeriod] = useState( '' );
    const dispatch = useDispatch();

    const { to, from } = useSelector( ( state ) => {
        return state.filter;
    } );

    const periodOptions = [  'Today','Yesterday', 'This Week','Last Week' , 'This Month', 'Last Month', 'This Quarter', 'Last Quarter', 'This Year', 'Last Year', 'Year-to-Date' ];

    function toISOStringDate( date ) {
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        
        const year = date.getFullYear();
        const monthIndex = date.getMonth();
        const month = monthNames[monthIndex];
        const day = String( date.getDate() ).padStart( 2, "0" );
        
        return `${day}-${month}-${year}`;
    }
      

    const handlePeriodChange = ( event ) =>{

        const selectedPeriod = event.target.value;
        setSelectedPeriod( selectedPeriod );
        let fromDate = "";
        let toDate = "";
        
        const startOfWeek = new Date();
        const endOfLastWeek = new Date();
        const startOfLastWeek = new Date();
        const startOfMonth =  new Date();

        switch( selectedPeriod ) {

            case "Today": {
                let presentDate = new Date();
                fromDate = toISOStringDate( presentDate );
                toDate = fromDate;
                break;
            }

            case "Yesterday": {
                let presentDate = new Date();
                const yesterday = new Date();
                yesterday.setDate( yesterday.getDate()-1 );
                fromDate = toISOStringDate( yesterday );
                toDate = toISOStringDate( presentDate );
                break;
            }
            case "This Week": {
                let presentDate = new Date();
                
                startOfWeek.setDate( startOfWeek.getDate() - startOfWeek.getDay() );
                fromDate = toISOStringDate( startOfWeek );
                toDate  = toISOStringDate( presentDate );
                break;
            }
            case "Last Week":
                
                endOfLastWeek.setDate( endOfLastWeek.getDate() - endOfLastWeek.getDay() );
                startOfLastWeek.setDate( endOfLastWeek.getDate() -7 );
                fromDate = toISOStringDate( startOfLastWeek );
                toDate = toISOStringDate( endOfLastWeek );
                break;

            case "This Month": {
                let presentDate = new Date();

                startOfMonth.setDate( 1 );
                // fromDate = startOfMonth.toISOString().substr( 0, 10 );
                fromDate = toISOStringDate( startOfMonth );
                toDate = toISOStringDate( presentDate );
                break;
            }
            case "Last Month": {
                let startOfLastMonth = new Date();
                startOfLastMonth.setMonth( startOfLastMonth.getMonth() -1 );
                startOfLastMonth.setDate( 1 );
                const endOfLastMonth = new Date( startOfLastMonth.getFullYear(), startOfLastMonth.getMonth() +1 ,0 );
                fromDate = toISOStringDate( startOfLastMonth );
                toDate = toISOStringDate( endOfLastMonth );
                break;
            }

            case "This Quarter": {
                const currentQuarter = Math.floor( ( new Date().getMonth() + 3 ) / 3 );
                const startOfQuarter = new Date( new Date().getFullYear(), 3 * currentQuarter - 3, 1 );
               
                fromDate = toISOStringDate( startOfQuarter );
                toDate = toISOStringDate( new Date() );
                break;
                
            }
                
            case "Last Quarter": {
                const currentQuarter = Math.floor( ( new Date().getMonth() + 3 ) / 3 );
                
                const startOfLastQuarter = new Date( new Date().getFullYear(), 3 * ( currentQuarter - 1 ) - 3, 1 );
                const endOfLastQuarter = new Date( startOfLastQuarter.getFullYear(), startOfLastQuarter.getMonth() + 3, 0 );
                fromDate = toISOStringDate( startOfLastQuarter );
                toDate = toISOStringDate( endOfLastQuarter );
                break;
            }
            case "This Year": {
                const startOfYear = new Date();
                startOfYear.setFullYear( startOfYear.getFullYear() - 1 );
                startOfYear.setDate( startOfYear.getDate() + 1 );
                fromDate = toISOStringDate( startOfYear );
                toDate = toISOStringDate( new Date() );
                break;
            }
              
            case "Last Year": {
                const startOfLastYear = new Date( new Date().getFullYear() - 1, 0, 1 );
                const endOfLastYear = new Date( startOfLastYear.getFullYear(), 11, 31 );
                fromDate = toISOStringDate( startOfLastYear );
                toDate = toISOStringDate( endOfLastYear );
                break;
            }
            case "Year-to-Date": {
                const startOfYearToDate = new Date( new Date().getFullYear(), 0, 1 );
                fromDate = toISOStringDate( startOfYearToDate );
                toDate = toISOStringDate( new Date() );
                break;
            }

            default:
                break;

        }
        dispatch( setFrom( fromDate ) );
        dispatch( setTo( toDate ) );

    };

    const handleFilterChange = () => {
        dispatch( setFrom( from ) );
        dispatch( setTo( to ) );
        dispatch( setFilter( true ) );
    };

    return (
        <div className={filtering ? 'chart-filter-contain-filtering':"chart-filter-container"}>
            <div>
                <select
                    className={filtering ? "filtering-dropdown" :"filter-dropdown"}
                    value={selectedPeriod}
                    onChange={handlePeriodChange}
                    // native
                >
                    <option disabled value="">{filtering ? "Workload Period" : "Report Period"}</option>
                    {periodOptions.map( ( item,index ) => ( <option key={index}>{item}</option> ) )}
                </select>
            </div>
            <div className={ filtering ? " d-flex align-items-center chart-filter-input-filtering": " d-flex align-items-center chart-filter-input"}>
            
                <DatePicker
                    id="from"
                    dateFormat="dd-MMM-yyyy"
                    placeholderText="From:"
                    selected={from ? new Date( from ) : null}
                    onChange={( date ) => dispatch( setFrom( date ? toISOStringDate( date ): "" ) )}
                    customInput={<CustomDatePickerInput placeholderText="From:"/>}
                    dayClassName={() => "filter-datepicker-day-class"}
                    popperClassName="filter-datepicker-class"
                    todayButton="TODAY"
                />
            </div>

            {/* <div className="d-flex align-items-center chart-filter-input"> */}
            <div className={ filtering ? " d-flex align-items-center chart-filter-input-filtering": " d-flex align-items-center chart-filter-input"}>

                
                <DatePicker
                    id="to"
                    dateFormat="dd-MMM-yyyy"
                    selected={to ? new Date( to ) : null}
                    onChange={( date ) => dispatch( setTo( date ? toISOStringDate( date ): "" ) )}
                    placeholderText="To:"
                    customInput={<CustomDatePickerInput placeholderText="To:"/>}
                    dayClassName={() => "filter-datepicker-day-class"}
                    popperClassName="filter-datepicker-class"
                    todayButton="TODAY"
                />
            </div>

            {filtering ? null : <button className="btn btn-secondary sizeBtn activeBtn" onClick={handleFilterChange}>
        Apply
                <FontAwesomeIcon icon={faFilter} className="ml-2" />
            </button>}
        </div>
    );
};

export default ChartFilter;
const CustomDatePickerInput = forwardRef( ( { value, onClick, onChange, placeholderText }, ref ) => (
  
    <input
        className="filter-custom-input"
        type="text"
        value={value}
        onClick={onClick}
        onChange={onChange}
        ref={ref}
        placeholder={placeholderText}
    ></input>
       
) );