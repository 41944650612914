import React, { Fragment, useState, useEffect } from "react";
import {  Modal } from "react-bootstrap";
import util from "../../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { MuiThemeProvider, TextField } from "@material-ui/core";
import MaterialTable from "material-table";
import PhoneInput from "react-phone-input-2";
const tableRef = React.createRef();


function ModalSelectPartnerClient( props ) {
    const [show, setShow] = useState( props.modalVisible );
    const [name, setName] = useState( "" );
    const [company_name, setCompany_name] = useState( "" );
    const [email, setEmail] = useState( "" );
    const [phone1, setPhone1] = useState( "" );
    const [phone2, setPhone2] = useState( "" );
    const [action, setAction] = useState( true );

    const handleClose = () => {
        setShow( false );
        props.hideModal();
    };

    function handleChanges( e ) {
        const { name, value } = e.target;
        if ( name === "name" ) setName( value );
        if ( name === "company_name" ) setCompany_name( value );
        if ( name === "email" ) setEmail( value );
        if ( name === "phone1" ) setPhone1( value );
        if ( name === "phone2" ) setPhone2( value );
    }

    useEffect( () => {
        setShow( props.modalVisible );
        return () => {};
    }, [props.modalVisible] );


    async function saveData() {
        try {
            if ( name === "" ) throw new Error( "Ingress name field" );
            if ( company_name === "" ) throw new Error( "Ingress Company Name field" );
            if ( !util.validateEmail( email ) ) throw new Error( "Invalid Email" );
            if ( phone1 === "" ) throw new Error( "Ingress phone field" );
            if ( phone1.length < 11 ) throw new Error( "Invalid  phone number " );
            const data = { name, company:company_name, email, phone1, phone2, role: props.modalType };
            const request = await util.axios.post( `${util.url}/users/new`, data );
            const { error, msg, user } = request.data;
            if ( error ) return util.toast().error( msg );
            util.toast().success( msg );
            // Clean vars
            setCompany_name( "" );
            setName( "" );
            setEmail( "" );
            setPhone1( "" );
            setPhone2( "" );
            props.selectedData( props.modalType, user );
            props.hideModal();
        }catch ( e ) {
            util.handleError( e );
        }
    }

    function handleAction( act ) {
        if ( act === "add" ) {
            setAction( false );
        } else {
            setAction( true );
        }
    }

    function selectedData( data ) {
        props.selectedData( props.modalType, data );
        props.hideModal();
    }

    return (
        <Fragment>
            <Modal size={action ? "xl" : "sm"} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    {/*<Modal.Title>Partners</Modal.Title>*/}
                </Modal.Header>
                <Modal.Body>
                    {action ?
                        <Fragment>
                            <div className={`w-100`}>
                                <MuiThemeProvider theme={util.defaultTableTheme}>
                                    <MaterialTable
                                        tableRef={tableRef}
                                        columns={[
                                            {
                                                title: 'Name', field: 'name', searchable: true,
                                            },
                                            {
                                                title: 'Email', field: 'email', searchable: true,
                                            },
                                            {
                                                title: 'Company', field: 'company', searchable: true,
                                            },
                                            {
                                                title: 'Phone 1', field: 'phone1'
                                            },
                                            {
                                                title: 'Phone 2', field: 'phone2'
                                            },
                                            {
                                                title: '',
                                                editable:"onUpdate",
                                                render: rowData => {
                                                    return <button onClick={()=> selectedData( rowData )} title="Select Partner" className="btn btn-outline-primary btn-sm">
                                                        <div className="text-center">Select</div>
                                                    </button>;
                                                }
                                            }

                                        ]}
                                        data={query =>
                                            util.axios.get( util.urlHandlerUsers( query, props.modalType ) )
                                                .then( response => {
                                                    const { users, total, page, error, msg } = response.data;
                                                    if ( error ) throw msg;
                                                    return ( {
                                                        data: users,
                                                        page: page,
                                                        totalCount: total
                                                    } );
                                                } )
                                                .catch( error=>{
                                                    util.handleError( error );
                                                } )
                                        }
                                        options={
                                            {
                                                pageSize: 10,
                                                sorting: true,
                                                padding:"dense",
                                                showTitle:false,
                                            }
                                        }
                                        localization={{
                                            toolbar:{
                                                searchPlaceholder: `Search for ${props.modalType === 4 ? "Partner":"Client"}...`
                                            }
                                        }}
                                        actions={[
                                            {
                                                icon: () => <div className="btn btn-sm btn-primary text-white"><FontAwesomeIcon  icon={faPlusCircle} /> {props.modalType === 4? "Add Partner":"Add Client"}</div>,
                                                tooltip: 'Add Candidate',
                                                isFreeAction: true,
                                                onClick: () => handleAction( "add" )
                                            }
                                        ]}
                                    />
                                </MuiThemeProvider>
                            </div>
                        </Fragment>:
                        <Fragment>
                            <div className={`w-100`}>
                                <button className="btn btn-sm btn-primary" onClick={()=>handleAction( "list" )}>
                                    <FontAwesomeIcon icon={faArrowLeft} /> Back to list
                                </button>
                                <table className="table table-borderless w-100">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <TextField
                                                    className={"w-100"}
                                                    label="Name"
                                                    value={name}
                                                    name={"name"}
                                                    onChange={handleChanges}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <TextField
                                                    className={"w-100"}
                                                    label={"Company Name"}
                                                    value={company_name}
                                                    name={"company_name"}
                                                    onChange={handleChanges}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <TextField
                                                    className={"w-100"}
                                                    label={"Email"}
                                                    value={email}
                                                    name={"email"}
                                                    onChange={handleChanges}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="labelFormControlMini">Phone 1</label>
                                                <PhoneInput
                                                    placeholder={"Phone 1"}
                                                    country={'us'}
                                                    name={"phone1"}
                                                    value={phone1}
                                                    onChange={phone => {
                                                        let e={ target:{ name:"phone1", value: phone } };
                                                        handleChanges( e );
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="labelFormControlMini">Phone 2</label>
                                                <PhoneInput
                                                    placeholder={"Phone 2"}
                                                    country={'us'}
                                                    name={"phone2"}
                                                    value={phone2}
                                                    onChange={phone => {
                                                        let e={ target:{ name:"phone2", value: phone } };
                                                        handleChanges( e );
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <button
                                                    className="float-right btn btn-block btn-primary"
                                                    onClick={() => saveData()}
                                                >
                          SAVE
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Fragment>}
                </Modal.Body>
            </Modal>
        </Fragment>
    );
}

export default ModalSelectPartnerClient;
