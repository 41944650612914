import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import EmailActionModal from './miniComponents/EmailActionModal';
import UploadDocumentModal from './miniComponents/UploadDocumentModal';
import ScheduleEvent from './miniComponents/ScheduleEvent';
import AssignTestModal from './miniComponents/AssignTestModal';
import util from '../../utils/miniUtils';

const ActionButton = ( { candidatesData } ) => {
    const [showEmailModal, setShowEmailModal] = useState( false );
    const [showDocModal, setShowDocModal] = useState( false );
    const [showScheduleModal, setShowScheduleModal] = useState( false );
    const [showTestModal, setShowTestModal] = useState( false );

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle id="dropdown-button-dark-example1" className='activeBtn sizeBtn' variant="secondary">
          Action
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setShowEmailModal( true )}>Email</Dropdown.Item>
                    {/* <Dropdown.Item onClick={() => setShowDocModal( true )} disabled>Upload Document</Dropdown.Item> */}
                    <Dropdown.Item onClick={() => setShowScheduleModal( true )}>Schedule Event</Dropdown.Item>
                  { !util.user.employer_id &&  <Dropdown.Item onClick={() => setShowTestModal( true )}>Assign Test</Dropdown.Item> }
                </Dropdown.Menu>
            </Dropdown>
            {/* Modals for each job position action */}
            <EmailActionModal 
                candidates={candidatesData}
                handleClose={() => {
                    setShowEmailModal( false );
                }}
                show={showEmailModal} 
                // updateCandidates={updateCandidates}
            />
            <UploadDocumentModal 
                candidates={candidatesData} 
                show={showDocModal} 
                // updateCandidates={updateCandidates}
                handleClose={() => {
                    setShowDocModal( false );
                } }/>

            <ScheduleEvent 
                candidates={candidatesData} 
                show={showScheduleModal} 
                // updateCandidates={updateCandidates}
                handleClose={() => {
                    setShowScheduleModal( false );
                    // setData( [...candidatesData] );
                }} />

            {showTestModal && <AssignTestModal 
                candidates={candidatesData} 
                show={showTestModal} 
                // updateCandidates={updateCandidates}
                handleClose={() => {
                    setShowTestModal( false );
                    // setData( [...candidatesData] );
                }} />}
        </>
    );
};

export default ActionButton;