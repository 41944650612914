
export const setFrom = ( from ) => {
    return {
        type: "SET_FROM",
        payload: from,
    };
};

export const setTo = ( to ) => {
    return {
        type: "SET_TO",
        payload: to,
    };
};

export const setFilter = ( applyFilter ) =>{
    return {
        type: "SET_FILTER",
        payload: applyFilter,
    };
};
