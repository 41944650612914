import React, { useEffect, useState } from 'react';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaterialTable from 'material-table';
import util from '../../../utils/miniUtils';
import useDropDownOptions from '../../../shared/jobs/useDropDownOptions';
import ReasonModal from './ReasonModal';

const DroppedReasons = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedReason, setSelectedReason] = useState(null);

    const handleShowModal = (reason = null) => {
        setSelectedReason(reason);
        setShowModal(true);
        console.log(reason)
    };
    const handleCloseModal = () => setShowModal(false);

    const { getDroppedReasons, reasons } = useDropDownOptions();

    useEffect(() => {
        getDroppedReasons();
    }, []);

    const handleDeleteReason = async (id) => {
        try {
            // console.log(`Deleting reason with id: ${id}`);  // Debug log
            let request = await util.axios.put(`/catalog/data/delete`, { id, catalog: 'dropped_reasons' });
            const { error, msg } = request.data;
            if (error) throw msg;
            util.toast().success(msg);
            console.log(`Successfully deleted reason with id: ${id}`);  // Debug log
        } catch (error) {
            // console.error(`Error deleting reason: ${error}`);  // Debug log
            util.handleError(error);
        }
        getDroppedReasons();
    };

    return (
        <div>
            <MaterialTable
                title="Job Dropped Reasons"
                columns={[
                    { title: 'Title', field: 'title' },
                    { title: 'Description', field: 'tooltip' },
                ]}
                data={reasons}
                actions={[
                    {
                        icon: () => <FontAwesomeIcon icon={faPlus} />,
                        tooltip: 'Add Reason',
                        isFreeAction: true,
                        onClick: () => handleShowModal(),
                    },
                    {
                        icon: () => <FontAwesomeIcon icon={faEdit} />,
                        tooltip: 'Edit Reason',
                        onClick: (event, rowData) => handleShowModal(rowData),
                    },
                    {
                        icon: () => <FontAwesomeIcon icon={faTrash} />,
                        tooltip: 'Delete Reason',
                        onClick: (event, rowData) => handleDeleteReason(rowData.id),
                    }
                ]}
                options={{
                    actionsColumnIndex: -1,
                    maxBodyHeight: 'calc(100vh - 200px)',
                }}
            />
            <ReasonModal
                show={showModal}
                handleCloseModal={handleCloseModal}
                reason={selectedReason}
                getDroppedReasons={getDroppedReasons}
            />
        </div>
    );
};

export default DroppedReasons;
