import React, { useState } from "react";
import CatalogList from "./components/CatalogList";
// import CatalogWorkloadLIst from "./components/CatalogWorkloadList";

const ServiceCatalog = () => {
    const [scId, setScId] = useState( null );

    console.log( scId );

    return <div className="row">
        <div className="col-sm-12">
            <CatalogList scId={scId} setScId={setScId} />
        </div>
        {/* <div className="col-md-7 col-sm-12">
            <CatalogWorkloadLIst scId={scId} />
        </div> */}
    </div>;
};

export default ServiceCatalog;
