import React, { useEffect, useState } from "react";
import {
    Card,   
    CardContent,
} from "@material-ui/core";
import util from "../../utils/miniUtils";
import ImageComponent from "../../utils/ImageComponent";

import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../../redux/actions/datefilter";


const IncentiveReport = () => {
    const [incentive, setIncentive] = useState( [] );
   
    let { from, to, applyFilter } = useSelector( ( state ) => state.filter ) || {};
    const dispatch = useDispatch();
    
    useEffect( () =>{
        if( applyFilter ) {
            getIncentiveReport();
        }
        
    },[applyFilter ] );
    
    const getIncentiveReport = async (  ) => {
        try {
            if( from === undefined ){
                const currentDate = new Date();
                from = currentDate.getFullYear() + '-' +
                  ( currentDate.getMonth() + 1 ) + '-' +
                  currentDate.getDate();
            }
            if( to === undefined ){
                const currentDate = new Date();
                to = currentDate.getFullYear() + '-' +
                ( currentDate.getMonth() + 1 ) + '-' +
                currentDate.getDate();
            }
            from = from ? util.convertToUTC( from ) : '';
            to = to ? util.convertToUTC( to ) : '';
            const response = await util.axios.get(
                `new_dashboard/get_incentive_report?from=${from}&to=${to}`
            );

            const { data, error, msg } = response.data;
           
            
            setIncentive( data );
            
            dispatch( setFilter( false ) );
            if ( error ) throw msg;
        } catch ( e ) {
            util.handleError( e );
        }
    };
    
    return (
        <Card style={{ width: "100%", height: "90%", overflow: 'hidden' }}>
            <CardContent style={{ height: "100%" }} >
                <div style={{ height: "100%", overflow: "auto" }}>
                    
                    
                    {/* <table className="table table-bordered"> */}
                    <table className="incentive-report">
                        <thead>
                            <tr>
                                <th>Job Id</th>
                                <th>Country</th>
                                <th>Client Name</th>
                                <th>Name Of Candidate </th>
                                <th>Sourced By</th>
                                <th>Date Of Joining</th>
                                <th className="numeric-values">Margin %</th>
                                {/* <th>Status</th> */}
                                
                            </tr>
                        </thead>
                        <tbody className="recruiter-report-body">
                            {incentive?.map( ( item,index ) => (
                                <tr key={index} className="recruiter-report-rows">
                                    <td>{item?.Job_ID}</td>
                                    <td>
                                        <img width={25} src={util.countryFlag( item?.country )} alt={item?.country} className="mr-1"/>
                                        {item?.country}</td>
                                    <td>{item?.client_name}</td>
                                    <td>{item.Onboarded_Talent_Name}</td>
                                    <td>{item?.recruiter_name}</td>
                                    <td>{item?.joining_date}</td>
                                    <td className="numeric-values">{item?.sell_margin}</td>
                                    {/* <td>{item.status}</td> */}
                                    
                                </tr>
                            ) )}
                        </tbody>
                    </table>
                    
                </div>
            </CardContent>
        </Card>
    );
};

export default IncentiveReport;

