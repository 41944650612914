import React from 'react';
import BigColumn from "../shared/BigColumn";
import "../shared/style.css";
import util from '../../utils/miniUtils';


const groupSettings = {
    createPath: `${util.url}/groups/create`,
    getListPath: `${util.url}/groups/simple-list`,
    getDetailPath: `${util.url}/groups/detail`,
    deletePath: `${util.url}/groups/delete_group`,
    updatePath: `${util.url}/groups/update`,
    getEmployeeList: `${util.url}/employee/get_employees_list`,
    assignTeamMemberPath: `${util.url}/groups/assign_member`,
    deleteTeamMemberPath: `${util.url}/groups/delete_member`,
    getAssignedTeamMemberPath: `${util.url}/groups/get_assigned_members`,
    title: "Groups",
    subTitle: "Groups Members"
};

export default function WorkloadCatalog() {
    return (
        <div className="container-fluid">
            <div className="row">
                <BigColumn settings={groupSettings} />
            </div>
        </div>
    );
}
