import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useReducer, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import util from '../../../utils/miniUtils';
import ReactQuill from 'react-quill';

const formReducer = ( state, action ) => {
    switch ( action.type ) {
        case 'UPDATE_STATE':
            return { ...action.payload };
        case 'SET_FIELD':
            return { ...state, [action.field]: action.value };
        case 'RESET_FORM':
            return {
                "gl_group": "",
                "wl_customer": "",
                "wl_resolver": "",
                "short_description": "",
                "long_description": "",
                "wl_category": "",
                "priority":"Medium",
                "parent_ticket_id": ""
            };
        default:
            return state;
    }
};

const initialState = {
    "gl_group": "",
    "wl_customer": "",
    "wl_resolver": "",
    "short_description": "",
    "long_description": "",
    "wl_category": "",
    "priority":"Medium",
    "parent_ticket_id": ""
};

const CreateEditWorkloadModal = ( { show, handleClose, isEdit=false, scId, workloadId=null } ) => {
    const [groupList, setGroupList] = useState( [] );
    const [categoryList, setCategoryList] = useState( [] );
    const [workloadList, setWorkloadList] = useState( [] );
    const [customerList, setCustomerList] = useState( [] );
    const [resolverList, setResolverList] = useState( [] );
    const [state, dispatch] = useReducer( formReducer, initialState );
    const [loading, setLoading] = useState( false );
    const [errors, setErrors] = useState( {} );    

    const getWorkloadDetails = async ( scId, workloadId ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/workload_service_catalog/workload/${scId}/${workloadId}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            dispatch( { type: 'UPDATE_STATE', payload: data } );
        } catch ( error ) {
            util.handleError( error );
        } finally {
            setLoading( false );
        }
    };

    const getCreateWorkloadInitialData = async () => {
        try {
            setLoading( true );
            const request = await util.axios.get( "/workload/get_create_initial_request" );
            const { error, msg, customerList, grpList } = request.data;
            if( error ) throw msg;
            setCustomerList( customerList );
            setGroupList( grpList );
        } catch ( error ) {
            util.handleError( error );
        } finally {
            setLoading( false );
        }
    };

    const getResolverList = async ( groupId ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/groups/get_assigned_members/${groupId}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setResolverList( data );
        } catch ( error ) {
            util.handleError( error );
        } finally {
            setLoading( false );
        }
    };

    const getCategoryList = async ( groupId ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/groups/get_categories/${groupId}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setCategoryList( data );
        } catch ( error ) {
            util.handleError( error );
        } finally {
            setLoading( false );
        }
    };

    const getWorkloadList = async () => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/workload_service_catalog/workloads/${scId}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setWorkloadList( data );
        } catch ( error ) {
            util.handleError( error );
        } finally {
            setLoading( false );
        }
    };

    const createWorkload = async ( scId, data ) => {
        try{
            setLoading( true );
            const request = await util.axios.post( `/workload_service_catalog/workload/${scId}`, data );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
            dispatch( { type: 'RESET_FORM' } );
            util.toast().success( msg );
            handleClose();
        } catch( error ) {
            util.handleError( error );
        } finally {
            setLoading( false );
        }
    };

    const updateWorkload = async ( scId, workloadId, data ) => {
        try {
            setLoading( true );
            const request = await util.axios.patch( `/workload_service_catalog/workload/${scId}/${workloadId}`, data );
            const { error, msg } = request.data;
            if( error ) throw msg;
            dispatch( { type: 'RESET_FORM' } );
            util.toast().success( msg );
            handleClose();
        } catch ( error ) {
            util.handleError( error );
        }finally {
            setLoading( false );
        }
    };

    const handleChange = ( e ) => {
        const { name, value } = e.target;
        dispatch( { type: "SET_FIELD", field: name, value } );
        validateField( name, value );
    };

    const handleLongDescription = ( e ) => {
        const value = e.toString( "html" );
        dispatch( { type: 'SET_FIELD', field: "long_description", value } );
    };

    const handleSubmitButton = () => {
        if ( validateForm() ) {
            const payload = { ...state };
            if ( isEdit ) {
                updateWorkload( scId, workloadId, payload );
            } else {
                createWorkload( scId, payload );
            }
        }
    };

    const validateForm = () => {
        const errors = {};
    
        if ( !state.wl_customer ) {
            errors.customer = "Customer is required.";
        }
    
        if ( !state.gl_group ) {
            errors.group = "Group is required.";
        }
    
        if ( !state.short_description ) {
            errors.short_description = "Title is required.";
        }
    
        if ( !state.wl_category ) {
            errors.category = "Category is required.";
        }
    
        if ( !state.wl_resolver ) {
            errors.resolver = "Resolver is required.";
        }
    
        if ( !state.priority ) {
            errors.priority = "Priority is required.";
        }
    
        setErrors( errors );
        return Object.keys( errors ).length === 0;
    };

    const validateField = ( name, value ) => {
        let error = "";
        switch ( name ) {
            case "wl_customer":
                if ( !value ) error = "Customer is required.";
                break;
            case "gl_group":
                if ( !value ) error = "Group is required.";
                break;
            case "short_description":
                if ( !value ) error = "Title is required.";
                break;
            case "wl_category":
                if ( !value ) error = "Category is required.";
                break;
            case "wl_resolver":
                if ( !value ) error = "Resolver is required.";
                break;
            case "priority":
                if ( !value ) error = "Priority is required.";
                break;
            default:
                break;
        }
        setErrors( ( prevErrors ) => ( {
            ...prevErrors,
            [name]: error,
        } ) );
    };
    
    useEffect( () => {
        if( show ) {
            getCreateWorkloadInitialData();
            getWorkloadList();
            if( isEdit && workloadId ) {
                getWorkloadDetails( scId, workloadId );
            }
        }
    },[show] );

    useEffect( () => {
        if( state.gl_group ) {
            getResolverList( state.gl_group );
            getCategoryList( state.gl_group );
        }
    },[state.gl_group] );

    return (
        <Modal show={show} size="lg" onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? "Edit Workload" : "Create Workload"}</Modal.Title>
            </Modal.Header>
            {loading ? (
                <Modal.Body>
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "30rem" }}>
                        <CircularProgress />
                    </div>
                </Modal.Body>
            ) : (
                <Modal.Body>
                    <Row>
                        <Col xs="12" md="6">
                            <div className='form-group'>
                                <label>Customer</label>
                                <select className='form-control' name="wl_customer" value={state.wl_customer} onChange={handleChange}>
                                    <option value=""></option>
                                    {customerList.length ? customerList.map( ( item, idx ) => <option
                                        key={item.account_id + "workload_customer" + idx}
                                        value={item.account_id}>{item.name}</option> ): ""}
                                </select>
                                {errors.customer && <span className="text-danger xs-fontSize">{errors.customer}</span>}
                            </div>
                        </Col>
                        <Col xs="12" md="6">
                            <div className='form-group'>
                                <label>Parent Ticket</label>
                                <select className='form-control'
                                    disabled={workloadList.length < 1}
                                    name="parent_ticket_id" 
                                    value={state.parent_ticket_id}
                                    onChange={handleChange}>
                                    <option value=""></option>
                                    {workloadList.map( el => <option key={el.id + "sc_workload"} value={el.id}>
                                        {el.short_description}
                                    </option> )}
                                </select>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" md="6">
                            <div className='form-group'>
                                <label>Group</label>
                                <select className='form-control' name="gl_group" value={state.gl_group} onChange={handleChange}>
                                    <option value=""> -- </option>
                                    {groupList.map( ( item ) => (
                                        <option key={item.id} value={item.id}>
                                            {item.itemName}
                                        </option>
                                    ) )}
                                </select>
                                {errors.group && <span className="text-danger xs-fontSize">{errors.group}</span>}
                            </div>
                        </Col>
                        <Col xs="12" md="6">
                            <div className='form-group'>
                                <label>Title</label>
                                <input className='form-control' name="short_description" value={state.short_description} onChange={handleChange} />
                                {errors.short_description && <span className="text-danger xs-fontSize">{errors.short_description}</span>}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" md="6">
                            <div className='form-group'>
                                <label>Category</label>
                                <select className='form-control' name="wl_category" value={state.wl_category} onChange={handleChange}>
                                    <option value=""> -- </option>
                                    {categoryList.map( ( item ) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ) )}
                                </select>
                                {errors.category && <span className="text-danger xs-fontSize">{errors.category}</span>}
                            </div>
                            
                            <div className='form-group'>
                                <label>Resolver</label>
                                <select className='form-control' name="wl_resolver" value={state.wl_resolver} onChange={handleChange}>
                                    <option value=""> -- </option>
                                    {resolverList.map( ( item ) => (
                                        <option key={item.id} value={item.account_id}>
                                            {item.name}
                                        </option>
                                    ) )}
                                </select>
                                {errors.resolver && <span className="text-danger xs-fontSize">{errors.resolver}</span>}
                            </div>

                            <div className='form-group'>
                                <label>Priority</label>
                                <select className='form-control' name="priority" value={state.priority} onChange={handleChange}>
                                    <option value=""> -- </option>
                                    {['Low','Medium','High','Critical'].map( ( item ) => (
                                        <option key={item} value={item}>
                                            {item}
                                        </option>
                                    ) )}
                                </select>
                                {errors.priority && <span className="text-danger xs-fontSize">{errors.priority}</span>}
                            </div>
                        </Col>
                        <Col xs="12" md="6">
                            <div className='form-group'>
                                <label>Description</label>
                                <ReactQuill
                                    value={state.long_description || ""}
                                    onChange={handleLongDescription}
                                    modules={{
                                        toolbar: [
                                            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                            ['bold', 'italic', 'underline'],
                                            [{ 'align': [] }],
                                            ['clean']
                                        ],
                                    }}
                                    formats={[
                                        'header', 'font',
                                        'bold', 'italic', 'underline',
                                        'list', 'bullet',
                                        'align'
                                    ]}
                                    style={{ height: '175px', marginBottom: '4rem' }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            )}
            <Modal.Footer>
                <button className='btn btn-secondary sizeBtn' onClick={handleClose}>Cancel</button>
                <button className='btn btn-secondary sizeBtn activeBtn' onClick={handleSubmitButton}>Save</button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateEditWorkloadModal;