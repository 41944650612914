import React, { useEffect, useState } from 'react';
import util from '../../../utils/miniUtils';
import ImageComponent from "../../../utils/ImageComponent";

import { MuiThemeProvider } from '@material-ui/core';
import MaterialTable from 'material-table';
import { Card } from 'react-bootstrap';
import CheckBoxSelector from '../../../shared/checkboxSelector';
import { RECRUITER_MANAGER, RECRUITER_ROLE } from '../../../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';

const SubmittedTodayReport = () => {
    const [loading, setLoading] = useState( true );
    const [data, setData] = useState( [] );
    const [filterData, setFilterData] = useState( {
        firstTime: true,
        recruiters: [],
        countries: [],
        clients: [],
        partners: [],
        sales_lead: [],
        startDate: new Date ( new Date().setDate( new Date().getDate() - 0 ) ).toISOString().split( 'T' )[0]
    } );

    // const getSubmittedTodayReport = async () => {
    //     try {
    //         setLoading( true );
    //         const report = await util.axios.get( `/dashboard/submitted_today` );
    //         const { error, result } = report.data;
    //         if( error ) throw error;
    //         setData( result );
    //     } catch ( error ) {
    //         util.handleError( error );
    //     }
    //     setLoading( false );
    // };

    const getFilterData = async () => {
        try {
            const request = await util.axios.get( `dashboard/filters_values` );
            const { error, msg, recruiters, countries, clients, partners, sales_lead } = request.data;
            if ( error ) throw  msg;
            setFilterData( { ...filterData, firstTime: false, recruiters, countries, clients, partners, sales_lead } );
        }catch ( e ) {
            util.handleError( e );
        }
    };

    const handleChange = ( e ) => {
        let { name, value } = e.target;
        setFilterData( { ...filterData, [name]: value } );
    };

    const updatedNewData = ( field, newData ) => {
        setFilterData( { ...filterData, [field]: newData } );
    };

    const applyFilter = async () => {
        // Put only required data in dataObject to filter the table
        setLoading( true );
        setData( [] );
        let dataObject = { 
            recruiters: filterData.recruiters.filter( item => { if ( item.checked === true ) return item.id;} ),
            country: filterData.countries.filter( item => { if ( item.checked === true ) return item.value;} ),
            partners: filterData.partners.filter( item => { if ( item.checked === true ) return item.id; } ),
            clients: filterData.clients.filter( item => { if ( item.checked === true ) return item.id; } ),
            sales_lead: filterData.sales_lead.filter( item => { if ( item.checked === true ) return item.id; } ),
            startDate: filterData.startDate
        };
        // In the case that the user is a recruiter, will send only hes OWN ID in the recruiters property
        if ( util.user.role === RECRUITER_ROLE ){
            dataObject.recruiters = [{ id: util.user.id }];
        }

        try {
            let url = `/dashboard/submitted_today`;
            const request = await util.axios.post( url, dataObject );
            let { error, msg, result } = request.data;
            if ( error ) throw  msg;
            setData( result );
            setLoading( false );
        }catch ( e ) {
            util.handleError( e );
            setLoading( false );
        }
    };


    useEffect( () => {
        if( !filterData.firstTime ) {
            applyFilter();
        }
    },[filterData] );

    useEffect( () => {
        getFilterData();
        // applyFilter();
    },[] );

    return (
        <div className='container-fluid mt-2'>
            <div className='row'>
                <div className='col-md-2'>
                    <Card elevation={1} className="p-2">
                        <SidebarFilter
                            filterData={filterData}
                            handleChange={handleChange}
                            updatedNewData={updatedNewData}
                        />
                    </Card>
                </div>
                <div className='col-md-10'>
                    { !loading && ( 
                        <MuiThemeProvider theme={util.defaultTableTheme}>
                            {/* {data.length > 0 && statusColumns.length > 0 && ( */}
                            <MaterialTable
                                columns={
                                    [   
                                        { 
                                            cellStyle: { maxWidth: '50px' },
                                            title: 'JobID', 
                                            field: 'job_id', 
                                            hidden:false,
                                            hiddenByColumnsButton:false,
                                            render: data => {
                                                return data.job_id ? <a href={`/jobs/position/detail/${data.job_id}`} target="new">
                                                    {util.zeroPad( data.job_id, 5 )}
                                                </a> : "TOTAL";
                                            }
                                        },
                                        { 
                                            cellStyle: { maxWidth: '50px' },
                                            title: 'Country', field: 'job_country', hidden:false,
                                            hiddenByColumnsButton:false,
                                            render: d => {
                                                return (
                                                    <div className="d-flex align-items-center">
                                                        <img width={25} src={util.countryFlag( d.job_country )} alt={d.job_country} className="mr-1"/>
                                                        {d.job_country}
                                                    </div>
                                                );
                                            } 
                                        },
                                        { 
                                            cellStyle: { maxWidth: "150px" },
                                            title: 'Job Title', field: 'job_title', hidden:false, hiddenByColumnsButton:false, 
                                        },
                                        {
                                            cellStyle: { maxWidth: "100px" },
                                            title: "Status", field: "statusName", hidden: false, hiddenByColumnsButton: false
                                        },
                                        {
                                            cellStyle: { maxWidth: "100px" },
                                            title: "Status Change Date",
                                            field: 'status_change_date',
                                            hidden: false,
                                            hiddenByColumnsButton: false,
                                            render: d => {
                                                return (
                                                    <div>{util.humanDateTime( d.status_change_date )}</div>
                                                );
                                            }
                                        },
                                        {
                                            cellStyle: { maxWidth: "100px" },
                                            title: 'Lead Recruiter', field: 'leadRecruiterName', hidden:false, hiddenByColumnsButton:false, 
                                        },
                                        { 
                                            cellStyle: { maxWidth: "100px" },
                                            title: 'Sales Lead', field: 'salesLeadName', hidden:false, hiddenByColumnsButton:false, 
                                        },
                                        { 
                                            cellStyle: { maxWidth: "100px", },
                                            title: 'Submitted Talent', 
                                            field: 'salesLeadName', 
                                            render: data => (
                                                <ol className="d-flex justify-content-center flex-column">
                                                    {data.talents.map( ( el,idx ) => <li key={"submittedTalent" + idx}>
                                                        {el}
                                                    </li> )}
                                                </ol>
                                            ),
                                            hidden:false, 
                                            hiddenByColumnsButton:false, 
                                        },
                                    ]
                                }
                                data={data}
                                options={{
                                    searchFieldAlignment:"left",
                                    toolbarButtonAlignment:'left',
                                    emptyRowsWhenPaging: false,
                                    pageSizeOptions:[20,50,100],
                                    pageSize: 50,
                                    tableLayout:"auto",
                                    columnsButton:true,
                                    showTitle: false,
                                    headerStyle:{ position:'sticky',top:0 },
                                    maxBodyHeight:'calc(100vh - 240px)',
                                }}
                                actions={[
                                //     {
                                //         icon: () => <div className={"btn btn-secondary jobButton activeBtn btn-sm"}>
                                //             <FontAwesomeIcon icon={faFileExport} className="mr-2" />
                                //         Export All
                                //         </div>,
                                //         tooltip: "Export All",
                                //         isFreeAction: true,
                                //         onClick: () => util.DownloadTableAsCsv( 'MuiTable-root', 1, "RecruiterReport" ),
                                //     },
                                    {
                                        icon: () => <FontAwesomeIcon icon={faSync} style={{ width:'15px' }} />,
                                        tooltip: "Reload Data",
                                        isFreeAction: true,
                                        onClick: () => applyFilter( ),
                                    }
                                ]}
                            />
                            {/* )} */}
                        </MuiThemeProvider>
                    )}
                </div>
            </div>

        </div>
    );
};

export default SubmittedTodayReport;

const SidebarFilter = ( { filterData, updatedNewData, customTitle = "Recruiter" } ) => {
    return <>
        <div className="container-fluid fontSize13">
            <div className="row">
                {/* <div className="col-md-12 p-sm-0 m-sm-0">
                    <div className="text-left w-100">
                        <div className="sss">
                            <label className="w-100" htmlFor="startDate"> Start Date <br/><input className="form-control" value={filterData.startDate} type="date" onChange={handleChange}  id="startDate" name="startDate"/></label>
                        </div>
                    </div>
                    <hr className="p-2 m-0"/>
                </div> */}
                {
                    util.user.role === RECRUITER_MANAGER ?

                        <CheckBoxSelector
                            dataArray={filterData.recruiters} 
                            title={customTitle}
                            updateData={ ( data ) => updatedNewData( "recruiters", data ) }  
                            isOpenByDeafult={true}
                        />
                        :
                        null
                }
                <CheckBoxSelector
                    dataArray={filterData.countries} 
                    title="Country"
                    updateData={ ( data ) => updatedNewData( "countries", data ) }
                    isOpenByDeafult={util.user.role !== RECRUITER_MANAGER}
                />
                {/* 
                <CheckBoxSelector
                    dataArray={filterData.clients} 
                    title="Client"
                    updateData={ ( data ) => updatedNewData( "clients", data ) }  
                />
                <CheckBoxSelector
                    dataArray={filterData.partners} 
                    title="Partner"
                    updateData={ ( data ) => updatedNewData( "partners", data ) }  
                />
                {customTitle === "Recruiter" ? <CheckBoxSelector
                    dataArray={filterData.sales_lead} 
                    title="Sales Lead"
                    updateData={ ( data ) => updatedNewData( "sales_lead", data ) }  
                /> : ""} */}
            </div>
        </div>   
    </>;
};