import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import util from "../../../../utils/miniUtils";
import { faBan, faCloudUploadAlt, faFileUpload, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";


export default function UploadForm( { data, refreshData } ) {
	
    const [selectedFile, setSelectedFile] = useState( null );
    const [loading, setLoading] = useState( false );
    const [info,setInfo] = useState( {} );
	
    useEffect( () => {
        setInfo( data );
    },[data] );
	
    const onChangeHandler = event => {
        const fileSize = ( event.target.files[0].size / 1024 / 1024 ).toString();
        if ( fileSize.split( "." )[0] > 2 ) {
            toast.error( "File size is exceded, max 2 MB" );
            setSelectedFile( null );
        } else {
            setSelectedFile( event.target.files[0] );
        }
    };
	
    async function saveDocument() {
        try {
            setLoading( true );
            let formData = new FormData();
            formData.append( 'docId', data["docId"] );
            formData.append( 'filename', selectedFile );
            let request = await util.axios.put( `${util.url}/job_process/documents/talent_file/${data["docId"]}`, formData );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            setLoading( false );
            setSelectedFile( null );
            refreshData();
            util.toast().success( msg );
        }catch ( e ) {
            setLoading( false );
            util.handleError( e );
        }
    }
	
	
    return(
        <Fragment>
            {selectedFile === null ?
                <Fragment>
                    <label htmlFor="filesX" className="uploadFileColor isPointer">
                        {info["talent_filename"] !== null ?
                            <FontAwesomeIcon icon={faSyncAlt} className="mr-2" />
                            :
                            <Fragment>
                                <FontAwesomeIcon icon={faFileUpload} className="mr-2" />
								Upload file
                            </Fragment>
                        }
					
                    </label>
                    <input
                        style={{ display: "none" }}
                        type="file"
                        id="filesX"
                        onChange={onChangeHandler}
                        name="file"
                        accept=".pdf,.doc,.docx"
                    />
                </Fragment>
                :
                <Fragment>
                    <div className="text-center">{selectedFile.name}
                        <Tooltip title="Cancel upload">
                            <span>
                                <FontAwesomeIcon
                                    onClick={()=> setSelectedFile( null )}
                                    icon={faBan} className="ml-2 text-danger" />
                            </span>
                        </Tooltip>
                        <Tooltip title="Upload file">
                            <span>
                                <FontAwesomeIcon
                                    onClick={saveDocument}
                                    icon={faCloudUploadAlt} className="ml-2 text-success" />
                            </span>
                        </Tooltip>
                    </div>
				
                </Fragment>
            }
			
            {selectedFile === null && info["talent_filename"] !== null ?
                ( info.filename ?
                    <a href={`${util.url}/${util.AWSURL}/candidate/${info.talent_id}/beeline/${info["talent_filename"]}`} target="new">View File</a>
                    :"" ):""}
			
            {util.LOADING_SCREEN( loading )}
        </Fragment>
    );
}