import React from 'react';
import BigColumn from "../shared/BigColumn";
import "../shared/style.css";
import util from '../../utils/miniUtils';

const departmentSettings = {
    createPath: `${util.url}/department/create`,
    getListPath: `${util.url}/department/simple-list`,
    getDetailPath: `${util.url}/department/detail`,
    deletePath: `${util.url}/department/delete`,
    updatePath: `${util.url}/department/update`,
    getEmployeeList: `${util.url}/employee/get_employees_list`,
    assignTeamMemberPath: `${util.url}/department/assign_member`,
    deleteTeamMemberPath: `${util.url}/department/delete_member`,
    getAssignedTeamMemberPath: `${util.url}/department/get_assigned_members`,
    title: "Departments",
    subTitle: "Department Members"
};



export default function WorkloadCatalog() {
    return (
        <div className="container-fluid">
            <div className="row">
                <BigColumn settings={departmentSettings} />
            </div>
        </div>
    );
}
