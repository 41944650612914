import React, { Fragment, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import util from "../../utils/miniUtils";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";

function Settings() {
    const [settings, setSettings] = useState( { session_expire_time:"", title: "" } );
    const [logoImage, setLogoImage] = useState( null );
    const [smallLogoImage, setSmallLogoImage] = useState( null );

    useEffect( () => {
        getSettings().then( null );
    },[] );

    async function getSettings() {
        try {
            const request = await util.axios.get( `${util.url}/app_resources/settings` );
            const { error, msg, appSettings } = request.data;
            setSettings( appSettings );
            if ( error ) throw msg;
        }catch ( e ) {
            util.handleError( e );
        }
    }

    async function saveChanges() {
        try {
            const request = await util.axios.put( `${util.url}/app_resources/settings`, settings );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
            await getSettings();
        }catch ( e ) {
            util.handleError( e );
        }
    }

    async function uploadLogo() {
        try {
            const data = new FormData();
            data.append( "file", logoImage );
            const request = await util.axios.post( "/app_resources/settings/upload_logo", data );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
            setLogoImage( null );
            await getSettings();
        } catch ( error ) {
            util.handleError( error ); 
        }
    }

    async function removeLogo() {
        try {
            const request = await util.axios.put( "/app_resources/settings/default_logo" );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
            setLogoImage( null );
            await getSettings();
        } catch ( error ) {
            util.handleError( error ); 
        }
    }

    async function removeSmallLogo() {
        try {
            const request = await util.axios.put( "/app_resources/settings/default_small_logo" );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
            setLogoImage( null );
            await getSettings();
        } catch ( error ) {
            util.handleError( error ); 
        }
    }

    async function uploadSmallLogo() {
        try {
            const data = new FormData();
            data.append( "file", smallLogoImage );
            const request = await util.axios.post( "/app_resources/settings/upload_small_logo", data );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
            setSmallLogoImage( null );
            await getSettings();
        } catch ( error ) {
            util.handleError( error ); 
        }
    }

    function onChangeHandler( e ) {
        const { name, value } = e.target;
        let temp = settings;
        if ( name ==="session_expire_time" ) temp.session_expire_time = value;
        setSettings( Object.assign( {}, temp ) );
    }

    function handleImgUpload( e ) {
        if( e.target.name === "logo" ) {
            setLogoImage( e.target.files[0] );
        } else {
            setSmallLogoImage( e.target.files[0] );
        }
    }

    return (
        <Fragment>
            <div className="bg-white container-fluid m-2 p-2">
                <div className="row mt-2 p-2">
                    <div className="col-md-12">
                        <h3>Global Settings</h3>
                        
                    </div>
                    <div className="col-md-6">
                        <div>
                            <b>Time for active session</b>
                            <div>
                                <input
                                    className="form-control"
                                    title="Time is in seconds"
                                    placeholder="Session Time"
                                    onChange={onChangeHandler}
                                    value={settings.session_expire_time}
                                    name="session_expire_time"
                                />
                                {/* <button
                                    onClick={saveChanges}
                                    className="btn ml-2 btn-secondary sizeBtn activeBtn">
                                    <FontAwesomeIcon icon={faSyncAlt} className="mr-2" />
                                    Update
                                </button> */}
                            </div>

                            <div className="mt-3">
                                <b>Application Title</b>
                                <div>
                                    <input
                                        className="form-control"
                                        title="Application Title"
                                        placeholder="Application Title"
                                        onChange={onChangeHandler}
                                        value={settings.title}
                                        name="title"
                                    />
                                </div>
                            </div>

                            <div className="mt-3">
                                <b>Logo</b>
                                <div>
                                    <input
                                        title="Logo"
                                        placeholder="Logo"
                                        accept="image/*, .svg, .webp"
                                        onChange={handleImgUpload}
                                        name="logo"
                                        type="file"
                                    />
                                    {logoImage ? <button
                                        className="btn btn-secondary sizeBtn activeBtn"
                                        onClick={uploadLogo}>
                                    Upload
                                    </button> : ""}
                                </div>
                                {settings.logo ? <div>
                                    <label>Preview</label>
                                    <img
                                        style={{ maxWidth: "100px", marginLeft: "10px" }}
                                        src={`${util.baseUrl()}${util.AWSURL()}/global/images/${settings.logo}`}
                                        alt={settings.title} />
                                    <button
                                        className="btn btn-secondary sizeBtn activeBtn ml-2"
                                        onClick={removeLogo}>
                                    Remove
                                    </button>
                                </div> : ""}
                            </div>

                            <div className="mt-3">
                                <b>Small Logo</b>
                                <div>
                                    <input
                                        title="Small Logo"
                                        placeholder="Logo"
                                        accept="image/*, .svg, .webp"
                                        onChange={handleImgUpload}
                                        name="small_logo"
                                        type="file"
                                    />
                                    {smallLogoImage ? <button
                                        className="btn btn-secondary sizeBtn activeBtn"
                                        onClick={uploadSmallLogo}>
                                    Upload
                                    </button> : ""}
                                </div>
                                {settings.small_logo ? <div>
                                    <label>Preview</label>
                                    <img 
                                        style={{ maxWidth: "100px", marginLeft: "10px" }}
                                        src={`${util.baseUrl()}${util.AWSURL()}/global/images/${settings.small_logo}`}
                                        alt={settings.title} />

                                    <button
                                        className="btn btn-secondary sizeBtn activeBtn"
                                        onClick={removeSmallLogo}>
                                    Remove
                                    </button>
                                </div> : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Settings;
