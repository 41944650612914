import React from 'react';
const ItemMember = ( { data, onDelete } ) => {
    const { name, employee_id } = data;
    return (
        <div className="d-flex justify-content-between align-items-center p-2 border rounded mt-1 mb-1 department_item">
            <div>
                <div>
                    <b className='text-dark'>{name}</b>
                </div>
            </div>
            <div>
                <span className="miniButton btn btn-outline-danger" onClick={( e ) => onDelete( e, employee_id )}>
					Delete
                </span>
            </div>
        </div>
    );
};

export default ItemMember;
