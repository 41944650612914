import React from "react";
import util from "../../../../../utils/miniUtils";


export default function WorkHistoryPrintVersion( { wh } ) {
	
    return(
        <tr key={wh.id}>
            <td className="d-flex justify-content-around">
                <span>
                    {util.fixDate( wh.from )}
                </span>
				-
                <span>
                    {util.fixDate( wh.to )}
                </span>
			
            </td>
            <td>
                <div className="d-flex">
                    {wh.company} / {wh.position}
                </div>
			
            </td>
            <td>
                {wh.location ? wh.location: ""}
            </td>
        </tr>
    );
}