import { Store } from "pullstate";

export const ResumeStore = new Store( {
    data:{},
    datas:[],
    file:null,
    index:null,
    files:[],
    section:"Personal"

} );