import React, { Fragment, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MaterialTable from "material-table";
import util from "../../utils/miniUtils";
import { faBan, faPlusCircle, faSave, faSyncAlt, } from "@fortawesome/free-solid-svg-icons";
import { MuiThemeProvider } from "@material-ui/core";

function EmailTemplates() {
    let [loading, setLoading] = useState( false );
    const [data, setData] = useState( { title: "", description: "", isNew: true } );
    const [emailTemplates, setEmailTemplates] = useState( [] );

    useEffect( () => {
        getEmailTemplateList().then( r => null );
    }, [] );

    async function getEmailTemplateList() {
        try {
            setLoading( true );
            const request = await util.axios.get( `${util.url}/app_resources/email_templates` );
            const { error, msg, emails } = request.data;
            if ( error ) return util.toast().error( msg );
            setEmailTemplates( emails );
            setLoading( false );
        }catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    }

    function selectJob( data ) {
        data.isNew = false;
        setData( data );
    }

    function createNewEmailTemplate() {
        setData( { title: "", description: "", isNew: true } );
    }

    function reloadEmailTemplates( emailTemplates, id ) {
        setEmailTemplates( emailTemplates );
        setData( emailTemplates.filter( ( et ) => et.id === id )[0] );
    }

    function reloadAfterDelete( emailTemplates ) {
        setEmailTemplates( emailTemplates );
        setData( { title: "", description: "", isNew: true } );
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-3">
                    <MuiThemeProvider theme={util.defaultTableTheme}>
                        <MaterialTable
                            columns={[
                                {
                                    field: "title",
                                    sorting: false,
                                },
                            ]}
                            data={emailTemplates}
                            options={{
                                pageSize: 15,
                                sorting: false,
                                showFirstLastPageButtons: false,
                                pageSizeOptions: [],
                                showTitle: false,
                                padding: "dense",
                                paginationType: "stepped",
                            }}
                            onRowClick={( event, rowData ) => selectJob( rowData )}
                            // actions={[
                            //     {
                            //         icon: () => (
                            //             <FontAwesomeIcon
                            //                 icon={faPlusCircle}
                            //                 id="addRequirementIcon"
                            //             />
                            //         ),
                            //         tooltip: "Add Job Description",
                            //         isFreeAction: true,
                            //         onClick: ( event ) => createNewEmailTemplate(),
                            //     },
                            // ]}
                        />
                    </MuiThemeProvider>
                </div>
                <div className="col-md-9">
                    <EmailTemplateForm data={data} reloadEmailTemplates={reloadEmailTemplates} relaodAfterDelete={reloadAfterDelete} />
                </div>
               



            </div>
            {util.LOADING_SCREEN( loading )}
        </Fragment>
    );
}

function EmailTemplateForm( props ) {
    const [emailTemplate, setEmailTemplate] = useState( props.data );
    const [title, setTitle] = useState( emailTemplate.title === "" ? "" : emailTemplate.title );
    const [description, setDescription] = useState( emailTemplate.description === "" ? "" : emailTemplate.description );
    const textAreaRef = useRef();


    useEffect( () => {
        setTitle( props.data.title );
        setDescription( props.data.description );
        setEmailTemplate( props.data );
        return () => {};
    }, [props.data] );

    function handleChange( e ) {
        const { value } = e.target;
        setTitle( value );
    }

    function handleRichTextChange( e ) {
    // const value = e.toString("html");
        const value = e.target.value;
        setDescription( value );
    }

    function handleClick() {
        if ( emailTemplate.isNew ) {
            createEmailTemplate();
        } else {
            updateEmailTemplate();
        }
    }

    function createEmailTemplate() {
        const data = { title: title, description: description };
        if ( title === "" ) {
            util.toast().error( "Title can't be empty" );
            return;
        }
        if ( description === "" ) {
            util.toast().error( "Description can't be empty" );
            return;
        }

        util
            .axios
            .post( `${util.url}/app_resources/email_templates`, data )
            .then( ( response ) => {
                const { msg, error, emails, id } = response.data;
                if ( error ) throw msg;
                util.toast().success( msg );
                props.reloadEmailTemplates( emails, id );
            } )
            .catch( ( error ) => {
            } );
    }

    function updateEmailTemplate() {
        const data = { title: title, description: description };
        util
            .axios
            .put( `${util.url}/app_resources/email_templates/${emailTemplate.id}`, data )
            .then( ( response ) => {
                const { msg, error, emails } = response.data;
                if ( error ) throw msg;
                util.toast().success( msg );
                props.reloadEmailTemplates( emails, emailTemplate.id );
            } )
            .catch( ( error ) => {
            } );
    }

    function deleteJobDescription() {
        util
            .axios
            .delete( `${util.url}/app_resources/email_templates/${emailTemplate.id}` )
            .then( ( response ) => {
                const { msg, error, emails } = response.data;
                if ( error ) throw msg;
                util.toast().success( msg );
                props.relaodAfterDelete( emails );
            } )
            .catch( ( error ) => {
            } );
    }

    function variableSelected( e ) {
        let VARIABLE = " ["+ e.target.innerText +"] ";
        setDescription( description + VARIABLE );
        textAreaRef.current.focus();
    }




    return (
        <Fragment>
            <div className="d-flex">
                <div className="col">
                    <div className="mb-1 mt-3">
                        <input
                            placeholder="Position Title"
                            value={title}
                            className="form-control textAreaEmailTemplate"
                            name="title"
                            onChange={handleChange}
                            autoFocus={true}
                        />
                    </div>
                    <div>
                        <textarea
                            ref={textAreaRef}
                            className="form-control textAreaEmailTemplate"
                            rows={23}
                            value={description|| ""}
                            onChange={handleRichTextChange}

                        />
                        {/*<Editor*/}
                        {/*  apiKey='co5tvvfh4ltli0l9urtqbvfbrevuswtkqdmu3lvf0t3lme9p'*/}
                        {/*  value={description || ""}*/}
                        {/*  init={{*/}
                        {/*    height: 500,*/}
                        {/*    menubar: false,*/}
                        {/*    plugins: "lists",*/}
                        {/*    toolbar:"formatselect | bold italic underline code| alignleft aligncenter alignright alignjustify | bullist numlist | removeformat",*/}
                        {/*  }}*/}
                        {/*  onEditorChange={handleRichTextChange}*/}
                        {/*/>*/}
          
                        {emailTemplate.isNew ? (
                            ""
                        ) : (
                            <button
                                onClick={deleteJobDescription}
                                className="btn btn-danger sizeBtn activeBtn float-left m-2"
                            >
                                <FontAwesomeIcon icon={faBan} className="mr-2" />
            Delete
                            </button>
                        )}

                        <button
                            onClick={handleClick}
                            className="btn btn-success sizeBtn activeBtn m-2"
                        >
                            <FontAwesomeIcon
                                icon={emailTemplate.isNew ? faSave : faSyncAlt}
                                className="mr-2"
                            />
                            {emailTemplate.isNew ? "Save" : "Update"}
                        </button>
                    </div>
                </div>
                <div className="variables col-lg-3 bg-white p-2">
                    <h5 className="font-weight-bold">You can use this variables</h5>
                    <ul onClick={( e )=>variableSelected( e )} className="emailUseList">
                        <li>EVALUATOR_NAME</li>
                        <li>EVALUATION_LINK</li>
                        <li>TALENT_NAME</li>
                        <li>JOB_TITLE</li>
                        <li>JOB_DESCRIPTION</li>
                        <li>JOB_LOCATION</li>
                        <li>INTERVIEW_DATE</li>
                        <li>RECRUITER_NAME</li>
                        <li>RECRUITER_EMAIL</li>
                        <li>RECRUITER_PHONE</li>
                        <li>EJ_AMERICA_LINK</li>
                    </ul>
                </div>
            </div>
        </Fragment>
    );
}

export default EmailTemplates;
