import React, { useEffect, useRef, useState } from 'react';
import util from '../../../utils/miniUtils';
import { MuiThemeProvider, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import MaterialTable from "material-table";
import { useSelector } from 'react-redux';

const CandidateJourney = () => {
    const { from, to } = useSelector( ( state ) => state.filter ) || {};
    const [state, setState] = useState( [] );
    const [search, setSearch] = useState( "" );
    const [page, setPage] = useState( 0 );
    const [limit, setLimit] = useState( 10 );
    const [total, setTotal] = useState( 0 );
    const [loading, setLoading] = useState( false );

    const getData = async () => {
        try {
            setLoading( true );
            const response = await util.axios.get( `${util.url}/dashboard/talent_journey?startDate=${from}&endDate=${to}&page=${page}&limit=${limit}&search=${search}` );
            const { error, msg, data, total } = response.data;
            if ( error ) throw msg;
            setState( data );
            setTotal( total );
        } catch ( error ) {
            util.handleError( error );
        } finally{
            setLoading( false );
        }
    };

    const previousSearch = useRef( search ); 

    useEffect( () => {
    
        let timeoutId;
    
        if ( previousSearch.current !== search ) {
            timeoutId = setTimeout( () => {
                getData();
            }, 1000 );
            previousSearch.current = search;
        } else {
            getData();
        }
    
        return () => clearTimeout( timeoutId );
    }, [search, page, limit, from, to] );

    function calculateTimeDifference( start, end ) {
        const startDate = new Date( start );
        const endDate = new Date( end );
        const diffMilliseconds = endDate - startDate;
        const days = Math.floor( diffMilliseconds / ( 1000 * 60 * 60 * 24 ) );
        const hours = Math.floor( ( diffMilliseconds % ( 1000 * 60 * 60 * 24 ) ) / ( 1000 * 60 * 60 ) );
        const minutes = Math.floor( ( diffMilliseconds % ( 1000 * 60 * 60 ) ) / ( 1000 * 60 ) );
        return `${days} ${days > 1 ? "days" : "day"} ${hours} ${hours > 1 ? "hours" : "hour"} ${minutes} ${minutes > 1 ? "minutes" : "minute"}`;
    }

    const GetOnboardedTime = ( positionDetails ) => {
        if ( positionDetails.length === 0 ) return "";
        const onboarded = positionDetails.find( el => el.status === "Onboarded" );
        if ( onboarded ) return calculateTimeDifference( onboarded.createdAt, onboarded.updatedAt );
        return "";
    };

    return (
        <div className='non-draggable'>
            <MuiThemeProvider theme={util.defaultTableTheme}>
                <MaterialTable
                    columns={[
                        { 
                            title: "Id",
                            field: "candidate_id",
                            render: rowData => <a
                                href={`/candidates/${rowData.candidate_id}`}
                                target="_blank" rel="noopener noreferrer"
                                className='text-primary'>{rowData.candidate_id}</a>,
                            
                            cellStyle: { "width": "10%" } 
                        },
                        { title: "Candidate Name", field: "name", cellStyle: { "width": "20%" } },
                        { title: "Email", field: "email" },
                        { 
                            title: "Location", 
                            field: "country", 
                            render: rowData => <div className="text-left d-flex justify-content-start align-items-center">
                                {rowData.country ? <img width={30} src={util.countryFlag( rowData.country )} alt={rowData.country} className="mr-1" /> : ""}
                                {util.location( rowData.country, rowData.state, rowData.city )}
                            </div>,
                            cellStyle: { "width": "10%" } },
                        {
                            title: "Positions",
                            field: "position_details",
                            render: rowData => rowData.position_details.length,
                            cellStyle: { "width": "10%" },
                            align: "right"
                        },
                        {
                            title: "Onboarded",
                            field: "onboarded",
                            align: "center",
                            render: rowData =>
                                rowData.position_details.find( el => el.status === "Onboarded" ) ? (
                                    <FontAwesomeIcon icon={faCheck} className='text-success' />
                                ) : (
                                    ""
                                )
                        },
                        {
                            title: "Onboarded Within",
                            field: "onboarded_within",
                            render: rowData => GetOnboardedTime( rowData.position_details ),
                            cellStyle: { "width": "10%" }
                        }
                    ]}
                    isLoading={loading}
                    data={state}
                    detailPanel={rowData => (
                        <div style={{ padding: "16px" }}>
                            {rowData.position_details.map( position => ( 
                                <div key={position.position_id}>
                                    <Typography variant="subtitle2" gutterBottom component="div">
                                        <a className='mr-1 text-primary' href={`/jobs/position/detail/${position.position_id}`} target="_blank" rel="noopener noreferrer">{position.position_id}</a>
                                        <span className='mr-1'>{position.job_title},</span>
                                        <span>
                                            {position.country ? <img width={30} src={util.countryFlag( position.country )} alt={position.country} className="mr-1" /> : ""}
                                            {util.location( position.country, position.state, position.city )}
                                        </span>
                                    </Typography>
                                    <StatusNote status_note={position.status_note} />
                                </div> ) )}
                        </div>
                    )}
                    totalCount={total}
                    page={page}
                    limit={limit}
                    onChangePage={page => setPage( page )}
                    onChangeRowsPerPage={limit => setLimit( limit )}
                    onSearchChange={search => setSearch( search )}
                    onRowClick={( event, rowData, togglePanel ) => togglePanel()}
                    localization={{
                        toolbar: {
                            searchPlaceholder: "Name, Email"
                        }
                    }}
                    options={{
                        sorting: true,
                        emptyRowsWhenPaging: false,
                        search: true,
                        showTitle: false,
                        searchFieldAlignment: "left",
                        paging: true,
                        pageSize: 10,
                        pageSizeOptions: [5, 10, 20]
                    }}
                />
            </MuiThemeProvider>
        </div>
    );
};

export default CandidateJourney;

const StatusNote = ( { status_note } ) => {

    if( !status_note ) return null;

    const data = JSON.parse( status_note );

    const GetStatusColor = ( { status } ) => {
        if( !status ) return "";
        if( status === "Onboarded" ) return <span className='badge badge-success mr-1'>{status}</span>;
        if( status === "Submitted" ) return <span className='badge badge-warning mr-1'>{status}</span>;
        if( status === "Rejected" ) return <span className='badge badge-danger mr-1'>{status}</span>;
        
        return <span className='badge badge-secondary mr-1'>{status}</span>;
    };

    return <div className='d-flex flex-wrap'>
        {data.map( ( el, idx ) => ( <div key={el.timeStamp + idx} className="card mr-1 p-1 mb-1" style={{ "width": "21rem" }}>
            <div className='d-flex flex-wrap'>
                <GetStatusColor status={el.statusName} />
                {el.comment ? <>
                    <span className="mr-1">updated by</span>
                    <span className="badge badge-primary">{`${el.comment.user.name}`}</span>
                    <span>{` : ${el.comment.value}`}</span>
                </> : ""}
                <span className="ml-1">{`on ${el.timeStamp.split( " " )[0]} at ${el.timeStamp.split( " " )[1]}`}</span>
            </div>
        </div> ) )}
    </div>;
    
};