import React, { Fragment, useEffect, useState } from "react";
import util from "../../../utils/miniUtils";
import ImageComponent from '../../../utils/ImageComponent';

import { FormControl, InputGroup } from "react-bootstrap";
import ModalChangeConfirmation from "./ModalChangeConfirmation";

export default function CountryCTCComponent( { singleCountry, refreshData } ) {

    const [data, setData] = useState( singleCountry );
    const [dataBase, setDataBase] = useState( singleCountry );
    const [showUpdate, setShowUpdate] = useState( true );
    const [expanded, setExpanded] = useState( false );
    const [show, setShow] = useState( false );

    // detect changes
    useEffect( () => {
        if ( JSON.stringify( data ) !== JSON.stringify( dataBase ) ){
            setShowUpdate( true );
        }else{
            setShowUpdate( false );
        }
    },[ data ] );
    
    // re-rendering when singleCountry change
    useEffect( () => {
        setData( singleCountry );
        setDataBase( singleCountry );
        setExpanded( true );
    },[ singleCountry ] );

    function onChangeHandler( e, index ) {
        const { name, value } = e.target;
        let temp = { ...JSON.parse( JSON.stringify( data ) ) };
        temp.data[index][name] = value;
        setData( temp );
    }

    // Update values of country ctc setting
    async function updateChanges() {
        try {
            // Prepare object for DB
            let temp = { ...JSON.parse( JSON.stringify( data ) ) };
            temp.value = {
                country: temp.country,
                data: temp.data
            };
            delete temp.country;
            delete temp.data;
            temp.isJSON = true;
            const request = await util.axios.put( `catalog/data/update`, temp );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            setShow( false );
            setDataBase( Object.assign( {}, data ) );
            setData( Object.assign( {}, data ) );
            util.toast().success( msg );
        }catch ( e ) {
            util.handleError( e );
        }
    }

    // Delete country ctc settings (status 0)
    async function deleteCountry() {
        try {
            // Prepare object for DB
            let temp = {
                catalog: "ctc_countries",
                id: data.id
            };

            const request = await util.axios.patch( `catalog/data/delete`, temp );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
            refreshData( );
        }catch ( e ) {
            util.handleError( e );
        }
    }


    return (
        <Fragment>
            {
                expanded ?
                    <Fragment>
                        <table className="table">
                            <tbody>
                                <tr className="text-left">
                                    <td className="align-middle border-0 text-center isPointer" title="Minimize" onClick={()=> setExpanded( !expanded )}>
                                        <img width={25} src={util.countryFlag( data.country )} alt={ data.country }/>
                                        {data.country}
                                    </td>
                                    <td colSpan={5} className=" border-0">
                                        <table className="w-100">
                                            <thead>
                                                <tr className="text-left">
                                                    <th>Talent Engagement Type</th>
                                                    <th>Markup (Payroll, Tax, SGA etc Loading)</th>
                                                    <th>Billable Days in a Year</th>
                                                    <th>Working Hours in Day</th>
                                                    <th>Avg. Billable Days in a Month</th>
                                                    <th>Default eJ-Offer-Unit  to Talent</th>
                                                    <th>Default Sell-Unit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.data.map( ( d, index ) => {

                                                        return (
                                                            <tr key={index}>
                                                                <td>{d.employmentType}</td>
                                                                <td>
                                                                    <InputGroup className="mb-3">
                                                                        <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                                                        <FormControl
                                                                            onChange={( e ) => onChangeHandler( e, index )}
                                                                            type="number"
                                                                            name="markUp"
                                                                            value={d.markUp}
                                                                        />
                                                                    </InputGroup>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        className="form-control text-center"
                                                                        onChange={( e ) => onChangeHandler( e, index )}
                                                                        type="number"
                                                                        name="billableDaysInYear"
                                                                        value={d.billableDaysInYear}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        className="form-control text-center"
                                                                        onChange={( e ) => onChangeHandler( e, index )}
                                                                        type="number"
                                                                        name="workingHoursInDay"
                                                                        value={d.workingHoursInDay}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        className="form-control text-center"
                                                                        onChange={( e ) => onChangeHandler( e, index )}
                                                                        type="number"
                                                                        name="averageBillableDaysInMonth"
                                                                        value={d.averageBillableDaysInMonth}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <select onChange={( e )=> onChangeHandler( e, index )} className="form-control" name="defaulteJOfferUnitToTalent" value={d.defaulteJOfferUnitToTalent} >
                                                                        {
                                                                            util.ejUnit.map( ( u , i ) => {
                                                                                return <option key={i} value={u}>{u}</option>;
                                                                            } )
                                                                        }
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <select onChange={( e )=> onChangeHandler( e, index )} className="form-control" name="defaultSellUnit" value={d.defaultSellUnit} >
                                                                        {
                                                                            util.ejUnit.map( ( u , i ) => {
                                                                                return <option key={i} value={u}>{u}</option>;
                                                                            } )
                                                                        }
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        );
                                                    } )
                                                }
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={6} className="text-right">
                                        {
                                            showUpdate ?
                                                <button onClick={()=>setShow( true )} className="btn btn-primary btn-sm mr-2">
                                                Update
                                                </button>
                                                :
                                                ""
                                        }
                                        <util.PopOverConfirmation callBackOk={deleteCountry} />

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        { 
                            show ?
                                <ModalChangeConfirmation show={show} handleClose={()=> setShow( false )} updateChanges={updateChanges} />
                                : 
                                null
                        }
                    </Fragment>
                    :
                    <div className="col-md-2">
                        <div className="card card-body isPointer" onClick={()=> setExpanded( !expanded )}>
                            <div className=" d-flex justify-content-center">
                                <span><img width={25} src={util.countryFlag( singleCountry.country )}  alt={singleCountry.country}/> {singleCountry.country}</span>
                            </div>
                        </div>
                    </div>
            }
        </Fragment>
    );
}