import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

const CategoryItem = ( { data, onDelete, onEdit, onSelect, isActive } ) => {
    const { groupName, name, id, active } = data;
    return (
        <div
            className={`d-flex justify-content-between align-items-center p-2 border rounded mt-1 mb-1 department_item isCursor ${
				isActive === id ? "activeBlock" : ""
			}`} onClick={( e ) => onSelect( e, id )}
        >
            <div className="w-100">
                <div className="d-flex align-items-center justify-content-between">
                    <b className="text-dark">{name}</b>
                    {!active && <span className="text-danger mr-3">Inactive</span>}
                </div>
                <div>
                    <div className="leadName">
                        <span>{groupName}</span>
                    </div>
                </div>
                
            </div>
            <div className="d-flex justify-content-between align-items-center">
                <span className="text-secondary mr-1" onClick={( e ) => onEdit( e, id, data )}>
                    <FontAwesomeIcon icon={faEdit} title="Edit" />
                </span>
                <span className="text-secondary" onClick={( e ) => onDelete( e, id )}>
                    <FontAwesomeIcon icon={faTrash} title="Delete" />

                </span>
            </div>
        </div>
    );
};

export default CategoryItem;
