import { useEffect, useState } from "react";
import util from "../../../../utils/miniUtils";


export default function useAccount( employee_id ) {
    
    const [account, setAccount] = useState( {
        name:"",
        company:"",
        phone1:"",
        phone2:"",
        email:"",
        profile_picture:null
    } );
    const [companies, setCompanies] = useState( [] );


    const onChangeProfilePicture = async  ( e ) => {
        
        try {
            // console.log( e.target.files[0] );
            const data = new FormData();
            data.append( "file", e.target.files[0] );
            const request = await util.axios.post( `/candidates/upload_candidate_picture/${account.candidate_id}`, data );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            getAccountData();
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const getAccountData = async () => {
        try {
            const request = await util.axios.get( `/employee/account/${employee_id}` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setAccount( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    async function getCompaniesList() {
        try {
            const request = await util.axios.get( `generic_data/available_companies` );
            const { error, msg, data } = request.data;
            if ( error ) throw new Error( msg );
            setCompanies( data );
        } catch( error ) {
            util.handleError( error );
        }
    }

    const handleChanges = ( e ) => setAccount( { ...account, [e.target.name]: e.target.value } );

    const updateUser = async ( ) => {
        try {
            let a = { ...account };
            delete a.id;
            delete a.profile_picture;
            const request = await util.axios.put( `/employee/account/update/${account.id}`, a );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().success( "Information Updated" );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    useEffect( ()=>{
        getAccountData();
        getCompaniesList();
    },[] );

    const ImageUrl = () => {
        return `${process.env.REACT_APP_BASE_URL}/candidate/${account.candidate_id}/${account.profile_picture}`;
    };

    return {
        setAccount,
        account, 
        companies, 
        ImageUrl, 
        updateUser, 
        handleChanges, 
        getCompaniesList, 
        onChangeProfilePicture
    };
}