import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import QuestionsListForm from '../../../screeningQuestions/Components/QuestionsListForm';
import QuestionsLibrary from '../../../screeningQuestions/Components/QuestionsLibrary';
import { Paper } from "@material-ui/core";
import util from '../../../utils/miniUtils';
import { useDispatch, useSelector } from 'react-redux';
import { initializeState } from '../../../../redux/actions/JobSqActions';

const ModalScreeningQuestions = ( { job ,show, hideModal, questions, reloadJob } ) => {
    const state = useSelector( state => state.sqReducer );
    const dispatch = useDispatch();

    const initialState = () => {
        if( questions && questions.length > 0 ) {
            dispatch( initializeState( questions ) );
        }
    };

    const clearStore = () => {
        dispatch( initializeState( [] ) );
    };

    const closeModal = () => {
        clearStore();
        hideModal();
    };

    useEffect( () => {
        initialState();
    },[] );

    const saveScreeningQuestions = async () => {
        try{
            const request = await util.axios.post( '/update_screening_questions', { questions: state, position: job.id } );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
            closeModal();
            reloadJob();
        } catch( error ) {
            util.handleError( error );
        }
    };

    function handleSave() {
        function validateQuestions(){
            let isValid = true;
            for( let i = 0; i < state.length; i++ ) {
                if( !state[i].question ) {
                    util.handleError( "Please enter the question!" );
                    isValid = false;
                    break;
                }
                if( state[i].options?.length > 0 ) {
                    state[i].options.forEach( option => {
                        if( option.length < 1 ) {
                            isValid = false;
                        }
                    } );
                    if( !isValid ) {
                        util.handleError( `Please enter the option text for question ${i + 1} !` );
                        break;
                    }
                }
            }
            return isValid;
        }
        return validateQuestions() ? saveScreeningQuestions() : "";
    }

    function handleCancel() {
        if( questions && questions.length > 0 ) hideModal();
        closeModal();
    }

    return (
        <>
            <Modal size="xl" show={show} onHide={closeModal} centered style={{ minHeight: "80vh" }}>
                <Modal.Header>{job ? job.job_title : ''}</Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-sm-12 col-xs-12">
                            <QuestionsListForm save={saveScreeningQuestions} cancel={closeModal} isModal={true} />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 h-100">
                            {/* <TabPanel selectedTab={tabValue} tabValue="questions"> */}
                            <Paper className="p-2 h-100">
                                <QuestionsLibrary />
                            </Paper>
                            {/* </TabPanel> */}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-end pb-2 w-100">
                        <button className="questionform-cancel bg-secondary text-light" onClick={handleCancel}>Cancel</button>
                        <button className="questionform-save bg-secondary text-light" onClick={handleSave}>Save</button>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* <Modal size="lg" show={show} onHide={hideModal} centered>
            <Modal.Header>Screening Questions</Modal.Header>
            <Modal.Body>
                {questions?.length > 0 && questions?.map( ( item, index ) =>(
                    <div key={item.id}>
                        <div>
                            <strong>{`Q ${index + 1}. `}{item.question}</strong>
                        </div>
                        <div className="d-flex">
                            <div className="mr-1"><strong>Type:</strong></div>
                            <div>
                                {handleType( item.type )}
                            </div>
                        </div>
                        { item?.options?.length ? <div className="d-flex">
                            <div className="mr-1"><strong>Options:</strong></div>
                            <div>{item.options?.length > 0 && item.options?.map( ( option, index ) => (
                                <div key={index}>{option}</div>
                            ) )}
                            </div>
                        </div> : ""}
                    </div>
                ) )}
            </Modal.Body>
        </Modal> */}
        </>
    );
};

export default ModalScreeningQuestions;