import React from 'react';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ItemWithLead = ( { data, onEdit, onSelect, active, onDelete } ) => {
    const { itemName, name, id } = data;

    return (
        <div className={`d-flex justify-content-between align-items-center p-2
        border rounded mt-1 mb-1 department_item ${active === id ? "activeBlock" : ""} isCursor`}
        onClick={( e ) => onSelect( e, id )}>
            <div className="isCursor">
                <div>
                    <b className='text-dark'>{itemName}</b>
                </div>
                <div className="leadName">
                    <span>{name}</span>
                </div>
            </div>
            <div>
                <span className="text-secondary mr-1" onClick={( e ) => onEdit( e, id )}>
                <FontAwesomeIcon className="isCursor" icon={faEdit} />
                </span>
                <span className="text-secondary" onClick={( e ) => onDelete( e, id )}>
                <FontAwesomeIcon className="isCursor" icon={faTrash}/>
                </span>
            </div>
        </div>
    );
};

export default ItemWithLead;
