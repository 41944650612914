import React, { useState, useEffect, } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { v4 as uuidv4 } from "uuid";
import { TextField, Card, CardContent, CardActions, Typography, Chip, Tooltip,Grid, Checkbox } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faPlus, faTimes, faMinus } from '@fortawesome/free-solid-svg-icons';
import util from '../../../utils/miniUtils';
import ImageComponent from '../../../utils/ImageComponent';

import CountryAutoFill from './CountryAutoFill';
import PhoneInput from "react-phone-input-2";
import useModalNewCandidate from "./useModalNewCandidate";
import countryList from "../../../utils/location/country.json";
import { CancelRounded } from '@material-ui/icons';
import axios from "axios";
import { currency } from '../../../utils/currencies';
import { currency2 } from '../../../utils/currency2';
import { option } from 'react-dom-factories';



const CandidateInfoCard = ( { candidate, index, onUpdate, onRemove, saveSingle,skillName,setSkillName,skillsList,setSkillsList,showSkillInput,setShowSkillInput, selectedFiles,currentIdx, candidateErrors, setCandidateErrors,countryState, setCandidates } ) => {
    const [isEditing, setIsEditing] = useState( false );    
    const { loading, countries } = countryState;
    const [workHistoryNew, setWorkHistoryNew] = useState( {
        company: "",
        position: "",
        desc: "",
        start_date: "",
        end_date: "",
        country: "",
        state: "",
        city: "",
        actual_job: 0,
    } );
    const [ show, setShow ] = useState( false );
    // const [showSkillInput, setShowSkillInput] = useState( false );
    const {
        newState
    } = useModalNewCandidate();
    // const [skillName, setSkillName] = useState( '' );
    // const [skillsList, setSkillsList] = useState( [] );
    if( candidate?.country === "" || candidate.country === undefined ){
        candidate.country = "United States";
    }
    function onChangeHandler( e ) {
        let { name, value } = e.target;
        if ( name === "actual_job" ) {
            value = e.target.checked ? 1 : 0;
        }
        let tempData = { ...workHistoryNew };
        tempData[name] = value;
        setWorkHistoryNew( tempData );
    }
    const getListofSkills = async ( search ) => {
        try {
            const response = await util.axios.get( `catalog/catalog_skills_search/${search}` );
            
            const { error, msg, data } = response.data;
            if ( error ) return util.handleError( msg );
            setSkillsList( data );
        } catch ( e ) {
            util.handleError( e );
        }
    };

    const handleReset = () => {

        setSkillName( '' );
        setSkillsList( [] );

    };
    // const addWorkHistory = () => {
    //     let work = candidate.work_history;
    //     work.push( workHistoryNew );  
    //     console.log( work );
    // };
    const addWorkHistory = () => {
        const updatedWorkHistory = [...candidate.work_history, workHistoryNew];
        onUpdate( index, { ...candidate, work_history: updatedWorkHistory } );
    };
    
    const addSkill = ( skill ) => {
	    let t = candidate.skills;

        // Check if the skill already exists in the skills list
        const skillIndex = t.findIndex( ( item ) => item.id === skill.id );

        if ( skillIndex === -1 ) {  
            // Skill does not exist, add it to the list
            t.push( { ...skill,yearsOfExperience:'0' }  );
        } else {
            // Skill exists, remove it from the list
            t.splice( skillIndex, 1 );
        }
        onUpdate( index, { ...candidate, skills: t } );
    };

    const onExpChange = ( e, i ) => {
	    const { name, value } = e.target;
	    let t = candidate.skills;
	    t[i][name] = value;
        onUpdate( index, { ...candidate, skills: t } );
    };

    const removeSkill = ( i ) => {
	    let t = candidate.skills;
	    t.splice( i, 1 );
        onUpdate( index, { ...candidate, skills: t } );

    };

    const handleInputChange = ( event ) => {
        const { name, value } = event.target;
        
        const updatedCandidate =  { ...candidate, [name]: value };

        if( name ==='country' ){
            let currencyValue = currency2.find( item => item.name === value );
            
            // onUpdate( index, { ...candidate, ['currency']: currencyValue.currency.name } );
            // const update = onUpdate( index, { ...candidate, [name]: value, 'currency': currencyValue.currency.name } );
            updatedCandidate.currency = currencyValue.currency.code;
            

        }
        onUpdate( index, updatedCandidate );
    };
    const handleClearSkills = () => {
        onUpdate( index, { ...candidate, skills: [] } );
    };

    const handleRemoveSkill = ( i ) => {
        const updatedSkills = [...candidate.skills];
        updatedSkills.splice( i, 1 );
        onUpdate( index, { ...candidate, skills: updatedSkills } );

    };
    const handleChange = ( e ) => {
        setSkillName( e.target.value );
    };

    const handleAddSkill = ( skill ) => {
        addSkill( skill );
        setSkillName( '' );
    };
    const handleClearWorkHistory = () => {
        onUpdate( index, { ...candidate, work_history: [] } );

    };

    // Function to handle deletion of a job from a candidate's work history
    const handleDeleteJob = ( jobIndex ) => {
    // Create a new array that doesn't include the job to be deleted
        const updatedWorkHistory = candidate.work_history.filter( ( _, idx ) => idx !== jobIndex );
        onUpdate( index, { ...candidate, work_history: updatedWorkHistory } );
    };


    useEffect( () => {
        if ( skillName ) {
            getListofSkills( skillName );
        }
    }, [skillName] );
    const handlePlusClick = () => {
        setShowSkillInput( true );
    };
    
    const searchSelectedCountry = countries.find( ( obj ) => {
        
        if ( obj.name.common === candidate.country ) {
            return true;
        }
        return false;
    } );
    const handleAddWorkhistory = () =>{
        setShow( !show );
    };
    const handleSaveWorkHistory =() => {
        addWorkHistory();
        setWorkHistoryNew( {
            company: "",
            position: "",
            desc: "",
            from: "",
            to: "",
            country: "",
            state: "",
            city: "",
            actual_job: 0,
        }  );
        setShow( false );
    };
    
    return (
        <Card >
            <CardContent>
                <Grid container spacing={2}>
                    <CardContent className="candidate-info-card">
                        <div className='candidateremove'><button className=' cancel-icon' onClick={()=>onRemove( index )} ><CancelRounded /></button></div>
                        
                        {/* <a href={URL.createObjectURL( selectedFiles[currentIdx] )} target="_blank" rel="noopener noreferrer">
                            {selectedFiles[currentIdx].name }
                        </a> */}
                        <div className='personal-info'>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="h6">Personal Information</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                
                                <TextField
                                    label="Name"
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    value={candidate?.name}
                                    name="name"
                                    onChange={handleInputChange}
                                    error={Boolean( candidateErrors[index]?.name )}
                                    helperText={candidateErrors[index]?.name || ""}
                                />
                                
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="Expected Salary"
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    value={candidate?.ctc}
                                    name="ctc"
                                    onChange={handleInputChange}
                                    // error={Boolean( candidateErrors[index]?.name )}
                                    // helperText={candidateErrors[index]?.name || ""}
                                />
                                
                            </Grid>
                            {/* <Grid item xs={12} sm={12}>
                                <TextField
                                    label="Phone"
                                    type='number'
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    value={candidate?.mobile_phone}
                                    name="mobile_phone"
                                    onChange={handleInputChange}
                                    error={Boolean( candidateErrors[index]?.mobile_phone )}
                                    helperText={candidateErrors[index]?.mobile_phone ||""}
                                />
                            </Grid> */}
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="Email"
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    type="email"
                                    value={candidate?.email}
                                    name="email"
                                    onChange={handleInputChange}
                                    error={Boolean( candidateErrors[index]?.email )}
                                    helperText={candidateErrors[index]?.email || ""}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Tooltip title={candidate?.country ? candidate?.country :"United States"}>
                                    <TextField
                                        select
                                        size="small"
                                        // type="number"
                                        variant="outlined"
                                        name="country"
                                        label="Country"
                                        value={candidate?.country ? candidate.country : 'United States'}
                                        onChange={handleInputChange}
                                        error={Boolean( candidateErrors[index]?.country )}
                                        helperText={candidateErrors[index]?.country || ""}
                                        className='form-control'
                                        InputLabelProps={{ shrink: true }}
                                        SelectProps={{
                                            native: true
                                        }}
                                    >
                                        <optgroup label="Most Active">
                                            {/* <option value="">--</option> */}
                                            <option value="United States">United States</option>
                                            <option value="Canada">Canada</option>
                                            <option value="India">India</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Mexico">Mexico</option>
                                            <option value="Japan">Japan</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Puerto Rico">Puerto Rico</option>
                                        </optgroup>
                                        <optgroup label="All Countries">
                                            <option value=""/>
                                            {countryList?.map( ( item ) => {
                                                return (
                                                    <option key={uuidv4()} value={item.name}>
                                                        {item.name}
                                                    </option>
                                                );
                                            } )}
                                        </optgroup>
                                    </TextField>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    select
                                    label="Currency"
                                    variant="outlined"
                                    value={candidate?.currency ? candidate.currency : 'USD'}
                                    onChange={handleInputChange}
                                    size={"small"}
                                    name="currency"
                                    className='form-control'
                                    SelectProps={{
                                        native: true,
                                    }}
                                >
                                    {currency2.map( ( option ) => (
                                        <option key={option.name} value={option.currency.code}>
                                            {option.currency.code} - {option.currency.symbol}{option.currency.name}
                                        </option>
                                    ) )}
                                </TextField>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <TextField
                                    select
                                    label="Highest Education"
                                    variant="outlined"
                                    value={candidate?.highestEducation || ""}
                                    onChange={handleInputChange}
                                    size="small"
                                    name="highestEducation"
                                    className='form-control'
                                    SelectProps={{
                                        native: true,
                                    }}
                                >
                                    {util.highestEducation.map( ( education ) => (
                                        <option key={education.highestEducation} value={education.highestEducation}>
                                            {education.highestEducation}
                                        </option>
                                    ) )}
                                </TextField>
                            </Grid>
                            {/* </div> */}
                            
                            <div className="d-flex row">
                                <div className="col-md-1 form-control ml-3">
                                    <img
                                        style={{ width:'25px',height:'20px' }}
                                        src={
                                            searchSelectedCountry &&
                            searchSelectedCountry?.flags?.webp
                                        }
                                        alt=""
                                    />
                                </div>
                                <div className="col-md-2 form-control ml-1">
                                    <p>
                                        {searchSelectedCountry &&
                          searchSelectedCountry.idd.root}
                                        {searchSelectedCountry?.name?.common !== "United States" &&
                          searchSelectedCountry?.idd?.suffixes[0]}
                                    </p>
                                </div>
                                <div className="col-md-8 ml-1">
                                    {/* <TextField
                                        // style={{ width:'106%' }}
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        placeholder="Phone"
                                        className='mt-2 form-control'
                                        // country={newState.country}
                                        name={"phone_mobile"}
                                        value={candidate.phone_mobile}
                                        // onChange={phone => {
                                        //     let e={ target:{ name:"phone_mobile", value: phone } };
                                        //     onChangeHandler( e );
                                        // }}
                                        onChange={handleInputChange}
                                    /> */}
                                    <TextField
                                        label="Phone"
                                        type='number'
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        value={candidate?.mobile_phone}
                                        name="mobile_phone"
                                        onChange={handleInputChange}
                                        error={Boolean( candidateErrors[index]?.mobile_phone )}
                                        helperText={candidateErrors[index]?.mobile_phone ||""}
                                    />
                                </div>
                            </div>
                            
                        </div>

                        {/* Skills Section */}
                        <Grid item xs={12} class>
                            <Typography variant="h6">Skills  {showSkillInput ? <FontAwesomeIcon icon={faMinus} className='plus-icon' onClick={()=> setShowSkillInput( false )}/> : <FontAwesomeIcon icon={faPlus} className='plus-icon' onClick={handlePlusClick}/>}                                          
                                {/* <FontAwesomeIcon icon={faPlus} className='plus-icon' onClick={handlePlusClick}/> */}
                            </Typography>
                            {showSkillInput &&
                            <>
                                <TextField fullWidth size='small'  label="Search Skill" variant="outlined" type="text" value={skillName} onChange={handleChange}/>

                                <ul className="skillsSelectorList ml-3 mt-2">
                                    {skillsList?.length ? skillsList?.map( el => (
                                        <li
                                            className="skillsSelectorList-item"
                                            key={el.id}
                                        >
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    onChange={() => handleAddSkill( el )}
                                                    checked={candidate?.skills.some( ( item ) => item.id === el.id )}
                                                />
                                                <span className="ml-1">{el.name}</span> 
                                            </label>
                                        </li>
                                    ) ) : ''}
                                </ul>
                            </>
                            }
                            <div>
                                <>

                                    <div className="skill-array">
                                        {candidate?.skills?.map( ( skill, index ) => (

                                            <div key={index} className="skill-value">
                                                <span>{skill.name}</span>
                                                <span className="resume-cross" onClick={() => handleRemoveSkill( index )}>x</span>
                                            </div>
                                        ) )}

                                    </div>
                                </>
                    
                            </div>
                            {/* <util.skillTalentForm removeSkill={removeSkill} onSkillChange={onExpChange}
                                handleChange={handleChange} handleRemoveSkill={handleRemoveSkill} handleReset={handleReset}
                                selectedSkills={candidate.skills} skillName={skillName} skillsList={skillsList}
                                
                            /> */}

                        </Grid>

                        {/* Work History Section */}
                        <Grid item xs={12}>
                            <Typography variant="h6" className='work-history'>Work History {show ? <FontAwesomeIcon icon={faMinus} className='plus-icon' onClick={()=> setShow( false )}/> : <FontAwesomeIcon icon={faPlus} className='plus-icon' onClick={handleAddWorkhistory}/>}</Typography>
                            <div className="work-history-item">
                                {candidate?.work_history && candidate?.work_history?.map( ( job, idx ) => (
                                    <Card key={idx} >
                                        <CardContent>
                                            <Typography variant="subtitle1">{job.company}</Typography>
                                            <Typography variant="body2">{job.position|| job.job_title || job.title}</Typography>
                                            {/* <Typography color="textSecondary">
                                            Duration: {job.duration || `${job.start_date} - ${job.end_date}` || `${job.from}`-`${job.to}` || ''}
         
                                            </Typography> */}
                                            <Typography color="textSecondary">
                                                Duration: {job.duration || ( job.start_date && job.end_date ? `${job.start_date} - ${job.end_date}` : ( job.from && job.to ? `${job.from} - ${job.to}` : '' ) )}
                                            </Typography>

                                            <Typography variant="body2">{job.location}</Typography>
                                            <Typography variant="body2">{job.desc || job.responsibilities || job.description || ""}</Typography>

                                        </CardContent>
                                        {/* <CardActions>
                                            <Button size="small" color="secondary" onClick={() => handleDeleteJob( idx )}>
                                    Delete
                                            </Button>
                                        </CardActions> */}
                                    </Card>
                                ) )}

                            </div>
                            {/* <Typography variant="h6">Work History                                          <FontAwesomeIcon icon={faPlus} className='plus-icon' onClick={handleAddWorkhistory}/>
                            </Typography> */}
                            {/* <div onClick={handleAddWorkhistory}>Add Work History</div> */}
                            {( show && (
                                <div>
                                    <div className="">
                                        <div className='work-history-header'>
                                            <div className="isCursor text-black-50"  >
                                                <b>{workHistoryNew.position} at {workHistoryNew.company} from {workHistoryNew.start_date} {workHistoryNew?.actual_job === 1 ? "until today" : `to ${workHistoryNew?.end_date}`}</b>
                                            </div>
                                            <div className="save-work-history" onClick={handleSaveWorkHistory}>Save</div>



                                        </div>
                                        
                                        <div className="form-row align-items-center"  >
                                            <util.RowComponent
                                                c={12}
                                                t={"Company"}
                                                n={"company"}
                                                m={( e ) => onChangeHandler( e )}
                                                v={workHistoryNew?.company}
                                            />
                                            <util.RowComponent
                                                c={12}
                                                t={"Job Title"}
                                                n={"position"}
                                                m={( e ) => onChangeHandler( e )}
                                                v={workHistoryNew?.position}
                                            />
                                            <util.RowComponent
                                                c={4}
                                                t={"Start date"}
                                                n={"start_date"}
                                                ty="date"
                                                m={( e ) => onChangeHandler( e )}
                                                v={workHistoryNew?.start_date}
                                            />
                                            {workHistoryNew?.actual_job ? "" :
                                                <util.RowComponent
                                                    c={4}
                                                    t={"End date"}
                                                    n={"end_date"}
                                                    ty="date"
                                                    m={( e ) => onChangeHandler( e )}
                                                    v={workHistoryNew?.end_date}
                                                />
                                            }

                                            {/* // Checkbox if candidate still working */}
                                            <div className={`form-group col-md-4`}>
                                                <div className="text-center">
                                                    <label htmlFor="checkBoxStillWorking">Current Job</label>
                                                    <br />
                                                    <Checkbox
                                                        id="checkBoxStillWorking"
                                                        checked={workHistoryNew?.actual_job === 1}
                                                        name="actual_job"
                                                        onChange={( e ) => onChangeHandler( e )}
                                                        style={{ color: "dodgerblue" }}
                                                    />
                                                </div>
                                            </div>

                                            {/* <div className="container">
                                                <div className="row">
                                                    <LocationCX
                                                        cols={4}
                                                        data={workHistoryNew} // contains everything
                                                        onChange={onChangeHandler}
                                                    />
                                                </div>
                                            </div> */}


                                            <util.RowComponent
                                                c={12}
                                                mul={true}
                                                t={"Job Responsibilities"}
                                                n={"desc"}
                                                m={( e ) => onChangeHandler( e )}
                                                v={workHistoryNew?.desc}
                                            />
                                        </div>

                                        {/* <div className="text-right">
                                            <div>
                                                <button onClick={() => {
                                                    if ( hasChanges() ) {
                                                        setShowConfirmation( true );
                                                    } else {
                                                        hideModal();
                                                    }
                                                }}
                                                className="btn btn-sm text-danger ">
                                                    <FontAwesomeIcon icon={faBan} className="mr-2" />
								Cancel
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        saveNewWorkHistory();
                                                        hideModal();
                                                    }}
                                                    className="btn btn-sm btn-success ">
                                                    <FontAwesomeIcon icon={faSave} className="mr-2" />
								Save
                                                </button>
                                            </div>
                                        </div> */}


                                    </div>
                                    
                                </div>
                            ) )}
                            
                            { candidate?.work_history?.length >0 && <Button variant="contained" color="primary" onClick={handleClearWorkHistory}>
                        Clear Work History
                            </Button>}
                        </Grid>
                    </CardContent>
                </Grid>
            </CardContent>
            {/* <CardActions>
                <Button size="small" color="secondary" onClick={() => onRemove( index )}>
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
                <Button size="small" color="primary" onClick={() => saveSingle( index )}>
                    <FontAwesomeIcon icon={faSave} />
                </Button>
            </CardActions> */}
        </Card>
    );
};

export default CandidateInfoCard;