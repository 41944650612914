import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import util from '../../../utils/miniUtils';

const DeleteWorkloadModal = ( { show, handleClose, workloadId, scId } ) => {
    const [loading, setLoading] = useState( false );
    const [state, setState] = useState( {
        short_description: "",
        long_description: "",
        categoryName: "",
        groupName: "",
        customerName: "",
        resolverName: ""
    } );

    const getWorkloadDetails = async ( scId, workloadId ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/workload_service_catalog/workload/${scId}/${workloadId}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setState( data );
        } catch ( error ) {
            util.handleError( error );
        } finally {
            setLoading( false );
        }
    };

    const deleteWorkload = async ( scId, workloadId ) => {
        try {
            setLoading( true );
            const request = await util.axios.delete( `/workload_service_catalog/workload/${scId}/${workloadId}` );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
            handleClose();
        } catch ( error ) {
            util.handleError( error );
        } finally {
            setLoading( false );
            handleClose();
        }
    };

    useEffect( () => {
        if( show ) {
            getWorkloadDetails( scId, workloadId );
        }
    },[show] );

    if( loading ) {
        return util.LOADING_SCREEN( loading );
    }

    return (
        <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Workload</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs="12" md="6">
                        <Row>
                            <Col xs="12" md="6"><strong>Title :</strong></Col>
                            <Col xs="12" md="6">{state.short_description}</Col>
                        </Row>
                    </Col>
                    <Col xs="12" md="6">
                        <Row>
                            <Col xs="12" md="6"><strong>Priority :</strong></Col>
                            <Col xs="12" md="6">{state.priority}</Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" md="6">
                        <Row>
                            <Col xs="12" md="6"><strong>Group :</strong></Col>
                            <Col xs="12" md="6">{state.groupName}</Col>
                        </Row>
                    </Col>
                    <Col xs="12" md="6">
                        <Row>
                            <Col xs="12" md="6"><strong>Category :</strong></Col>
                            <Col xs="12" md="6">{state.categoryName}</Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" md="6">
                        <Row>
                            <Col xs="12" md="6"><strong>Customer :</strong></Col>
                            <Col xs="12" md="6">{state.customerName}</Col>
                        </Row>
                    </Col>
                    <Col xs="12" md="6">
                        <Row>
                            <Col xs="12" md="6"><strong>Resolver :</strong></Col>
                            <Col xs="12" md="6">{state.resolverName}</Col>
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-secondary sizeBtn' onClick={handleClose}>Cancel</button>
                <button className='btn btn-danger sizeBtn activeBtn' 
                    type="button"
                    onClick={() => deleteWorkload( scId, workloadId )}>Delete</button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteWorkloadModal;