import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CandidateSelector from './CandidateSelector';
import util from '../../../utils/miniUtils';
import moment from 'moment-timezone';

const ScheduleEvent = ( { show, handleClose, candidates, updateCandidates } ) => {
    const [notificationEmail, setNotificationEmail] = useState( { 
        mail: `<p>Dear [TALENT_NAME],</p>
        <p>I hope this email finds you well. You are cordially invited to attend a meeting organized by eJAmerica. Please find the details below:</p>
        <ul>
            <li><strong>Meeting Topic:</strong> [MEETING_TITLE]</li>
            <li><strong>Start at:</strong> [STARTS_AT]</li>
            <li><strong>Ends at:</strong> [ENDS_AT]</li>
            <li>Timezone:<strong> [TIMEZONE]</strong></li>
        </ul>
        <p>We have attached a calendar invite to this email for your convenience. Please ensure to add it to your calendar.</p>
        <p>Your participation is required.</p>
        <p>Should you have any questions or need further clarifications, feel free to contact me directly at [RECRUITER_EMAIL] or [RECRUITER_PHONE].</p>
        <p>Thank you and best regards,</p>
        <p>[RECRUITER_NAME]<br>
        eJAmerica<br>
        [RECRUITER_EMAIL]</p>`, 
        cc: "" 
    } );
    const [schedule, setSchedule] = useState( {
        title: "",
        startsAt: "",
        endsAt: "",
        timezone: "US/Pacific",
        location: "",
        summary: "",
    } );

    const timezones = moment.tz.names();
    
    const handleChange = ( e ) => {
        const { name, value } = e.target;
        setSchedule( prev => ( {
            ...prev,
            [name]: value
        } ) );
    };

    const handleDateTime = ( value, timezone ) => {
        const dateTimeWithZone = moment.tz( value, timezone );
        return dateTimeWithZone.format( 'YYYY-MM-DDTHH:mm:ssZ' );
    };

    const handleEmailChange = ( e ) => {
        const { name, value } = e.target;
        setNotificationEmail( prev => ( {
            ...prev,
            [name]: value
        } ) );
    };

    const handleSubmit = async ( e ) => {
        e.preventDefault();
        if( candidates.length ) {
            try{
                const request = await util.axios.post( `job_position/actions/schedule_event`, 
                    { 
                        ...schedule,
                        startsAt: handleDateTime( schedule.startsAt, schedule.timezone ),
                        endsAt: handleDateTime( schedule.endsAt, schedule.timezone ),
                        email: notificationEmail, 
                        candidates: candidates 
                    } );
                const { msg, error } = request.data;
                if( error ) throw msg;
                util.toast().success( msg );
                handleClose();
            } catch ( error ) {
                util.handleError( error );
            }
        } else {
            util.toast().error( "Select at least 1 candidate" );
        }
        
    };

    console.log( schedule );

    return <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
        <form onSubmit={handleSubmit} className='form'>
            <Modal.Header closeButton>
                <Modal.Title>Schedule</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CandidateSelector candidates={candidates} updateCandidates={updateCandidates} />
                <div className="d-flex">
                    <div className='w-50 mr-2'>
                        {/* title */}
                        <label htmlFor="scheduleTitle">Title</label>
                        <input
                            id="scheduleTitle" 
                            className="form-control" 
                            placeholder="Event Title" 
                            name="title"
                            value={schedule.title}
                            onChange={handleChange}
                        />
                        <div className="d-flex flex-column">
                            {/* Start time */}
                            <div className="w-100">
                                <label htmlFor="scheduleStartTime">Starts at</label>
                                <input
                                    id="scheduleStartTime"
                                    className="form-control"
                                    type="datetime-local"
                                    name="startsAt"
                                    value={schedule.startsAt}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="w-100">
                                {/* End Time */}
                                <label htmlFor="scheduleEndTime">Ends at</label>
                                <input
                                    id='scheduleEndTime'
                                    className="form-control"
                                    type="datetime-local" 
                                    name="endsAt"
                                    value={schedule.endsAt}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="w-100">
                                {/* Location */}
                                <label htmlFor="scheduleLocation">Timezone</label>
                                <select value={schedule.timezone} onChange={handleChange} className="form-control" name="timezone">
                                    {timezones.map( tz => (
                                        <option key={tz} value={tz}>{tz}</option>
                                    ) )}
                                </select>
                            </div>
                            <div className="w-100">
                                {/* Location */}
                                <label htmlFor="scheduleLocation">Location</label>
                                <input
                                    id='scheduleLocation'
                                    className="form-control"
                                    name="location"
                                    value={schedule.location}
                                    placeholder='Chime or any other online platform or city name'
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Comment */}
                    <div className='w-50 ml-2'>
                        <label htmlFor='scheduleComment'>Summary</label>
                        <textarea
                            id="scheduleComment" 
                            className="form-control" 
                            rows={10}
                            name="summary"
                            value={schedule.summary}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div>
                    <label htmlFor='notificationEmailCC'>cc</label>
                    <input className="form-control" value={notificationEmail.cc} 
                        onChange={handleEmailChange}
                        name="cc"
                    />

                    {/* <label htmlFor="notificationEmail">Notification Email</label>
                    <textarea
                        className="form-control"
                        rows={8}
                        name="mail"
                        id="notificationEmail" 
                        value={notificationEmail.mail}
                        onChange={handleEmailChange}
                    /> */}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
            Close
                </Button>
                <Button variant="secondary" type="submit">
            Save
                </Button>
            </Modal.Footer>
        </form>

    </Modal>;
};

export default ScheduleEvent;
