import MaterialTable from "material-table";
import React, { useCallback, useEffect, useState } from "react";
import util from "../../../utils/miniUtils";
import { MuiThemeProvider } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import CatalogWorkloadList from "./CatalogWorkloadList";
import CreateEditServiceCatalogModal from "./CreateEditServiceCatalogModal";
import DeleteCatalogModal from "./DeleteCatalogModal";

const CatalogList = () => {
    const [list, setList] = useState( [] );
    const [total, setTotal] = useState( 0 );
    const [pageNo, setPageNo] =useState( 0 );
    const [limit, seLimit] = useState( 10 );
    const [loading, setLoading] = useState( false );
    const [showAddForm, setShowAddForm] = useState( false );
    const [showEditForm, setShowEditForm] = useState( false );
    const [showDeletedModal, setShowDeletedModal] = useState( false );
    const [scId, setScId] = useState( null );

    const getServiceCatalogList = useCallback( async () => {
        setLoading( true );
        try {
            const request = await util.axios.get( `/workload_service_catalog?page=${pageNo}&limit=${limit}&orderBy=id&direction=DESC` );
            const { data, page, total, error, msg } = request.data;
            if( error ) throw msg;
            setList( data );
            setPageNo( page );
            setTotal( total );
            setLoading( false );
        } catch ( error ) {
            util.handleError( error );
            setLoading( false );
        }
    },[pageNo] );

    const addServiceCatalog = () => {
        setShowAddForm( true );
    };

    const editServiceCatalog = ( e, id ) => {
        e.preventDefault();
        setScId( id );
        setShowEditForm( true );
    };

    const deleteServiceCatalog = ( e, id ) => {
        e.preventDefault();
        setScId( id );
        setShowDeletedModal( true );
    };

    useEffect( () => {
        getServiceCatalogList();
    },[getServiceCatalogList] );

    return <>
        <MuiThemeProvider theme={util.defaultTableTheme}>
            <MaterialTable
                columns={[
                    {
                        title: "Id",
                        field: "id",
                        width: "4%",
                    },
                    {
                        title: "Title",
                        field: "name",
                    },
                    {
                        title: "Department",
                        field: "departmentName",
                    },
                    {
                        title: "Workloads",
                        field: "workloads",
                    },
                    {
                        title: "Description",
                        field: "description",
                    },
                    {
                        title: "Created By",
                        field: "created_by_name",
                    },
                    {
                        title: "",
                        field: "id",
                        cellStyle: {
                            textAlign : "end",
                        },
                        render: rowData => <div>
                            <button
                                className="btn btn-sm btn-outline-danger border-0 mr-1"
                                onClick={( e ) => deleteServiceCatalog( e, rowData.id )}>
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                            <button
                                className="btn btn-sm btn-outline-primary border-0 mr-1"
                                onClick={( e ) => editServiceCatalog( e, rowData.id )}>
                                <FontAwesomeIcon icon={faEdit} />
                            </button>
                        </div>
                    },
                ]}
                data={list}
                totalCount={total}
                page={pageNo}
                isLoading={loading}
                onChangePage={page => setPageNo( page )}
                onChangeRowsPerPage={( pageSize ) => seLimit( pageSize )}
                options={{
                    showTitle: false,
                    emptyRowsWhenPaging: false,
                    pageSize: limit,
                    pageSizeOptions: [10, 20, 50],
                    sorting: false,
                    searchFieldAlignment: "left",
                    toolbarButtonAlignment: "left",
                    rowStyle: {
                        overflowWrap: 'break-word'
                    },
                    headerStyle:{ position:'sticky',top:0 },
                }}
                detailPanel={rowData => <CatalogWorkloadList scId={rowData.id} />}
                actions={[
                    {
                        icon: () => <span className='btn btn-sm btn-primary rounded-circle fs-1'>
                            <FontAwesomeIcon icon={faPlus} />
                        </span>,
                        tooltip: "Add Workload",
                        isFreeAction: true,
                        onClick: () => addServiceCatalog(),
                    },
                ]}
            />
        </MuiThemeProvider>
        <CreateEditServiceCatalogModal
            show={showAddForm}
            handleClose={() => {
                setShowAddForm( false );
                getServiceCatalogList();
                setScId( null );
            }}
            isEdit={false}
        />

        <CreateEditServiceCatalogModal
            show={showEditForm}
            handleClose={() => {
                setShowEditForm( false );
                getServiceCatalogList();
                setScId( null );
            }}
            isEdit={true}
            scId={scId}
        />

        <DeleteCatalogModal 
            show={showDeletedModal}
            handleClose={() => {
                setShowDeletedModal( false );
                getServiceCatalogList();
                setScId( null );
            }}
            scId={scId}
        />
    </>;
};

export default CatalogList;
