import React, { useState, useEffect } from "react";
import { Form, Row, Col, Tooltip } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { CountryInfo } from "../../utils/countryInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPaperclip, faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import util from "../../utils/miniUtils";

const CountryFillOne = ( { newState, onChangeHandler ,isReadOnly,attachmentFiles,setAttachmentFiles } ) => {
    const [countryState, setCountryState] = useState( {
        countries: CountryInfo,
    } );
    const [validationMessage, setValidationMessage] = useState( "" ); // Feedback message
    const [loading,setLoading]=useState( false );
    const { countries } = countryState;

    // Set default country to India if newState.country is not set
    useEffect( () => {
        if ( !newState.country ) {
            onChangeHandler( { target: { name: "country", value: "India" } } );
        }
    }, [newState.country, onChangeHandler] );

    // Find selected country data
    const searchSelectedCountry = countries.find(
        ( obj ) => obj.name === newState.country
    );

    const handleChangePhone = ( e ) => {
        const inputValue = e.target.value.replace( /[^0-9]/g, "" ); // Remove non-numeric characters
        onChangeHandler( {
            target: { name: e.target.name, value: inputValue },
        } );
    };
   
    

    
    useEffect( () => {
        if ( newState.tax_id_file && !attachmentFiles.length ) {
            setAttachmentFiles( [{ name: newState.tax_id_file }] );
        } else if ( !newState.tax_id_file && attachmentFiles.length ) {
            setAttachmentFiles( [] ); // Clear the UI state when no file exists
        }
    }, [newState.tax_id_file, attachmentFiles, setAttachmentFiles] );
    

    const handleFileUpload = async ( e ) => {
        const file = e.target.files[0];
        if ( !file ) return;

        const formData = new FormData();
        formData.append( "file", file );

        try {
            const response = await util.axios.post(
                `/employer/upload_attachment/${util.user.employer_id}`,
                formData
            );
            console.log( response );
            const { error,validationResult
                
            } = response.data;
            if ( error ) {
                util.toast().error( validationResult
                    
                );
            } else {
                util.toast().success( `Uploaded ${file.name} successfully` );
                setAttachmentFiles( [{ name: file.name }] );
                console.log( attachmentFiles );
                // Update the employer details state to include the new file name
                onChangeHandler( { target: { name: "tax_id_file", value: file.name } } );
            }
        } catch ( error ) {
            util.handleError( error );
        }
    };
   
    
    
    const handleRemoveAttachment = async () => {
        if ( !attachmentFiles.length ) return;
    
        const fileName = attachmentFiles[0].name;
    
        try {
            // Call the API to delete the file
            const response = await util.axios.delete(
                `/employer/delete_attachment/${util.user.employer_id}`,
                {
                    data: { fileName }, // Pass the file name to the backend
                }
            );
    
            const { error, msg } = response.data;
    
            if ( error ) {
                util.toast().error( msg );
            } else {
                util.toast().success( "File removed successfully" );
                setAttachmentFiles( [] ); // Clear the attachment files state
                onChangeHandler( { target: { name: "tax_id_file", value: "" } } ); // Clear the form field
                newState.tax_id_file = "";
            }
        } catch ( error ) {
            util.handleError( error );
        }
    };
    // Validate Tax ID using the backend API
    const validateTaxId = async ( taxId, country ) => {
        try {
            const response = await util.axios.get( `/gpt/validate_tax_id/${taxId}/${country}` );
            const { error, validationResult } = response.data;
    
            if ( error ) {
                setValidationMessage( validationResult );   // Set the error message
            } else {
                setValidationMessage( validationResult );     // Set the success message
            }
        } catch ( err ) {
            setValidationMessage( "Failed to validate Tax ID. Please try again." ); // Generic error message
        }
    };
    
    

    const handleTaxIdChange = ( e ) => {
        const { name, value } = e.target;
        onChangeHandler( { target: { name, value } } );

        // Clear validation states when changing the input
        setValidationMessage( "" );
    };
    
   
    
    
    
    
    const fileLabel =
        newState.country === "India" ? "Attach PAN Card File" : "Attach Tax ID File";
    return (
        <>
            {/* Country Field */}
            <Col md={6}>
                <Form.Group controlId="formCountrySelect">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                        as="select"
                        name="country"
                        value={newState.country || "India"}
                        onChange={onChangeHandler}
                        readOnly={isReadOnly}
                    >
                        <optgroup label="Most Active">
                            <option value="">--</option>
                            <option value="United States">United States</option>
                            <option value="Canada">Canada</option>
                            <option value="India">India</option>
                            <option value="Australia">Australia</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Japan">Japan</option>
                            <option value="Germany">Germany</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                        </optgroup>
                        <optgroup label="All Countries">
                            <option value="" />
                            {countries.map( ( item ) => (
                                <option key={uuidv4()} value={item.name}>
                                    {item.name}
                                </option>
                            ) )}
                        </optgroup>
                    </Form.Control>
                </Form.Group>
            </Col>

            {/* Selected Country Info and Phone Field */}
            {searchSelectedCountry && (
                <>
                    {/* Employer Phone and Company Tax ID Fields */}
                    <Row className="align-items-center ml-3">
                        {/* Employer Phone */}
                        <Col md={6}>
                            <Form.Group controlId="formEmployerPhone">
                                <Form.Label style={{ paddingRight: "50px", display: "block",textAlign:'left' }}>Employer Phone</Form.Label>
                                <Row className="align-items-center">
                                    {/* Flag */}
                                    <Col md={2} className="p-0">
                                        <img
                                            style={{ width: "25px", height: "20px" }}
                                            src={searchSelectedCountry.flagPng}
                                            alt={searchSelectedCountry.name}
                                        />
                                    </Col>

                                    {/* Country Code */}
                                    <Col md={3} className="d-flex align-items-center">
                                        <span>{searchSelectedCountry.root}</span>
                                        {searchSelectedCountry.name !== "United States" && (
                                            <span>{searchSelectedCountry.suffix}</span>
                                        )}
                                    </Col>

                                    {/* Phone Input */}
                                    <Col md={7} className="p-0">
                                        <Form.Control
                                            type="text"
                                            name="employer_phone"
                                            value={newState.employer_phone || ""}
                                            placeholder="+123 456 7890"
                                            onChange={handleChangePhone}
                                            readOnly={isReadOnly}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>

                        {/* Company Tax ID */}
                        <Col md={6}>
                            <Form.Group controlId="formCompanyTaxId">
                                <Form.Label>
                                    {newState.country === "India" ? "PAN Number" : "Company Tax ID"}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="company_tax_id"
                                    value={newState.company_tax_id || ""}
                                    onChange={handleTaxIdChange}
                                    onBlur={() => validateTaxId( newState.company_tax_id, newState.country )}
                                    placeholder={
                                        newState.country === "India"
                                            ? "Enter PAN Number"
                                            : "Enter Company Tax ID"
                                    }
                                    readOnly={isReadOnly}
                                           
                                />
                                {/* <Col md={4}>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={()=>validateTaxId( newState.company_tax_id,newState.country )}
                                            disabled={!newState.company_tax_id}
                                        >
                                    Verify
                                        </button>
                                    </Col> */}
                                {validationMessage && (
                                    <div
                                        className={`mt-2 d-flex align-items-center ${
                validationMessage.includes( "Invalid" ) ? "text-danger" : "text-success"
            }`}
                                    >
                                        <FontAwesomeIcon
                                            icon={validationMessage.includes( "Invalid" ) ? faTimesCircle : faCheck}
                                            className="mr-2"
                                        />
                                        <span>{validationMessage}</span>
                                    </div>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                </>
            )}
            {isReadOnly ? "" :
                <Row className="mb-3">
                    <Col md={12} className="ml-3">
                        <label htmlFor="emp_attachment" className="btn btn-sm btn-primary mt-2">
                            <FontAwesomeIcon icon={faPaperclip} />
                            <span className="p-1">{fileLabel}</span>
                        </label>
                        <input
                            type="file"
                            name="tax_id_file"
                            id="emp_attachment"
                            onChange={handleFileUpload}
                            accept="image/*,.pdf,.txt,.xls,.xlsx,.doc,.docx,.ppt,.pptx"
                            className="d-none"
                        />
                        <div className="mt-1">
                            {attachmentFiles.length > 0 && (
                                <span className="d-flex align-items-center p-1">
                                    <a
                                        href={util.getFileUrl(
                                            util.user.employer_id, 
                                            attachmentFiles[0].name
                                        )}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-primary mr-2"
                                    >
                                        <span>{attachmentFiles[0].name}</span></a>
                                    <span
                                        className="p-1 text-danger cursor-pointer"
                                        onClick={handleRemoveAttachment}
                                    >
                                        <FontAwesomeIcon icon={faTimes} />
                                    </span>
                                </span>
                            )}
                        </div>
                    </Col>
                </Row>}
           
        </>
    );
};

export default CountryFillOne;
