import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { RECRUITER_ROLE } from "../../../constants";
import util from "../../utils/miniUtils";

export default function useSalesReport () {

    const [data, setData] = useState( [] );
    const [openDetail, setOpenDetail] = useState( {
        showDetail: false,
        jobsId: null
    } );

    const [ filterData, setFilterData ] = useState( {
        firstTime: true,
        recruiters: [],
        clients: [],
        partners: [],
        countries: [],
        startDate: new Date( new Date().setDate( new Date().getDate() - 0 ) ).toISOString().split( "T" )[0],
        endDate: new Date().toISOString().split( "T" )[0],
    } );

    
    /**
     * This method will use all the selected data in "filterData" to 
     * request specific information in the server
     */
    const applyFilter = async ( ) => {
        // Put only required data in dataObject to filter the table
        let dataObject = {
            recruiters: filterData.recruiters.filter( item => { if ( item.checked === true ) return item.id;} ),
            country: filterData.countries.filter( item => { if ( item.checked === true ) return item.value;} ),
            partners: filterData.partners.filter( item => { if ( item.checked === true ) return item.id; } ),
            clients: filterData.clients.filter( item => { if ( item.checked === true ) return item.id; } ),
            startDate: filterData.startDate + " 00:00:00",
            endDate: filterData.endDate + " 23:59:59",
        };

        // In the case that the user is a recruiter, will send only hes OWN ID in the recruiters property
        if ( util.user.role === RECRUITER_ROLE ){
            dataObject.recruiters = [{ id: util.user.id }];
        }

        try {
            let url = `dashboard/report_jobs`;
            dataObject.sales_lead = dataObject.recruiters;
            delete dataObject.recruiters;
            const request = await util.axios.post( url, dataObject );
            let { error, msg, result } = request.data;
            if ( error ) throw  msg;
            setData( result );
        }catch ( e ) {
            util.handleError( e );
        }
    };
    

    const getFilterData = async () => {
        try {
            const request = await util.axios.get( `dashboard/filters_values` );
            const { error, msg, recruiters, countries, clients, partners } = request.data;
            if ( error ) throw  msg;
            setFilterData( { ...filterData, firstTime: false, recruiters,countries, clients, partners } );
        }catch ( e ) {
            util.handleError( e );
        }
    };

    


    const handleChange = ( e ) => {
        let { name, value } = e.target;
        setFilterData( { ...filterData, [name]: value } );
    };

    const updatedNewData = ( field, newData ) => {
        setFilterData( { ...filterData, [field]: newData } );
    };


    const totalsRowJobs = ( data ) => {

        const totals = {
            new: 0,
            active: 0,
            hot: 0,
            hold: 0,
            closed: 0,
            filled: 0,
            dropped: 0,
        };

        data.forEach( d => {
            totals.new      = totals.new + d.new;
            totals.active   = totals.active + d.active;
            totals.hot      = totals.hot + d.hot;
            totals.hold     = totals.hold + d.hold;
            totals.closed   = totals.closed + d.closed;
            totals.filled   = totals.filled + d.filled;
            totals.dropped  = totals.dropped + d.dropped;
        } );

        
        return(
            <Fragment>
                <tbody>
                    <tr className="fontSize13 text-center">
                        <td colSpan={3}/>
                        <td>Totals</td>
                        <td>{util.jobStatusOnlyIcons( 0, false )}{totals.new}</td>
                        <td>{util.jobStatusOnlyIcons( 1, false )}{totals.active}</td>
                        <td>{util.jobStatusOnlyIcons( 5, false )}{totals.hot}</td>
                        <td>{util.jobStatusOnlyIcons( 2, false )}{totals.hold}</td>
                        <td>{util.jobStatusOnlyIcons( 3, false )}{totals.closed}</td>
                        <td>{util.jobStatusOnlyIcons( 4, false )}{totals.filled}</td>
                        <td>{util.jobStatusOnlyIcons( 6, false )}{totals.dropped}</td>
                    </tr>
                </tbody>
            </Fragment>
        );
    };

    const funnelTotals = ( data ) => {
        const totals = {
            new: 0,
            active: 0,
            hot: 0,
            hold: 0,
            closed: 0,
            filled: 0,
            dropped: 0,
        };

        data.forEach( d => {
            totals.new      = totals.new + d.new;
            totals.active   = totals.active + d.active;
            totals.hot      = totals.hot + d.hot;
            totals.hold     = totals.hold + d.hold;
            totals.closed   = totals.closed + d.closed;
            totals.filled   = totals.filled + d.filled;
            totals.dropped  = totals.dropped + d.dropped;
        } );
        return totals;
    };

    const sumittedToDateTooltip = () => {
        return(
            <Tooltip title="Total submissions within the date range">
                <span><FontAwesomeIcon icon={faExclamationCircle} /></span>
            </Tooltip>
        );
    };

    return {
        data, setData,
        filterData, setFilterData,
        applyFilter,getFilterData, 
        handleChange,
        updatedNewData,
        totalsRowJobs,
        funnelTotals,
        sumittedToDateTooltip,
        openDetail, setOpenDetail
    };

}