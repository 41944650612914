import React, { Fragment, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faClock,
    faCommentAlt,
    faEnvelope,
    faPencilAlt,
    faPlusCircle,
    faTags,
    faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { setCandidateStatus } from "../../../../redux/actions/JobCandidatesAction";
import FormInterView from "../../../shared/FormInterView";
import ButtonCX from "../../../microComponents/ButtonCX";
import util from "../../../utils/miniUtils";
import { ADD, EDIT } from "../../../../constants";
import Drawer from "@material-ui/core/Drawer";
import StarRatings from "react-star-ratings";
import EmailModal from "../../../shared/EmailModal";
import SAlert from "../../../microComponents/AlertSA";


function Step3( props ){
    const [isDisabled, setIsDisabled] = useState( props.candidate.status > 3 );
    const [interviews, setInterviews] = useState( [] );
    const [showForm, setShowForm] = useState( false );
    const [actionType, setActionType] = useState( ADD );
    const [talent, setTalent] = useState( props.candidate );
    const [index, setIndex] = useState( 0 );
    const [evaluation, setEvaluation] = useState( null );
    const [showDrawer, setShowDrawer] = useState( false );
    const [emailModal, setEmailModal] = useState( false );
    const [temp, setTemp] = useState( null );
    // const [setSwitches] = useState({client:talent["client_submit"], partner: talent["partner_submit"]})
    const [alertEmailShow, setAlertShow] = useState( { client:false, partner: false } );
    const CLIENT = 1;
    const PARTNER = 2;
	
    useEffect( () => {
        getListOfInterviews().then( null );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[] );
	
	
    useEffect( () => {
        // eslint-disable-next-line
		// setSwitches({client:talent["client_submit"] === 1, partner: talent["partner_submit"] === 1})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[talent] );
	
    async function getListOfInterviews() {
        try {
            const { position_id, candidate_id } = props.candidate;
            const request = await util.axios.get( `${util.url}/job_position/client_interviews_list/${position_id}/${candidate_id}` );
            const { error, msg, interviews } = request.data;
            if ( error ) throw msg;
            setInterviews( interviews );
        } catch ( error ) {
            util.handleError( error );
        }
    }
	
    function canShowAddButton() {
        if ( interviews.length === 0 && !showForm ){
            // We don't have anything, must show the button
            return true;
        }else{
            return !showForm;
        }
    }
	
    function showEditForm( index ) {
        setShowForm( true );
        setActionType( EDIT );
        setIndex( index );
    }
	
    async function clearedAndSelected( index ) {
        try {
            const { position_id, candidate_id } = props.candidate;
            let interviewId = interviews[index].id;
            const request = await util.axios.post( `${util.url}/job_position/client_interviews_cleared/${position_id}/${candidate_id}/${interviewId}` );
            const { error, msg, interviewsUpdated } = request.data;
            if ( error ) throw msg;
            setInterviews( interviewsUpdated );
            setIsDisabled( true );
            props.setCandidateStatus( { id:candidate_id, status: 4, talent_desc_updated: "Evaluations Cleared" } );
            setIsDisabled( true );
        } catch ( error ) {
            util.handleError( error );
        }
    }
	
    async function sendEvaluationRequest( index, emailTemplate ) {
        try {
			
            const { position_id, candidate_id } = props.candidate;
            let interviewId = interviews[index].id;
            const request = await util.axios.post( `${util.url}/job_position/send_evaluation_request/${position_id}/${candidate_id}/${interviewId}`, emailTemplate );
            const { error, msg, interviewsUpdated } = request.data;
            if ( error ) throw msg;
            setInterviews( interviewsUpdated );
            setIndex( null );
            setTemp( null );
            setEmailModal( false );
            props.setCandidateStatus( { id:candidate_id, status: 3, talent_desc_updated: "Evaluation Request Sent" } );
        } catch ( error ) {
            util.handleError( error );
        }
    }
	
    async function clearedAndAddRound( index ) {
        try {
            const { position_id, candidate_id } = props.candidate;
            let interviewCleared = interviews[index].id;
            const request = await util.axios.put( `${util.url}/job_position/client_interviews_clear_add_more/${position_id}/${candidate_id}/${interviewCleared}` );
            const { error, msg, interviewsUpdated } = request.data;
            if ( error ) throw msg;
            setInterviews( interviewsUpdated );
            props.setCandidateStatus( { id:candidate_id, status: 3, talent_desc_updated: "Evaluation Cleared Add More" } );
        } catch ( error ) {
            util.handleError( error );
        }
    }
	
    function reloadInterviews( data ) {
        setShowForm( false );
        setInterviews( Object.assign( [], data ) );
    }
	
    function addInterviewForm() {
        setShowForm( true );
        setActionType( ADD );
    }
	
    async function notClearedAndRejected( index ) {
        try {
            const { position_id, candidate_id } = props.candidate;
            const request = await util.axios.put( `${util.url}/job_position/client_interviews_rejected/${position_id}/${candidate_id}/${interviews[index].id}` );
            const { error, msg, interviewsUpdated } = request.data;
            if ( error ) throw msg;
            setInterviews( interviewsUpdated );
            props.setCandidateStatus( { id:candidate_id,status: 11, talent_desc_updated: "Evaluation Not Cleared and Rejected" } );
        } catch ( error ) {
            util.handleError( error );
        }
    }
	
    function showEvaluation( evaluation ) {
        setEvaluation( evaluation );
        // show modal or drawer
        setShowDrawer( true );
    }
	
    function saveAndSend( index, interview_id, interviewsArray ) {
        setIndex( index );
        setInterviews( Object.assign( [], interviewsArray ) );
        setTemp( { id:interview_id } );
        setEmailModal( true );
    }
	
	
    function hideSwalAlerts() {
        setAlertShow( { client:false, partner: false } );
    }
	
    async function onConfirmSendEmail( TYPE ) {
        try {
            // CLIENT    = 1
            // PARTNER   = 2
            const { id_position_candidate } = props.candidate;
            let data = { ...props.candidate };
            data.user_type = TYPE;
            const request = await util.axios.post( `${util.url}/job_position/update_submission_email/${id_position_candidate}`, data );
            const { error, msg, updateTalentData } = request.data;
            if ( error ) throw msg;
            // Receives the new data on the talent
            setTalent( updateTalentData );
            hideSwalAlerts();
        } catch ( error ) {
            util.handleError( error );
        }
    }
	
    return(
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <div className="card-body">
                        <div className="d-flex justify-content-center">
                            {alertEmailShow.partner ?
                                <SAlert
                                    show={alertEmailShow.partner}
                                    confirmText="Yes"
                                    typeButton="info"
                                    msg="Submit this talent to partner?"
                                    hideAlert={hideSwalAlerts}
                                    onConfirm={()=> onConfirmSendEmail( PARTNER )}
                                />:""}
							
                            {alertEmailShow.client ?
                                <SAlert
                                    show={alertEmailShow.client}
                                    confirmText="Yes"
                                    typeButton="info"
                                    msg="Submit this talent to partner?"
                                    hideAlert={hideSwalAlerts}
                                    onConfirm={()=> onConfirmSendEmail( CLIENT )}
                                />:""}
						
                        </div>
                        <h4 className="card-title text-muted">Evaluations</h4>
                        {interviews.length > 0 ?
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th width="5%">Evaluation</th>
                                        <th width="10%">Schedule Date</th>
                                        <th width="10%">Evaluator</th>
                                        <th width="10%">Email</th>
                                        <th width="40%">Note</th>
                                        <th className="text-center" width="20%">Actions</th>
                                        <th className="text-center" width="5%">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {interviews && interviews.map( ( interview, index ) => {
                                        return (
                                            <tr key={index}>
                                                <td>{interview["evaluation"] && interview["evaluation"].final_decision ?
                                                    <button
                                                        className="btn btn-sm btn-primary"
                                                        onClick={()=>showEvaluation( interview["evaluation"], index )}>
													Show
                                                    </button>:""}
                                                </td>
                                                <td>{util.humanDateTime( interview.date )}</td>
                                                <td>{interview.name}</td>
                                                <td>{interview.email}</td>
                                                <td>{interview.note}</td>
                                                <td className="text-center">
                                                    {
                                                        interview.status === undefined ?
                                                            ""
                                                            :
                                                            interview.status === 0 && !showForm && !isDisabled ?
                                                                <Fragment>
                                                                    <div className="btn-group">
                                                                        <button onClick={()=> showEditForm( index )} className="btn btn-sm btn-default"><FontAwesomeIcon icon={faPencilAlt}/></button>
                                                                        {interview["emailSent"] === 0 ?
                                                                            <button onClick={()=> {
                                                                                setIndex( index );
                                                                                setTemp( interview );
                                                                                setEmailModal( true );
                                                                            }}
																		        className="btn btn-sm btn-default"
                                                                            >
                                                                                <FontAwesomeIcon icon={faEnvelope}/>
                                                                            </button>
                                                                            :
                                                                            `Email sent on ${util.humanDateTime( interview["emailSentDate"] )}`
                                                                        }
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle variant="success" size="sm" id="dropdown-basic">
																			Status
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu className="dropDownCustomClass">
                                                                                <Dropdown.Item onClick={()=> clearedAndAddRound( index )} href="#">Cleared and Add Round</Dropdown.Item>
                                                                                <Dropdown.Item onClick={()=> clearedAndSelected( index )}  href="#">Cleared and Selected</Dropdown.Item>
                                                                                <Dropdown.Item onClick={()=> notClearedAndRejected( index )}  href="#">Not cleared - Rejected</Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                </Fragment>
                                                                :
                                                                "" // We will not show anything if the status is different that 0 (0 is editable/ in progress)
                                                    }
                                                </td>
                                                <td className="text-center">{util.statusCheck( interview.status )}</td>
                                            </tr>
                                        );
                                    } )}
                                </tbody>
                            </table>:""}
                        {/* Form to create or edit interviews */}
                        {showForm ?
                            <FormInterView
                                cancel={()=> setShowForm( false )}
                                reloadInterviews={( data )=> reloadInterviews( data )}
                                candidate={props.candidate}
                                actionType={actionType}
                                index={index}
                                interviews={interviews}
                                sendRequest={( i, emailTemplate )=>sendEvaluationRequest(index, emailTemplate )}
                                saveAndSend={saveAndSend}
                            />
                            :
                            ""}
						
						
                        {/*  Add new interview only appears if status is lower than 3 and any interview is in progress*/}
                        {!isDisabled && canShowAddButton() ?
                            <ButtonCX
                                text={"Schedule Evaluation"}
                                onClick={()=> addInterviewForm()}
                                icon={faPlusCircle}
                            />
                            :
                            ""}
                    </div>
                </div>
            </div>
            <Drawer anchor="right" open={showDrawer} onClose={()=>setShowDrawer( false )}>
                <div className="container pt-2" style={{ width: window.innerWidth * .30 }}>
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Evaluation</h2>
                            {evaluation ?
                                <Fragment>
                                    <p><b>Final Decision:</b> {parseInt( evaluation.final_decision ) === 1 ?
                                        <Fragment>
                                            <FontAwesomeIcon icon={faCheck} className="mr-2 text-success" />
											Interview Cleared
                                        </Fragment>
                                        :
                                        parseInt( evaluation.final_decision ) === 2 ?
                                            <Fragment>
                                                <FontAwesomeIcon icon={faTimesCircle} className="mr-2 text-danger" />
												Rejected
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <FontAwesomeIcon icon={faClock} className="mr-2 text-warning" />
												Hold
                                            </Fragment>
                                    }</p>
                                    <p><FontAwesomeIcon icon={faCommentAlt} className="mr-2"/> <b>Notes:</b></p>
                                    <p className="textFeedbackDrawer">{evaluation.notes}</p>
                                    <hr/>
                                    <p><FontAwesomeIcon icon={faTags} className="mr-2"/> <b>Skills</b></p>
                                    {evaluation.skills.map( skill =>{
                                        return(
                                            <div key={skill.id}>
                                                <h3>{skill["skillName"]}</h3>
                                                <p><b>Rate</b>:
                                                    <StarRatings
                                                        rating={skill["givenRate"] || 0}
                                                        starDimension="20px"
                                                        starSpacing="1px"
                                                        starRatedColor="rgb(233,227,92)"
                                                    /> {skill["givenRate"]}/5</p>
                                                <p><b>Feedback:</b> </p>
                                                <p className="textFeedbackDrawer">{skill.feedback}</p>
                                            </div>
                                        );
                                    } )}
                                </Fragment>:""
                            }
						
						
                        </div>
                    </div>
                </div>
            </Drawer>
			
            {/* Modal for Email to Evaluator */}
            {emailModal ?
                <EmailModal
                    emailType={2}
                    show={emailModal}
                    handleClose={()=>setEmailModal( false )}
                    sendRequest={( i, emailTemplate )=>sendEvaluationRequest( index, emailTemplate )}
                    interview={temp}
                    index={index}
                />
                :""}
        </Fragment>
    );
}


function mapDispatchToProps( dispatch ) {
    return{
        setCandidateStatus:( data ) => dispatch( setCandidateStatus( data ) ),
    };
}

export default connect( null,mapDispatchToProps )( Step3 );
