import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';

import './SocialMediaTokensForm.css'; // Import a CSS file for styling
import SaveSocialmediaCredentials from './SaveSocialMediaCredentials';


function SocialMediaTokensForm() {

    const parentData = [
        { id: 1, name: "LinkedIn", type: "linkedIn" },
        { id: 2, name: "Instagram", type: "instagram" },
        // { id: 3, name: "Facebook ", type: "facebook" },
        { id: 4, name: "Twitter ", type: "twitter" },
        { id: 5, name: "Email ", type: "email" },
        { id: 6, name: "AWS Bucket ", type: "aws_bucket" },
        { id: 7, name: "Google ", type: "google" },
    ];

    const [newType, setNewType] = useState( 'linkedIn' );
    const [state, setState] = useState( {

        parentData: parentData,
        credentialType: newType,
        id:''
    } );



    const onSelect = ( e, type, name, id ) => {
        console.log(type,name,id)
        e.stopPropagation();
        setNewType( type );

        setState( {
            ...state,
            credentialType: type,
            id:id
        } );

    };

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-4 col-sm-6 bg-white">
                    <div className="d-flex justify-content-between align-items-center mt-1 mb-2">
                        <b>Manage Social Media</b>
                    </div>
                    <div className="listContainer">
                        {parentData.map( ( d, i ) => {
                            return ( <div key={i} className={`d-flex justify-content-between align-items-center p-2
    border rounded mt-1 mb-1 department_item ${state.id === d.type ? "activeBlock" : ""} isCursor`}
                            onClick={( e ) => onSelect( e, d.type, d.name, d.id )}>
                                <div className="isCursor">
                                    <div>
                                        <b className='text-dark'>{d.name}</b>
                                    </div>
                                    <div className="leadName">
                                        <span>{d.name} Access Keys</span>
                                    </div>
                                </div>

                            </div> );
                        } )}
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 bg-white ml-1" style={{ backgroundColor:'#f9f9f9' }}>
                    <div className=" justify-content-between align-items-center mt-1 mb-2">
                      
                        <SaveSocialmediaCredentials  statedata={state}/>
                    </div>
                    {/* Step 1: Search input */}
                  
                  
                </div></div>
        </Fragment> );
}

export default SocialMediaTokensForm;






// return (
//     <div className="social-media-tokens-form">
//         <form onSubmit={handleSubmit}>
//             <h2>LinkedIn</h2>
//             <div className="form-group">
//                 <label>LinkedIn Client ID:</label>
//                 <input type="text" name="linkedin_client_id" value={formData.linkedin_client_id} onChange={handleChange} />
//             </div>
//             <div className="form-group">
//                 <label>LinkedIn Client Secret:</label>
//                 <input type="text" name="linkedin_client_secret" value={formData.linkedin_client_secret} onChange={handleChange} />
//             </div>
//             <h2>Twitter</h2>
//             <div className="form-group">
//                 <label>Twitter API Key:</label>
//                 <input type="text" name="twitter_api_key" value={formData.twitter_api_key} onChange={handleChange} />
//             </div>
//             <div className="form-group">
//                 <label>Twitter API Secret Key:</label>
//                 <input type="text" name="twitter_api_secret_key" value={formData.twitter_api_secret_key} onChange={handleChange} />
//             </div>
//             <div className="form-group">
//                 <label>Twitter Access Token:</label>
//                 <input type="text" name="twitter_access_token" value={formData.twitter_access_token} onChange={handleChange} />
//             </div>
//             <div className="form-group">
//                 <label>Twitter Access Token Secret:</label>
//                 <input type="text" name="twitter_access_token_secret" value={formData.twitter_access_token_secret} onChange={handleChange} />
//             </div>
//             <h2>Instagram</h2>
//             <div className="form-group">
//                 <label>Instagram Access Token:</label>
//                 <input type="text" name="instagram_access_token" value={formData.instagram_access_token} onChange={handleChange} />
//             </div>
//             <div className="form-group">
//                 <label>Instagram Business Account ID:</label>
//                 <input type="text" name="instagram_business_account_id" value={formData.instagram_business_account_id} onChange={handleChange} />
//             </div>
//             <div className="form-group">
//                 <label>Instagram Client Secret:</label>
//                 <input type="text" name="instagram_client_secret" value={formData.instagram_client_secret} onChange={handleChange} />
//             </div>
//             <div className="form-group">
//                 <label>Instagram Client ID:</label>
//                 <input type="text" name="instagram_client_id" value={formData.instagram_client_id} onChange={handleChange} />
//             </div>
//             <div className="form-group">
//                 <label>Instagram Short-Lived Access Token:</label>
//                 <input type="text" name="instagram_short_lived_access_token" value={formData.instagram_short_lived_access_token} onChange={handleChange} />
//             </div>
//             <button type="submit" className="submit-button">{isEdit ? 'Update' : 'Save'} Tokens</button>
//         </form>
//     </div>
// );


