import React, { useEffect, useState } from 'react';
import util from '../../utils/miniUtils';
import { Responsive, WidthProvider } from "react-grid-layout";  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faTimes } from '@fortawesome/free-solid-svg-icons';
import CategoryDropdown from './CategoryDropdown';
import ChartFilter from './ChartFilter';
import { useDispatch } from 'react-redux';
import { setFilter, setFrom, setTo } from '../../../redux/actions/datefilter';
import DashboardFeatures from './DashboardFeatures';
import useGetCharts from './ChartComponent';

const ResponsiveGridLayout = WidthProvider( Responsive );

const DndDasboardNew = () => {
    let chartComp = useGetCharts();
    const [layouts, setLayouts] = useState( [] ); // layouts only uses the initialState to render so don't mix it
    // const [layouts, setLayouts] = useState( chartComp.map( item => item.initialState ) );
    const uniqueCategories = [...new Set( chartComp.map( item => item.category ) )];
    const [reportNames, setReportNames] = useState( [] );
    const [selectedReport,setSelectedReport] = useState( '' );
    const [availableCharts, setAvailableCharts] = useState( chartComp ); //availableCharts are taking all values initialSTate, category title so always wary of it's use
    const [newReport, setNewReport] = useState( false );
    const dispatch = useDispatch();
    const accountId = util.user.id;
    function toISOStringDate( dateString ) {
        const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
      
        const [year, month, day] = dateString.split( '-' );
        const formattedMonth = months[parseInt( month ) - 1];
        const formattedDay = parseInt( day ).toString().padStart( 2, '0' );
      
        return `${formattedDay}-${formattedMonth}-${year}`;
    }
    
    // Get savedlayouts from db where account_id = user.id

    const getLayout = async ( accountId ) => {
        try {
            const response = await util.axios.get(
            `new_dashboard/get_layout_data/${accountId}`
            );  
            const { data, error, msg } = response.data;
            
            const reportNames = data.map( ( item ) => item.report_name );
            setReportNames( reportNames );
            // console.log( reportNames );
            if( reportNames.length ===0 ){
                // console.log( 'hello' );
                setNewReport( true );
                setSelectedReport( '' );
                setAvailableCharts( chartComp );
                setLayouts( [] );
                dispatch( setTo( '' ) );
                dispatch( setFrom( '' ) );
                dispatch( setFilter( false ) );
            }
            if( selectedReport == "" && newReport === false ){
                setSelectedReport( reportNames[0] );
            }
            const layout_data = data.find( ( item ) => item.report_name === selectedReport )?.layout_data;
            let toDate = data.find( ( item ) => item.report_name === selectedReport )?.toDate;
            let fromDate = data.find( ( item ) => item.report_name === selectedReport )?.fromDate;
            if( toDate && fromDate ){
                fromDate = toISOStringDate( fromDate );
                toDate = toISOStringDate( toDate );
                
                dispatch( setFrom( fromDate ) );
                dispatch( setTo( toDate ) );
            }
                
            dispatch( setFilter( true ) );
            
            if ( layout_data !== null && selectedReport && layout_data !== undefined  ) {

                const parsedLayoutData = JSON.parse( layout_data );
                
                setLayouts( [ ...parsedLayoutData] );
                const updatedAvailableCharts = chartComp.filter( ( chart ) => {
                    
                    return !parsedLayoutData.some( ( item ) => item.i == chart.id );
                } );
                setAvailableCharts( updatedAvailableCharts );
            }
            if ( reportNames.length > 0  ) {
                setReportNames( reportNames );
            }

            if ( error ) throw msg;
        } catch ( e ) {
            util.handleError( e );
        }   
    };
    
    useEffect( () => {
        
        getLayout( accountId );
    }, [accountId,selectedReport, chartComp] );
    // According to layout get chart info (db_layout_chart table )
    // 1. chart 1 => get "chart 1" data to show
    // 2. show => generic component which changes behaviour according to props

   
    const handleRemove = ( chartId ) => {
        const removedChart = layouts?.find( ( item ) => item.i == chartId );
        const updatedLayout = layouts.filter( ( item ) => item.i != chartId );
        
        setLayouts( updatedLayout );
        if ( removedChart ) {
            setAvailableCharts( ( prev ) => [...prev, chartComp.find( ( item ) => item.initialState.i == chartId )] );
        }
    };
  
    
    const onLayoutChange = ( newLayout ) =>{
        setLayouts( newLayout );
    };
    

    return (
        <div className='drag-drop'>            
            <div className="m-3 select-header-dashboard">
                <DashboardFeatures selectedReport={selectedReport} setSelectedReport={setSelectedReport} reportNames={reportNames} layouts={layouts} setLayouts={setLayouts} setAvailableCharts={setAvailableCharts} newReport={newReport} setNewReport={setNewReport} setReportNames={setReportNames} />
                
                <CategoryDropdown availableCharts={availableCharts} setAvailableCharts={setAvailableCharts} setLayouts={setLayouts} uniqueCategories={uniqueCategories}/>
                <ChartFilter />
            </div>
            <ResponsiveGridLayout
                className="layout"
                draggableCancel=".non-draggable"
                layouts={{ lg: layouts }}
                breakpoints={{ lg: 1200 }}
                // breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                cols={{ lg: 48 }}
                onLayoutChange={onLayoutChange}
                // autoSize={true}
            >
                {layouts.map( ( item, index ) => {
                    if( !item || !item.i ){
                        return null;
                    }
                    const chartItem = chartComp?.find( chart => chart?.initialState?.i == item?.i );
                    if ( chartItem ) {
                        const ComponentToRender = chartItem.component;
                        return (
                            <div key={chartItem.id || index} style={{ position: "relative" }}>
                                <div
                                    style={{
                                        position: "relative",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        backgroundColor: "white",
                                        padding: " 0 0.25rem",
                                    }}
                                >
                                    <div style={{ fontSize: "20px", color: "#757575" }}>
                                        {chartItem.title}
                                    </div>
                                    <span className="non-draggable" >
                                        <FontAwesomeIcon icon={faTimes} onClick={()=> handleRemove( item.i )} />
                                    </span>
                                </div>
                                <ComponentToRender />
                            </div>
                        );
                    }
                    return null; // Render null if the component is not found in chartComp
                } )}
                
            </ResponsiveGridLayout>
        </div>
    );

};

export default DndDasboardNew;