import React, { Fragment, useEffect, useState } from "react";
import { MuiThemeProvider } from "@material-ui/core";
import util from "../../utils/miniUtils";
import MaterialTable from "material-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import TestModal from "./TestModal";

export default function Test() {
	
    const [state, setState] = useState( {
        data: [],
        id:null,
        loading: false,
        modal: false
    } );
	
    useEffect( () => {
        getTests().then( null );
    },[] );
	
    const getTests  = async () => {
        try {
            setState( { ...state, loading: true } );
            const request = await util.axios.get( `${util.url}/tests/list`, );
            let { error, msg, tests } = request.data;
            if ( error ) throw msg;
            setState( { ...state, data: tests, loading: false } );
        }catch ( e ) {
            setState( { ...state, loading: false } );
            util.handleError( e );
        }
    };
	
    return (
        <Fragment>
            <div className="row mb-4">
                <div className="col-md-12">
                    <MuiThemeProvider theme={util.defaultTableTheme}>
                        <MaterialTable
                            title="Tests"
                            columns={[
                                {
                                    title:"ID",
                                    field: "id",
                                    render: data => {
                                        return <div className="text-center">{util.zeroPad( data.id, 5 )}</div>;
                                    }
                                },
                                {
                                    title:"Name",
                                    field: "name",
                                },
                                {
                                    title: "Description",
                                    field: "desc",
                                    render: data => {
                                        return data.desc.replace( /<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, "" ).substring( 0,200 );
                                    }
                                },
                                {
                                    title: "Date",
                                    field: "createdAt",
                                    render:data => {
                                        return <div className="text-center">{util.humanDate( data.createdAt )}</div>;
                                    }
                                },
                            ]}
                            data={state.data}
                            options={{
                                pageSize: 20,
                                sorting: false,
                                showFirstLastPageButtons: false,
                                pageSizeOptions: [20,50,100],
                                padding: "dense",
                                emptyRowsWhenPaging:false
                            }}
                            onRowClick={( event, rowData ) => setState( { ...state, id: rowData.id } )}
                            actions={[
                                {
                                    icon: () => (
                                        <div id="addTestIcon" className="text-primary">
                                            <FontAwesomeIcon
                                                icon={faPlusCircle}
                                                className="mr-2"
                                            />
											
                                        </div>
                                    ),
                                    tooltip: "Add edit test",
                                    isFreeAction: true,
                                    onClick: ( event, data ) => setState( { ...state, modal: true, id: null } ),
                                },
                            ]}
                            onRowClick={( e,rowData ) => {
                                setState( { ...state, modal: true, id: rowData.id } );
                            }}
                        />
                    </MuiThemeProvider>
                </div>
            </div>
            {state.modal ?
                <TestModal
                    id={state.id}
                    show={state.modal}
                    refreshTestList={()=> getTests()}
                    hideModal={() => setState( { ...state, modal: false, id: null } )}
                />
                :
                ""
            }
			
            {util.LOADING_SCREEN( state.loading )}
        </Fragment>
    );
}