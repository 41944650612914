import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { PositionsStore } from "../stores/PositionsStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import ReactRecaptcha from "react-recaptcha";
import axios from "axios";
import { bake_cookie, read_cookie } from "sfcookies";
import { toast } from "react-toastify";
import util from "../../utils/miniUtils";
import ImageComponent from "../../utils/ImageComponent";

import validations from "../../utils/validations";
import { Form, Formik } from "formik";
import TextField from "@material-ui/core/TextField";
import jwt_decode from "jwt-decode";
import CryptoJS from 'crypto-js';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import MicrosoftLoginButton from "./MicrosoftLoginButton";
import google from '../../../assets/images/google.webp';
import { IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import GreetingPopup from "./GreetingPopup";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export default function Login() {
    const [showPassword, setShowPassword] = useState( false );
    const handleClickShowPassword = () => setShowPassword( !showPassword );
    const handleMouseDownPassword = () => setShowPassword( !showPassword );
    const show = PositionsStore.useState( state => state.loginModal );
    const [state, setState] = useState( {} );
    const [recaptchaEnabled, setRecaptchaEnabled] = useState( false );
    // const [isModalVisible, setIsModalVisible] = useState(false);
    const history = useHistory();
    const { t } = useTranslation();
    // const { instance } = useMsal();

    // const accountDetail = instance?.getActiveAccount();

    // useEffect( () => {
    //     // Use accountDetail directly inside useEffect
    //     if ( accountDetail ) {
    //         // Now you have account details, you can send them to your server for validation
    //         // and further processing like you do in your existing login flow
    //         validateMicrosoftAccount( accountDetail );
    //     }
    // }, [accountDetail] );
    // console.log( activeAccount );
    // console.log( activeAccount?.username,'username' );
    // console.log( activeAccount?.name,'name' );


    useEffect( () => {
        setTimeout( () => {
            if ( !recaptchaEnabled ) {
                setRecaptchaEnabled( true );
            }
        }, 1000 );
    }, [] );
    const handleClose = () => { PositionsStore.update( s => { s.loginModal = false; } ); };

    const verifyCallBack = data => {
        if ( data ) {
            setState( { ...state, isVerified: true } );
        }
    };

    const validateMicrosoftAccount = async ( accountDetail ) => {
        try {
            const value = {
                email: accountDetail?.account.username,
                name: accountDetail.account.name,
                idToken: accountDetail.idToken,
            };
            const request = await axios.post( `${util.url}/auth/microsoft_login`, value );

            // Handle response (e.g., store token, redirect)
            const { error, msg, accessToken } = request.data;
            if ( error ) {
                PositionsStore.update( s => { s.loading = false; } );
                return util.handleError( msg );
            }
            bake_cookie( "tokenServer", accessToken );
            const urlParams = new URLSearchParams( window.location.search );
            const jobId = urlParams.get( 'job' );
            toast.success( msg );

            let u = jwt_decode( accessToken );

            if ( u.role === 2 ) {
                PositionsStore.update( s => {
                    s.isLogged = true;
                    s.loginModal = false;
                    s.createAccount = false;
                    s.profileProcess = true;
                    s.user = jwt_decode( accessToken );
                } );
            } else {
                if ( jobId !== null ) {
                    let u = jwt_decode( accessToken );
                    if ( u.role === 2 ) {
                        window.history.replaceState( "", "", `/careers?search=&job=${jobId}` );
                        window.location.reload();
                    } else if( u.role === 9 ){
                        window.history.replaceState( "", "", "/support" );
                    }
                    else {
                        window.history.replaceState( "", "", `/jobs` );
                    }

                } else {
                    // window.history.replaceState("", "", "/support");
                    window.location.reload();
                }
                // Every time the talent log in, it will be redirected to the main page
                setTimeout( () => {
                    window.location.reload();
                }, 1500 );
            }


        } catch ( e ) {
            PositionsStore.update( s => { s.loading = false; } );
            util.handleError( e );
        }
    };

    const tryLogin = async ( values ) => {
        try {
            //Validate is human
            if ( !state.isVerified ) return util.handleError( "Validate captcha" );

            PositionsStore.update( s => { s.loading = true; } );
            const data = { ...state };
            // Base64 password
            let enc_password = values.password === "" ? "" : values.password;
            values.password = '';
            data.email = values.email;
            data.password = CryptoJS.AES.encrypt( enc_password, process.env.REACT_APP_PASS_KEY ).toString();

            // Existing login logic for non-Microsoft accounts
            const request = await axios.post( `${util.url}/auth/sign_in`, data );
            const { error, msg, accessToken,requireEmailVerification,redirectTo, userData } = request.data;
            if ( error ) {
                PositionsStore.update( s => { s.loading = false; } );
                return util.handleError( msg );
            }
            if( requireEmailVerification ){
                PositionsStore.update( s => { s.loading = false;
                    s.userData = userData;

                } );
                return history.push( redirectTo + "?internal=true" );
            }

            bake_cookie( "tokenServer", accessToken );
            const urlParams = new URLSearchParams( window.location.search );
            const jobId = urlParams.get( 'job' );
            toast.success( msg );


            // PositionsStore.update(s => {
            //     // s.isLogged = true;
            //     // s.loginModal = false;

            //     s.isModalVisible = true;
            //     s.user = jwt_decode(accessToken);


            // });
            PositionsStore.update( s => { s.loading = false; } );
            if ( jobId !== null ) {
                let u = jwt_decode( accessToken );
                if ( u.role === 2 ) {
                    window.history.replaceState( "", "", `/careers?search=&job=${jobId}` );
                    window.location.reload();
                } else {
                    window.history.replaceState( "", "", `/jobs` );
                    PositionsStore.update( s => { s.firstTime = false; } );
                }

            } else {
                // window.history.replaceState( "", "", "/" );
                window.location.reload();
            }
            // Every time the talent log in, it will be redirected to the main page
            setTimeout( () => {
                window.location.reload();
            }, 1500 );
        } catch ( e ) {
            PositionsStore.update( s => { s.loading = false; } );
            util.handleError( e );
        }
    };


    const createAccount = () => {
        setRecaptchaEnabled( false );
        PositionsStore.update( s => {
            s.loginModal = false;
            s.createAccount = true;
        } );
    };

    const forgotPassword = () => {
        PositionsStore.update( s => {
            s.recoveryModal = true;
            s.loginModal = false;
        } );
    };

    const initialValues = {
        email: "",
        password: '',
    };

    const logo = "/images/demo_logo.svg";
    // console.log( instance );
    const handleGoogleLogin = async () => {
        try {

            window.location.href = `${util.url}/auth/google`;


        } catch ( e ) {
            PositionsStore.update( s => { s.loading = false; } );
            util.handleError( e );
        }
    };
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                size="sm"
            >
                <Modal.Body>
                    <div className="mt-4 text-center">
                        <img
                            src={logo}
                            className="mx-auto d-block m-4 logo"
                            alt="eJRekruit"
                        />
                        {/* <h3>eJRekruit</h3> */}
                        {state.isError ?
                            <span className="text-danger">
                                <FontAwesomeIcon icon={faExclamationCircle} />{" "}
                                {state.msg}
                            </span>
                            :
                            ""
                        }
                        <div className="card-body">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validations.validateLogin}
                                onSubmit={( values ) => {
                                    tryLogin( values ).then( null );
                                }}
                            >
                                {( { values, handleChange, touched, errors } ) => (
                                    <Form>
                                        <table className="registerTable w-100">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <TextField
                                                            fullWidth
                                                            id="email"
                                                            name="email"
                                                            label={t( "Email" )}
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            error={touched.email && Boolean( errors.email )}
                                                            helperText={touched.email && errors.email}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <TextField
                                                            fullWidth
                                                            type={showPassword ? "text" : "password"}
                                                            id="password"
                                                            name="password"
                                                            label={t( 'Password' )}
                                                            value={values.password}
                                                            onChange={handleChange}
                                                            error={touched.password && Boolean( errors.password )}
                                                            helperText={touched.password && errors.password}
                                                            InputProps={{ // <-- This is where the toggle button is added.
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowPassword}
                                                                            onMouseDown={handleMouseDownPassword}
                                                                        >
                                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            }}

                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="text-center">
                                            {
                                                recaptchaEnabled && show ?
                                                    <ReactRecaptcha
                                                        elementID={"diihdhod"}
                                                        sitekey={process.env["REACT_APP_SITE_KEY"]}
                                                        render="explicit"
                                                        // onloadCallback={() => console.log( 'captchaLoaded' )}
                                                        verifyCallback={verifyCallBack}
                                                    />
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className="form-group mt-2">
                                            <button
                                                type={"submit"}
                                                id="button-login"
                                                data-testid="button-login"
                                                disabled={state.isDisabled}
                                                className="btn btn-primary btn-block"
                                            // onClick={tryLogin}
                                            >
                                                { t ( 'Login' )}
                                            </button>
                                        </div>
                                    </Form>
                                )}

                            </Formik>
                            <div className="separator">
                                <span>or</span>
                            </div>
                            {/* <UnauthenticatedTemplate> */}
                            {/* <button onClick={handleRedirect}>Continue With Microsoft</button> */}
                            <MicrosoftLoginButton ejLogin={validateMicrosoftAccount} />
                            <div>
                                <button className='border p-2 login-btns' href="http://localhost:34567/auth/google" onClick={handleGoogleLogin}>
                                    <img alt="google" src={google} href="" width={20} height={20} />
                                    {t( 'Sign in with Google' )} 
                                </button>
                            </div>
                            {/* </UnauthenticatedTemplate> */}
                            {/* <AuthenticatedTemplate>
                            <div>login completed</div>
                            {/* <button onClick={handleLogout}>logout</button> */}
                            {/* </AuthenticatedTemplate> */}

                            <div className="d-flex justify-content-around">
                                <span onClick={forgotPassword} className="likeLink">{t( 'Forgot Password' )}</span>
                                <span onClick={createAccount} className="likeLink">{t( 'Create Account' )}</span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* <GreetingPopup /> */}

        </>
    );
}
