import React, { Fragment, useEffect, useState } from "react";
import util from "../../../utils/miniUtils";
import { Modal, Form, Spinner, ListGroup, ListGroupItem } from "react-bootstrap";
import { TextField, Tooltip } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import PhoneInput from "react-phone-input-2";
import PermissionRow from './PermissionRow';
import { faPlusCircle, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import selector from "../../../utils/selector";
import useModalUsers from "./useModalUsers";
import { EMPLOYEE_MANAGER, EMPLOYEE_ROLE, RECRUITER_MANAGER, RECRUITER_ROLE } from "../../../../constants";
const tableRef = React.createRef();

export default function ModalUser( { user, hideModal, modalVisible } ) {
    const {
        modalUser, setModalUser,
        isNew, setIsNew,
        companyList,
        loading,
        needSkills,
        companies,
        skills,
        access,
        getAccess,
        handleChanges,
        updateUser,
        createUser,
        handleChangesPermission,
        isDisabled,
        onSkillChange,
        addSkill,
        removeSkill,
        impersonateUser,
        reviewPermissions,
        options, toggleChecked, sortedOptions, handleSubmit
    } = useModalUsers( user, hideModal, tableRef );

    const handleSelectCustomerCompany = ( event, newValue ) => {
        const selectedCompany = companyList.find( option => option.customer_name === newValue );
        setModalUser( {
            ...modalUser,
            customer_company_id: selectedCompany ? selectedCompany.customer_company_id : null
        } );
    };

    useEffect( () => {
        let u = user;
        if ( user.id === 0 ) {
            if ( user.permissions === undefined ) {
                u.permissions = {
                    account_id: 0,
                    recruiter: 0,
                    recruiter_manager: 0,
                    evaluator: 0,
                    employee_manager: 0,
                    asset_manager: 0,
                    talent: 0,
                    employee: 0,
                    client: 0,
                    partner: 0,
                };
            }
            setIsNew( true );
            setModalUser( u );
        } else {
            u.id = user.id;
            setIsNew( false );
            setModalUser( u );
        }
    }, [user] );
    return (
        <Fragment>
            <Modal size={"lg"} show={modalVisible} onHide={hideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <table className="table table-borderless w-100">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <TextField
                                                    className={"w-100"}
                                                    label="Name"
                                                    value={modalUser.name}
                                                    name={"name"}
                                                    onChange={handleChanges}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Autocomplete
                                                    freeSolo
                                                    title="Customer Company"
                                                    name="customer_company_id"
                                                    onChange={handleSelectCustomerCompany}
                                                    value={companyList.find( option => option.customer_company_id === modalUser.customer_company_id )?.customer_name || ''}
                                                    options={companyList.map( ( option ) => option.customer_name )}
                                                    renderInput={( params ) => <TextField {...params} label="Company" />}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="d-flex">
                                                <TextField
                                                    className={"w-60"}
                                                    label={"Login Email"}
                                                    value={modalUser.email.split( '@' )[0]}
                                                    name={"email"}
                                                    onChange={handleChanges}
                                                />
                                                <select name={"email"} onChange={handleChanges} value={modalUser.email} label="domain" className="w-30 align-self-end rounded-bottom important" style={{ height: "fit-content" }}>
                                                    <option value=''>{'@' + modalUser.email.split( '@' )[1]}</option>
                                                    <option value={modalUser.email.split( '@' )[0] + '@ejamerica.com'}>@ejamerica.com</option>
                                                    <option value={modalUser.email.split( '@' )[0] + '@akanaservices.com'}>@akanaservices.com</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <TextField
                                                    className={"w-100"}
                                                    label="Personal Email"
                                                    value={modalUser.candidate_email}
                                                    name={"candidate_email"}
                                                    onChange={handleChanges}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="labelFormControlMini">Phone 1</label>
                                                <PhoneInput
                                                    placeholder={"Phone 1"}
                                                    country={'us'}
                                                    name={"phone1"}
                                                    value={modalUser.phone1}
                                                    onChange={phone => {
                                                        let e = { target: { name: "phone1", value: phone } };
                                                        handleChanges( e );
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="labelFormControlMini">Phone 2</label>
                                                <PhoneInput
                                                    placeholder={"Phone 2"}
                                                    country={'us'}
                                                    name={"phone2"}
                                                    value={modalUser.phone2}
                                                    onChange={phone => {
                                                        let e = { target: { name: "phone2", value: phone } };
                                                        handleChanges( e );
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {/*  Select the country of the recruiter  */}
                                                {( [RECRUITER_ROLE, RECRUITER_MANAGER, EMPLOYEE_ROLE, EMPLOYEE_MANAGER].includes( modalUser.role ) ||
                                                    reviewPermissions()
                                                ) ?
                                                    <div className={`mt-2`}>
                                                        <TextField
                                                            select
                                                            name="country"
                                                            className="w-100"
                                                            label="Recruiter Location"
                                                            value={modalUser.country || ""}
                                                            onChange={handleChanges}
                                                            InputLabelProps={{ shrink: true }}
                                                            SelectProps={{ native: true }}
                                                            variant='outlined'
                                                            size="small"
                                                        >
                                                            <option value=""> -- </option>
                                                            {selector.country().map( ( option ) => (
                                                                <option key={option.country} value={option.country}>
                                                                    {option.country}
                                                                </option>
                                                            ) )}
                                                        </TextField>
                                                    </div>
                                                    : ""
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <button
                                                    className="float-right btn btn-block btn-secondary activeBtn"
                                                    onClick={handleSubmit}
                                                >
                                                    {isNew ? "SAVE" : "UPDATE"}
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-6">
                                {/* <div>
                                    <h3>Primary Role</h3>
                                    <select name="role" onChange={handleChanges} value={modalUser.role} className="form-control">
                                        <option value="1">Recruiter</option>
                                        <option value="2">Talent</option>
                                        <option value="3">Client</option>
                                        <option value="4">Partner</option>
                                        <option value="5">Evaluator</option>
                                        <option value="6">Recruiter Manager</option>
                                        <option value="7">Employee</option> 
                                        <option value="8">Employee Manager</option>
                                    </select>
                                </div>
                                <div>
                                    <div>
                                        <h3>Secondary Role</h3>
                                        <table>
                                            <thead />
                                            <tbody>
                                                {modalUser.role === 1 ?
                                                    <Fragment>
                                                        <PermissionRow permission={modalUser.permissions.recruiter} disabled={isDisabled( "recruiter" )} role="Recruiter" handleChangesPermission={handleChangesPermission} />
                                                        <PermissionRow permission={modalUser.permissions.recruiter_manager} disabled={isDisabled( "recruiter_manager" )} role="Recruiter Manager" handleChangesPermission={handleChangesPermission} />
                                                        <PermissionRow permission={modalUser.permissions.talent} disabled={isDisabled( "talent" )} role="Talent" handleChangesPermission={handleChangesPermission} />
                                                        <PermissionRow permission={modalUser.permissions.evaluator} disabled={isDisabled( "evaluator" )} role="Evaluator" handleChangesPermission={handleChangesPermission} />
                                                        <PermissionRow permission={modalUser.permissions.employee} disabled={isDisabled( "employee" )} role="Employee" handleChangesPermission={handleChangesPermission} />
                                                        <PermissionRow permission={modalUser.permissions.employee_manager} disabled={isDisabled( "employee_manager" )} role="Employee Manager" handleChangesPermission={handleChangesPermission} />
                                                        <PermissionRow permission={modalUser.permissions.asset_manager} disabled={isDisabled( "asset_manager" )} role="Asset Manager" handleChangesPermission={handleChangesPermission} />
                                                    </Fragment> : null}
                                                {modalUser.role === 2 ?
                                                    <Fragment>
                                                        <PermissionRow permission={modalUser.permissions.talent} disabled={isDisabled( "talent" )} role="Talent" handleChangesPermission={handleChangesPermission} />
                                                        <PermissionRow permission={modalUser.permissions.employee} disabled={isDisabled( "employee" )} role="Employee" handleChangesPermission={handleChangesPermission} />
                                                    </Fragment> : null}
                                                {modalUser.role === 3 ?
                                                    <Fragment>
                                                        <PermissionRow permission={modalUser.permissions.client} disabled={isDisabled( "client" )} role="Client" handleChangesPermission={handleChangesPermission} />
                                                        <PermissionRow permission={modalUser.permissions.partner} disabled={isDisabled( "partner" )} role="Partner" handleChangesPermission={handleChangesPermission} />
                                                    </Fragment> : null}
                                                {modalUser.role === 4 ?
                                                    <Fragment>
                                                        <PermissionRow permission={modalUser.permissions.client} disabled={isDisabled( "client" )} role="Client" handleChangesPermission={handleChangesPermission} />
                                                        <PermissionRow permission={modalUser.permissions.partner} disabled={isDisabled( "partner" )} role="Partner" handleChangesPermission={handleChangesPermission} />
                                                    </Fragment> : null}
                                                {modalUser.role === 5 ?
                                                    <Fragment>
                                                        <PermissionRow permission={modalUser.permissions.evaluator} disabled={isDisabled( "evaluator" )} role="Evaluator" handleChangesPermission={handleChangesPermission} />
                                                    </Fragment> : null}
                                                {modalUser.role === 6 ?
                                                    <Fragment>
                                                        <PermissionRow permission={modalUser.permissions.recruiter} disabled={isDisabled( "recruiter" )} role="Recruiter" handleChangesPermission={handleChangesPermission} />
                                                        <PermissionRow permission={modalUser.permissions.recruiter_manager} disabled={isDisabled( "recruiter_manager" )} role="Recruiter Manager" handleChangesPermission={handleChangesPermission} />
                                                        <PermissionRow permission={modalUser.permissions.talent} disabled={isDisabled( "talent" ) } role="Talent" handleChangesPermission={handleChangesPermission} />
                                                        <PermissionRow permission={modalUser.permissions.evaluator} disabled={isDisabled( "evaluator" )} role="Evaluator" handleChangesPermission={handleChangesPermission} />
                                                        <PermissionRow permission={modalUser.permissions.employee} disabled={isDisabled( "employee" )} role="Employee" handleChangesPermission={handleChangesPermission} />
                                                        <PermissionRow permission={modalUser.permissions.employee_manager} disabled={isDisabled( "employee_manager" )} role="Employee Manager" handleChangesPermission={handleChangesPermission} />
                                                    </Fragment> : null}
                                                {modalUser.role === 7 ?
                                                    <Fragment>
                                                        <PermissionRow permission={modalUser.permissions.talent} disabled={isDisabled( "talent" )} role="Talent" handleChangesPermission={handleChangesPermission} />
                                                        <PermissionRow permission={modalUser.permissions.evaluator} disabled={isDisabled( "evaluator" )} role="Evaluator" handleChangesPermission={handleChangesPermission} />
                                                        <PermissionRow permission={modalUser.permissions.employee} disabled={isDisabled( "employee" )} role="Employee" handleChangesPermission={handleChangesPermission} />
                                                        <PermissionRow permission={modalUser.permissions.employee_manager} disabled={isDisabled( "employee_manager" )} role="Employee Manager" handleChangesPermission={handleChangesPermission} />
                                                    </Fragment> : null}
                                                {modalUser.role === 8 ?
                                                    <Fragment>
                                                        <PermissionRow permission={modalUser.permissions.evaluator} disabled={isDisabled( "evaluator" )} role="Evaluator" handleChangesPermission={handleChangesPermission} />
                                                        <PermissionRow permission={modalUser.permissions.employee} disabled={isDisabled( "employee" )} role="Employee" handleChangesPermission={handleChangesPermission} />
                                                        <PermissionRow permission={modalUser.permissions.employee_manager} disabled={isDisabled( "employee_manager" )} role="Employee Manager" handleChangesPermission={handleChangesPermission} />
                                                    </Fragment> : null}
                                            </tbody>
                                        </table>
                                    </div> */}
                                <div className="col-md-12">
                                    <h3>Roles</h3>
                                    <Form>
                                        <Form.Group controlId="roleDropdown">
                                            <Form.Label>Select Roles</Form.Label>
                                            {loading ? (
                                                <Spinner animation="border" />
                                            ) : (
                                                <ListGroup style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                                                    {sortedOptions.map( ( option ) => (
                                                        <ListGroupItem key={option.role_id}>
                                                            <Form.Check
                                                                type="checkbox"
                                                                label={option.name}
                                                                checked={option.checked}
                                                                onChange={() => toggleChecked( option.role_id, option.name )}
                                                            />
                                                        </ListGroupItem>
                                                    ) )}
                                                </ListGroup>
                                            )}
                                        </Form.Group>
                                    </Form>
                                    <div className="d-flex justify-content-center mt-3">
                                        {!isNew ?
                                            <Fragment>
                                                {access ?
                                                    <button
                                                        className="btn btn-secondary mr-3 activeBtn"
                                                        onClick={() => impersonateUser( modalUser )}
                                                    >
                                                    Impersonate user
                                                    </button>
                                                    :
                                                    ""
                                                }
                                                {/* <button
                                            className="float-right btn btn-secondary activeBtn"
                                            onClick={() => isNew ? createUser( modalUser ) : updateUser( modalUser, 1 )}
                                        >
                                                Apply Permissions
                                        </button> */}
                                            </Fragment>
                                            : ""}
                                    </div>
                                </div>
                                {/* This section is only displayed if the selected user haven't a talent profile*/}
                                {needSkills ?
                                    <Fragment>
                                        <table>
                                            <thead />
                                            <tbody>
                                                <tr>
                                                    <td colSpan={3} className="text-center text-black-50">
                                                            Skills
                                                        <Tooltip title="Skills are mandatory">
                                                            <span><FontAwesomeIcon icon={faQuestionCircle} className="ml-2" /></span>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                                {/* {skills && skills.map( ( skill, i ) => {
                                                        return (
                                                            <util.SkillForm key={i} skill={skill} index={i} removeSkill={removeSkill} onSkillChange={onSkillChange} />
                                                        );
                                                    } )} */}
                                                <tr>
                                                    <td colSpan={3}>
                                                        <div className="col-md-12 text-right p-2">
                                                            <button onClick={addSkill} className="btn btn-sm btn-primary">
                                                                <FontAwesomeIcon icon={faPlusCircle} className="mr-2" /> Skill
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Fragment> : ""}
                                {/* <Fragment>
                                        <div className="container-fluid">
                                            <div className="row">
                                                <table className="table table-borderless w-100">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <TextField
                                                                    className={"w-100"}
                                                                    label={"Personal Email"}
                                                                    value={modalUser.candidate_email}
                                                                    name={"candidate_email"}
                                                                    onChange={handleChanges}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Fragment> */}


                            </div>

                            
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {util.LOADING_SCREEN( loading )}
        </Fragment>
    );
}
