import { faCalendar, faPlus, faSave, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import util from '../../../../../utils/miniUtils';

export const CtcComments = ( { ctc, saveComments } ) => {
    const [state, setState] = useState( {
        comment: "",
        comments: ctc?.comments ? ctc.comments : []
    } );
    const [showForm, setShowForm] = useState( false );

    const onChange = ( e ) => {
        const { name, value } = e.target;
        setState( { ...ctc, [name]: value } );
    };

    const addCommentToComments = () => {
        if ( state.comment === "" ) return false;
        const tempComments = ctc.comments ? [...state.comments] : [];
        tempComments.push( {
            user: util.user.name,
            date: new Date().toISOString().split( "." )[0].split( "T" ).join( " " ),
            comment: state.comment
        } );
        setState( ( prev ) => {
            return {
                ...prev,
                comment: "",
                comments: tempComments
            };
        } );
        setShowForm( false );
        saveComments( tempComments );
    };

    const resetForm = () => {
        setState( ( prev ) => {
            return {
                ...prev,
                comment: ""
            };
        } );
        setShowForm( false );
    };

    return (
        <div className='mb-5'>
            <div><b>Comments</b></div>
            <div>
                {state.comments && state.comments?.map( ( c, i ) => {
                    return (
                        <div key={i} className="card p-2 mb-2">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <FontAwesomeIcon color="grey" icon={faCalendar} />
                                    <span className="p-1">{util.humanDateTime( c.date )}</span>
                                </div>
                                <div>
                                    <FontAwesomeIcon color="dodgerblue" icon={faUserAlt} />
                                    <span className="p-1">{c.user}</span>
                                </div>
                            </div>
                            <div>{c.comment}</div>
                        </div>
                    );
                } )}
            </div>

            {!showForm ? (
                <div className='d-flex justify-content-center'>
                    <button
                        onClick={() => setShowForm( true )}
                        className="btn btn-primary btn-sm"
                    >
                        <FontAwesomeIcon icon={faPlus} />
                        <span className="p-1">Add Comment</span>
                    </button>
                </div>
            ) : (
                <div className="">
                    <div>
                        <textarea
                            autoFocus
                            className="form-control"
                            name="comment"
                            value={state.comment || ""}
                            onChange={onChange}
                        />
                    </div>
                    <div className="d-flex justify-content-end align-items-center p-1">
                        <a href="#c" onClick={resetForm} className="text-danger">
                            <span className="p-1">Cancel</span>
                        </a>
                        <button
                            onClick={addCommentToComments}
                            className="btn btn-primary btn-sm"
                        >
                            <FontAwesomeIcon icon={faSave} />
                            <span className="p-1">Save Comment</span>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};