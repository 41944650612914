import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import util from "../../utils/miniUtils";

const CustomerMembersTable = ( { id } ) => {
    const [data, setData] = useState( [] );
    const [loading, setLoading] = useState( false );
    const [page, setPage] = useState( 0 );
    const [total, setTotal] = useState( 0 );
    const [pageSize, setPageSize] = useState( 20 );
    const [search, setSearch] = useState( "" );

    const fetchData = async () => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/customer_companies/members/${id}?page=${page}&pageSize=${pageSize}&search=${search}` );
            const { error, data, total } = request.data;
            setData( data );
            setTotal( total );
            if ( error ) throw new Error( "Error Fetching Companies" );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    useEffect( () => {
        fetchData();
    }, [id, page, pageSize, search] ); // Added page, pageSize, search to the dependency array

    return (
        <>
            <MaterialTable
                title="User Details"
                columns={[
                    { title: 'ID', field: 'id' },
                    { title: 'Name', field: 'name' },
                    { title: 'Email', field: 'email' },
                    // Add more columns as needed
                ]}
                data={data}
                options={{
                    search: true,
                    paging: true,
                    pageSize: 20,
                    pageSizeOptions: [20, 50, 100],
                    exportButton: true,
                }}
                isLoading={loading}
                page={page}
                totalCount={total}
                onChangePage={newPage => {
                    setPage( newPage );
                }}
                onChangeRowsPerPage={( newPageSize ) => {
                    setPageSize( newPageSize );
                    setPage( 0 );
                }}
                onSearchChange={query => {
                    setSearch( query );
                    setPage( 0 );
                }}
            />
        </>
    );
};

export default CustomerMembersTable;
