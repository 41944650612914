import React, { Fragment, useEffect, useState } from 'react';
import CheckBoxSelector from "./../../../shared/checkboxSelector";
import util from '../../../utils/miniUtils';
import ImageComponent from '../../../utils/ImageComponent';

import { Collapse } from '@material-ui/core';


const EVALUATION_FREQUENCY = 1;

const Filter = ( { reloadData } ) => {


    const [filter, setFilter] = useState( {
        countries: [],
        assignedDepartments:[],
        assignedGroups:[],
        country:[],
        time_zone:[],
        job_role:[],
        job_class:[],
        is_billable:[],
        elegible_for_bonus:[],
        annual_bonus:[],
        l1ManagerName:[],
        l2ManagerName:[],
        l3ManagerName:[],
        hrManagerName:[],
        job_title:[],
    } );

    const [collapseSection, setCollapseSection] = useState( {
        evaluation_frequency: false
    } );
    const [isBillable, setIsBillable] = useState( [{ id: 1, value: "Yes" }, { id: 2, value: "No" }] );
    const [isEligible, setIsEligible] = useState( [{ id: 1, value: "Yes" }, { id: 2, value: "No" }] );

    const getFilterData = async ()=>{
        try {
            const request = await util.axios.get( `/employee/filter-data` );
            const { error, msg, assignedDepartments, assignedGroups,country,time_zone,job_role,job_class,annual_bonus,l1ManagerName,l2ManagerName,l3ManagerName,hrManagerName,job_title } = request.data;
            setFilter( {
                ...filter,
                assignedDepartments, assignedGroups,country,time_zone,job_role,job_class,isBillable,
                isEligible,annual_bonus,l1ManagerName,l2ManagerName,l3ManagerName,hrManagerName,job_title
            } );
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const exapandSection = ( option ) => {
        let field;
        switch ( option ) {
            case EVALUATION_FREQUENCY: field = "evaluation_frequency"; break;
            default: console.log( "unknown" ); break;
        }
        setCollapseSection( { ...collapseSection, [field]: !collapseSection[field] } );
    };

    const caretIcon = ( open ) => {
        return open ? (
            <img src={window.location.origin + "/images/up.webp"} alt="" style={{ width: "10px" }} />
        ) : (
            <img src={window.location.origin + "/images/down.webp"} alt="" style={{ width: "10px", height: "8px" }} />
        );
    };

    useEffect( ()=>{
        getFilterData();
    },[] );

    useEffect( ()=>{
        
        reloadData( filter );
    }, [filter] );

    return ( 
        <Fragment>
            <div className="col-md-2 mt-2" style={{ maxHeight: 'calc(100vh - 6rem)',overflowY:'auto' }}>
                <CheckBoxSelector
                    isOpenByDeafult={true}
                    dataArray={filter.assignedDepartments}
                    title="Departments"
                    updateData={( data ) =>
                        setFilter( { ...filter, assignedDepartments: data, ready: true } )
                    }
                />

                
                <CheckBoxSelector
                    dataArray={filter.assignedGroups}
                    title="Groups"
                    updateData={( data ) =>
                        setFilter( { ...filter, assignedGroups: data, ready: true } )
                    }
                />

                <CheckBoxSelector
                    dataArray={isEligible}
                    title="Eligible for bonus"
                    updateData={( data ) =>{

                        setFilter( { ...filter, elegible_for_bonus: data, ready: true } );}
                    }
                />

                <CheckBoxSelector
                    dataArray={isBillable}
                    title="Is billable"
                    updateData={( data ) =>{
                        setFilter( { ...filter, is_billable: data, ready: true } );
                    }
                        
                    }
                />

                <div>
                    <div onClick={()=>exapandSection( EVALUATION_FREQUENCY )}
                        className="isPointer d-flex justify-content-between align-items-center"
                    >
                        <b>Evaluation Frequency</b>
                        {caretIcon( collapseSection.evaluation_frequency )}
                    </div>
                    <Collapse in={collapseSection.evaluation_frequency} collapsedHeight={0}>
                        <select 
                            onChange={( e )=> setFilter( { ...filter, evaluation_frequency: ( e.target.value ) } )}
                            className='form-control' name="evaluation_frequency">
                            <option  value="" >Select</option>
                            {/* <option value="30">Monthly</option> */}
                            <option value="Quarterly">Quarterly</option>
                            <option value="Half Yearly">Half Yearly</option>
                            <option value="Yearly">Yearly</option>
                        </select>

                    </Collapse>
                    <hr className="p-2 m-0" />
                </div>

                <CheckBoxSelector
                    dataArray={filter.country}
                    title="Country"
                    updateData={( data ) =>
                        setFilter( { ...filter, country: data, ready: true } )
                    }
                />


                <CheckBoxSelector
                    dataArray={filter.l1ManagerName}
                    title="L1 Manager"
                    updateData={( data ) =>
                        setFilter( { ...filter, l1ManagerName: data, ready: true } )
                    }
                />


                <CheckBoxSelector
                    dataArray={filter.l2ManagerName}
                    title="L2 Manager"
                    updateData={( data ) =>
                        setFilter( { ...filter, l2ManagerName: data, ready: true } )
                    }
                />


                <CheckBoxSelector
                    dataArray={filter.l3ManagerName}
                    title="L3 Manager"
                    updateData={( data ) =>
                        setFilter( { ...filter, l3ManagerName: data, ready: true } )
                    }
                />

                <CheckBoxSelector
                    dataArray={filter.hrManagerName}
                    title="HR Manager"
                    updateData={( data ) =>
                        setFilter( { ...filter, hrManagerName: data, ready: true } )
                    }
                />

                <CheckBoxSelector
                    dataArray={filter.time_zone}
                    title="Time Zone"
                    updateData={( data ) =>
                        setFilter( { ...filter, time_zone: data, ready: true } )
                    }
                />
            </div>
        </Fragment>
    );
};
 
export default Filter;