import React, { useState, useEffect } from 'react';
import CustomTable from './customerTable';
import util from "../../utils/miniUtils";

const Customer = () => {
    const [tableData, setTableData] = useState( [] );
    // Initialize filter with page and pageSize
    const [status, setStatus] = useState( "All Status" );
    const [page, setPage ] = useState( 0 );
    const [total, setTotal ] = useState( 0 );
    const [pageSize, setPageSize] = useState( 10 );
    const [search, setSearch] = useState( "" );
    const [loading, setLoading] = useState( false );
    
    const fetchData = async () => {
        try {
            setLoading( true );
            // api updated for customers company
            const request = await util.axios.get( `/customer_companies?page=${page}&pageSize=${pageSize}&status=${status}&search=${search}` );
         
            // const request = await util.axios.get( `/customer_companies?page=${filter.page}&pageSize=${filter.pageSize}` );  
            const { error, data, total } = request.data;
            setTableData( data );
            setTotal( total );
            if ( error ) throw new Error( "Error Fetching Companies" );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    useEffect( () => { 
        fetchData();
    }, [page, pageSize, status] );

    useEffect( () => {
        const timerId = setTimeout( () => {
            fetchData();
        }, 500 );
        return () => clearTimeout( timerId );
    },[search] );

    return (
        <div>
            <CustomTable
                data={tableData}
                pageSize={pageSize}
                setPageSize={setPageSize}
                page={page}
                setPage={setPage}
                search={search}
                setSearch={setSearch}
                total={total}
                status={status}
                setStatus={setStatus}
                loading={loading}
            />
        </div>
    );
};

export default Customer;
