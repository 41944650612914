import React, { useEffect, useState } from "react";
import { Container, Form, Row, Col,  Card } from "react-bootstrap";
import util from "../../utils/miniUtils";
import CountryFillOne from "./CountryFillOne";
import IncreaseLimitsModal from "./IncreaseLimitModal";

const AccountInfo = () => {
    // console.log( util.user );
    const industries = [
        "Agriculture",
        "Apparel & Fashion",
        "Automotive",
        "Aviation & Aerospace",
        "Banking & Financial Services",
        "Biotechnology",
        "Chemicals",
        "Construction",
        "Consumer Electronics",
        "Consumer Goods",
        "Defense & Space",
        "Education",
        "Electrical & Electronics",
        "Energy & Utilities",
        "Engineering Services",
        "Entertainment",
        "Environmental Services",
        "Food & Beverages",
        "Furniture",
        "Gaming",
        "Government",
        "Healthcare & Pharmaceuticals",
        "Hospitality",
        "Human Resources & Staffing",
        "Information Technology (IT)",
        "Insurance",
        "Investment & Private Equity",
        "Legal Services",
        "Logistics & Transportation",
        "Luxury Goods",
        "Manufacturing",
        "Marine",
        "Media & Publishing",
        "Mining",
        "Nonprofit & NGOs",
        "Oil & Gas",
        "Packaging",
        "Professional Services",
        "Public Safety",
        "Real Estate",
        "Renewable Energy",
        "Retail & E-commerce",
        "Security & Investigations",
        "Software Development",
        "Sports & Recreation",
        "Telecommunications",
        "Textiles & Apparel",
        "Travel & Tourism",
        "Veterinary Services",
        "Wholesale Trade",
    ];
    
    const [employer, setEmployer] = useState( null );
    const [loading, setLoading] = useState( true );
    const [error, setError] = useState( null );
    const [attachmentFiles, setAttachmentFiles] = useState( [] );
    const [logoImage, setLogoImage] = useState( null );

    const isReadOnly = util.user.role === 18;
    const [showModal, setShowModal] = useState( false );
    const [newJobLimit, setNewJobLimit] = useState( "" );
    const [newEmployeeLimit, setNewEmployeeLimit] = useState( "" );
    const [logoUrl, setLogoUrl] = useState( null ); // State to hold the fetched logo URL

    useEffect( () => {
        const fetchLogoUrl = async () => {
            if ( employer && employer.company_logo_file ) {
                const url = await util.fetchImageUrl( `src/public/employer_logos/${util.user.employer_id}/${employer.company_logo_file}` );
                setLogoUrl( url ); // Set the fetched URL to state
            }
        };

        fetchLogoUrl();
    }, [employer] );

    useEffect( () => {
        const fetchEmployerDetails = async () => {
            try {
                const employerId = util.user.employer_id;
                setLoading( true );
                const request = await util.axios.get( `/employers/${employerId}` );
                const { error, msg, data } = request.data;

                if ( error ) throw new Error( msg );

                setEmployer( data );
                // util.toast().success( msg || "Employer details fetched successfully" );
            } catch ( error ) {
                util.handleError( error );
                setError( "Failed to fetch employer details." );
            } finally {
                setLoading( false );
            }
        };

        fetchEmployerDetails();
    }, [] );

    const handleChange = ( e ) => {
        const { name, value } = e.target;
        setEmployer( ( prevState ) => {
            const updatedEmployer = { ...prevState, [name]: value };
            console.log( updatedEmployer ); 
            return updatedEmployer;
        } );
    };
    const handleSave = async () => {
        try {
            if (
                employer.country === "India" &&
                !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test( employer.company_tax_id )
            ) {
                return util.toast().error( "Invalid PAN Number" );
            }
    
            if ( !employer.company_tax_id ) {
                return util.toast().error(
                    employer.country === "India"
                        ? "PAN Number is required"
                        : "Company Tax ID is required"
                );
            }
    
            setLoading( true );
            const payload = { 
                ...employer,
                attachments: attachmentFiles.map( ( file ) => file.path ) // Add uploaded file paths
            };
    
            const employerId = util.user.employer_id;
            const response = await util.axios.put( `/employers/${employerId}`, payload );
            const { error, msg } = response.data;
            console.log( error,msg,"hi" );
            if ( error ) throw msg;    
            util.toast().success( msg || "Employer details updated successfully" );
            // Fetch updated employer data
            const updatedEmployer = await util.axios.get( `/employers/${employerId}` );
            setEmployer( updatedEmployer.data.data );
        } catch ( error ) {
            util.toast().error( error || "Failed to update employer details" );
        } finally {
            setLoading( false );
        }
    };
    function handleImgUpload( e ) {
        const file = e.target.files[0];
        if ( file ) {
            setLogoImage( file );
            console.log( "Selected file:", file.name );
        }
    }
    async function uploadLogo() {
        try {
            if ( !logoImage ) {
                util.toast().error( "Please select a logo to upload." );
                return;
            }
    
            const data = new FormData();
            data.append( "file", logoImage );
    
            const employerId = util.user.employer_id; // Get the employer ID from the current user's data
            const request = await util.axios.post( `/employer/upload_comapny_logo/${employerId}`, data );
            const { error, msg, employerData } = request.data;
    
            if ( error ) throw new Error( msg );
    
            util.toast().success( msg || "Logo uploaded successfully." );
    
            // Update the employer state with the new data
            setEmployer( ( prevEmployer ) => ( {
                ...prevEmployer,
                company_logo_file: employerData.company_logo_file,
            } ) );
    
            setLogoImage( null ); // Clear the selected file
        } catch ( error ) {
            util.handleError( error );
        }
    }
    
    
    async function removeLogo() {
        try {
            const employerId = util.user.employer_id;
            const request = await util.axios.delete( `/employer/delete_company_logo/${employerId}` );
            const { error, msg } = request.data;
    
            if ( error ) throw new Error( msg );
    
            util.toast().success( msg || "Logo removed successfully." );
    
            setEmployer( ( prevEmployer ) => ( {
                ...prevEmployer,
                company_logo_file: null,
            } ) );
        } catch ( error ) {
            util.handleError( error );
        }
    }
    

    const handleShowModal = () => setShowModal( true );
    const handleCloseModal = () => setShowModal( false );

    const handleModalSave = async () => {
        try {
            setLoading( true );
            const response = await util.axios.put( `/update_employers_account_limits`, { employee_limit: newEmployeeLimit, job_limit: newJobLimit } );
            const { error, msg } = response.data;
    
            if ( error ) throw new Error( msg );
            util.toast().success( msg || "Employer limits updated successfully" );
        } catch ( error ) {
            util.handleError( error );
        } finally {
            setLoading( false );
            handleCloseModal();
        }
    };

    if ( loading ) {
        return <div>Loading...</div>;
    }

    if ( error ) {
        return <div>{error}</div>;
    }

    if ( !employer ) {
        return <div>No Employer Found</div>;
    }

    return (
        <Container className="mt-3">
            <Card>
                <Card.Body>
                    <Card.Header className="text-center mb-4">{employer.company_legal_name} Details</Card.Header>
                    <Form>
                        <div className="mt-3">
                            <b>Logo</b>
                            <div>
                                <input
                                    title="Logo"
                                    placeholder="Logo"
                                    accept="image/*, .svg, .webp"
                                    onChange={handleImgUpload}
                                    name="company_logo_file"
                                    type="file"
                                />
                                {logoImage ? (
                                    <button
                                        type="button"
                                        className="btn btn-secondary sizeBtn activeBtn"
                                        onClick={uploadLogo}
                                    >
                                        Upload
                                    </button>
                                ) : null}

                            </div>
                            {employer.company_logo_file ? (
                                <div>
                                    <label>Preview</label>
                                    <img
                                        style={{ maxWidth: "100px", marginLeft: "10px" }}
                                        src={logoUrl} // Use the fetched logo URL from state
                                        alt={employer.company_legal_name}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-secondary sizeBtn activeBtn ml-2"
                                        onClick={removeLogo}
                                    >
                                    Remove
                                    </button>
                                </div>
                            ) : null}

                        </div>
                        <Row className="mb-3 mt-3">
                            <Col md={6}>
                                <Form.Group controlId="formCompanyName">
                                    <Form.Label>Company Legal Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="company_legal_name"
                                        value={employer.company_legal_name || ""}
                                        placeholder="Enter your organization's name (e.g. ABC Corp.)"
                                        onChange={handleChange}
                                        readOnly={isReadOnly} 
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formCompanyEmail">
                                    <Form.Label>Employer Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="employer_email"
                                        value={employer.employer_email || ""}
                                        placeholder="Enter your official company email (e.g. info@abc.com)"
                                        onChange={handleChange}
                                        readOnly={isReadOnly} 
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        

                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group controlId="formCompanyWebsite">
                                    <Form.Label>Employer Website</Form.Label>
                                    <Form.Control
                                        type="url"
                                        name="employer_website"
                                        value={employer.employer_website || ""}
                                        placeholder="https://www.yourcompany.com"
                                        onChange={handleChange}
                                        readOnly={isReadOnly} 
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formHQAddress">
                                    <Form.Label>HQ Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="address"
                                        value={employer.address || ""}
                                        placeholder="Enter the main office address (e.g. 123 Main St)"
                                        onChange={handleChange}
                                        readOnly={isReadOnly} 
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={3}>
                                <Form.Group controlId="formYearFounded">
                                    <Form.Label>Year Founded</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="year_founded"
                                        value={employer.year_founded || ""}
                                        placeholder="Enter the year your company was established"
                                        onChange={handleChange}
                                        readOnly={isReadOnly} 
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group controlId="formStatus">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="status"
                                        value={employer.status || ""}
                                        onChange={handleChange}
                                        readOnly={isReadOnly}
                                    >
                                        <option value="">Select Status</option>
                                        <option value="active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formEmailDomain">
                                    <Form.Label>Email Domain</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="email_domain"
                                        value={employer.email_domain || ""}
                                        placeholder="Enter your company's email domain (e.g. abc.co)"
                                        onChange={handleChange}
                                        readOnly={isReadOnly} 
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        

                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group controlId="formSocialMediaLinks">
                                    <Form.Label>Social Media Links</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="social_media"
                                        value={employer.social_media|| ""}
                                        placeholder="Add links to your social media profiles (e.g. LinkedIn)"
                                        onChange={handleChange}
                                        readOnly={isReadOnly} 
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="formIndustry">
                                    <Form.Label>Industry</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="industry"
                                        value={employer.industry || ""}
                                        onChange={handleChange}
                                        readOnly={isReadOnly}
                                    >
                                        <option value="">Select Industry</option>
                                        {industries.map( ( industry, index ) => (
                                            <option key={index} value={industry}>
                                                {industry}
                                            </option>
                                        ) )}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            
                        </Row>

                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group controlId="formMaxJobLimit">
                                    <Form.Label>Maximum Job Limit</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="job_limit"
                                        value={employer.job_limit || ""}
                                        placeholder="Max number of jobs this employee can post"
                                        onChange={handleChange}
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formStatus">
                                    <Form.Label>Maximum Number of Members</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="limit"
                                        value={employer.employee_limit || 0}
                                        onChange={handleChange}
                                        readOnly={isReadOnly} 
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group controlId="formAvailableJobsToPost">
                                    <Form.Label>Available Jobs To Post</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="job_count"
                                        value={employer.job_count || 0}
                                        placeholder="Available Jobs to Post"
                                        onChange={handleChange}
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formEmployeeCount">
                                    <Form.Label>Employee Count</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="employee_count"
                                        value={employer.employee_count || ""}
                                        placeholder="Total number of Members"
                                        onChange={handleChange}
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            
                            <CountryFillOne
                                newState={employer}
                                onChangeHandler={handleChange}
                                isReadOnly={isReadOnly}
                                attachmentFiles={attachmentFiles}
                                setAttachmentFiles={setAttachmentFiles}
                            />
                        </Row>
                        <Col md={6}>
                            <span className="ml-3 mt-2 likeLink" onClick={handleShowModal}>
                                    Increase Job Limit
                            </span>
                        </Col>

                        

                        {!isReadOnly && (
                            <div className="float-right"> 
                                <button 
                                    type="button" 
                                    className="btn btn-sm btn-secondary activeBtn sizeBtn" 
                                    onClick={handleSave} 
                                    disabled={loading} 
                                > 
                                    {loading ? "Saving..." : "Save"} 
                                </button> 
                            </div> 
                        )} 
                    </Form>
                </Card.Body>
            </Card>

            <IncreaseLimitsModal
                show={showModal}
                handleClose={handleCloseModal}
                employer={employer}
                newJobLimit={newJobLimit}
                setNewJobLimit={setNewJobLimit}
                newEmployeeLimit={newEmployeeLimit}
                setNewEmployeeLimit={setNewEmployeeLimit}
                handleModalSave={handleModalSave}
            />
        </Container>
    );
};

export default AccountInfo;
