import util from "../../../utils/miniUtils";
import { useState } from "react";
import countryList from "../../../utils/location/country.json";
import { CTCStore } from "./CTCStore";

export default function useCostToCompany(  ) {

    const [settings] = useState( { session_expire_time:"" } );
    const [show] = useState(  false );
    const [ jobSetting, setJobSetting ] = useState( [] );
    const [ newCountry, setNewCountry ] = useState( util.countrySettings );
    const [countries] = useState( countryList );

    const handleClose = () => CTCStore.update( s => { s.modalAddCountry = false; } );

    const openModal = () => CTCStore.update( s => { s.modalAddCountry = true; } );

    async function getCountryCTCSettings() {
        try {
            const request = await util.axios.get( `catalog/data/list/ctc_countries` );
            let { error, msg, data } = request.data;
            if ( error ) throw msg;
            // Update store
            CTCStore.update( s => {
                s.countries =  Object.assign( [], data );
            } );
        }catch ( e ) {
            util.handleError( e );
        }
    }

    async function saveChanges() {
        try {
            const request = await util.axios.put( `${util.url}/app_resources/settings`, settings );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
        }catch ( e ) {
            util.handleError( e );
        }
    }

    async function saveNewCountries() {
        try {
            const v = {};
            v.value = newCountry;
            v.catalog = "ctc_countries";
            v.isJSON = true;
            const request = await util.axios.post( `catalog/data/create`, v );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setJobSetting( Object.assign( [], data ) );
            handleClose();
            util.toast().success( msg );
            await getCountryCTCSettings();
        }catch ( e ) {
            util.handleError( e );
        }
    }

    // Handle only new country ctc setting, nothing more
    function onChangeHandlerNewCountry( e, index ) {
        const { name, value } = e.target;
        let t = { ...newCountry } ;
        if ( name === "country" ){
            t.country = value;
        }else{
            t.data[index][name] = value;
        }
        setNewCountry( Object.assign( {}, t ) );
    }

    function onChangeHandler( country, e, index ) {
        const { name, value } = e.target;

        let temp = [ ...jobSetting ];
        
        // eslint-disable-next-line
        temp.map( ( j, i ) => {
            if ( j.country === country ){
                temp[i].data[index][name] = value;
            }
        } );
        setJobSetting( Object.assign( [], temp ) );
    }

    return {
        settings,
        jobSetting,
        show,
        handleClose,
        newCountry,
        countries,
        onChangeHandlerNewCountry,
        openModal,
        getCountryCTCSettings,
        saveChanges,
        onChangeHandler,
        saveNewCountries,
    };
}