import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import util from "../utils/miniUtils";
import Register from "./createAccount";
import { PositionsStore } from "../../components/positions/stores/PositionsStore";

export default function Invitation() {
    const [isLoading, setIsLoading] = useState( true );
    const [step, setStep] = useState( 0 ); // 0 = "loading", 1 = "createAccount", 2 = "finalizing"
    const [successMessage, setSuccessMessage] = useState( "" );
    const [errorMessage, setErrorMessage] = useState( "" );
    const [email, setEmail] = useState( "" );
    const [token, setToken] = useState( "" );
    const [isLogged, setIsLogged] = useState( PositionsStore.getRawState().isLogged );
    const [user, setUser] = useState( null ); // New state for user

    const location = useLocation();
    const history = useHistory();

    useEffect( () => {
        const queryParams = new URLSearchParams( location.search );
        const get_token = queryParams.get( "token" );

        const validateToken = async () => {
            try {
                const response = await util.axios.post( `/employer_invitation/validate/?token=${get_token}` );
                const { data, email } = response.data;
                if ( data ) {
                    setEmail( email );
                    setToken( get_token );
                    setStep( 1 ); // If token is valid, move to step 1
                }
            } catch ( error ) {
                console.error( "Token validation error:", error );
                setErrorMessage( "Token validation failed. Please try again." );
            } finally {
                setIsLoading( false );
            }
        };

        validateToken();
    }, [location] );

    useEffect( () => {
        const currentUser = util.user;
        if ( currentUser?.id && typeof currentUser.id === "number" && isValidEmail( currentUser.email ) ) {
            setUser( currentUser );
        }
    }, [] );

    useEffect( () => {
        if ( user && token && email && isLogged && step === 2 ) {
            console.log( "user:", user );
            completeRegistration( token, email, user.id );
        }
    }, [user, token, email, isLogged, step] );

    const isValidEmail = ( email ) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test( email );
    };

    const completeRegistration = async ( validatedToken, validatedEmail, userId ) => {
        try {
            if ( !userId ) {
                setErrorMessage( "User not found. Please log in again." );
                return;
            }

            await util.axios.post( `/employers_subordinates/addMemberToEmployer/?token=${validatedToken}&user_id=${userId}` );
            setSuccessMessage( "Successfully joined!" );
            setStep( 3 ); 
            setTimeout( () => {
                history.push( "/jobs" );
            }, 2000 );
        } catch ( error ) {
            console.error( "Error completing registration:", error );
            setErrorMessage( "An error occurred while completing registration. Please try again." );
        } finally {
            PositionsStore.update( ( s ) => {
                s.loading = false;
            } );
        }
    };

    const renderMessage = ( message ) => (
        <div className="centered-container-employer">
            <p className="message-employer">{message}</p>
            <span onClick={() => history.push( "/jobs" )} className="likeLink"> Please Click this link for redirecting you in few moments.</span>
        </div>
    );


    if ( successMessage ) {
        return renderMessage( successMessage, false );
    }

    if ( errorMessage ) {
        return renderMessage( errorMessage );
    }

    if ( isLoading ) {
        return (
            <div className="centered-container-employer">
                <div className="loader-employer"></div>
            </div>
        );
    }

    if ( step === 1 ) {
        return (
            <Register
                onComplete={() => {
                    setIsLogged( true ); // Update isLogged state
                    setStep( 2 ); // Move to the next step
                }}
                email={email}
            />
        );
    }

    if ( step === 2 ) {
        return renderMessage( "Finalizing registration..." );
    }

    return null;
}

