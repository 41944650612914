import React, { useEffect, useState, Fragment } from "react";
import TalentMethods from './TalentMethods';
import util from "../../../../../utils/miniUtils";
import CircularProgress from "@material-ui/core/CircularProgress";
import selector from "../../../../../utils/selector";
import WorkHistory from "./WorkHistory";
import PrintVersion from "./PrintVersion";
import { faFileWord, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Skills from "./Skills";


export  default function TalentForm( { talent_id, job_id } ) {
	
    const [loading, setLoading] = useState( false );
    const [data, setData] = useState( null );
    const [state, setState] = useState( { expandWorkHistory: false, print:false } );
	
	
    useEffect( () => {
        getTalentInformation( talent_id, job_id ).then( null );
    },[talent_id] );
	
	
    // Retrieve functions stored in TalentMethods
    const {	getTalentInformation, handleChange, defaultWH, deleteWorkHistory, handleChangeSkill,
        handleChangeWorkHistory, calculateTotalYearsOfExperience, deleteSkill, addSkill, addWorkHistory,
        saveData, generatePDF } = TalentMethods( data, setData,loading, setLoading, state, setState );
	
    if ( data === null ){
        return <div className="text-center"><CircularProgress /> </div>;
    }
	
    if ( state.print ){
        return (
            <PrintVersion
                calculateTotalYearsOfExperience={calculateTotalYearsOfExperience}
                defaultWH={defaultWH}
                data={data}
                generatePDF={()=>{
                    generatePDF().then( null );
                }}
                continueEditing={()=>{setState( { ...state, print: false } );}}
            />
        );
    }

    return (
        <Fragment>
            <div className="d-flex justify-content-end mb-2">
                <button
                    onClick={()=>{
						
                        setState( { ...state, print: true } );
                    }}
                    className="btn btn-sm  btn-info mr-2">
                    <FontAwesomeIcon icon={faFileWord} className="mr-1"/>
					Preview Information
                </button>
				
                <button
                    onClick={saveData}
                    className="btn btn-sm  btn-success">
                    <FontAwesomeIcon icon={faSave} className="mr-2"/>
					Save
                </button>
            </div>
            <table className="table" id="testPDF">
                <thead/>
                <tbody>
                    <tr>
                        <th colSpan={2} className="centeredText text-center">{data.name}</th>
                    </tr>
                    <tr>
                        <th width="50%">Interview POC Name (Supplier)</th>
                        <th>Interview POC Contact # (Supplier)</th>
                    </tr>
                    <tr>
                        <td>{data["leadRecruiterName"]}</td>
                        <td>
                            <util.InputSlim
                                name="leadRecruiterData"
                                onChange={handleChange}
                                value={data["leadRecruiterData"]}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Current Location</th>
                        <td>
                            <util.InputSlim
                                name="talentLocation"
                                onChange={handleChange}
                                value={data["talentLocation"]}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Willing to relocate? If so, please provide the preferred location:</th>
                        <td>
                            <util.SelectSlim
                                value={data.relocation === 1 ? "Yes": "No"}
                                onChange={handleChange}
                                name="relocation"
                                options={["Yes", "No"]}
                            />
                            <util.InputSlim
                                name="location"
                                onChange={handleChange}
                                value={data.location}
                                placeholder="Example: Calexico, California USA"
                            />
                        </td>
                    </tr>
				
                    <tr>
                        <th>Willing to attend in-person interview?</th>
                        <td>
                            <util.InputSlim
                                name="inPersonInterview"
                                onChange={handleChange}
                                value={data.inPersonInterview ? data.inPersonInterview: "Yes"}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Availability to Start</th>
                        <td>
                            <util.SelectSlim
                                value={data.AvailabilityToStart ? data.AvailabilityToStart: "0 Weeks"}
                                onChange={handleChange}
                                name="AvailabilityToStart"
                                options={selector.AvailabilityToStart}
                            />
                        </td>
                    </tr>
				
                    <tr>
                        <th>Notice Period/ LWD on last project</th>
                        <td>
                            <util.InputSlim
                                name="noticePeriod"
                                onChange={handleChange}
                                value={data.noticePeriod ? data.noticePeriod: "2 weeks"}
                            />
                        </td>
                    </tr>
				
                    <tr>
                        <th>Interviews/ Offers in Pipeline</th>
                        <td>
                            <util.InputSlim
                                name="offersInPipeline"
                                onChange={handleChange}
                                value={data.offersInPipeline ? data.offersInPipeline: "None"}
                            />
                        </td>
                    </tr>
				
                    <tr>
                        <th colSpan={2} className="text-center centeredText">
						LIST ALL EMPLOYMENT FOR THE PAST 7 YEARS
                        </th>
                    </tr>
				
				
				
                    <tr>
                        <td colSpan={3}>
                            <table>
                                <thead/>
                                <tbody>
                                    <tr>
                                        <th width="10%">
									Dates of Employment
									(Month/Year - Month/Year)
                                        </th>
                                        <th width="60%">
									Parent Employer Name /Project Company Name
                                            <br/>
									i.e. ABC Staffing / Project at XYZ Client Name
								
                                        </th>
                                        <th width="30%">Location</th>
                                    </tr>
                                    {
                                        data.workHistory.length === 0 ?
                                            "Nothing to display"
                                            :
                                            <Fragment>
										
                                                {
                                                    data.workHistory.map( ( wh,index ) => {
                                                        return(
                                                            <Fragment key={index}>
                                                                <WorkHistory
                                                                    handleChangeWorkHistory={handleChangeWorkHistory}
                                                                    wh={wh}
                                                                    index={index}
                                                                    deleteWorkHistory={deleteWorkHistory}
                                                                />
                                                            </Fragment>
                                                        );
                                                    } )
                                                }
                                                <tr>
                                                    <td colSpan={3} className="text-right">
                                                        <button
                                                            onClick={()=>addWorkHistory()}
                                                            className="btn btn-sm btn-primary">
													+ Add
                                                        </button>
                                                    </td>
                                                </tr>
                                            </Fragment>
                                    }
                                </tbody>
                            </table>
                        </td>
                    </tr>
				
				
				
                    <tr>
                        <td colSpan={3}>
                            <table>
                                <thead>
                                    <tr>
                                        <th colSpan={4} className="text-center centeredText">
									Supplier Synopsis
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
							
                                    <tr>
                                        <th width="20%">
									Mandatory Skills (As listed in JD)
                                        </th>
                                        <th width="10%"># of Years Experience</th>
                                        <th width="70%">Summary of Candidates relevant hands-on experience</th>
                                    </tr>
                                    {
                                        data["mandatorySkills"] && data["mandatorySkills"].length > 0 && data["mandatorySkills"].map( ( ps, i ) => {
                                            return(
                                                <Skills
                                                    key={i}
                                                    section="mandatorySkills"
                                                    skill={ps}
                                                    handleChange={handleChangeSkill}
                                                    deleteSkill={deleteSkill}
                                                    index={i}
                                                />
                                            );
                                        } )
                                    }
                                    <tr>
                                        <td colSpan={3} className="text-right">
                                            <button
                                                onClick={()=>addSkill( 'mandatorySkills' )}
                                                className="btn btn-sm btn-primary">
										+ Add
                                            </button>
                                        </td>
                                    </tr>
							
                                    <tr>
                                        <th width="20%">
									Nice to Have Skills (As listed in JD)
                                        </th>
                                        <th width="10%"># of Years Experience</th>
                                        <th width="70%">Summary of Candidates relevant hands-on experience</th>
                                    </tr>
							
                                    {
                                        data["niceToHaveSkills"] && data["niceToHaveSkills"].length > 0 && data["niceToHaveSkills"].map( ( ps, i ) => {
                                            return(
                                                <Skills
                                                    key={i}
                                                    section="niceToHaveSkills"
                                                    skill={ps}
                                                    handleChange={handleChangeSkill}
                                                    deleteSkill={deleteSkill}
                                                    index={i}
                                                />
                                            );
                                        } )
                                    }
                                    <tr>
                                        <td colSpan={3} className="text-right">
                                            <button
                                                onClick={()=>addSkill( 'niceToHaveSkills' )}
                                                className="btn btn-sm btn-primary">
										+ Add
                                            </button>
                                        </td>
                                    </tr>
							
							
                                </tbody>
                            </table>
                        </td>
                    </tr>
				
                    <tr>
                        <th>Additional comments</th>
                        <td>
                            <util.InputSlim
                                name="additionalComments"
                                onChange={handleChange}
                                value={data.additionalComments ? data.additionalComments: "Req ID"}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Certifications</th>
                        <td>
                            <util.InputSlim
                                name="certifications"
                                onChange={handleChange}
                                value={data.certifications ? data.certifications: "None"}
                            />
                        </td>
                    </tr>
				
                </tbody>
            </table>
			
            <div className="d-flex justify-content-end">
                <button
                    onClick={()=>{
						
                        setState( { ...state, print: true } );
                    }}
                    className="btn btn-sm  btn-info mr-2">
                    <FontAwesomeIcon icon={faFileWord} className="mr-1"/>
					Preview Information
                </button>
				
                <button
                    onClick={saveData}
                    className="btn btn-sm  btn-success">
                    <FontAwesomeIcon icon={faSave} className="mr-2"/>
					Save
                </button>
            </div>
			
            {util.LOADING_SCREEN( loading )}
        </Fragment>
    );
	
}