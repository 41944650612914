import Modal from "react-bootstrap/Modal";
import React  from "react";
import useCostToCompany from "./useCostToCompany";
import { CTCStore } from "./CTCStore";
import util from "../../../utils/miniUtils";
import { FormControl, InputGroup } from "react-bootstrap";

export default function NewCountry( { show, handleClose } ) {

    const { newCountry, onChangeHandlerNewCountry, countries, saveNewCountries } = useCostToCompany();
    const usedCountries = CTCStore.useState( state => state.countries ).map( d => d.country );

    const fullTime      = newCountry.data[0];
    const contractor    = newCountry.data[1];
    const c2c           = newCountry.data[2];

    return(
        <Modal
            size={"xl"}
            show={show}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>Create new CTC Settings by Country</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <table className="table">
                        <thead>
                            <tr className="text-center">
                                <th>Country</th>
                                <th>Talent Engagement Type</th>
                                <th>Markup (Payroll, Tax, SGA etc Loading)</th>
                                <th>Billable Days in a Year</th>
                                <th>Working Hours in Day</th>
                                <th>Avg. Billable Days in a Month</th>
                                <th>Default eJ-Offer-Unit  to Talent</th>
                                <th>Default Sell-Unit</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="text-center">
                                <td>
                                    <select className="form-control" name="country" onChange={( e )=>onChangeHandlerNewCountry( e, 0 )} >
                                        <option value={null}> -- </option>;
                                        { 
                                            countries.map( ( c, ind )=>{
                                                return (
                                                    <option
                                                        key={ind}
                                                        disabled={usedCountries.includes( c.name )}
                                                        title={usedCountries.includes( c.name ) ? "Already used": ""}
                                                        value={c.name}>
                                                        {c.name}
                                                    </option>
                                                );
                                            } )
                                        }
                                    </select>
                                </td>
                                <td>{fullTime.employmentType}</td>
                                <td>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                        <FormControl
                                            onChange={( e )=>onChangeHandlerNewCountry( e, 0 )}
                                            type="number"
                                            name="markUp"
                                            value={fullTime.markUp}
                                        />
                                    </InputGroup>
                                </td>
                                <td><input className="form-control" onChange={( e )=>onChangeHandlerNewCountry( e, 0 )} type="number" name="billableDaysInYear" value={fullTime.billableDaysInYear}/></td>
                                <td><input className="form-control" onChange={( e )=>onChangeHandlerNewCountry( e, 0 )} type="number" name="workingHoursInDay" value={fullTime.workingHoursInDay}/></td>
                                <td><input className="form-control" onChange={( e )=>onChangeHandlerNewCountry( e, 0 )} type="number" name="averageBillableDaysInMonth" value={fullTime.averageBillableDaysInMonth}/></td>
                                <td>
                                    <select  onChange={( e )=>onChangeHandlerNewCountry( e, 0 )} className="form-control" name="defaulteJOfferUnitToTalent" value={fullTime.defaulteJOfferUnitToTalent} >
                                        {
                                            util.ejUnit.map( ( u , i ) => {
                                                return <option key={i} value={u}>{u}</option>;
                                            } )
                                        }
                                    </select>
                                </td>
                                <td>
                                    <select  onChange={( e )=>onChangeHandlerNewCountry( e, 0 )} className="form-control" name="defaultSellUnit" value={fullTime.defaultSellUnit} >
                                        {
                                            util.ejUnit.map( ( u , i ) => {
                                                return <option key={i} value={u}>{u}</option>;
                                            } )
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr className="text-center">
                                <td/>
                                <td>{contractor.employmentType}</td>
                                <td>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                        <FormControl
                                            onChange={( e )=>onChangeHandlerNewCountry( e, 1 )}
                                            type="number"
                                            name="markUp"
                                            value={contractor.markUp}
                                        />
                                    </InputGroup>
                                </td>
                                <td><input className="form-control" onChange={( e )=>onChangeHandlerNewCountry( e, 1 )} type="number" name="billableDaysInYear" value={contractor.billableDaysInYear}/></td>
                                <td><input className="form-control" onChange={( e )=>onChangeHandlerNewCountry( e, 1 )} type="number" name="workingHoursInDay" value={contractor.workingHoursInDay}/></td>
                                <td><input className="form-control" onChange={( e )=>onChangeHandlerNewCountry( e, 1 )} type="number" name="averageBillableDaysInMonth" value={contractor.averageBillableDaysInMonth}/></td>
                                <td>
                                    <select  onChange={( e )=>onChangeHandlerNewCountry( e, 1 )} className="form-control" name="defaulteJOfferUnitToTalent" value={contractor.defaulteJOfferUnitToTalent} >
                                        {
                                            util.ejUnit.map( ( u , i ) => {
                                                return <option key={i} value={u}>{u}</option>;
                                            } )
                                        }
                                    </select>
                                </td>
                                <td>
                                    <select  onChange={( e )=>onChangeHandlerNewCountry( e, 1 )} className="form-control" name="defaultSellUnit" value={contractor.defaultSellUnit} >
                                        {
                                            util.ejUnit.map( ( u , i ) => {
                                                return <option key={i} value={u}>{u}</option>;
                                            } )
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr className="text-center">
                                <td/>
                                <td>{c2c.employmentType}</td>
                                <td>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                        <FormControl
                                            onChange={( e )=>onChangeHandlerNewCountry( e, 2 )}
                                            type="number"
                                            name="markUp"
                                            value={c2c.markUp}
                                        />
                                    </InputGroup>
                                </td>
                                <td><input className="form-control" onChange={( e )=>onChangeHandlerNewCountry( e, 2 )} type="number" name="billableDaysInYear" value={c2c.billableDaysInYear}/></td>
                                <td><input className="form-control" onChange={( e )=>onChangeHandlerNewCountry( e, 2 )} type="number" name="workingHoursInDay" value={c2c.workingHoursInDay}/></td>
                                <td><input className="form-control" onChange={( e )=>onChangeHandlerNewCountry( e, 2 )} type="number" name="averageBillableDaysInMonth" value={c2c.averageBillableDaysInMonth}/></td>
                                <td>
                                    <select  onChange={( e )=>onChangeHandlerNewCountry( e, 2 )} className="form-control" name="defaulteJOfferUnitToTalent" value={c2c.defaulteJOfferUnitToTalent} >
                                        {
                                            util.ejUnit.map( ( u , i ) => {
                                                return <option key={i} value={u}>{u}</option>;
                                            } )
                                        }
                                    </select>
                                </td>
                                <td>
                                    <select  onChange={( e )=>onChangeHandlerNewCountry( e, 2 )} className="form-control" name="defaultSellUnit" value={c2c.defaultSellUnit} >
                                        {
                                            util.ejUnit.map( ( u , i ) => {
                                                return <option key={i} value={u}>{u}</option>;
                                            } )
                                        }
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-sm" onClick={handleClose}>
                    Close
                </button>
                <button className="btn btn-sm btn-primary" onClick={saveNewCountries}>
                    Save Changes
                </button>
            </Modal.Footer>
        </Modal>
    );
}