import React, { useState, useEffect } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import axios from 'axios';
import util from '../../utils/miniUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import SweetAlert from 'react-bootstrap-sweetalert';

const FileUploadForm = ( { customer,fetchData,assistant } ) => {
    const [filename, setFilename] = useState( '' );
    const [file, setFile] = useState( null );
    const [description,setDescription] = useState( null );
    const [isDragging, setIsDragging] = useState( false );
    const [instructions, setInstructions] = useState( assistant?.instructions?assistant.instructions:"" );
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( '' );
    const [isAssistantModifed,setIsAssistantModified] =useState( false );
    const [additionalInfo, setAdditionalInfo] = useState( '' );
    const [showSweetAlert, setShowSweetAlert] = useState( false ); // State for showing SweetAlert
    const [isEditable, setIsEditable] = useState( false );

    useEffect( () => {
        setInstructions( assistant?.instructions?assistant.instructions:"" );
        getAccess();
    }, [assistant] );


    const saveAssistant = async() => {
        if( assistant?.id  ){
            try { const formData = new FormData();
                formData.append( 'name', customer.customer_name );
                formData.append( 'instructions', instructions );
                formData.append( 'description', description );
                formData.append( 'assistant_id', assistant.assistant_id );
                formData.append( 'additional_info', additionalInfo );
                const response = await util.axios.put( `/customer_company_files/update_assistant/${customer.customer_company_id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    } } );

                const { error, msg, data } = response.data;
                if ( error ) throw msg;
                util.toast().success( msg );
                fetchData();

            } catch ( error ) {
                util.handleError( error );
                
            }
        } 
        else
        {
            try {
                const formData = new FormData();
                formData.append( 'name', customer.customer_name );
                formData.append( 'instructions', instructions );
                const response = await util.axios.post( `/customer_company_files/generateAssistant/${customer.customer_company_id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                } );
                const { error, msg, data } = response.data;
                if ( error ) throw msg;

                util.toast().success( msg );
                fetchData();

            } catch ( error ) {
                util.handleError( error );
            }        
        }
 
    };

    const handleSubmit = async ( ) => {
        setShowSweetAlert( false );
        setError( '' );
        try {
            if( file ){
                const formData = new FormData();
                formData.append( 'file', file );
                formData.append( 'name', customer.customer_name );
          
                const response = await util.axios.post( `/customer_company_files/uploadFilesAssistant/${customer.customer_company_id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                } );
                await saveAssistant();
                setResponse( response.data.data );
                util.toast().success( response.data.msg );
                setFile( null );
                setFilename( "" );
                fetchData();
            }
            else{
                await saveAssistant();
                fetchData();
            }
            
        } catch ( error ) {
            setError( 'An error occurred while processing your request.' );
        }
    };
    
  
      
    const handleDragEnter = ( e ) => {
        e.preventDefault();
        setIsDragging( true );
    };
    
    const handleDragLeave = ( e ) => {
        e.preventDefault();
        setIsDragging( false );
    };
    
    const handleDragOver = ( e ) => {
        e.preventDefault();
    };
    
    const handleDrop = ( e ) => {
        e.preventDefault();
        setIsDragging( false );
        const droppedFiles = Array.from( e.dataTransfer.files );
        setFile( droppedFiles[0] );
        setFilename( droppedFiles[0].name );
       
    };

    const handlePreSubmit = ( e ) => {
        e.preventDefault();
        setShowSweetAlert( true ); // Show the SweetAlert confirmation
    };
    async function getAccess() {
        try {
            const request = await util.axios.get( `/access` );
            const { error, data, msg } = request.data;
            if ( error ) throw new Error( msg );
            setIsEditable( data );
        } catch ( e ) {
            util.handleError( e );
        }
    
    }
    return (
        <div>
            <SweetAlert
                show={showSweetAlert}
                warning
                showCancel
                confirmBtnText="Yes, Update it!"
                confirmBtnBsStyle="danger"
                title={assistant?.id ? "Are you sure you want to Update Assistant": "Are you sure you want to Create Assistant"}
                onConfirm={handleSubmit}
                onCancel={() => {
                    setShowSweetAlert( false );
                }}
                focusCancelBtn
            />
            <Form onSubmit={handlePreSubmit}>
                <Form.Group controlId="formFile" className='mt-5'>
                    <Form.Label>Upload File:</Form.Label>
                    <Form.Control type="file" onChange={( e ) => setFile( e.target.files[0] )} />
                    {filename && <Form.Text className='ml-3 mt-2'>Selected File: {filename}</Form.Text>}
                </Form.Group>

                <Form.Group controlId="formInstructions" style={{ marginTop: '50px' }}>
                    <Form.Label>Instructions:</Form.Label>
                    <Form.Control
                        as="textarea"
                        placeholder="Write Instructions..."
                        value={instructions}
                        onChange={( e ) => setInstructions( e.target.value )}
                        readOnly={!isEditable}
                        style={{ height: '100px', overflowY: 'auto' }}
                    />
                </Form.Group>

                {/* Conditional rendering of Additional Information form group */}
                {instructions && (
                    <Form.Group controlId="formAdditionalInfo" style={{ marginTop: '25px' }}>
                        <Form.Label>Additional Information:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter additional info..."
                            value={additionalInfo}
                            onChange={( e ) => setAdditionalInfo( e.target.value )}
                        />
                    </Form.Group>
                )}
                <Button type="submit" className='btn activeBtn sizeBtn btn-secondary' style={{ marginTop: '25px', marginBottom: '30px' }}>
                    {assistant?.id ? "Update Assistant" : "Create Assistant"}
                </Button>
            </Form>
            {error && <p>{error}</p>}
        </div>
    );
};

export default FileUploadForm;
