import React, { Fragment, useEffect, useRef, useState } from "react";
import { MuiThemeProvider } from "@material-ui/core";
import util from "../../../../utils/miniUtils";
import MaterialTable from "material-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import EmailToTalent from "./EmailToTalent";
import UploadForm from "./UploadForm";
import SAlert from "../../../../microComponents/AlertSA";

export default function BeelineDocuments( { data } ) {
	
    const tableRef = useRef();
    const [state, setState] = useState( {
        data:{},
        loading: false,
        showAlert: false,
        document:{},
        documents:[]
    } );
	
    useEffect( () => {
        getDocuments().then( null );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[] );
	
    const getDocuments = async () => {
        try {
            setState( { ...state, loading: true } );
            const d = {
                job_id: data.position_id,
                talent_id: data.candidate_id
            };
            const request = await util.axios.post( `${util.url}/job_process/documents/list`, d );
            const { error, msg, documents } = request.data;
            if ( error ) return msg;
            setState( { ...state, documents: Object.assign( [], documents ), loading: false } );
        } catch ( error ) {
            setState( { ...state, loading: false } );
            util.handleError( error );
        }
    };
	
    async function onConfirmSendRenewDocuments() {
        try {
            setState( { ...state, loading: true, showAlert: false } );
            let request = await util.axios.post( `${util.url}/job_process/documents/send_email`, { data: state.data } );
            const { error, msg, newData } = request.data;
            if ( error ) throw  msg;
            // getDocuments().then(null)
            // tableRef.current.onAllSelected(false)
            util.toast().success( "Documents sent" );
            setState( { ...state, loading: false, data: null, documents: Object.assign( [], newData ), showAlert: false } );
        }catch ( e ) {
            setState( { ...state, loading: false } );
            util.handleError( e );
        }
    }
	
    return(
        <Fragment>
            <MuiThemeProvider theme={util.defaultTableTheme}>
                <MaterialTable
                    tableRef={tableRef}
                    columns={[
                        {
                            field: "title",
                            title:"Title",
                            render: d =>
                                d.filename ?
                                    <a href={`${util.url}/documents/${d.filename}`} target="new">
                                        <FontAwesomeIcon icon={faDownload} className="mr-2" />
                                        {d.title}
                                    </a>
                                    :
                                    ""
                        },
                        {
                            field: "sent_date",
                            title:"Email",
                            render: d => {
                                return <div className="text-center"><EmailToTalent data={d} refreshData={getDocuments} /></div>;
                            }
                        },
                        {
                            field: "senderName",
                            title:"Sender Name",
                            render: d => {
                                return <div className="text-center">{d["senderName"]}</div>;
                            }
                        },
                        {
                            field: "talent_filename",
                            title:"Upload Signed Document",
                            render: d => {
                                if ( d["sent_date"] !== null ){
                                    return <div className="text-center"><UploadForm data={d} refreshData={getDocuments} /></div>;
                                }
								
                            }
                        },
                        {
                            field: "uploaderName",
                            title:"Uploader Name",
                            render: d => {
                                return <div className="text-center">{d["uploaderName"]}</div>;
                            }
                        },
                        {
                            field: "upload_date",
                            title:"Date upload",
                            render: d => {
                                return <div className="text-center">{util.humanDate( d["upload_date"] )}</div>;
                            }
                        },
                        {
                            field: "status",
                            title:"Status",
                            render:d => {
                                return <div className="text-center">
                                    {
                                        util.statusDocument( d.status )
                                    }
                                </div>;
                            }
                        },
                    ]}
                    data={state.documents}
                    options={{
                        pageSize: 15,
                        tableLayout: "auto",
                        showFirstLastPageButtons: false,
                        pageSizeOptions: [],
                        showTitle: false,
                        padding: "dense",
                        paginationType: "stepped",
                        selection:true,
                        emptyRowsWhenPaging: false
                    }}
                    actions={[
                        {
                            tooltip: 'Renew Documents',
                            icon: ()=> <div className="btn btn-success btn-sm">
                                <FontAwesomeIcon icon={faSyncAlt} className="mr-2" />
								Renew Documents
                            </div>,
                            onClick: ( evt, data ) => {
                                setState( { ...state, showAlert: true, data: data } );
                            }
                        }
                    ]}
                />
            </MuiThemeProvider>
            {state.showAlert ?
                <SAlert
                    show={state.showAlert}
                    confirmText="Yes"
                    typeButton="info"
                    title={"Send this documents to talent?"}
                    msg=""
                    hideAlert={()=> setState( { ...state, showAlert: false } )}
                    onConfirm={onConfirmSendRenewDocuments}
                />:""}
			
            {util.LOADING_SCREEN( state.loading )}
        </Fragment>
    );
}


