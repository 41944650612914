import * as Yup from "yup";

let validations = {};
const restrictedDomains = ["gmail.com",
    "outlook.com",
    "yahoo.com",
    "hotmail.com",
    "aol.com",
    "zoho.com",
    "icloud.com",
    "mail.com",
    "protonmail.com",
    "yandex.com"];

validations.validateLogin = Yup.object( {
    email: Yup.string().required(),
    password: Yup.string().required(),
} );

validations.validateRegister = Yup.object( {
    employer: Yup.string().required( "Please select an option" ),
    companyName: Yup.string()
        .when( 'employer', {
            is: '1', // Check if the employer value is "1"
            then: Yup.string().required( 'Company Name is required' ),
            otherwise: Yup.string().notRequired(),
        } ),
    name: Yup.string()
        .required( 'Required' ),
    email: Yup.string()
        .email( 'Email is invalid' )
        .required( 'Email is required' )
        .max( 35, 'Max 35 digits' )
        .test(
            "is-valid-email",
            `Please use your corporate email instead of third-party emails.`,
            function ( value ) {
                const { employer } = this.parent; // Access other fields in the form
                if ( employer === "1" && value ) {
                    const domain = value.split( "@" )[1]; // Extract domain
                    return !restrictedDomains.includes( domain );
                }
                return true; // Allow all emails for job seekers
            }
        ),
        
    phone: Yup.string()
        .min( 10, 'Phone number needs 10 digits' )
        .max( 12, 'Max 12 digits' )
        .required( 'Phone number is required' ),
    password: Yup.string()
        .min( 12, 'Password must be at least 12 characters long' )
        .matches( /[A-Z]/, 'Password must contain at least one uppercase letter' )
        .matches( /[a-z]/, 'Password must contain at least one lowercase letter' )
        .matches( /\d/, 'Password must contain at least one number' )
        .matches( /[!@#$%^&*]/, 'Password must contain at least one special character' )
        .required( 'Password is required' ),
    confirmPassword: Yup.string()
        .oneOf( [Yup.ref( 'password' ), null], 'Passwords must match' )  // Check that confirmPassword matches password
        .required( 'Confirm Password is required' ),
    // skills: Yup.array().of(Yup.object().shape({
    // 	skill_name: Yup.string()
    // 		.min(4, 'Minimum 4 characters')
    // 		.max(30, 'Max 30 characters')
    // 		.required('Required'),
    // 	years_experience: Yup.number()
    // 		.positive('Must be positive digit')
    // 		.required('Required'),
    // }))
} );

export default validations;