import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import util from '../../../utils/miniUtils';
import CandidateSelector from './CandidateSelector';

const AssignTestModal = ( { show, handleClose, candidates, updateCandidates } ) => {
    const [testsList, setTestsList] = useState( [] );
    const [testSelected, setTestSelected] = useState( "" );

    async function getAvailableTests() {
        try {
            const response = await util.axios.get( `/tests/list` );
            const { error, msg, tests } = response.data;
            if ( error ) throw msg;
            setTestsList( tests );
        } catch ( e ) {
            util.handleError( e );
        }
    }

    const handleSubmit = async () => {
        for( let i = 0; i < candidates.length; i ++ ) {
            try {
                const response = await util.axios.post( `/tests_user/assign_test/${candidates[i].candidate_id}/${testSelected}` );
                const { error, msg } = response.data;
                if ( error ) throw msg;
            } catch ( e ) {
                util.handleError( e );
            }
        }
        handleClose();
    };

    useEffect( () => {
        if( show ) {
            getAvailableTests();
        }
    },[] );

    return (
        <Modal show={show} onHide={handleClose} backdrop='static' keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Assign Test</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <CandidateSelector candidates={candidates} updateCandidates={updateCandidates} />
                </div>
                <label></label>
                <select className='form-control' onChange={( e ) => setTestSelected( e.target.value )}>
                    <option value=""></option>
                    {testsList?.map( ( t, i ) => {
                        return <option key={i} value={t.id}>{t.name} - {t.numQuestions} Questions - {t.time} minutes</option>;
                    } )}
                </select>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
            Close
                </Button>
                <Button variant="secondary" onClick={handleSubmit}>Assign</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AssignTestModal;