import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPaper, faThumbsDown, faThumbsUp, faUserClock } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@material-ui/core/Tooltip";


function LikeDislike( props ) {
    const data = props.data;

    return (
        <Fragment>
            <div className="titlesRow d-flex  justify-content-around" >
                <div className="p-2">Partner</div>
                <div className="p-2">Client</div>
            </div>
            <div className="titlesRow33 d-flex  justify-content-around"  >
                <div style={{ textAlign:"center" }}>
                    {data.partner_flag !== null ?
                        data.partner_flag === 0 ?
                            <Fragment>
                                <span className={`${data.partner_flag === 0 ? "dislikeThumb":"defaultThumb"}`}>
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faThumbsDown}
                                    />
                                </span>
                            </Fragment>
                            :
                            data.partner_flag === 1 ?
                                <Fragment>
                                    <span className={`${data.partner_flag === 1 ? "likeThumb":"defaultThumb"}`}>
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faThumbsUp}
                                        />
                                    </span>
                                </Fragment>:
                                <Fragment>
                                    <span className={`${data.partner_flag === 2 ? "holdThumb":"defaultThumb"}`}>
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faHandPaper}
                                        />
                                    </span>
                                </Fragment> :
                        <Tooltip title="No feedback from partner">
                            <span className="noFeedBackFrom">
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faUserClock}
                                />
                            </span>
                        </Tooltip>
                    }
                </div>
                <div style={{ textAlign:"center" }}>
                    {data.client_flag !== null ?
                        data.client_flag === 0 ?
                            <Fragment>
                                <span className={`${data.client_flag === 0 ? "dislikeThumb":"defaultThumb"}`}>
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faThumbsDown}
                                    />
                                </span>
                            </Fragment>
                            :
                            data.client_flag === 1 ?
                                <Fragment>
                                    <span className={`${data.client_flag === 1 ? "likeThumb":"defaultThumb"}`}>
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faThumbsUp}
                                        />
                                    </span>
                                </Fragment>:
                                <Fragment>
                                    <span className={`${data.client_flag === 2 ? "holdThumb":"defaultThumb"}`}>
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faHandPaper}
                                        />
                                    </span>
                                </Fragment>:
                        <Tooltip title="No feedback from client">
                            <span className="noFeedBackFrom">
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faUserClock}
                                />
                            </span>
                        </Tooltip>
                    }
                </div>
            </div>
        </Fragment>
    );
}

export default  LikeDislike;
