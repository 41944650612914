import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import { Card, CardContent } from "@material-ui/core";
import util from "../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar, faChartLine, faChartPie } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../../redux/actions/datefilter";
import Select from 'react-select';
import CompareFilter from "./CompareFilter";
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register( ChartDataLabels );
const ReusableChartComponent = ( {
    endpoint,
    graphLabel,
    graphValue,
    showFilter,
    from,
    to,
    isAppraisal,
    chartType
} ) => {
    const chartRef = React.useRef();
    const [talents, setTalents] = useState( [] );
    const [periodFilter, setPeriodFilter] = useState( [] );
    const [originalTalents, setOriginalTalents] = useState( [] );
    const [selectedPeriod, setSelectedPeriod] = useState( [] );
    const [selectedChart, setSelectedChart] = useState( chartType );
    const dispatch = useDispatch();
    const [compareTo, setCompareTo] = useState( "" );
    const [compareFrom, setCompareFrom] = useState( "" );
    let compareApply = false;
    const [compare, setCompare] = useState( [] );

    const { applyFilter } = useSelector( ( state ) => state.filter ) || {};    
    useEffect( () => {
        
        if ( applyFilter ) {
            fetchData();
        }
    }, [applyFilter] );
      

    const fetchData = async () => {
        try {
            
            if( from === undefined ){
                const currentDate = new Date();
                from = currentDate.getFullYear() + '-' +
                  ( currentDate.getMonth() + 1 ) + '-' +
                  currentDate.getDate();
            }

            if( to === undefined ){
                const currentDate = new Date();
                to = currentDate.getFullYear() + '-' +
                ( currentDate.getMonth() + 1 ) + '-' +
                currentDate.getDate();
            }

            let url = endpoint;
            if( showFilter ) {
                if ( from && to ) {
                    url += `?from=${from}&to=${to}`;
                }
                
            }
            const response = await util.axios.get( url );

            const { data, error, msg } = response.data;
            
            const filteredData = data.filter( ( item )=> item[graphLabel] !== null && item[graphLabel] !== "" );
            if( isAppraisal ){
                const period =  [...new Set( filteredData.map( item => item.period ) )];
                const periodOptions= period.map( period => ( { label: period, value: period } ) );
                setPeriodFilter( periodOptions );
                setOriginalTalents( filteredData );
            }
            // console.log( periodFilter );
            
            setTalents( filteredData );

            dispatch( setFilter( false ) );
            if ( error ) throw msg;
        } catch ( e ) {
            util.handleError( e );
        }
        
    };
    const fetchCompareData = async () => {
        try {
            
            if( compareFrom === undefined ){
                const currentDate = new Date();
                setCompareFrom( currentDate.getFullYear() + '-' +
                  ( currentDate.getMonth() + 1 ) + '-' +
                  currentDate.getDate() );
            }

            if( compareTo === undefined ){
                const currentDate = new Date();
                setCompareTo( currentDate.getFullYear() + '-' +
                ( currentDate.getMonth() + 1 ) + '-' +
                currentDate.getDate() );
            }

            let url = endpoint;
            if( showFilter ) {
                if ( compareFrom && compareTo ) {
                    url += `?from=${compareFrom}&to=${compareTo}`;
                }
                
            }
            const response = await util.axios.get( url );

            const { data, error, msg } = response.data;
            
            const filteredData = data.filter( ( item )=> item[graphLabel] !== null && item[graphLabel] !== "" );
            
            setCompare( filteredData );
            if ( error ) throw msg;
        } catch ( e ) {
            util.handleError( e );
        }
        
    };

    useEffect( () => {
        if ( talents?.length === 0 ) {
            return;
        }
        const allLabels = Array.from(
            new Set( [
                ...talents.map( ( item ) => item[graphLabel] ),
                ...compare.map( ( item ) => item[graphLabel] ),
            ] )
        );
        const talentsData = allLabels.map( ( label ) => {
            const found = talents.find( ( item ) => item[graphLabel] === label );
            return found ? found[graphValue] : 0;
        } );
        const compareData = allLabels.map( ( label ) => {
            const found = compare.find( ( item ) => item[graphLabel] === label );
            return found ? found[graphValue] : 0;
        } );
        const colors = generateColors( talents?.length, talents );
        const compareColors = generateColors( compare?.length, compare );
       

        const chartConfig = {
            type: selectedChart,
            data: {
                labels: allLabels,
                datasets: [
                    {
                        data: talentsData,
                        backgroundColor: selectedChart !== "pie" ? "#0170FF" : colors,
                        borderWidth: 1,
                        label: to ?  `${from} to ${to}`: `${graphLabel}`,
                        datalabels: {
                            color: 'black', 
                            anchor: 'end', 
                            align: 'top',
                            formatter: ( value ) => value, 
                            font: {
                                size: 12, 
                            }
                        }
                    },
                    ...( compareTo ? [{
                        label: `${compareFrom} to ${compareTo}`,
                        data: compareData,
                        backgroundColor: selectedChart !== "pie" ? "#0A0D1C" : compareColors,
                        borderWidth: 1,
                        datalabels: {
                            color: 'black',
                            anchor: 'end',
                            align: 'top',
                            formatter: ( value ) => value, 
                            font: {
                                size: 12,
                            }
                        }
                    }] : [] ),
                ],
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                    },
                },
                plugins: {
                    legend: {
                        display: true,
                        labels: {
                            generateLabels: function ( chart ) {
                                const data = chart.data;
                                let labelText = "";
                                if ( data.datasets.length ) {
                                    return data.datasets.map( ( dataset, i ) => {
                                        if( to ){
                                            labelText = i === 0 
                                                ? `${from} to ${to}` 
                                                : `${compareFrom} to ${compareTo}`;
                                            
                                        }else {
                                            labelText =  `${graphLabel}`;
                                        }
                                        
                                        return {
                                            text: labelText, 
                                            fillStyle: selectedChart === 'pie' ? dataset.backgroundColor[0] : dataset.backgroundColor,
                                            strokeStyle: dataset.backgroundColor,
                                            lineWidth: 1,
                                            hidden: dataset.hidden,
                                            datasetIndex: i,
                                        };
                                    } );
                                }
                                return [];
                            },
                        },
                    },
                },
            },
        };
        

        const myChart = new Chart( chartRef.current, chartConfig );

        return () => {
            myChart.destroy();
        };
    }, [talents, selectedChart,compare] );

    const generateColors = ( count, value ) => {
        const colors = [
            "#0170FF", "#0038FF", "#0425CC", "#000D6B",
            "#68E1FD", "#6F7CFF", "#5B46F9", "#480CA8",
            "#3344FF", "#58FFF0", "#EFF0F9", "#DFE1F0",
            "#B8BACC", "#9193A8", "#75788F", "#5A5C76",
            "#404259", "#23273C", "#1A1B2F", "#0A0D1C",
            "#EFF0F9", "#DFE1F0", "#B8BACC", "#9193A8", 
            "#75788F", "#5A5C76", "#404259", "#23273C", 
            "#1A1B2F", "#0A0D1C",
        ];
        if( value === compare ){

            return colors.reverse().slice( 0, count );
        }
        return colors.slice( 0, count );
    };

    
    const handleBarChart = () => {
        setSelectedChart( "bar" );
    };

    const handleLineChart = () => {
        setSelectedChart( "line" );
    };

    const handlePieChart = () => {
        setSelectedChart( "pie" );
    };
    // const renderChart = () => {
    //     if ( selectedChart === "pie" ) {
    //         return (
    //             <canvas ref={chartRef} className="dashboard-canvas" style={{ position: 'relative' }} />
    //         );
    //     } else {
    //         return (
    //             <canvas ref={chartRef} className="dashboard-canvas" style={{ position: 'relative', height: '500px' }} />
    //         );
    //     }
    // };
    const handlePeriodChange = ( selectedPeriod ) =>{
        setSelectedPeriod( selectedPeriod );
        const filteredTalents = originalTalents?.filter( ( item ) => ( selectedPeriod?.length ===0 )|| selectedPeriod?.some( sel => sel.value === item.period ) );
        setTalents( filteredTalents );
    };
    return (
        <Card className="card-chart" >
            <CardContent>
                <div className="chart-content">
                    <div>
                        <span onClick={handleBarChart} className="non-draggable">
                            <FontAwesomeIcon icon={faChartBar} />
                        </span>
                        <span onClick={handlePieChart} className="non-draggable">
                            <FontAwesomeIcon icon={faChartPie} />
                        </span>
                        <span onClick={handleLineChart} className="non-draggable">
                            <FontAwesomeIcon icon={faChartLine} />
                        </span>
                        {isAppraisal && <Select
                            isMulti
                            placeholder="Select period..."
                            value={selectedPeriod}
                            onChange={handlePeriodChange}
                            options={periodFilter}
                            className="mr-2 non-draggable dashboard-select"
                        />}
                        
                        {to && ( <span className="non-draggable d-flex flex-row">
                             
                            <p className="mt-2">Compare with :</p><CompareFilter 
                                to={compareTo} 
                                from={compareFrom} 
                                setTo={setCompareTo} 
                                setFrom={setCompareFrom} 
                                compareApply={compareApply}
                                fetchCompareData={fetchCompareData}
                            />

                        </span> )}
                    </div>
                    
                </div>
                <div className="chart-wrapper">
                    {talents?.length === 0 ? (
                        <div className="chart-text-empty">
            No data available for the selected period. Please choose a different period.
                        </div>
                    ) : (
                        <canvas ref={chartRef} className="dashboard-canvas" style={{ minHeight: '200px', maxHeight: '400px' }} />
                    )}
                </div>
                {/* {renderChart()} */}
            </CardContent>
        </Card>

    );
};

export default ReusableChartComponent;
