import { Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import mailImage from "../../assets/images/email_verification.png";
import { PositionsStore } from "../positions/stores/PositionsStore";
import util from "../utils/miniUtils";
import { toast } from "react-toastify";
import axios from "axios";
import { useLocation } from "react-router-dom";


const VerifyEmail = () => {
    const position = PositionsStore.useState( state => state );
    const location = useLocation();
    const [timer, setTimer] = useState( 30 );
    const [canResend, setCanResend] = useState( false );
    useEffect( () => {
        const queryParams = new URLSearchParams( window.location.search );
        const isInternal = queryParams.get( "internal" );
        if ( !isInternal ) {
            window.history.replaceState( "","", "/" );
        }
    },[location] );
    useEffect( () => {
        let countdown;
        if ( timer > 0 ) {
            countdown = setInterval( () => setTimer( ( prev ) => prev - 1 ), 1000 );
        } else {
            setCanResend( true );
        }
        return () => clearInterval( countdown ); // Cleanup the interval on component unmount
    }, [timer] );
    const queryParams = new URLSearchParams( location.search );
    if ( queryParams.get( "internal" ) !== "true" ) {
        return null; // Render nothing if the access is invalid
    }
    const ResendEmail = async () => {
        try{
            const request = await axios.post( `${util.url}/employer_verification/resend_token`, { userData: position.userData } );
            console.log( request );
            const { error, msg } = request.data;
            setTimer( 30 );
            setCanResend( false );
            if ( error ) throw msg;
            util.toast().success( msg );
        } catch ( e ) {
            PositionsStore.update( s => { s.loading = false; } );
            util.handleError( e );
        }
       

    };
    return (
        <div className="text-center">
            <Paper
                elevation={4}
                className="paper-container d-flex flex-column justify-content-center align-items-center"
            >
                <div className="text-center">
                    <img
                        src={mailImage}
                        alt="Verification Email"
                        className="email-image mb-4"
                    />
                </div>
                <h2 className="verify-header">
                    Verify Your Email Address
                </h2>
                <p className="verify-description">
                    We've sent you an email with a verification link. Please click the link in the email to confirm your email address.
                </p>
                <div className="mb-3">
                    <button
                        className="btn btn-secondary sizeBtn activeBtn"
                        onClick={ResendEmail}
                        disabled={!canResend}
                    >
                        Resend Email
                    </button>
                    <div>{!canResend && `in ${timer}s`}</div>
                </div>
                <p className="verify-footer">
                    Didn’t receive the email? Check your spam folder or click the button above to resend it.
                </p>
            </Paper>
        </div>
    
    );
};

export default VerifyEmail;
