import React, { useEffect, useState } from "react";
import { ClientJobStatusChart } from "./ChartList";
import { useDispatch } from "react-redux";
import { setFilter } from "../../../redux/actions/datefilter";
const ClientJobStatus  = () =>{
    const statusList = [
        { id:0, name:'New' },
        { id:1, name:'Active' },
        { id:2, name:'Hold' },
        { id:3, name:'Closed' },
        { id:4, name:'Filled' },
        { id:5, name:'Hot' },
        { id:6, name:'Dropped' },
    ];
    const [selectedStatus, setSelectedStatus] = useState( 0 );
    let dispatch = useDispatch();
    
    const handleSelectChange = ( e ) => {
        setSelectedStatus( e.target.value );
        dispatch( setFilter( true ) );
    };
    return (
        <>
            <div>
                <div className="d-flex justify-content-around bg-white">
                    <div>
                    Choose Status:
                    </div>
                    <select onChange={handleSelectChange}>
                        {statusList.map( ( item ) => <option key={item.id} value={item.id}>{item.name}</option> )}
                    </select>
                </div>
                <ClientJobStatusChart selectedStatus={selectedStatus}/>
            </div>
        </>
    );
};

export default ClientJobStatus;