import React, { Fragment, useState } from "react";
import util from "../../../utils/miniUtils";
import { Modal } from "react-bootstrap";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { setCandidateStatus } from "../../../../redux/actions/JobCandidatesAction";


function  RestartProcess ( props )  {
    /**
     * This is the modal that will appear when you want to restart the process of a talent
     * receives
     * modalVisible = boolean
     * hideModal = method
     * candidate = object with all the data of the candidate
     * */
    const ids = props.ids;
    const position_id = props.position_id;
    const [reason, setReason] = useState( "" );
    const [loading, setLoading] = useState( false );

    function handleChange( e ) {
        const { value } = e.target;
        setReason( value );
    }

    async function ReStartProcessOfTalent() {
        try {
            setLoading( true );
            if ( reason === "" ) throw new Error( "Select a motive to stop the process" );
            let data =  { position_id, talents: ids, reason };
            const request = await util.axios.post( `${util.url}/job_position/restart_process`, data );
            const { error, msg } = request.data;
            if ( error ) return util.toast().error( msg );
            util.toast().success( msg );
            setLoading( false );
            props.setCandidateStatus( { id:ids[0], status: 2,talent_desc_updated: "Process was restarted" } );
            props.hideModal();
        }catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    }

    return(
        <Fragment>
            <Modal size={"md"} show={props.modalVisible} onHide={props.hideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Restart process</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputLabel id="RestartProcess">Select a reason</InputLabel>
                    <Select
                        labelId="RestartProcess"
                        onChange={handleChange}
                        fullWidth
                        native
                    >
                        <option value="0"> -- </option>
                        <option value={"Candidate became available"}>Candidate became available</option>
                        <option value={"Missing information found"}>Missing information found</option>
                    </Select>
                    <hr/>
                    <div className="text-center">
                        {loading ? <CircularProgress/>:
                            <button className="btn btn-danger" onClick={()=> ReStartProcessOfTalent()}>
                                Restart Process
                            </button>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
}

export default  connect( null,mapDispatchToProps )( RestartProcess );

function mapDispatchToProps( dispatch ) {
    return{
        setCandidateStatus:( data ) => dispatch( setCandidateStatus( data ) ),
    };
}
