import React, { Fragment } from 'react';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
// import ReportRecruiter from "./recruiter_performance/manager_report/ReportRecruiter";
import Overall from "./overall/Overall";
import ReportJobs from "./sales_performance/ReportJobs";
import NewRecuiterReport from './recruiter_performance/manager_report/NewRecuiterReport';
import util from '../utils/miniUtils';
import SubmittedTodayReport from './recruiter_performance/manager_report/SubmittedTodayReport';

function Dashboard () {

    return (
        <Fragment>
            <Tabs defaultActiveKey="overall" id="uncontrolled-tab-example" unmountOnExit={true} mountOnEnter={true}>
                <Tab eventKey="overall" title="Overall">
                    <Overall />
                </Tab>
                <Tab eventKey="submitted_today" title="Submitted Today">
                    <SubmittedTodayReport />
                    {/* <ReportRecruiter /> */}
                </Tab>
                <Tab eventKey="recruiter_performance" title="Recruiter Performance">
                    <NewRecuiterReport />
                    {/* <ReportRecruiter /> */}
                </Tab>
                {  util.user.role === 6 &&   <Tab eventKey="jobs_report" title="Sales Performance">
                    <ReportJobs />
                </Tab>}
            </Tabs>
        </Fragment>
    );
}

export default Dashboard;
