import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import util from "../../utils/miniUtils";
import { MuiThemeProvider } from "@material-ui/core";
import { useSelector } from "react-redux";

const PerformanceReport = () => {
    const [users, setUsers] = useState( [] ); // Recruiter data
    const [loading, setLoading] = useState( false ); // Loading state
    const [page, setPage] = useState( 0 ); // Current page
    const [pageSize, setPageSize] = useState( 10 ); // Current page size
    const [totalCount, setTotalCount] = useState( 0 ); // Total number of records
    const [search, setSearch] = useState( "" );
    const { from, to } = useSelector( ( state ) => state.filter ) || {};

    // Fetch recruiter data from the API
    const fetchRecruiterData = async () => {
        setLoading( true );
        try {
            const response = await util.axios.get( `${util.url}/dashboard/recruitersResponsiveness`, {
                params: {
                    limit: pageSize, // Number of rows per page
                    offset: page * pageSize, // Calculate offset for pagination
                    startDate: from,
                    endDate: to,
                    search: search
                },
            } );

            const { error, msg, users, totalRecords } = response.data;
            if ( error ) throw new Error( msg );

            // Update state with fetched data
            setUsers( users );
            setTotalCount( totalRecords ); // Update total count for pagination
        } catch ( error ) {
            console.error( "Error fetching recruiter data:", error.message );
            util.handleError( error ); // Handle the error
        } finally {
            setLoading( false ); // Stop loading
        }
    };

    // Fetch data on page or page size change
    useEffect( () => {
        fetchRecruiterData();
    }, [page, pageSize, from, to, search] ); // Refetch data when page or page size changes

    // Handle page change
    const handlePageChange = ( newPage ) => {
        setPage( newPage ); // Update current page
    };

    // Handle rows per page change
    const handleRowsPerPageChange = ( newPageSize ) => {
        setPageSize( newPageSize ); // Update page size
        setPage( 0 ); // Reset to the first page
    };

    function convertMinutesToDuration( totalMinutes ) {
        const days = Math.floor( totalMinutes / ( 24 * 60 ) ); // Calculate total days
        const hours = Math.floor( ( totalMinutes % ( 24 * 60 ) ) / 60 ); // Calculate remaining hours
        const minutes = Math.floor( totalMinutes % 60 ); // Calculate remaining minutes
    
        return `${days} days, ${hours} hours, and ${minutes} minutes`;
    }

    // Table columns
    const columns = [
        { title: 'Id', field: 'id' },
        { title: 'Recruiter', field: 'name' },
        { title: 'Jobs Count', field: 'job_count' },
        { title: 'Candidates Reviewed', field: 'candidates_review' },
        { 
            title: 'Avg Response Time (Minutes)', 
            field: 'averageTimeInMinutes', 
            render: row => convertMinutesToDuration( row.averageTimeInMinutes ), 
        },
    ];

    return (
        <div className='non-draggable'>
            <MuiThemeProvider theme={util.defaultTableTheme}>
                <MaterialTable
                    title="Recruiter Responsiveness Report"
                    columns={columns}
                    data={users} // Dynamic data from API
                    isLoading={loading} // Show loading indicator
                    options={{
                        showTitle: false,
                        searchFieldAlignment: "left",
                        emptyRowsWhenPaging: true,
                        sorting: true, // Enable sorting
                        paging: true, // Enable pagination
                        page: page, // Current page
                        pageSize: pageSize, // Current page size
                        pageSizeOptions: [5, 10, 20, 50], // Rows per page options
                        serverSide: true, // Server-side pagination
                    }}
                    totalCount={totalCount} // Total number of records
                    page={page} // Current page
                    onChangePage={( newPage ) => handlePageChange( newPage )} // Handle page change
                    onChangeRowsPerPage={( newPageSize ) => handleRowsPerPageChange( newPageSize )} // Handle rows per page change
                    onSearchChange={searchValue => {
                        const timer = setTimeout( () => {
                            setSearch( searchValue );
                        }, 1000 );

                        return () => clearTimeout( timer );
                    }}
                />
            </MuiThemeProvider>
        </div>
    );
};

export default PerformanceReport;