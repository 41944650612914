import React, { Fragment, useState, useEffect, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDownload,
    faSync,
    faUpload,
    faCloudUploadAlt,
    faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import util from "../utils/miniUtils";

/**
 *
 * const settings = {
    candidate_id: 0,
    hasFile: 0,
    file: "",
    name:""
  };
 */

const HAVE_FILE = 2;
const NOT_HAVE_FILE = 0;
const SELECTED_BUT_NOT_UPLOADED_FILE = 1;

function UploadFile( props ) {

    // Receive props
    const cfg = props.config;
    const doc = props.data;

    const [haveFile, setHaveFile] = useState( cfg.hasFile );
    const [file, setFile] = useState( "" );
    const [urlFile, setUrlFile] = useState( "#" );
    /**
   * 0 No
   * 1 Selected file, prepared to upload
   * 2 Send the file/ have file
   */

    const fileInput = useRef( null );
    const handleClick = () => {
        fileInput.current.click();
    };

    const handleFileChange = ( event ) => {
        const fileSize = ( event.target.files[0].size / 1024 / 1024 ).toString();
        if ( fileSize.split( "." )[0] > 2 ) {
            util.toast().error( "File size is exceded, max 2 MB" );
            onCancelResumeUpload();
        } else {
            if ( event.target.files[0] != null ) {
                setFile( event.target.files[0] );
                setHaveFile( SELECTED_BUT_NOT_UPLOADED_FILE );
            }
        }
    };

    function onCancelResumeUpload() {
        setFile( "" );
        setHaveFile( 0 );
    }

    function uploadFile() {
        const data = new FormData();
        data.append( "file", file );
        util
            .axios
            .post( cfg.path, data )
            .then( ( response ) => {
                const { error, msg, fileName } = response.data;
                if ( error ) {
                    util.toast().error( msg );
                } else {
                    setFile( "" );
                    setHaveFile( 2 );
                    setUrlFile( `${util.url}/${util.AWSURL}/candidate/${cfg.candidate_id}/docs/${fileName}` );
                    util.toast().success( msg );
                    if ( cfg.callback !== null ) {
                        cfg.callback( msg );
                    }
                }
            } );
    }


    useEffect( () => {
        setUrlFile( `${util.url}/${util.AWSURL}/candidate/${cfg.candidate_id}/docs/${cfg.file}` );
        return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doc] );

    if ( haveFile === HAVE_FILE ) {
        return (
            <Fragment>
                <a className="btn-info btn btn-sm mr-2" target="new" href={urlFile}>
                    <FontAwesomeIcon icon={faDownload} />
                </a>

                <div className="btn-warning btn btn-sm">
                    <FontAwesomeIcon onClick={handleClick} icon={faSync} />
                    <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={( e ) => handleFileChange( e )}
                        ref={fileInput}
                        accept=".doc,.docx, .pdf,.xls,.xlsx"
                    />
                </div>
            </Fragment>
        );
    }

    if ( haveFile === NOT_HAVE_FILE ) {
        return (
            <Fragment>
                <div className="mr-2 btn-primary btn btn-sm">
                    <FontAwesomeIcon onClick={handleClick} icon={faUpload} />
                    <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={( e ) => handleFileChange( e )}
                        ref={fileInput}
                        accept=".doc,.docx, .pdf,.xls,.xlsx"
                    />
                </div>
            </Fragment>
        );
    }

    if ( haveFile === SELECTED_BUT_NOT_UPLOADED_FILE ) {

        return (
            <Fragment>
                <div onClick={uploadFile} className="mr-2 btn-success btn btn-sm">
                    <FontAwesomeIcon icon={faCloudUploadAlt} />
                </div>
                <div
                    onClick={onCancelResumeUpload}
                    className="mr-2 btn-danger btn btn-sm"
                >
                    <FontAwesomeIcon icon={faMinusCircle} />
                </div>
            </Fragment>
        );
    }
}

export default UploadFile;
