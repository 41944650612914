import React, { useEffect, useRef, useState } from 'react';
import { Drawer } from "@material-ui/core";
import util from '../../utils/miniUtils';
import { Button } from 'react-bootstrap';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileUploadForm from './fileuploadForm';
import { Close } from  "@material-ui/icons";

const DrawerComponent = ( { open, onClose, customer } ) => {
    const [files, setFiles] = useState( [] );
    const [showfiles, setShowFiles] = useState( [] );
    const[assistant,setAssistant ]  =useState( [] );
    const fetchData = async () => {
        try {
            // Adjust the request to use the filter state for pagination 
            const request = await util.axios.get( `/customer_company_files/getFiles/${customer.customer_company_id}` );  
            // const request = await util.axios.get( `/customer_companies?page=${filter.page}&pageSize=${filter.pageSize}` );  
            const { error, data,assistant } = request.data;
            setShowFiles( data );
            setAssistant( assistant );
            if ( error ) throw new Error( "Error Fetching Companies" );
        } catch ( error ) {
            util.handleError( error );
        }
    };
   
    const deleteFile = async ( id ) => {
        try {
            
            const response = await util.axios.delete( `/customer_company_files/delete_file/${id}/${customer.customer_company_id}` );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
          
            util.toast().success( "File Deleteead" );
            fetchData();
        } catch ( e ) {
           
            util.handleError( e );
        }
    };

    useEffect( () => {
      
        fetchData();
    }, [] );
    return (
        <Drawer anchor="right" open={open} onClose={onClose}>
            <div  className='mt-4 ml-4' style={{ width: '550px' }}>
                <h5>System Setup</h5>
                <div className='float-right mr-3 color-green'>{assistant?.status?assistant.status:""}</div>
                {/* <FileDropZone 
                    files={file}
                    setFile={setFile}
                    customer={customer} /> */}
                <FileUploadForm  
                    customer={customer}
                    fetchData= { fetchData}
                    assistant ={assistant}
                />

                <div style={{ width:'95%' }}>
                    {showfiles?.length > 0 && (
                        <ul style={{ border: '1px solid' }}>
                            {showfiles.map( ( file, index ) => (
                                <li key={index} style={{ listStyleType: 'none' }}>
                                    <div className='d-flex justify-content-between align-items-center ml-3 mt-2'>
                                        <div>{file.assistant_file_name}</div>
                                        <div className='mr-3'>
                                            <Close onClick={() => deleteFile( file.assistant_file_id )} className="iconGray" style={{ width: '17px', cursor: "pointer" }} />
                                        </div>
                                    </div>
                                </li>
                            ) )}
                        </ul>
                   
                    )}</div>
            </div>
        </Drawer>
    );
};

export default DrawerComponent;


