import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import util from '../../utils/miniUtils';
import { MuiThemeProvider } from '@material-ui/core';

const VendorList = ( { handleVendorClick } ) => {
    const [data, setData] = useState( [] );
    const [pageSize, setPageSize] = useState( 20 );
    const [page, setPage] = useState( 0 );
    const [total, setTotal] = useState( 0 );
    const [search, setSearch] = useState( "" );
    const [loading, setLoading] = useState( false );
    const columns = [
        { title: 'ID', field: 'vendor_company_id' },
        { title: 'Status', field: 'vendor_Status' },
        { title: 'Name', field: 'vendor_name' },
        { title: 'Email', field: 'vendor_Email' },
        { title: 'Phone', field: 'vendor_Phone' },
        {
            title: '',
            field: '',
            render: rowData => (
                <button className='btn  activeBtn sizeBtn btn-secondary' onClick={() => handleVendorClick( rowData )}>
                    
                    View
                </button>
            ),
        },
    ];

    const getVendorList = async () => {
        setLoading( true );
        try {
            const request = await util.axios.get( `/vendor?page=${page}&pageSize=${pageSize}&search=${search}` );
            const { data, error, msg, total } = request.data;
            if( error ) throw msg;
            setData( data );
            setTotal( total );
            setLoading( false );
        } catch ( error ) {
            util.handleError( error );
            setLoading( false );
        }
    };

    useEffect( () => {
        getVendorList();
    },[] );

    return (
        <MuiThemeProvider theme={util.defaultTableTheme}>
            <MaterialTable
                className="company-list-table"
                title=""
                columns={columns}
                data={data}
                options={{
                    searchFieldAlignment: "left",
                    emptyRowsWhenPaging: false,
                    search: true,
                    selection:true,
                    exportButton: false,
                    paging: true, 
                    sorting: true,
                    pageSize: pageSize,
                    pageSizeOptions: [20, 50, 100], 
                    actionsColumnIndex: -1,
                    maxBodyHeight: 'calc(100vh - 320px)',
                }}
                isLoading={loading}
                page={page}
                totalCount={total}
                onChangePage={newPage => {
                    setPage( newPage );
                }}
                onChangeRowsPerPage={( pageSize ) => {
                    setPageSize( pageSize );
                    setPage( 0 ); 
                }}
                onSearchChange={query => {
                    setSearch( query );
                    setPage( 0 );
                }}                       
            />
        </MuiThemeProvider>
    );
};

export default VendorList;