import React, { useEffect, useRef, useState } from "react";
// import { Responsive, WidthProvider } from "react-grid-layout";  
// import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import Swal from 'sweetalert2';
import CandidateByJobPosition from "./CandidateByJobPosition";
import ActiveJob from "./ActiveJob";
import JobData from './JobData';
import WelcomeComponent from "./WelcomeComponent";
import TalentDaysTab from "./TalentDaysTab";
import  { CandidatesAddedByActiveRecruiter, CandidatesAddedByCountry, CandidatesAddedByRecruiter, CandidatesAddedByWorkingStatus, TalentAddedByPlatform, TalentStatus  } from "./ChartList";
import util from "../../utils/miniUtils";
import { Select } from "@material-ui/core";
import ChartFilter from "./ChartFilter";
import { useDispatch, useSelector } from "react-redux";
import { setFilter, setFrom, setTo } from "../../../redux/actions/datefilter";
import { faAngleDown, faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


// const ResponsiveGridLayout = WidthProvider( Responsive );

const categoryNames = ['WorkLoad', 'Appraisal', 'Recruiter' ];

const chartComponentsByCategory = {
    Recruiter: {
        initialState: [
        // ... initialState1 data ...
            { i: "chart1", x: 0, y: 0, w: 48, h: 1 , minW:12 },
            { i: "chart2", x: 0, y: 0, w: 12, h: 1 ,minW:6 },
            { i: "chart3", x: 1, y: 1, w: 12, h: 1 ,minW:6 },
            { i: "chart4", x: 2, y: 8, w: 24, h: 3 ,minW:12 },
            { i: "chart5", x: 0, y: 16, w: 24, h: 2.5 ,minW:12 },
            { i: "chart6", x: 2, y: 16, w: 24, h: 2.5 ,minW:12 },
            { i: "chart7", x: 0, y: 24, w: 24, h: 2.5 ,minW:12 },
            { i: "chart8", x: 2, y: 24, w: 24, h: 2.5 ,minW:12 },
            { i: "chart9", x: 0, y: 32, w: 24, h: 2.5 ,minW:12 },
            { i: "chart10", x: 2, y: 32, w: 24, h: 2.5 ,minW:12 },
            { i: "chart11", x: 0, y: 40, w: 24, h: 2.5 ,minW:12 },
        ],
        titles: [
        // ... titles1 data ...
            '',
            'Active Jobs',
            'Total Talents',
            'Talents Added by Active Recruiters',
            'Talent Insights by Country',
            'Talents Onboarded by Recruiters',
            'Talent Insights by Source',
            'Talent Insights by Work Authorization',
            'Talent Insights by Status',
            'Talent Insights by Job Titles',
            'Talent Added by Years, Months and Days',
        ],
        chartComponents: {
            chart1: WelcomeComponent,
            chart2: ActiveJob,
            chart3: JobData,
            chart4: CandidatesAddedByActiveRecruiter,
            chart5: CandidatesAddedByCountry,
            chart6: CandidatesAddedByRecruiter,
            chart7: TalentAddedByPlatform,
            chart8: CandidatesAddedByWorkingStatus,
            chart9: TalentStatus,
            chart10: CandidateByJobPosition,
            chart11: TalentDaysTab,
        },
    },
    Appraisal: {
        initialState: [
        // ... initialState2 data ...
            { i: "chart1", x: 0, y: 0, w: 48, h: 1 , minW:12 },
            { i: "chart2", x: 0, y: 0, w: 12, h: 1 ,minW:6 },
            { i: "chart3", x: 1, y: 1, w: 12, h: 1 ,minW:6 },
            { i: "chart4", x: 2, y: 8, w: 24, h: 3 ,minW:12 },
            { i: "chart5", x: 0, y: 16, w: 24, h: 2.5 ,minW:12 },
            { i: "chart6", x: 2, y: 16, w: 24, h: 2.5 ,minW:12 },

        ],
        titles: [
        // ... titles2 data ...
            '',
            'Active Jobs',
            'Total Talents',
            'Talents Added by Active Recruiters',
            'Talent Insights by Country',
            'Talents Onboarded by Recruiters',
           
        ],
        chartComponents: {
            chart1: WelcomeComponent,
            chart2: ActiveJob,
            chart3: JobData,
            chart4: CandidatesAddedByActiveRecruiter,
            chart5: CandidatesAddedByCountry,
            chart6: CandidatesAddedByRecruiter,
        },
    },
    WorkLoad: {
        initialState: [
        // ... initialState3 data ...
            { i: "chart1", x: 0, y: 0, w: 48, h: 1 , minW:12 },
            { i: "chart2", x: 0, y: 0, w: 12, h: 1 ,minW:6 },
            { i: "chart3", x: 1, y: 1, w: 12, h: 1 ,minW:6 },
            { i: "chart4", x: 2, y: 8, w: 24, h: 3 ,minW:12 },
            { i: "chart5", x: 0, y: 16, w: 24, h: 2.5 ,minW:12 },
            { i: "chart6", x: 2, y: 16, w: 24, h: 2.5 ,minW:12 },
            { i: "chart7", x: 0, y: 24, w: 24, h: 2.5 ,minW:12 },
            { i: "chart8", x: 2, y: 24, w: 24, h: 2.5 ,minW:12 },
        ],
        titles: [
        // ... titles3 data ...
            '',
            'Active Jobs',
            'Total Talents',
            'Talents Added by Active Recruiters',
            'Talent Insights by Country',
            'Talents Onboarded by Recruiters',
            'Talent Insights by Source',
            'Talent Insights by Work Authorization',
        ],
        chartComponents: {
            chart1: WelcomeComponent,
            chart2: ActiveJob,
            chart3: JobData,
            chart4: CandidatesAddedByActiveRecruiter,
            chart5: CandidatesAddedByCountry,
            chart6: CandidatesAddedByRecruiter,
            chart7: TalentAddedByPlatform,
            chart8: CandidatesAddedByWorkingStatus,
        },
    },
};

const chartComponents1 = {
    chart1: WelcomeComponent,
    chart2: ActiveJob,
    chart3: JobData,
    chart4: CandidatesAddedByActiveRecruiter,
    chart5: CandidatesAddedByCountry,
    chart6: CandidatesAddedByRecruiter,
    chart7: TalentAddedByPlatform,
    chart8: CandidatesAddedByWorkingStatus,
    chart9: TalentStatus,
    chart10: CandidateByJobPosition,
    chart11: TalentDaysTab,
};
const chartComponents2 = {
    chart1: WelcomeComponent,
    chart2: ActiveJob,
    chart3: JobData,
    chart4: CandidatesAddedByActiveRecruiter,
    chart5: CandidatesAddedByCountry,
    chart6: CandidatesAddedByRecruiter,
    // chart7: TalentAddedByPlatform,
    // chart8: CandidatesAddedByWorkingStatus,
    // chart9: TalentStatus,
    // chart10: CandidateByJobPosition,
    // chart11: TalentDaysTab,
};
const chartComponents3 = {
    chart1: WelcomeComponent,
    chart2: ActiveJob,
    chart3: JobData,
    chart4: CandidatesAddedByActiveRecruiter,
    chart5: CandidatesAddedByCountry,
    chart6: CandidatesAddedByRecruiter,
    chart7: TalentAddedByPlatform,
    chart8: CandidatesAddedByWorkingStatus,
    // chart9: TalentStatus,
    // chart10: CandidateByJobPosition,
    // chart11: TalentDaysTab,
};
// const categoryNames = ['WorkLoad', 'Appraisal', 'Recruiter' ];

const titles1 = [
    '',
    'Active Jobs',
    'Total Talents',
    'Talents Added by Active Recruiters',
    'Talent Insights by Country',
    'Talents Onboarded by Recruiters',
    'Talent Insights by Source',
    'Talent Insights by Work Authorization',
    'Talent Insights by Status',
    'Talent Insights by Job Titles',
    'Talent Added by Years, Months and Days',

];
const titles2 = [
    '',
    'Active Jobs',
    'Total Talents',
    'Talents Added by Active Recruiters',
    'Talent Insights by Country',
    'Talents Onboarded by Recruiters',
    // 'Talent Insights by Source',
    // 'Talent Insights by Work Authorization',
    // 'Talent Insights by Status',
    // 'Talent Insights by Job Titles',
    // 'Talent Added by Years, Months and Days',
];
const titles3 = [
    '',
    'Active Jobs',
    'Total Talents',
    'Talents Added by Active Recruiters',
    'Talent Insights by Country',
    'Talents Onboarded by Recruiters',
    'Talent Insights by Source',
    'Talent Insights by Work Authorization',
    // 'Talent Insights by Status',
    // 'Talent Insights by Job Titles',
    // 'Talent Added by Years, Months and Days',

];
const initialState1 =  [
    { i: "chart1", x: 0, y: 0, w: 48, h: 1 , minW:12 },
    { i: "chart2", x: 0, y: 0, w: 12, h: 1 ,minW:6 },
    { i: "chart3", x: 1, y: 1, w: 12, h: 1 ,minW:6 },
    { i: "chart4", x: 2, y: 8, w: 24, h: 3 ,minW:12 },
    { i: "chart5", x: 0, y: 16, w: 24, h: 2.5 ,minW:12 },
    { i: "chart6", x: 2, y: 16, w: 24, h: 2.5 ,minW:12 },
    { i: "chart7", x: 0, y: 24, w: 24, h: 2.5 ,minW:12 },
    { i: "chart8", x: 2, y: 24, w: 24, h: 2.5 ,minW:12 },
    { i: "chart9", x: 0, y: 32, w: 24, h: 2.5 ,minW:12 },
    { i: "chart10", x: 2, y: 32, w: 24, h: 2.5 ,minW:12 },
    { i: "chart11", x: 0, y: 40, w: 24, h: 2.5 ,minW:12 },
];
const initialState2 =  [
    { i: "chart1", x: 0, y: 0, w: 48, h: 1 , minW:12 },
    { i: "chart2", x: 0, y: 0, w: 12, h: 1 ,minW:6 },
    { i: "chart3", x: 1, y: 1, w: 12, h: 1 ,minW:6 },
    { i: "chart4", x: 2, y: 8, w: 24, h: 3 ,minW:12 },
    { i: "chart5", x: 0, y: 16, w: 24, h: 2.5 ,minW:12 },
    { i: "chart6", x: 2, y: 16, w: 24, h: 2.5 ,minW:12 },
    // { i: "chart7", x: 0, y: 24, w: 24, h: 2.5 ,minW:12 },
    // { i: "chart8", x: 2, y: 24, w: 24, h: 2.5 ,minW:12 },
    // { i: "chart9", x: 0, y: 32, w: 24, h: 2.5 ,minW:12 },
    // { i: "chart10", x: 2, y: 32, w: 24, h: 2.5 ,minW:12 },
    // { i: "chart11", x: 0, y: 40, w: 24, h: 2.5 ,minW:12 },
];
const initialState3 =  [
    { i: "chart1", x: 0, y: 0, w: 48, h: 1 , minW:12 },
    { i: "chart2", x: 0, y: 0, w: 12, h: 1 ,minW:6 },
    { i: "chart3", x: 1, y: 1, w: 12, h: 1 ,minW:6 },
    { i: "chart4", x: 2, y: 8, w: 24, h: 3 ,minW:12 },
    { i: "chart5", x: 0, y: 16, w: 24, h: 2.5 ,minW:12 },
    { i: "chart6", x: 2, y: 16, w: 24, h: 2.5 ,minW:12 },
    { i: "chart7", x: 0, y: 24, w: 24, h: 2.5 ,minW:12 },
    { i: "chart8", x: 2, y: 24, w: 24, h: 2.5 ,minW:12 },
    // { i: "chart9", x: 0, y: 32, w: 24, h: 2.5 ,minW:12 },
    // { i: "chart10", x: 2, y: 32, w: 24, h: 2.5 ,minW:12 },
    // { i: "chart11", x: 0, y: 40, w: 24, h: 2.5 ,minW:12 },
];

const DraggableAndResizeable = () => {
    const [layouts, setLayout] = useState( [ ] );
    const [reportNames, setReportName] = useState( [] );
    const [selectedReport,setSelectedReport] = useState( '' );
    const [isOpen, setIsOpen] = useState( false );
    const [selectedCategory, setSelectedCategory] = useState( '' );

    let initialState = [];
    let titles = [];
    let chartComponents = {};
    if ( selectedCategory === 'Recruiter' ) {
        initialState = initialState1;
        titles = titles1;
        chartComponents = chartComponents1;
    }
    if ( selectedCategory === 'Appraisal' ) {
        initialState = initialState2;
        titles = titles2;
        chartComponents = chartComponents2;
    }
    if ( selectedCategory === 'WorkLoad' ) {
        initialState = initialState3;
        titles = titles3;
        chartComponents = chartComponents3;
    }
    // const [selectedCategory, setSelectedCategory] = useState( 'WorkLoad' );
      
    // const currentCategoryData = chartComponentsByCategory[selectedCategory] || {};
    // const { initialState, titles, chartComponents } = currentCategoryData;

    const [removedCharts, setRemovedCharts] = useState( initialState );
    const { from, to } = useSelector( ( state ) => state.filter ) || {};
    const dispatch = useDispatch();
    const dropdownRef = useRef( null );


    const accountId = util.user.id; 
    // function toISOStringDate( dateString ) {
    //     const monthNames = [
    //         "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    //         "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    //     ];
    //     const date = new Date( dateString );
    //     const year = date?.getFullYear();
    //     const monthIndex = date?.getMonth();
    //     const month = monthNames[monthIndex];
    //     const day = String( date?.getDate() ).padStart( 2, "0" );
        
    //     return `${day}-${month}-${year}`;
    // }
    function toISOStringDate( dateString ) {
        const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
      
        const [year, month, day] = dateString.split( '-' );
        const formattedMonth = months[parseInt( month ) - 1];
        const formattedDay = parseInt( day ).toString().padStart( 2, '0' );
      
        return `${formattedDay}-${formattedMonth}-${year}`;
    }
      
    useEffect( () => {
        
        getLayout( accountId, selectedCategory, selectedReport );
        setRemovedCharts( initialState.filter( ( chart ) => {
            return !layouts.some( ( item ) => item.i === chart.i );
        } ) );
    }, [accountId,selectedReport,selectedCategory] );

    const getLayout = async ( accountId, selectedCategory, selectedReport ) => {
        try {
            const response = await util.axios.get(
            `new_dashboard/get_layout_data/${accountId}`
            );  
            const { data, error, msg } = response.data;
            
            const category = data.map( ( item ) => item.category );
            if( selectedCategory == "" ){
                setSelectedCategory( category[0] );

            }
            
            const filteredData = data.filter( ( item ) => item.category === selectedCategory ); 
            const reportNames = filteredData.map( ( item ) => item.report_name );
            
            setReportName( reportNames );
            
            if( reportNames.length > 0 && selectedReport !=='' && !reportNames.includes( selectedReport ) ){
                
                setSelectedReport( reportNames[0] );
            }
            
            const layout_data = data.find( ( item ) => item.report_name === selectedReport )?.layout_data;
            let toDate = data.find( ( item ) => item.report_name === selectedReport )?.toDate;
            let fromDate = data.find( ( item ) => item.report_name === selectedReport )?.fromDate;
            
            if( toDate && fromDate ){
                fromDate = toISOStringDate( fromDate );
                toDate = toISOStringDate( toDate );
                
                dispatch( setFrom( fromDate ) );
                dispatch( setTo( toDate ) );
            }
            
            dispatch( setFilter( true ) );
            
            if ( layout_data !== null && selectedReport !== 'new report' && selectedReport  ) {

                const parsedLayoutData = JSON.parse( layout_data );
                setLayout( [ ...parsedLayoutData] );
                // setRemovedCharts( prevRemovedCharts => {
                const updatedRemovedCharts = initialState.filter( ( chart ) => {
                    return !parsedLayoutData.some( ( item ) => item.i === chart.i );
                } );
                //     return updatedRemovedCharts;
                // } );
                setRemovedCharts( updatedRemovedCharts );
            }
            if ( reportNames.length > 0  ) {
                setReportName( reportNames );
            }

            if ( error ) throw msg;
        } catch ( e ) {
            util.handleError( e );
        }
    };
    const toggleDropdown = () => {
        setIsOpen( !isOpen );
        // setIsOpen( ( prevIsOpen ) => !prevIsOpen );
    };
    const saveLayoutData = async ( accountId, layoutData, layoutName, selectedCategory ) => {
        
        try {
            const request = await util.axios.post( `${util.url}/new_dashboard/post_layout_data`,{ account_id: accountId ,layout_data:  layoutData , report_name: layoutName, toDate: to, fromDate: from,category: selectedCategory } );
            const { error, msg } = request.data;
            setReportName( ( prevReportNames ) => [...prevReportNames, layoutName] );
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const updateLayoutData = async ( accountId, layoutData, layoutName, selectedCategory ) => {
        
        try {
            const request = await util.axios.put( `${util.url}/new_dashboard/update_layout_data`,{ account_id: accountId ,layout_data:  layoutData , report_name: layoutName, toDate: to, fromDate: from, category: selectedCategory } );
            const { error, msg } = request.data;
            // setReportName( ( prevReportNames ) => [...prevReportNames, layoutName] );
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };
    const deleteLayout= async (  accountId, layoutName, selectedCategory ) => {
        
        try {
            
            const request = await util.axios.delete( `${util.url}/new_dashboard/delete_layout?account_id=${accountId}&report_name=${layoutName}&category=${selectedCategory}` );
            const { error, msg } = request.data;
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const addLayout = () =>{

        // setReportName( [] );
        setSelectedCategory( '' );
        setSelectedReport( '' );
        setRemovedCharts( initialState );
        setLayout( [] );
        dispatch( setTo( '' ) );
        dispatch( setFrom( '' ) );
        dispatch( setFilter( false ) );

    };
    
    const handleSaveLayoutClick = async( newLayout ) => {
        
        const { value: layoutName } = await Swal.fire( {
            input: 'text',
            inputPlaceholder: 'Enter layout name...',
            inputAttributes: {
                'aria-label': 'Type your Layout Name'
            },
            showCancelButton: true,
            preConfirm: ( value ) => {
                if ( !value.trim() ) {
                    Swal.showValidationMessage( 'Layout name cannot be empty' );
                }
            }
        } );
          
        if ( layoutName ) {
            const isLayoutExist = reportNames.some( ( report ) => report === layoutName );
            if ( isLayoutExist ) {
                Swal.fire( {
                    icon: 'error',
                    title: 'Layout Name Already Exists',
                    text: 'The layout name already exists. Please use a different name.',
                } );
            } else {
                setLayout( newLayout );
                saveLayoutData( accountId, newLayout, layoutName, selectedCategory );
                Swal.fire( 'Layout Saved', 'The layout has been successfully saved.', 'success' );
            }
        }
    };
    const onLayoutChange = ( newLayout ) =>{
        setLayout( newLayout );
    };
    const removeChart = ( chartId ) => {
        const updatedLayout = layouts.filter( ( item ) => item.i !== chartId );
        const removedChart = layouts.find( ( item ) => item.i === chartId );
        setLayout( updatedLayout );
        setRemovedCharts( ( prevRemovedCharts ) => [
            ...prevRemovedCharts,
            removedChart
        ] );
    };

    const showChart = ( chartId ) => {
        const removedChart = removedCharts.find( ( item ) => item.i === chartId );
        const updatedRemovedCharts = removedCharts.filter(
            ( item ) => item.i !== chartId
        );
        dispatch( setFilter( true ) );
        setLayout( ( prevLayout ) => [...prevLayout, removedChart] );
        setRemovedCharts( updatedRemovedCharts );
    };
 
    const handleUpdateLayoutClick = ( newLayout ) =>{
        setLayout( newLayout );
        updateLayoutData( accountId, newLayout, selectedReport, selectedCategory );
        Swal.fire( 'Layout Updated', 'The layout has been successfully updated.', 'success' );  
    };
    // const handleDelete = ( layoutName ) =>{
        
    //     deleteLayout( accountId, layoutName );
    //     // setReportName( reportNames );
    //     setSelectedReport( '' );
    //     // setReportName( reportNames );
    //     setReportName( ( prevReportNames ) =>
    //         prevReportNames.filter( ( report ) => report !== layoutName )
    //     );
   
    // };
    const handleDelete = async ( layoutName ) => {
        try {
            const result = await Swal.fire( {
                title: "Confirm Deletion",
                text: "Are you sure you want to delete this report?",
                // icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Delete",
            } );
            
            if ( result.value ) {
                deleteLayout( accountId, layoutName, selectedCategory );
                setSelectedReport( "" );
                setLayout( [] );
                setReportName( ( prevReportNames ) =>
                    prevReportNames.filter( ( report ) => report !== layoutName )
                );
      
                Swal.fire( "Deleted", "The report has been deleted.", "success" );
            }
        } catch ( error ) {
            Swal.fire( "Error", "An error occurred while deleting the report.", "error" );
        }
    };
      
    useEffect( () => {
        const handleClickOutside = ( event ) => {
            if ( dropdownRef.current && !dropdownRef.current.contains( event.target ) ) {
                setIsOpen( false );
            }
        };
      
        document.addEventListener( "click", handleClickOutside );
      
        return () => {
            document.removeEventListener( "click", handleClickOutside );
        };
    }, [] );
      
    const handleCreateReport = () =>{
        setSelectedReport( '' );
        setSelectedCategory( '' );
        setRemovedCharts( initialState );
        setLayout( [] );
        // dispatch( setTo( '' ) );
        // dispatch( setFrom( '' ) );
        // dispatch( setFilter( false ) );
        setIsOpen( false );
        
    };

    return (
        <div>
            <div className="m-3 select-header-dashboard">
                <span className="m-2" onClick={addLayout}>
                    <FontAwesomeIcon className="text-primary" size="2x" icon={faPlusCircle} />
                </span>
                <div className="d-flex align-items-center dashboard-report">
                    
                    <div className="custom-dropdown" ref={dropdownRef}>
                        <div className="selected-option" onClick={toggleDropdown}>
                            {selectedReport ? selectedReport : 'Select a Report'}
                            <FontAwesomeIcon icon={faAngleDown} className='icon'/>
                        </div>
                        {isOpen && (
                            <ul className="dropdown-list">
                                {( selectedReport !== "new report" && <li
                                    className={`dropdown-option ${selectedReport === 'new report' ? 'selected' : ''}`}
                                    onClick={() => handleCreateReport()}
                                >
        new report
                                </li> )}
                                {reportNames.map( ( reportName, i ) => (
                                    <li
                                        key={i}
                                        className={`dropdown-option ${selectedReport === reportName ? 'selected' : ''}`}
                                        onClick={() => { setSelectedReport( reportName ); setIsOpen( false ); }}
                                    >
                                        {reportName}
                                        <button 
                                            className="delete-option"
                                            onClick={( e ) => {
                                                e.stopPropagation(); // Stop event propagation here
                                                handleDelete( reportName );
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </li>
                                ) )}
                            </ul>
                        )}
                    </div>

                    { selectedReport === '' || selectedReport === 'new report' ?
                        <span
                            className="btn btn-secondary sizeBtn activeBtn"
                            onClick={()=> handleSaveLayoutClick( layouts )}>
                        Save
                        </span>:
                        <span
                            className="btn btn-secondary sizeBtn activeBtn"
                            onClick={()=> handleUpdateLayoutClick( layouts )}>
                        Update
                        </span>
                    }
                </div>
                <div>
                    <select
                        className="p-2 dashboard-category "
                        value={selectedCategory}
                        onChange={( e ) => {setSelectedCategory( e.target.value );}}
                        native
                    >
                        <option disabled value="">Category</option>  
                        {categoryNames.map( ( categoryName, index )=> (
                            <option key={index}  value={categoryName}>{categoryName}</option>
                        ) )}
                    </select>
                </div>
                
                
                <div><ChartFilter /></div>
                <div className="customize-dropdown">
                    { removedCharts && removedCharts.length > 0 && (
                        <div>
                            <select
                                onChange={( e ) => {showChart( e.target.value );}}
                                native
                                value=""
                                // style={{ marginBottom: "8px",marginLeft:'10px' }}
                                className="dashboard-category"
                            >
                                <option disabled value="">Customize</option>
                                {removedCharts.map( ( item ) => (
                                    <option key={item.i} value={item.i}>
                                        {titles[parseInt( item.i.replace( "chart", "" ) ) - 1]}
                                    </option>
                                ) )}
                            </select>
                        </div> 
                    )}
                </div>
               
            </div>

            {/* { removedCharts.length >= 0 && ( <ResponsiveGridLayout
                className="layout"
                layouts={{ lg: layouts }}
                breakpoints={{ lg: 1200 }}
                // breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                cols={{ lg: 48 }}
                onLayoutChange={onLayoutChange}
                // autoSize={true}
            >
                {layouts.map( ( item ) => {
                    const ChartComponent = chartComponents[item.i];
                    return (
                        <div
                            key={item.i}
                            style={{  position: "relative" }}
                        >             
                            <div style={{ 
                                position: "relative",
                                display: "flex",
                                justifyContent: "space-between",
                                backgroundColor:'white',
                                padding: " 0 0.25rem "
                            }}>              
                                <div style={{ fontSize:'20px' , color:'#757575' }}>{titles[parseInt( item.i.replace( "chart", "" ) ) - 1]}</div>
                                <span
                                    className="btn btn-sm"
                                    onClick={() => removeChart( item.i )}
                                >
                X
                                </span>
                            </div>
                            <ChartComponent />
                        </div>
                    );
                } )}
            </ResponsiveGridLayout> )} */}
            {/* { removedCharts.length >= 0 && ( <ResponsiveGridLayout
                className="layout"
                layouts={{ lg: layouts }}
                breakpoints={{ lg: 1200 }}
                // breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                cols={{ lg: 48 }}
                onLayoutChange={onLayoutChange}
                // autoSize={true}
            >
                {chartComponents &&
            chartComponents !== undefined &&
            layouts.map( ( item ) => {
                const ChartComponent = chartComponents[item.i];
                // Conditional rendering to wait for ChartComponent data
                return ChartComponent ? (
                    <div key={item.i} style={{ position: "relative" }}>
                        <div
                            style={{
                                position: "relative",
                                display: "flex",
                                justifyContent: "space-between",
                                backgroundColor: "white",
                                padding: " 0 0.25rem",
                            }}
                        >
                            <div style={{ fontSize: "20px", color: "#757575" }}>
                                {titles[parseInt( item.i.replace( "chart", "" ) ) - 1]}
                            </div>
                            <span
                                className="btn btn-sm"
                                onClick={() => removeChart( item.i )}
                            >
                      X
                            </span>
                        </div>
                        <ChartComponent />
                    </div>
                ) : null;
            } )}
            </ResponsiveGridLayout> )} */}
        </div>
    );
};

export default DraggableAndResizeable;