import { createTheme, MuiThemeProvider, Paper } from '@material-ui/core';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import util from '../../../utils/miniUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import CreateEditWorkloadModal from './CreateEditWorkloadModal';
import DeleteWorkloadModal from './DeleteWorkloadModal';

const CatalogWorkloadList = ( { scId } ) => {
    const [workloads, setWorkloads] = useState( [] );
    const [loading, setLoading] = useState( false );
    const [showAddForm, setShowAddForm] = useState( false );
    const [showEditForm, setShowEditForm] = useState( false );
    const [showDeletedModal, setShowDeletedModal] = useState( false );
    const [workloadId, setWorkloadId] = useState( null );

    const getServiceCatalogWorkloads = async () => {
        setLoading( true );
        try {
            const request = await util.axios.get( `/workload_service_catalog/workloads/${scId}` );
            const { data, error, msg } = request.data;
            if( error ) throw msg;
            setWorkloads( data );
        } catch ( error ) {
            util.handleError( error );
        } finally {
            setLoading( false );
        }
    };

    const addWorkload = () => {
        setShowAddForm( true );
    };

    const editWorkload = ( e, id ) => {
        e.preventDefault();
        setWorkloadId( id );
        setShowEditForm( true );
    };

    const deleteWorkload = ( e, id ) => {
        e.preventDefault();
        setWorkloadId( id );
        setShowDeletedModal( true );
    };

    useEffect( () => {
        if( scId ) {
            getServiceCatalogWorkloads();
        }
    },[scId] );

    return  <Paper elevation={3}>
        <MuiThemeProvider theme={tableTheme}>
            <MaterialTable
                columns={[
                    { 
                        title: "Id",
                        field: "id",
                        width: "4%"
                    },
                    { 
                        title: "Priority",
                        field: "priority",
                    },
                    { 
                        title: "Workload Title",
                        field: "short_description",
                        render: rowData => <div className="isPointer" onClick={( e ) => {editWorkload( e, rowData.id );}}>{rowData.short_description}</div>,
                        cellStyle: {
                            backgroundColor: "#f0f0f0"
                        },
                    },
                    { 
                        title: "Description",
                        field: "long_description",
                    },
                    { 
                        title: "Group",
                        field: "groupName",
                    },
                    { 
                        title: "Category",
                        field: "categoryName",
                    },
                    { 
                        title: "Customer",
                        field: "customerName",
                    },
                    { 
                        title: "Resolver",
                        field: "resolverName",
                    },
                    {
                        title: "",
                        field: "id",
                        cellStyle: {
                            textAlign : "end",
                        },
                        render: rowData => <div>
                            <button className="btn btn-sm btn-outline-danger border-0 mr-1" onClick={( e ) => deleteWorkload( e, rowData.id )}>
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                            <button className="btn btn-sm btn-outline-primary border-0 mr-1" onClick={( e ) => {editWorkload( e, rowData.id );}}>
                                <FontAwesomeIcon icon={faEdit} />
                            </button>
                        </div>
                    },
                ]}
                data={workloads}
                isLoading={loading}
                options={{
                    showTitle: false,
                    paging: false,
                    searchFieldAlignment: "left",
                    toolbarButtonAlignment: "left",
                    rowStyle: {
                        overflowWrap: 'break-word'
                    },
                }}
                actions={[
                    {
                        icon: () => <span className='btn btn-sm btn-primary rounded-circle fs-1'>
                            <FontAwesomeIcon icon={faPlus} />
                        </span>,
                        tooltip: "Add Workload",
                        isFreeAction: true,
                        onClick: () => addWorkload(),
                    },
                ]}
            />
        </MuiThemeProvider>
        <CreateEditWorkloadModal 
            show={showAddForm}
            handleClose={() => {
                setShowAddForm( false );
                getServiceCatalogWorkloads();
                setWorkloadId( null );
            }}
            scId={scId}
            isEdit={false}
        />

        <CreateEditWorkloadModal 
            show={showEditForm}
            handleClose={() => {
                setShowEditForm( false );
                getServiceCatalogWorkloads();
                setWorkloadId( null );
            }}
            scId={scId}
            isEdit={true}
            workloadId={workloadId}
        />

        <DeleteWorkloadModal
            show={showDeletedModal}
            handleClose={() => {
                setShowDeletedModal( false );
                getServiceCatalogWorkloads();
                setWorkloadId( null );
            }}
            workloadId={workloadId}
            scId={scId}
        />
    </Paper>;
};

export default CatalogWorkloadList;

const tableTheme = createTheme( {
    palette: {
        primary: {
            main: '#2196F3',
        },
        secondary: {
            main: '#2196F3',
        }
    },
    overrides: {
        MuiPaper: {
            root: {
                padding: "0 6px",
            }
        },
        MuiTableCell: {
            root: {
                padding: 3,
                fontSize: 13,
                backgroundColor: "transparent"
            },
            head: {
                // textAlign:"center!important",
                textDecoration: "none",
                lineHeight: 1,
                backgroundColor: "#DFE1E0!important",
                color: "black"
            },
            body: {
                fontSize: "12px!important"
            }
        }
    }
} );