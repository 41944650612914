import React, { useState } from 'react';
import MaterialTable from 'material-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import CustomerDetail from './customerDetail';
import util from "../../utils/miniUtils";

const CustomTable = ( { data, total, status, setStatus, page, setPage, pageSize, setPageSize, setSearch, loading } ) => {
    const columns = [
        { title: 'ID', field: 'customer_company_id' },
        { title: 'Status', field: 'customer_Status' },
        { title: 'Name', field: 'customer_name' },
        { title: 'Email', field: 'customer_Email' },
        { title: 'Phone', field: 'customer_Phone' },
        {
            title: '',
            field: '',
            render: rowData => (
                <button className='btn  activeBtn sizeBtn btn-secondary' onClick={() => viewDetails( rowData )}>
                    
                    View
                </button>
            ),
        },
    ];
    const [selectedCustomer, setSelectedCustomer] = useState( null );

    const handleBackClick = () => {
        setSelectedCustomer( null );  
    };
  
    const addNewCustomer = () => {
        setSelectedCustomer( {} ); 
    };
  
    const handleSaveCustomer = async ( customer ) => {
        try {
            let response;
            if ( customer.customer_company_id ) {
                response = await util.axios.put( `/customer_companies/${customer.customer_company_id}`, customer );
                setSelectedCustomer( null );
            } else {
                response = await util.axios.post( `/customer_companies`, customer );
                handleBackClick();
            }
            const { error } = response.data;
            if ( error ) {
                throw new Error( error.message || "Error occurred while saving the customer." );
            }
           
        } catch ( error ) {
            util.handleError( error );
        }
       
    };
    
    const viewDetails = ( data ) => {
    // Logic to handle view details
        setSelectedCustomer( data );  
    };


    return (
        <>
            {selectedCustomer ? (
                <CustomerDetail
                    customer={selectedCustomer}
                    onBackClick={handleBackClick}
                    onSave={handleSaveCustomer}/>
            ) : (
                <>
                    <div className='d-flex mt-3'>  <button onClick={addNewCustomer} className="btn btn-primary mb-3 mr-3">
                        <FontAwesomeIcon icon={faPlus} /> Add  Customer
                    </button>
                    <div style={{ width: '15%' }}>
                        <select className="form-control" style={{ borderRadius: '5px' }} placeholder="Status"  value={status} onChange={( e ) => setStatus(  e.target.value )}>
                            <option value="All Status">All Status</option>
                            <option value="Active">Active</option>
                            <option value="At Risk">At Risk</option>
                            <option value="Blacklisted"> Blacklisted</option>
                            <option value="Churned"> Churned</option>
                            <option value="Closed"> Closed</option>
                            <option value="Inactive">InActive</option>
                            <option value="Onboarding">Onboarding</option>
                            <option value="Prospective">Prospective</option>
                            <option value="Renewed">Renewed</option>
                            <option value="Suspended">Suspended</option>
                        </select></div></div>
                    <MaterialTable
                        className="company-list-table"
                        title=""
                        columns={columns}
                        data={data}
                        options={{
                            searchFieldAlignment: "left",
                            search: true,
                            selection:true,
                            exportButton: false,
                            paging: true, 
                           
                            sorting: true,
                            pageSize: pageSize,
                            pageSizeOptions: [20, 50, 100], 
                            actionsColumnIndex: -1,
                            
                            maxBodyHeight: 'calc(100vh - 320px)',
                        }}
                        isLoading={loading}
                        page={page}
                        totalCount={total}
                        onChangePage={newPage => {
                            setPage( newPage );
                        }}
                        onChangeRowsPerPage={( pageSize ) => {
                            setPageSize( pageSize );
                            setPage( 0 ); 
                        }}
                        onSearchChange={query => {
                            setSearch( query );
                            setPage( 0 );
                        }}                       
                    />
                 
                </> )}
        </>
    );
};

export default CustomTable;
