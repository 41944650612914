import { Chip, Drawer, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ReactPlayer from "react-player";
import util from '../../../utils/miniUtils';
import ImageComponent from "../../../utils/ImageComponent";
import { faEnvelope, faGlobe, faMapMarkedAlt, faMoneyBill, faMoneyBillAlt, faPhone, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DocumentViewer from '../../../utils/DocumentViewer';

const TalentDrawer = ( { show, handleClose, data } ) => {
    const [talentState, setTalentState] = useState( { id: null } );
    // const [open, setOpen] = useState( false );
    const [skills, setSkills] = useState( [] );
    const [isPlaying, setIsPlaying] = useState( false );
    const handlePlayPause = () => {
        setIsPlaying( !isPlaying );
    };


    const getCandidateInformation = async ( id ) => {
        try {
            const response = await util.axios.get( `/candidates/${id}` );
            let { error, msg, candidate } = response["data"];
            setTalentState( candidate );
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error ); 
        }
    };

    const getCandidateSkills = async ( candidate_id ) => {
        try {
            const response = await util.axios.get( `/talent/skill_list/${candidate_id}` );
            let { error, msg, data } = response["data"];
            setSkills( data );
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const isProfilePicAvailable = async ( profile_picture, id ) => {
        let url;
        if ( !util.noValues.includes( profile_picture ) ) {
            url = "src/public/candidate/" + id + "/" + profile_picture;
        } else {
            url = "/images/avatar.svg";
        }
        try {
            const imageUrl = await util.fetchImageUrl( url );
            const profilePictureElement = document.getElementById( 'candidateProfilePicture' );
            if ( profilePictureElement ) {
                profilePictureElement.src = imageUrl || "/images/avatar.svg";
            }
        } catch ( error ) {
            console.error( 'Error setting profile picture:', error );
        }
    };

    useEffect( () => {
        getCandidateInformation( data.candidate_id );
        getCandidateSkills( data.candidate_id );
    }, [data] );

    useEffect( () => {
        if( talentState.id ) {
            isProfilePicAvailable( talentState.profile_picture, talentState.id );
        }
    }, [talentState] );

    if( !talentState.id ) {
        return null;
    }

    return (
        <Drawer anchor="right" open={show} onClose={handleClose}>
            <div className="container pt-3" style={{ width: .45 * window.innerWidth,  }}>
                <a
                    href={`/candidates/${data.candidate_id}?jp=true&job_id=${data.position_id}`}
                    target="_blank" rel="noopener noreferrer"
                    className='text-light'
                >
                    <button className='btn btn-sm btn-secondary activeBtn sizeBtn mb-1'>   
                 View more...
                    </button>
                </a>
                {/* Intro video */}
                {talentState.video_profile ?
                    <ReactPlayer
                        url={talentState.video_profile}
                        width="100%"
                        height="15rem"
                        controls
                        playing={isPlaying}
                        onPlay={handlePlayPause}
                        onPause={handlePlayPause}
                    /> : <div>
                        <strong>Video profile is not added!!</strong>
                    </div>}

                <div className="w-100 h-screen">
                    <div className="px-3 py-2 card my-1" id="personal-details-card" style={{
                        alignItems: "center",
                        justifyContent: "space-around"
                    }}>
                        <div className="d-flex text-start align-items-center">
                            <div>
                                <div>
                                    <img
                                        width="150px"
                                        height="150px"
                                        alt="Profile"
                                        id="candidateProfilePicture"
                                        className="candidateprofile_picture"
                                        src=""
                                    />
                                </div>
                                <div className='text-center'>{talentState.name}</div>
                            </div>
                            <div className="text-start">
                                <Tooltip title="Job Title" placement="top">
                                    <div>
                                        {!!Object.keys( talentState ).length && 
                                    talentState.jobTitles.length ? 
                                            talentState.jobTitles.map( el => <span key={el.id}>{el.name}</span> ) : ""}
                                    </div>
                                </Tooltip>

                                <Tooltip title="Email" placement="top">
                                    <div>
                                        <FontAwesomeIcon icon={faEnvelope} className="iconGray mr-1"/>
                                        <a href={`mailto:${talentState.email}`}>{talentState.email}</a>
                                    </div>
                                </Tooltip>

                                <Tooltip title="Phone" placement="top">
                                    <div>
                                        <FontAwesomeIcon icon={faPhone} className="iconGray mr-1"/>
                                        <a href={`tel:${talentState.phone_mobile}`}>{util.formatPhone( talentState.phone_mobile )}</a>
                                    </div>
                                </Tooltip>
                                <Tooltip title="Location" placement="top">
                                    <div>
                                        <FontAwesomeIcon icon={faMapMarkedAlt} className="iconGray mr-1"/>
                                        <span>{util.location( talentState.country, talentState.state, talentState.city )}</span>
                                    </div>
                                </Tooltip>
                                
                                <Tooltip title="Relocation" placement="top">
                                    <div>
                                        <FontAwesomeIcon icon={faGlobe} className="iconGray mr-1" />
                                        <span className='mr-1 text-secondary'>Relocation:</span>
                                        <span>{talentState.relocation ? "Yes": "No"}</span>
                                    </div>
                                </Tooltip>

                                {talentState.noticePeriod ? <Tooltip title="Relocation" placement="top">
                                    <div>
                                        <FontAwesomeIcon icon={faSignOutAlt} className="iconGray mr-1" />
                                        <span className='mr-1 text-secondary'>Notice Period:</span>
                                        <span>{talentState.noticePeriod}</span>
                                    </div>
                                </Tooltip> : ""}

                                {talentState.ctc ? <Tooltip title="Expected CTC" placement="top">
                                    <div>
                                        <FontAwesomeIcon icon={faMoneyBillAlt} className="iconGray mr-1" />
                                        <span className='mr-1 text-secondary'>Expected CTC:</span>
                                        <span>{util.currencyFormat( talentState.ctc, talentState.country )}</span>
                                    </div>
                                </Tooltip> : ""}

                                {talentState.current_salary ? <Tooltip title="Current Salary" placement="top">
                                    <div>
                                        <FontAwesomeIcon icon={faMoneyBill} className="iconGray mr-1" />
                                        <span className='mr-1 text-secondary'>Current Salary:</span>
                                        <span>{util.currencyFormat( talentState.current_salary, talentState.country )}</span>
                                        <span className="ml-1">{talentState.salary_mode}</span>
                                    </div>
                                </Tooltip> : ""}
                            </div>
                        </div>
                    </div>

                    <div className="mt-2" id="skills">
                        <h5>Skills:</h5>
                        <div className='d-flex flex-wrap'>
                            {skills.length ? skills.map( el => <Chip
                                key={el.catalog_skill_id}
                                className="mr-1 mb-1" 
                                label={el.skill_name} /> ) : ""}
                            {talentState.skillsSet ? <Chip
                                className="mr-1 mb-1" 
                                label={talentState.skillsSet} /> : ""}
                        </div>
                    </div>

                    {talentState.workHistory.length > 0 ? <div id="experience" className='text-start w-100'>
                        <h5>Experience:</h5>
                        {talentState.workHistory.map( el => <div className="card mb-1 d-flex" key={el.id}>
                            <div className='card-body'>
                                <h5 className="card-title">{el.position}</h5>
                                <p className="card-subtitle text-secondary">{el.company}</p>
                                <p className="card-subtitle mb-2 text-secondary">{`${el.from} to ${el.to ? el.to : "Present"}`}</p>
                                <p className="card-text">{el.desc}</p>
                            </div>
                        </div> )}
                    </div> : <div>
                        <strong>Experience is not added!!</strong>
                    </div>}
                    
                    {talentState.resume ? <div>
                        <a href={util.getResume( talentState )} target="__blank" rel="noreferrer">
                           Download Resume
                        </a>
                        {( util.getResume( talentState ).split( '.' ).pop().toLowerCase() === "pdf" ? 
                            <DocumentViewer
                                fileType="pdf"
                                filePath={util.getResume( talentState )}
                            /> : 
                            <DocumentViewer
                                fileType="docx"
                                filePath={util.getResume( talentState )}
                            />
                        )}
                    </div>: <div><strong>Resume is not added!!</strong></div>}
                </div>
            </div>
        </Drawer>
    );
};

export default TalentDrawer;