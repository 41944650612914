import React, { Fragment, lazy, Suspense, useEffect, useState } from "react";
import "../../assets/css/main.css";
import "../../assets/css/global.css";
import "../../assets/css/style.css";
import "../../components/workload/style.css";
import "../../assets/css/responsive.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faBell,
    faKey,
    faSignOutAlt,
    faUser,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import util from "../utils/miniUtils";
import { Dropdown } from "react-bootstrap";
import { bake_cookie, delete_cookie } from "sfcookies";
import Axios from "axios";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField  } from "@material-ui/core";
import CryptoJS from 'crypto-js';
import Dialog from "@material-ui/core/Dialog";
import NotificationDropdown from "../shared/CustomizeNotificactionModal/NotificationDropdown";
import NotificationModal from "../shared/CustomizeNotificactionModal/NotificationModal";
import { Tooltip } from '@material-ui/core';
import $ from "jquery";
import { Route, Switch, NavLink, Redirect } from "react-router-dom";
import AppSettings from "../settings/AppSettings";
import Dashboard from "../dashboard";
import PositionDetail from "../jobs/components/PositionDetail";
const VerifyEmail = lazy( () => import( "./VerifyEmail" ) );
const Requirements = lazy( () => import( "../jobs" ) );
const NewRequirement = lazy( () => import( "../jobs/components/NewRequirement" ) );
const CandidateDetail = lazy( () => import( "../talents/components/detailComponents/CandidateDetail" ) );

function SuspenseWrapper( { children } ) {
    return (
        <Suspense fallback={util.LOADING_SCREEN( true )}>
            {children}
        </Suspense>
    );
}

const Employer = ( props ) => {
    const [showNotificationModal, setNotificationModal] = useState( false );
    const [showModal, setShowModal] = useState( false );
   
    useEffect( () => {
        const main = document.getElementById( "main" );
        const initialWidth = window.innerWidth;

        if ( initialWidth < 500 ) {
            $( ".sidebarClass" ).toggleClass( "open" );
            $( "nav" ).toggleClass( "open" );
            main.style.width = "calc(100% - 220px)";
            main.style.width = "100%";
        }

        let width = window.innerWidth;

        $( ".sidebarClass a" ).on( "click", () => {
            if ( width < 500 ) {
                $( ".navbar-toggle" ).trigger( "click" );
            }
        } );
        $( function () {
            $( ".navbar-toggle" ).click( function () {
                $( ".sidebarClass" ).toggleClass( "open" );
                $( "nav" ).toggleClass( "open" );
                if ( width < 500 ) {
                    document.getElementById( "nav" ).className.search( "open" ) === -1
                        ? ( main.className = "invisible" )
                        : ( main.className = "visible" );
                } else {
                    document.getElementById( "nav" ).className.search( "open" ) === -1
                        ? ( main.style.width = "calc(100% - 220px)" )
                        : ( main.style.width = "calc(100% - 70px)" );
                }
            } );
        } );
    }, [] );

    const links = [
        // {
        //     title: "Dashboard",
        //     icon: "Dashboard",
        //     url: "/dashboard",
        // },
        {
            title: "Jobs",
            icon: "Jobs",
            url: "/jobs",
        },
        {
            title: "Settings",
            icon: 'grear',
            url: '/settings'
        },
    ];

    // async function changeRole( selectedRole ) {
    //     try {
    //         let role = 2;
    //         switch ( selectedRole ) {
    //             case "employerRecruiter":
    //                 role = 16;
    //                 break;
    //             case "employerAdmin":
    //                 role = 17;
    //                 break;
    //             default:
    //                 break;
    //         }
    //         let u = util.user;
    //         u.role = role;

    //         const request = await Axios.put( `${util.url}/auth/change_user_role`, {
    //             user: u,
    //         } );
    //         const { error, msg, accessToken } = request.data;
    //         if ( error ) throw msg;
    //         bake_cookie( "tokenServer", accessToken );
    //         window.history.replaceState( "", "", "/" );
    //         window.location.reload();
    //     } catch ( e ) {
    //         util.handleError( e );
    //     }
    // }

    function handleClose() {
        setShowModal( false );
    }

    function handleCloseNotificationModal() {
        setNotificationModal( false );
    }

    // const deleteAccount = async () => {
    //     try {
    //         const request = await util.axios.get( `/delete_data` );
    //         const { error, msg } = request.data;
    //         util.logOut();
    //         if ( error ) throw msg;
    //     } catch ( error ) {
    //         util.handleError( error );
    //     }
    // };

    const OnLogout = async( ) => {
        try {
            let userId = {
                id: util.user.id
            };
            const request = await util.axios.post( `/candidates/logout`,userId );
            const { error, msg } = request.data;
            if( error ) throw new Error( msg );
            
        } catch ( error ) {
            util.handleError( error );
        }
    };

    async function logoutYa() {
        await OnLogout();
        localStorage.removeItem( 'effect' );
        delete_cookie( "tokenServer" );
        props.logOut();
    }

    // function AvailableRole( { selectedRole, role } ) {
    //     if ( util.user.permissions[selectedRole] === 1 ) {
    //         return (
    //             <Dropdown.Item>
    //                 <div
    //                     onClick={() =>
    //                         changeRole( selectedRole, util.user.permissions.recruiter )
    //                     }
    //                 >
    //                     {role}
    //                 </div>
    //             </Dropdown.Item>
    //         );
    //     } else {
    //         return "";
    //     }
    // }

    return (
        <>
            <nav id="nav" className="navbar navbar-default navbar-fixed">
                <div className="container-fluid">
                    <div className="d-inline-flex">
                        <button
                            type="button"
                            className="navbar-toggle collapsed"
                            data-toggle="collapse"
                            data-target="#bs-example-navbar-collapse-1"
                            aria-expanded="false"
                        >
                            <FontAwesomeIcon color="#757575" icon={faBars} />
                        </button>
                    </div>
                    <div className="d-inline-flex">
                        {/* { util.user.customer_company_id && util.user.customer_company_id == 183 ? (
                                <span className="">
                                    <img src="/images/encore.svg" height="40" />
                                </span> ) : ""} */}
                        {/* <CustomerCompanyLogo /> */}
                    </div>
                    <div className="d-inline-flex">
                        {/* <Notifications /> */}
                        {util.user.role === 2 ? "" : <NotificationDropdown />}
                        <div>
                            <span className="text-black-50 mr-3">
                                {util.humanRole( util.user.role )}
                            </span>
                        </div>
                        <Dropdown>
                            <Dropdown.Toggle
                                variant="secondary"
                                size="sm"
                                id="dropdown-basic"
                            >
                                <FontAwesomeIcon color="#007bff" icon={faUser} />
                                <span className="ml-1 dropDownBtnText">
                                    {" "}
                                        Hi, {util.user.name}
                                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="mainDropdown">
                                <Dropdown.Item>
                                    <div onClick={() => setNotificationModal( true )}>
                                        <FontAwesomeIcon icon={faBell} className="mr-2" /> Notifications
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item>
                                    <div onClick={() => setShowModal( true )}>
                                        <FontAwesomeIcon icon={faKey} className="mr-2" /> Change
                                            password
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item>
                                    <div onClick={logoutYa}>
                                        <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />{" "}
                                            Logout
                                    </div>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </nav>
            <div className="sidebarClass">
                <div className="logo w-100">
                    <a href="/">
                        <img
                            height={50}
                            src="/images/ejem_logo.webp"
                            className="desktop"
                            alt="eJRekruit"
                            loading="lazy"
                        />
                        <img
                            height={50}
                            src="/images/ej_logo.webp"
                            className="mobile"
                            alt="logo"
                        />
                    </a>
                </div>
                <ul>
                    {
                        links.map( ( item ) => {
                            return (
                                <li key={item.title}>
                                    <NavLink to={item.url}>
                                        <i
                                            className={item.title + " icon"}
                                            style={{
                                                backgroundImage:
                                                                "url('/images/" + item.icon + ".svg')",
                                                color: "gray"
                                            }}
                                        />
                                        <Tooltip title={item.desc ? item.desc : ""}>
                                            <span>{item.title}</span>
                                        </Tooltip>
                                    </NavLink>
                                </li>
                            );
                        } )}
                </ul>
                <div className="versionOfApp">App Version v1.2.25</div>
            </div>
            <main id="main" className="main px-2">
                <SuspenseWrapper>
                    <Switch>
                        <Route exact path="/verify_email">
                            <VerifyEmail />
                        </Route>
                        <Route exact path="/jobs">
                            <Requirements />
                        </Route>
                        <Route exact path="/jobs/new">
                            <NewRequirement />
                        </Route>
                        <Route exact path="/jobs/position/detail/:id" component={PositionDetail} />
                        <Route exact path="/candidates/:id">
                            <CandidateDetail />
                        </Route>
                        <Route exact path="/profile/:id">
                            <Redirect to="/candidates/:id" />
                        </Route>
                        <Route exact path="/">
                            <Redirect to="/jobs" />
                        </Route>
                        <Route exact path="/settings">
                            <AppSettings />
                        </Route>
                        <Route exact path="/dashboard">
                            <Dashboard />
                        </Route>
                    </Switch>
                </SuspenseWrapper>
            </main>
            <ChangePasswordModal
                show={showModal}
                handleClose={handleClose}
                backDropOpen={() => null}
                backDropClose={() => null}
            />
            <NotificationModal
                show={showNotificationModal}
                handleClose={handleCloseNotificationModal}
            />
        </>
    );
};

export default Employer;

function ChangePasswordModal( props ) {
    const [currentPassword, setCurrentPassword] = useState( "" );
    const [password, setPassword] = useState( "" );
    const [passwordConfirm, setPasswordConfirm] = useState( "" );
    const [loading, setLoading] = useState( false );

    async function changePasswordRequest() {
        try {
            if ( currentPassword === "" || password === "" || passwordConfirm === "" ) {
                util.toast().warn( "Please fill all the fields" );
                return;
            }
            if ( password.length <= 7 || passwordConfirm.length <= 7 ) {
                util.toast().warn( "Password must at least 8 characters." );
                return;
            }
            if ( password !== passwordConfirm ) {
                util
                    .toast()
                    .warn( "New password and password confirmation are not equal" );
                return;
            }
            props.backDropOpen();
            setLoading( true );
            let data = {
                user_id: util.user.id
            };
            // data.password = new Buffer( password ).toString( 'base64' );
            // data.current_password = new Buffer( currentPassword ).toString( 'base64' );
            data.password = CryptoJS.AES.encrypt( password, process.env.REACT_APP_PASS_KEY ).toString();
            data.current_password = CryptoJS.AES.encrypt( currentPassword, process.env.REACT_APP_PASS_KEY ).toString();
            const request = await util.axios.put(
                `${util.url}/users/update_password`,
                data
            );
            const { error, msg } = request.data;
            util.toast().success( msg );
            if ( error ) throw msg;
            props.backDropClose();
            setLoading( false );
            // // Clean the fields
            setCurrentPassword( "" );
            setPassword( "" );
            setPasswordConfirm( "" );
        } catch ( e ) {
            props.backDropClose();
            setLoading( false );
            util.handleError( e );
        }
    }

    function onChangeHandler( e ) {
        const { name, value } = e.target;
        if ( name === "currentPassword" ) setCurrentPassword( value );
        if ( name === "password" ) setPassword( value );
        if ( name === "passwordConfirm" ) setPasswordConfirm( value );
    }

    return (
        <Fragment>
            <Dialog
                open={props.show}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Change your password"}
                </DialogTitle>
                {loading ? (
                    <div style={{ width: "100%", height: "100px" }}>
                        <p>Changing password</p>
                    </div>
                ) : (
                    <div>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                This action will send you a email with a reset password link. We
                                will send this email to the registered account.
                            </DialogContentText>
                            <div className="text-center">
                                <TextField
                                    label="Current password"
                                    type="password"
                                    className="w-50"
                                    name="currentPassword"
                                    value={currentPassword}
                                    onChange={onChangeHandler}
                                />
                            </div>
                            <div className="text-center">
                                <TextField
                                    label="New Password"
                                    type="password"
                                    className="w-50"
                                    name="password"
                                    value={password}
                                    onChange={onChangeHandler}
                                />
                            </div>
                            <div className="text-center">
                                <TextField
                                    label="Password confirmation"
                                    type="password"
                                    className="w-50"
                                    name="passwordConfirm"
                                    value={passwordConfirm}
                                    onChange={onChangeHandler}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={props.handleClose} color="default">
                                Cancel
                            </Button>
                            <button
                                onClick={() => changePasswordRequest()}
                                className="btn btn-md btn-primary"
                            >
                                Change Password
                            </button>
                        </DialogActions>
                    </div>
                )}
            </Dialog>
            {util.LOADING_SCREEN( loading )}
        </Fragment>
    );
}