import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import util from '../../utils/miniUtils';
import ImageComponent from '../../utils/ImageComponent';


const CustomerLogo = ( { customerDetails } ) => {
    const [selectedFile, setSelectedFile] = useState( null );
    // Ensure customerDetails is defined to avoid undefined errors
    const defaultImage = "/images/avatar.webp"; // Path to your default image
    const [imageSrc, setImageSrc] = useState( defaultImage );

    const initialLogo = customerDetails?.customer_Logo ? 
    `${util.url}/customer_company/${customerDetails.customer_company_id}/${customerDetails.customer_Logo}` : 
        null;

    useEffect( () => {
        const uploadFile = async () => {
            if ( !selectedFile ) return;

            const formData = new FormData();
            formData.append( 'file', selectedFile );
            if ( customerDetails?.customer_company_id ) {
                formData.append( 'customer_company_id', customerDetails.customer_company_id );
            }
            try {
                const response = await util.axios.post( `/customer_companies/upload_logo`, formData );
                const { error, msg, data } = response.data;
                if ( error ) throw new Error( msg );
                const logoURL = data.customer_Logo 
                    ? `${util.url}/customer_company/${util.user.customer_company_id}/${data.customer_Logo}`
                    : defaultImage;
                setImageSrc( logoURL );
                util.toast().success( msg );
            } catch ( error ) {
                util.handleError( error );
                setImageSrc( defaultImage ); // Fallback to default image on error
            }
        };

        uploadFile();
    }, [selectedFile] );

    const handleFileChange = ( event ) => {
        const file = event.target.files[0];
        if ( file ) {
            setSelectedFile( file );
            setImageSrc( URL.createObjectURL( file ) ); // Update the image preview immediately
        }
    };

    const handleError = () => {
        setImageSrc( defaultImage ); // Fallback to default image on error
    };

    return (
        <div className='d-flex align-items-center'>
            <label>
                <input
                    type='file'
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
                <Button as="span" className="p-0" style={{ background: 'none', border: 'none' }}>
                    <img 
                        src={imageSrc} 
                        style={{ height: '40px', width: '40px', marginRight: '15px' }} 
                        onError={handleError}
                    />
                </Button>
            </label>
            <h4>{customerDetails?.customer_name || ""}</h4>
        </div>
    );
};

export default CustomerLogo;
