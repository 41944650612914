import { useEffect, useState } from "react";
import util from "../../../../utils/miniUtils";
import {
    CONTRACTOR,
    CORP_TO_CORP,
    DAILY,
    DAY,
    FULLTIME_EMPLOYEE,
    HOUR,
    HOURLY,
    MONTH,
    MONTHLY,
    WEEKLY,
} from "./components/constants";

export default function useCtcCalculation(talent, jobTemp, reloadData) {
    const [ctc, setCTC] = useState(talent.ctc_settings); // All related with calculations of the talent ctc
    const [ctcBase, setCTCBase] = useState(talent.ctc_settings); // All related with calculations of the talent ctc
    const [job] = useState(jobTemp); // Need JOB data for job title
    const [jobSettings] = useState(jobTemp.ctc_settings); // CTC settings on this particular job
    const [showSaveButton, setShowSaveButton] = useState(false);

    const selectSection = (section) => {
        setCTC((prev) => {
            return { ...prev, mode: section };
        });
    };

    const onChange = (e) => {
        let { name, value } = e.target;
        // debugger
        // Allow only numbers and 1 "."
        if (
            [
                "ejOffer",
                "additionalDays",
                "sellRateValue",
                "sell_margin",
                "talentExpectation",
                "additionalInputCost"
            ].includes(name)
        ) {
            if (value === "") {
                value = "";
                setCTC((prev) => {
                    return { ...prev, [name]: value };
                });
            } else {
                const digitsOnly = (string) =>
                    [...string].every((c) => "0123456789.".includes(c));
                if (!digitsOnly(value)) {
                    value = "";
                } else {
                    setCTC((prev) => {
                        return { ...prev, [name]: value };
                    });
                }
            }
        } else {
            setCTC((prev) => {
                return { ...prev, [name]: value };
            });
        }
    };

    const onChangeSlider = (e, value) => {
        let tempCtc = { ...ctc };
        tempCtc.sell_margin = value;
        recalculateByMargin(tempCtc, true);
    };

    useEffect(() => {
        if (ctc && ctcBase) {
            if (
                ctc.additionalDays?.toString() !== ctcBase.additionalDays?.toString() ||
                ctc.sell_margin?.toString() !== ctcBase.sell_margin?.toString() ||
                ctc.employmentType.toString() !== ctcBase.employmentType.toString() ||
                ctc.offerType.toString() !== ctcBase.offerType.toString() ||
                ctc.talentExpectation?.toString() !==
                ctcBase.talentExpectation?.toString() ||
                ctc.ejOffer.toString() !== ctcBase.ejOffer.toString()
            ) {
                setShowSaveButton(true);
            } else {
                setShowSaveButton(false);
            }
        }
        // eslint-disable-next-line
    }, [
        ctc.employmentType,
        ctc.offerType,
        ctc.ejOffer,
        ctc.talentExpectation,
        ctc.sell_margin,
        ctc.additionalDays,
    ]);

    useEffect(() => {
        // console.log("changes on ejOffer");
        recalculateOnEJOfferChange();
    }, [ctc.ejOffer, ctc.additionalDays, ctc.employmentType, ctc.offerType, ctc.additionalInputCost]);

    useEffect(() => {
        // console.log("changes on sellRateValue & offerTypeEstimate");
        recalculateMarginBySellRate(null, true);
    }, [ctc.sellRateValue, ctc.offerTypeEstimate]);

    const currentJobSettings = () => {
        let index = 0;
        switch (ctc.employmentType) {
            case FULLTIME_EMPLOYEE:
                index = 0;
                break;
            case CONTRACTOR:
                index = 1;
                break;
            case CORP_TO_CORP:
                index = 2;
                break;
            default:
            // console.log( ctc.employmentType );
        }
        return jobSettings.data[index];
    };

    const recalculateOnEJOfferChange = () => {
        // Recalculate CTC values and margins on by sell rate and by margin
        if (job === null) return "";
        const { ejOffer } = ctc;

        let {
            markUp,
            workingHoursInDay,
            averageBillableDaysInMonth,
            billableDaysInYear,
        } = currentJobSettings();

        const additionalDays = parseInt(ctc.additionalDays);
        const additionalInputCost = parseInt(ctc.additionalInputCost);
        if (!util.noValues.includes(additionalDays)) {
            billableDaysInYear = billableDaysInYear - additionalDays;
        }
        if (!util.noValues.includes(additionalInputCost)) {
            billableDaysInYear = billableDaysInYear - (additionalInputCost / ctc.ctcPerDay);
        }
        // Variables
        let hour;
        let day;
        let month;
        let year;
        if (ctc.employmentType === "Fulltime Employee") {
            // Detect type of employmentType
            // ByDayFullTime
            day = parseFloat(
                (ejOffer * (1 + parseFloat(markUp) / 100)) /
                parseFloat(billableDaysInYear)
            );
            hour = day / parseFloat(workingHoursInDay);
            month = day * parseFloat(averageBillableDaysInMonth);
            year = 12 * month;
        } else {
            switch (ctc.offerType) {
                case HOUR:
                    // By Hour
                    hour = ejOffer * (1 + parseFloat(markUp) / 100);
                    day = parseFloat(hour * parseFloat(workingHoursInDay));
                    month = parseFloat(day * parseFloat(averageBillableDaysInMonth));
                    year = 12 * month;
                    break;
                case DAY:
                    // By Day / =(1+ markOp)*ejOffer
                    day = parseFloat(ejOffer * (1 + parseFloat(markUp) / 100));
                    hour = day / parseFloat(workingHoursInDay);
                    month = day * parseFloat(averageBillableDaysInMonth);
                    year = 12 * month;
                    break;
                case MONTH:
                    // By MOnth
                    month = parseFloat(ejOffer * (1 + parseFloat(markUp) / 100));
                    day = parseFloat(month / parseFloat(averageBillableDaysInMonth));
                    hour = day / parseFloat(workingHoursInDay);
                    year = 12 * month;
                    break;
                default:
                // console.log( "ctc.offerType unknown" );
            }
        }

        let tempCTCs = {
            ctcPerHour: hour,
            ctcPerDay: day,
            ctcPerMonth: month,
            ctcPerAnnum: year,
        };
        // Calculate By Sell Rate
        const {
            sell_margin,
            sellRatePerHour,
            sellRatePerDay,
            sellRatePerMonth,
            sellRatePerAnnum,
        } = recalculateMarginBySellRate(tempCTCs, false);
        // Update directly the ctc state
        setCTC((prev) => {
            return {
                ...prev,
                ctcPerHour: hour,
                ctcPerDay: day,
                ctcPerMonth: month,
                ctcPerAnnum: year,
                workingHoursInDay: workingHoursInDay,
                // By Sell Rate
                sell_margin,
                sellRatePerHour,
                sellRatePerDay,
                sellRatePerMonth,
                sellRatePerAnnum,
            };
        });
    };

    function recalculateMarginBySellRate(data, localFlow) {
        let tempCTCs;
        if (localFlow) {
            tempCTCs = ctc;
        } else {
            tempCTCs = data;
        }
        const byHour =
            ((parseFloat(ctc.sellRateValue) - parseFloat(tempCTCs.ctcPerHour)) /
                parseFloat(ctc.sellRateValue)) *
            100;
        const byDay =
            ((parseFloat(ctc.sellRateValue) - parseFloat(tempCTCs.ctcPerDay)) /
                parseFloat(ctc.sellRateValue)) *
            100;
        const byMonth =
            ((parseFloat(ctc.sellRateValue) - parseFloat(tempCTCs.ctcPerMonth)) /
                parseFloat(ctc.sellRateValue)) *
            100;
        const byWeekly =
            ((parseFloat(ctc.sellRateValue / 5) - parseFloat(tempCTCs.ctcPerHour)) /
                parseFloat(ctc.sellRateValue / 5)) *
            100;

        let finalValue = ctc.sell_margin;
        switch (ctc.offerTypeEstimate) {
            case HOUR:
                finalValue = byHour;
                break;
            case HOURLY:
                finalValue = byHour;
                break;
            case DAY:
                finalValue = byDay;
                break;
            case DAILY:
                finalValue = byDay;
                break;
            case MONTH:
                finalValue = byMonth;
                break;
            case MONTHLY:
                finalValue = byMonth;
                break;
            case WEEKLY:
                finalValue = byWeekly;
                break;
            default:
            // console.log( "Unknown offerTypeEstimate" );
        }
        let sell_margin = finalValue;
        //**************************************/
        let sellRatePerHour = tempCTCs.ctcPerHour / (1 - finalValue / 100);
        let sellRatePerDay = tempCTCs.ctcPerDay / (1 - finalValue / 100);
        let sellRatePerMonth = tempCTCs.ctcPerMonth / (1 - finalValue / 100);
        let sellRatePerAnnum = tempCTCs.ctcPerAnnum / (1 - finalValue / 100);
        //**************************************/

        // Validate integrity of data
        if (isNaN(sellRatePerHour)) sellRatePerHour = 0;
        if (isNaN(sellRatePerDay)) sellRatePerDay = 0;
        if (isNaN(sellRatePerMonth)) sellRatePerMonth = 0;
        if (isNaN(sellRatePerAnnum)) sellRatePerAnnum = 0;
        if (isNaN(sell_margin)) sell_margin = 0;

        if (localFlow) {
            // In local flow it will set state
            setCTC((prev) => {
                return {
                    ...prev,
                    sell_margin,
                    sellRatePerHour,
                    sellRatePerDay,
                    sellRatePerMonth,
                    sellRatePerAnnum,
                };
            });
        } else {
            return {
                sell_margin,
                sellRatePerHour,
                sellRatePerDay,
                sellRatePerMonth,
                sellRatePerAnnum,
            };
        }
    }

    function recalculateByMargin(tempCTCs, localFlow) {
        let sellRatePerHour =
            tempCTCs.ctcPerHour / (1 - tempCTCs.sell_margin / 100);
        let sellRatePerDay = tempCTCs.ctcPerDay / (1 - tempCTCs.sell_margin / 100);
        let sellRatePerMonth =
            tempCTCs.ctcPerMonth / (1 - tempCTCs.sell_margin / 100);
        let sellRatePerAnnum =
            tempCTCs.ctcPerAnnum / (1 - tempCTCs.sell_margin / 100);

        //**************************************
        let value = 0;
        //**************************************

        switch (tempCTCs.offerTypeEstimate) {
            case "Hour":
                value = sellRatePerHour;
                break;
            case "Day":
                value = sellRatePerDay;
                break;
            case "Month":
                value = sellRatePerMonth;
                break;
            default:
            // console.log( "offerTypeEstimate" );
        }

        tempCTCs.sellRateValue = value ? value.toFixed(2) : 0.0;
        tempCTCs.sellRatePerHour = sellRatePerHour;
        tempCTCs.sellRatePerDay = sellRatePerDay;
        tempCTCs.sellRatePerMonth = sellRatePerMonth;
        tempCTCs.sellRatePerAnnum = sellRatePerAnnum;

        if (localFlow) {
            setCTC(Object.assign({}, tempCTCs));
        } else {
            return {
                sellRateValue: value ? value.toFixed(2) : 0.0,
                sellRatePerHour,
                sellRatePerDay,
                sellRatePerMonth,
                sellRatePerAnnum,
            };
        }
    }

    const saveChangesOnMarginEstimate = async () => {
        try {
            // Prepare object for DB
            const request = await util.axios.put(
                `job_position/talent/update_ctc_settings/${jobTemp.id}/${talent.id}`,
                ctc
            );
            const { error, msg } = request.data;
            if (error) throw msg;
            util.toast().success(msg);
            setCTCBase(() => ctc);
            setShowSaveButton(false);
            reloadData();
        } catch (e) {
            util.handleError(e);
        }
    };

    const saveComments = async (newCommentsData) => {
        try {
            // Prepare object for DB
            let ctcTemporal = { ...ctc };
            ctcTemporal.comments = newCommentsData;
            setCTC((prev) => {
                return {
                    ...prev,
                    comments: newCommentsData
                };
            });
            const request = await util.axios.put(
                `job_position/talent/update_ctc_settings/${jobTemp.id}/${talent.id}`,
                ctcTemporal
            );
            const { error, msg } = request.data;
            if (error) throw msg;
            setCTCBase(() => ctc);
            setShowSaveButton(false);
            reloadData();
        } catch (e) {
            util.handleError(e);
        }
    };

    return {
        ctc,
        onChange,
        onChangeSlider,
        selectSection,
        currentJobSettings,
        showSaveButton,
        saveChangesOnMarginEstimate,
        saveComments
    };
}