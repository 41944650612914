import React from 'react';
import { Fragment } from "react";
import { InputGroup } from 'react-bootstrap';
import util from '../../../../../utils/miniUtils';

export default function Input( { value, title, name, onChange, isCurrency, job, isVendor } ) {
    return (
        <Fragment>
            <div className="row d-flex align-content-center align-items-center">
                <div className="col-md-6"><b>{title}</b></div>
                <div className="col-md-6">
                    {
                        isCurrency ?
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                    {util.symbolDependingCurrencySelected( job.country )}
                                </InputGroup.Text>
                                <input  disabled={ isVendor && util.user.role !==15 } className="form-control" value={value} name={name} onChange={onChange} />
                            </InputGroup>
                            : 
                            <input disabled={ isVendor && util.user.role !==15 } className="form-control" value={value} name={name} onChange={onChange} />
                    }
                    
                </div>     
            </div>
            <hr className="m-1" />
        </Fragment>
    );
}
