import React, { useState, useEffect, } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { v4 as uuidv4 } from "uuid";
import util from '../../../utils/miniUtils';
import { toast } from "react-toastify";
import CandidateInfoCard from './CandidateInfoCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import useModalNewCandidate from './useModalNewCandidate';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setCandidates } from '../../../../redux/actions/JobCandidatesAction';
import { setIsTalent } from '../../../../redux/actions/addTalent';

const NewCandidateGPTModal = ( { show, onHide, closeModal,position_id, questions, setCandidateInfo, refreshTalentTable  } ) => {
    
    const [screeningResponse, setScreeningResponse] = useState( ( questions && questions.length > 0 ) ? handleIntialSqData() : [] );

    const [candidates, setCandidate] = useState( [] ); // Array to store candidates
    const [candidateErrors, setCandidateErrors] = useState( [] );

    const [currentIdx, setCurrentIdx] = useState( 0 ); // Index of the currently displayed candidate
    const [loading, setLoading] = useState( false );
    const [selectedFiles, setSelectedFiles] = useState( [] );
    const [isFile,setIsFile] = useState( false );
    const [showSkillInput, setShowSkillInput] = useState( false );
    const {
        countries
    } = useModalNewCandidate();
    const [skillName, setSkillName] = useState( '' );
    const [skillsList, setSkillsList] = useState( [] );
    let failedCandidatesIndices = []; // Array to store indices of failed candidate deployments
    function handleIntialSqData() {
        const responseMap = {};
        questions.forEach( el => {
            return responseMap[el.id] = { questionId: el.id, answer: "" };
        } );
        return responseMap;
    }
    
    let errors = Array( candidates.length ).fill( {} );
    const handleFileSelection = async ( event ) => {
        
        const files = Array.from( event.target.files );
        setSelectedFiles( files );
        
        setIsFile( true );
        await handleFileUpload( files );
    };
    const handleFileUpload = async ( files ) => {
        if ( !files || files.length === 0 ) {
            console.log( 'No files selected.' );
            return;
        }

        const formData = new FormData();
        for ( let i = 0; i < files.length; i++ ) {
            formData.append( `file${i}`, files[i] );
        }
        setLoading( true ); // Start loading
        try {
            // Replace '/gpt/multiparser' with your actual API endpoint
            const response = await util.axios.post( '/gpt/multiparser', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            } );

            // Assuming the server responds with an array of candidate objects
            setCandidate( [...candidates, ...response.data] );
        } catch ( error ) {
            console.error( 'Error uploading files:', error );
            // Handle error
        }
        setLoading( false ); // Stop loading
        setIsFile( false );
        
        setCurrentIdx( candidates.length );

    };

    const saveSingleCandidate = async ( idx ) => {
        if ( !candidates || !Array.isArray( selectedFiles ) || idx >= candidates.length || idx >= selectedFiles.length ) {
            console.log( 'Invalid candidate or file index.' );
            return;
        }
        // selectedFiles[currentIdx]
        const candidate = candidates[idx];
        const file = selectedFiles[idx];
    
        const formData = new FormData();
        formData.append( 'candidate', JSON.stringify( candidate ) ); // Convert candidate object to JSON string
        formData.append( 'file', file );
    
        setLoading( true ); // Start loading
    
        try {
            // Replace '/your/api/endpoint' with your actual API endpoint
            const response = await util.axios.post( '/candidates/create-from-resume', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            } );
            const { error, msg } = response.data;
            if ( error ) throw msg;
            // Check if the API response indicates success
            if ( !error ) {
                const updatedCandidates = candidates.filter( ( _, i ) => i !== idx );
                const updatedFiles = selectedFiles.filter( ( _, i ) => i !== idx );
                setCandidate( updatedCandidates );
                setSelectedFiles( updatedFiles );
                
        
                // Update currentIdx
                if ( currentIdx >= updatedCandidates.length && updatedCandidates.length !== 0 ) {
                    setCurrentIdx( updatedCandidates.length - 1 );
                } else if ( updatedCandidates.length === 0 ) {
                    setCurrentIdx( 0 );
                }

                console.log( 'Candidate and file successfully processed and removed.' );
            } else {
                if ( error ) throw msg;
            }
        } catch ( error ) {
            console.error( 'Error uploading candidate data:', error );
            // Handle error
        }
        setLoading( false ); // Stop loading
    };
    const dispatch = useDispatch();    
    
    const saveAllCandidates = async () => {
        
        if( candidates.length >0 ){
            let candidate = candidates[currentIdx];
            if ( candidate.name === "" ) {
                errors[currentIdx] = { ...errors[currentIdx], name: "Name is required" };
                failedCandidatesIndices.push( currentIdx );
                    
            }
    
            if ( candidate.email === "" ) {
                errors[currentIdx] = { ...errors[currentIdx ], email: "Email is required" };
                failedCandidatesIndices.push( currentIdx );
                    
            }
            if( candidate.mobile_phone === "" ){
                errors[currentIdx ] = { ...errors[currentIdx ], mobile_phone: "Phone is required" };
                failedCandidatesIndices.push( currentIdx  );
                    
            }
            if( candidate.country === "" ){
                errors[currentIdx ] = { ...errors[currentIdx ], country: "Country is required" };
                failedCandidatesIndices.push( currentIdx  );
                    
            }
            if ( failedCandidatesIndices.length > 0 ) {
                setCandidateErrors( errors );
                // Handle errors or show messages for failed candidates if needed
                console.log( "Some fields are required for the current candidate." );
                return; // Stop further processing if there are errors
            }
        }
        setLoading( true ); // Start loading

        const formData = new FormData();

        for ( let idx = 0; idx < candidates.length; idx++ ) {
            // try {
            const candidate = candidates[idx];
            const file = selectedFiles[idx];
            if ( candidate.name === "" ) {
                errors[idx] = { ...errors[idx], name: "Name is required" };
                failedCandidatesIndices.push( idx );
                continue;
            }
    
            if ( candidate.email === "" ) {
                errors[idx] = { ...errors[idx], email: "Email is required" };
                failedCandidatesIndices.push( idx );
                continue;
            }
            if( candidate.mobile_phone === "" ){
                errors[idx] = { ...errors[idx], mobile_phone: "Phone is required" };
                failedCandidatesIndices.push( idx );
                continue;
            }
            if( candidate.country === "" ){
                errors[idx] = { ...errors[idx], country: "Country is required" };
                failedCandidatesIndices.push( idx );
                continue;
            }

            formData.append( 'candidates', JSON.stringify( candidates ) );
            {file && formData.append( 'file', file );}
                
            const sqResponse=  Object.values( screeningResponse )   ;
                
            formData.append( 'position_id', position_id ? position_id: null );
            formData.append( 'sqResponse', JSON.stringify( Object.values( sqResponse ) ) );
            
        }
        try {
            
            const response = await util.axios.post( util.user.role===15 ? '/candidates/vendor-create-from-resume-multiple' : '/candidates/create-from-resume-multiple', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            } );
           

            const { error, msg, candidateInfo } = response.data;
            if ( error ) throw msg;
            dispatch( setIsTalent( true ) );
            if( candidateInfo.length > 0 ) {
                // setCandidateInfo( candidateInfo );
                // console.log( candidateInfo );
                dispatch( setCandidates( candidateInfo ) );

                setScreeningResponse( ( questions && questions.length > 0 ) ? handleIntialSqData() : [] );

            }
            
            util.toast().success( msg );
            
        }catch ( error ) {
            
            util.handleError( error );
            // failedCandidatesIndices.push( idx ); // Pushing index even if there's an error
        }
        
        
        if ( failedCandidatesIndices.length > 0 ) {
            // Update candidates and selectedFiles to include only the failed ones
            setCandidateErrors( errors );
            // Handle errors or show messages for failed candidates if needed
            
            const updatedCandidates = failedCandidatesIndices.map( index => candidates[index] );
            const updatedFiles = failedCandidatesIndices.map( index => selectedFiles[index] );
    
            setCandidate( updatedCandidates );
            setSelectedFiles( updatedFiles );
    
            if ( updatedCandidates.length > 0 ) {
                setCurrentIdx( 0 ); // Reset current index for the updated list
            } else {
                setCurrentIdx( 0 ); // If no failed candidates, reset the index
            }
        } else {
            // Clear the candidates and files arrays if no failures
            setCandidate( [] );
            setSelectedFiles( [] );
            setCurrentIdx( 0 ); // Reset current index
            setCandidateErrors( [] );
        }
    
        setLoading( false ); // Stop loading
    };
    
    
    const handleSaveCandidate = ( idx ) => {
        // API call to save individual candidate
        // Add your logic here
    };

    const handleSaveAllCandidates = () => {
        saveAllCandidates();        // Add your logic here
    };

    const handleRemoveCandidate = ( idx ) => {
        
        const updatedCandidates = candidates.filter( ( _, index ) => index !== idx );
        setCandidate( updatedCandidates );
        setCurrentIdx( 0 );
        
    };

    const handleClearAllCandidates = () => {
        setCandidate( [] );
    };

    const handlePrevious = () => {
        setCurrentIdx( ( prevIdx ) => ( prevIdx > 0 ? prevIdx - 1 : candidates.length - 1 ) );
    };

    const handleNext = () => {
        if ( candidates.length > 0 ) {
            setCurrentIdx( prevIdx => {
                // Calculate the next index, considering the array length
                let nextIdx = ( prevIdx + 1 ) % candidates.length;
                return nextIdx;
            } );
        } else {
            // If the candidates array is empty, reset the current index to 0
            setCurrentIdx( 0 );
        }    };
    const handleCloseModal = () =>{
        setCandidate( [] );
        setCandidateErrors( [] );

        closeModal();
    };
    
    const updateCandidate = ( index, updatedCandidate ) => {
        // Create a new array with the updated candidate
        const updatedCandidates = candidates.map( ( candidate, idx ) => {
            if ( idx === index ) {
                return updatedCandidate;
            }
            return candidate;
        } );
    
        // Update the state with the new candidates array
        setCandidate( updatedCandidates );
    };

    useEffect( () => {
        // This effect runs whenever currentIdx changes
        console.log( 'Current Index Updated:', currentIdx );
        // You can add any logic here that should execute when currentIdx changes
    }, [currentIdx] );

    const forceRenderKey = candidates.length + '-' + currentIdx;
    const [countryState, setCountryState] = useState( {
        loading: false,
        countries: [],
        errorMessage: "",
    } );

    useEffect( () => {
        const fetchData = async () => {
            try {
                // fetch spinner
                setCountryState( {
                    ...countryState,
                    loading: true,
                } );

                //  fetch data
                const dataUrl = `https://restcountries.com/v3.1/all`;
                const response = await axios.get( dataUrl );
                setCountryState( {
                    ...countryState,
                    countries: response.data,
                    loading: false,
                } );
            } catch ( error ) {
                setCountryState( {
                    ...countryState,
                    loading: false,
                    errorMessage: "Sorry Something went wrong",
                } );
            }
        };

        fetchData();
    }, [] );
    const handleCreateManually = () => {
        if( candidates.length >0 ){
            let candidate = candidates[currentIdx];
            if ( candidate.name === "" ) {
                errors[currentIdx] = { ...errors[currentIdx], name: "Name is required" };
                failedCandidatesIndices.push( currentIdx );
                    
            }
    
            if ( candidate.email === "" ) {
                errors[currentIdx] = { ...errors[currentIdx ], email: "Email is required" };
                failedCandidatesIndices.push( currentIdx );
                    
            }
            if( candidate.mobile_phone === "" ){
                errors[currentIdx ] = { ...errors[currentIdx ], mobile_phone: "Phone is required" };
                failedCandidatesIndices.push( currentIdx  );
                    
            }
            if( candidate.country === "" ){
                errors[currentIdx ] = { ...errors[currentIdx ], country: "Country is required" };
                failedCandidatesIndices.push( currentIdx  );
                    
            }
            if ( failedCandidatesIndices.length > 0 ) {
                setCandidateErrors( errors );
                // Handle errors or show messages for failed candidates if needed
                console.log( "Some fields are required for the current candidate." );
                return; // Stop further processing if there are errors
            }
        }
       
        const newCandidate = {
            name: '',
            email: '',
            mobile_phone: '',
            home_phone: '',
            other_phone: '',
            address: '',
            zip_code: '',
            city: '',
            state: '',
            country: '',
            salary_type: '',
            ctc: '',
            currency: '',
            salary_mode: '',
            working_status: '',
            platform: '',
            website: '',
            linkeIn: '',
            skills: [],
            work_history: [],
        };
        
        // Append the new candidate to the existing candidates
        setCandidate( [...candidates, newCandidate] );
        
        // Reset other relevant states if needed
        setSelectedFiles( [] );
        setCurrentIdx( candidates.length ); 
        
    };
    const indexClick = ( index ) => {
        
        setCurrentIdx( index );

        // setCurrentIdx( index );
    };
    return (
        <>
            {util.LOADING_SCREEN( loading )}
            <Modal show={show} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Add Talent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='parsing-header'>
                        <div className='parseBtns'>
                            {/* <button className="btn btn-secondary activeBtn">Auto fill with resume</button> */}
                        
                            <label htmlFor="file-upload" className="custom-file-upload btn btn-secondary resumeParsingBtn activeBtn">
Upload Resume                  
                                <input
                                    type="file"
                                    id="file-upload"
                                    // accept='application/pdf'
                                    accept=".doc, .docx, .pdf" 
                                    onChange={handleFileSelection}
                                />
                            </label>
                            <button onClick={handleCreateManually} className="btn btn-secondary  resumeParsingBtn activeBtn">{candidates.length > 0 ? 
                                "Add Talent Manually":"Add Talent Manually"}</button>
                            { candidates.length >0 && <button onClick={handleSaveAllCandidates} className="btn btn-secondary resumeParsingBtn activeBtn">Save</button>}
                        </div>
                        <div className='parsefeature'>
                            <div><FontAwesomeIcon onClick={handlePrevious} icon={faChevronLeft}/></div>
                            <div>{candidates.map( ( item,index ) => ( <span className='candidate-index' onClick={( ) =>indexClick( index )} key={index}>{index+1}</span> ) )}</div>
                            <div><FontAwesomeIcon onClick={handleNext} icon={faChevronRight}/></div>
                            {/* <div></div> */}
                        </div>
                    </div>
                    <div>
                        
                        {/* Display file preview */}
                        {/* {isFile  && selectedFiles.length > 0 && currentIdx < selectedFiles.length && (
                            <>
                                <a href={URL.createObjectURL( selectedFiles[currentIdx] )} target="_blank" rel="noopener noreferrer">
                                    {selectedFiles[currentIdx].name }
                                </a>
                                <div className="upload-file" onClick={handleFileUpload} disabled={!selectedFiles}>
                            Upload File 
                                </div>
                            </>
                        )} */}
                        {/* {isFile && ( <div className="upload-file" onClick={handleFileUpload} disabled={!selectedFiles}>
                Upload File
                        </div> )} */}
                    </div>


                    {candidates.length > 0 && (
                        <CandidateInfoCard
                            isFile={isFile}
                            handleFileUpload={handleFileUpload}
                            countryState={countryState}
                            key={forceRenderKey} // Use key to force re-render
                            candidate={candidates[currentIdx]}
                            index={currentIdx}
                            onUpdate={updateCandidate}
                            onSave={() => handleSaveCandidate( currentIdx )}
                            onRemove={() => handleRemoveCandidate( currentIdx )}
                            saveSingle={() => saveSingleCandidate( currentIdx )}
                            skillName={skillName}
                            setSkillName={setSkillName}
                            showSkillInput={showSkillInput}
                            setShowSkillInput={setShowSkillInput}
                            countries={countries}
                            skillsList={skillsList}
                            setSkillsList={setSkillsList}
                            selectedFiles={selectedFiles}
                            candidateErrors={candidateErrors}
                            setCandidateErrors={setCandidateErrors}
                            setCandidates={setCandidate}
                        />
                    ) }
                    
                </Modal.Body>
                <Modal.Footer>
                    
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default NewCandidateGPTModal;
