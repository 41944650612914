import React, { useEffect, useState } from "react";
import {
    Card,   
    CardContent,
} from "@material-ui/core";
import util from "../../utils/miniUtils";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../../redux/actions/datefilter";
import Select from 'react-select';

const WorkloadByResolver = () => {
    const [resolvers, setResolvers] = useState( [] );
    let { from, to, applyFilter } = useSelector( ( state ) => state.filter ) || {};
    const [resolverOptions, setResolverOptions] = useState( [] );
    const [originalResolversList, setOriginalResolversList] = useState( [] );
    const [selectedResolvers, setSelectedResolvers] = useState( [] );
    const dispatch = useDispatch();
    
    useEffect( () =>{
        if( applyFilter ) {
            getResolverByWorkload();
        }
    },[applyFilter] );

    const getResolverByWorkload = async (  ) => {
        try {
            if( from === undefined ){
                const currentDate = new Date();
                from = currentDate.getFullYear() + '-' +
                  ( currentDate.getMonth() + 1 ) + '-' +
                  currentDate.getDate();
            }
            if( to === undefined ){
                const currentDate = new Date();
                to = currentDate.getFullYear() + '-' +
                ( currentDate.getMonth() + 1 ) + '-' +
                currentDate.getDate();
            }
            from = from ? util.convertToUTC( from ) : '';
            to = to ? util.convertToUTC( to ) : '';
            const response = await util.axios.get(
                `new_dashboard/get_resolver_by_workload_closed?from=${from}&to=${to}`
            );
            
            const { data, error, msg } = response.data;
            
            setResolvers( data );
            setOriginalResolversList( data );
            const uniqueResolvers = [...new Set( data?.map( item => item.Resolver_Name ) )];
            const resolverOptionList = uniqueResolvers?.map( ( resolver ) =>( { label: resolver, value: resolver } ) );
            setResolverOptions( resolverOptionList );
            dispatch( setFilter( false ) );
            if ( error ) throw msg;
        } catch ( e ) {
            util.handleError( e );
        }
    };
    const handleResolverChange = ( selectedResolvers ) =>{
        setSelectedResolvers( selectedResolvers );
        const filteredResolverList = originalResolversList?.filter( ( item ) => ( selectedResolvers?.length ===0 || selectedResolvers?.some( ( el ) => el.value === item.Resolver_Name ) ) );
        setResolvers( [...filteredResolverList ] );
    };

    return (
        <Card style={{ width: "100%", height: "90%", overflow: 'hidden' }}>
            <CardContent style={{ height: "100%" }} >
                <div style={{ height: "100%", overflow: "auto" }}>
                    <div className="recruiter-filter">

                        <Select 
                            isMulti
                            options={resolverOptions}
                            value={selectedResolvers}
                            onChange={handleResolverChange}
                            placeholder='Select Resolvers'
                            className="mr-2 non-draggable dashboard-select"
                        />
                    </div>
                    {/* <table className="table table-bordered"> */}
                    <table className="workload-resolver-table">
                        <thead>
                            <tr>
                                <th>Resolver Name</th>
                                <th>Resolver Id</th>
                                <th>Account Id</th>
                                {/* <th>Last Change User</th> */}
                                <th>Ticket Count</th>
                                {/* <th>Partner Name</th> */}
                                <th>Average Business Days SLA</th>
                                
                                <th>Average Calendar Days SLA</th>
                                
                            </tr>
                        </thead>
                        <tbody className="recruiter-report-body">
                            {resolvers?.map( ( item,index ) => (
                                <tr key={index} className="recruiter-report-rows">
                                    {/* <td>{item.recruiter_name}</td> */}
                                    <td>{item.Resolver_Name}</td>
                                    <td>{item.resolverid}</td>
                                    <td>{item.accountId}</td>
                                    {/* <td>{item.lastChangeUser}</td> */}
                                    <td>{item.Ticket_Count}</td>
                                    {/* <td>{item.partnerName}</td> */}
                                    <td>{item.Average_Business_Days_SLA}</td>
                                    
                                    <td>{item.Average_Calendar_Days_SLA}</td>
                                    

                                </tr>
                            ) )}
                        </tbody>
                    </table>
                    
                </div>
            </CardContent>
        </Card>
    );
};

export default WorkloadByResolver;

