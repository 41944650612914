import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import util from '../../../utils/miniUtils';
import { CircularProgress, MuiThemeProvider } from '@material-ui/core';
import MaterialTable from 'material-table';

const DeleteCatalogModal = ( { show, handleClose, scId } ) => {
    const [loading, setLoading] = useState( false );
    const [state, setState] = useState( {
        name: "",
        gl_department: "",
        description: "",
        workload: []
    } );

    const getServiceCatalogDetails = async () => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/workload_service_catalog/${scId}` );
            const { data, error, msg } = request.data;
            if( error ) throw msg;
            setState( data );
        } catch ( error ) {
            util.handleError( error );
        } finally {
            setLoading( false );
        }
    };

    const deleteWorkloadServiceCatalog = async () => {
        try {
            setLoading( true );
            const request = await util.axios.delete( `/workload_service_catalog/${scId}` );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
        } catch ( error ) {
            util.handleError( error );
        } finally {
            setLoading( false );
            handleClose();
        }
    };

    useEffect( () => {
        if( show ) {
            getServiceCatalogDetails();
        }
    }, [show] );

    return (
        <Modal size="xl" show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Workload Service Catalog</Modal.Title>
            </Modal.Header>
            {loading ? (
                <Modal.Body>
                    <div className="d-flex justify-content-center align-items-center">
                        <CircularProgress />
                    </div>
                </Modal.Body>
            ) : (
                <Modal.Body>
                    <Row>
                        <Col className='xs-fontSize text-danger mt-1'><i>Are you sure you want to delete this Workload Service Catalog ?</i></Col>
                    </Row>
                    <Row>
                        <Col sm="12" md="6">
                            <Row>
                                <Col sm="12" md="6"><strong>Title :</strong></Col>
                                <Col sm="12" md="6">{state.name}</Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="6"><strong>Department :</strong></Col>
                                <Col sm="12" md="6">{state.departmentName}</Col>
                            </Row>
                        </Col>
                        <Col sm="12" md="6">
                            <Row>
                                <Col sm="12" md="6"><strong>Created by :</strong></Col>
                                <Col sm="12" md="6">{state.created_by_name}</Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="6"><strong>Active :</strong></Col>
                                <Col sm="12" md="6">{state.active ? "Yes" : "No"}</Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md="3"><strong>Description</strong></Col>
                        <Col sm="12" md="9">{state.description}</Col>
                    </Row>
                    {state.workload.length ? <>
                        <Row className='mt-1'>
                            <Col className='xs-fontSize text-danger'><i>Deleting will also permanently remove all related workloads listed below</i></Col>
                        </Row>
                        <Row>
                            <Col>
                                <MuiThemeProvider theme={util.defaultTableTheme}>
                                    <MaterialTable 
                                        columns={[
                                            { 
                                                title: "Id",
                                                field: "id",
                                            },
                                            { 
                                                title: "Priority",
                                                field: "priority",
                                            },
                                            { 
                                                title: "Workload Title",
                                                field: "short_description",
                                            },
                                            { 
                                                title: "Description",
                                                field: "long_description",
                                            },
                                            { 
                                                title: "Group",
                                                field: "group.name",
                                            },
                                            { 
                                                title: "Category",
                                                field: "category.name",
                                            },
                                            { 
                                                title: "Customer",
                                                field: "customer.name",
                                            },
                                            { 
                                                title: "Resolver",
                                                field: "resolver.name",
                                            },
                                            { 
                                                title: "Parent Ticket ID",
                                                field: "parent_ticket_id",
                                            },
                                        ]}
                                        data={state.workload}
                                        options={{
                                            showTitle: false,
                                            emptyRowsWhenPaging: true,
                                            paging: false,
                                            searchFieldAlignment: "left"
                                        }}
                                    />
                                </MuiThemeProvider>
                            </Col>
                        </Row>
                    </> : ""}
                </Modal.Body> )}

            <Modal.Footer>
                <button className='btn btn-secondary sizeBtn' onClick={handleClose}>Cancel</button>
                <button className='btn btn-danger
                 sizeBtn activeBtn' onClick={deleteWorkloadServiceCatalog}>Delete</button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteCatalogModal;