import React from 'react';
const SubItemCatalog = ( { data, onDelete,onEdit,ScreenQ } ) => {
    
    const { id, name,question } = data;
    return (
        <div className="d-flex justify-content-between align-items-center p-2 border rounded mt-1 mb-1 department_item">
            <div>
                {ScreenQ === "job_sq"?
                    <div>
                        <b className='text-dark'>{question}</b>
                    </div>:
                    <div>
                        <b className='text-dark'>{name}</b>
                    </div>
                }
            </div>
            <div className="d-flex ">
                <span className="miniButton btn btn-outline-success col-5  mr-1 " onClick={( e ) => onEdit( e, id,name )}>
                Edit
                </span>
                <span className="miniButton btn btn-outline-danger mr-2 " onClick={( e ) => onDelete( e, id )}>
                Delete
                </span>
            </div>
        </div>
    
    );
};

export default SubItemCatalog;
