import React, { useState, useEffect } from 'react';
import util from "../../../utils/miniUtils";
import { LoadingStore } from "../../../shared/LoadingStore";
import { ResumeStore } from "../talent_from_resume/ResumeStore";

const tableRef = React.createRef();

function useListOfCandidate() {
    // Flag for handling the visibility of the form.
    const [showForm, setShowForm] = useState( false );
    const [confirmCloseModal, setConfirmCloseModal] = useState( false );
    const [simpleSearch, setSimpleSearch] = useState( "" );
    const loading = LoadingStore.useState( state => state.isLoading );
   
    const [filterState, setFilterState] = useState( {
        showFilter: false,
        skills: [],
        noticePeriod:[],
        certifications: [],
        job_title: [],
        // country: [],
        countries: [],
        states: [],
        cities: [],
        resume: [],
        noticePeriod:[],
        min: 0,
        max: 0
    } );
    
    const fromResume = () => {
        setShowForm( true );
    };

    useEffect( () => {
    }, [loading] );
    
    const closeAndReload = ( closeModal ) => {
        // Send message to parent to update state of addModalShow to false
        closeModal();
        tableRef.current && tableRef.current.onQueryChange();
    };
    
    const closeFormAndReloadList = () => {
        // Send message to parent to update state of addModalShow to false
        setShowForm( false );
        tableRef.current && tableRef.current.onQueryChange();
    };
    
    const closeModal = ( closeAddModa ) => {
        // Send message to parent to update state of addModalShow to false
        closeAddModa();
    };

    // const searchData = ( d ) => {
    //     setListState( Object.assign( {}, d, { showFilter: true } ) );
    //     tableRef.current && tableRef.current.onQueryChange();
    // };
    const searchDataVersion2 = ( d ) => {
        setFilterState( Object.assign( {}, d, { showFilter: true } ) );
        tableRef.current && tableRef.current.onQueryChange( undefined );
    };
    
    

    const urlHandlerversion2 = ( query )  => {
        let orderby = ( query.orderBy ) ? query.orderBy.field:"createdAt";
        let direction = ( query.orderDirection ==="" ) ? "desc": query.orderDirection;
        let search = ( query.search === "" ) ? "": filterState.showFilter ? "": query.search;
        setSimpleSearch( search );
        const {
            resume,
            skills,
            certifications,
            job_title,
            countries,
            states,
            cities, max, min, noticePeriod } = filterState;

        // Function to convert array of objects to query string
        function serializeArray( array ) {
            return array.map( ( obj, index ) => {
                return Object.keys( obj )
                    .map( key => `${encodeURIComponent( `skills[${index}][${key}]` )}=${encodeURIComponent( obj[key] )}` )
                    .join( '&' );
            } ).join( '&' );
        }

        // Convert the array to a query string
        const queryString = serializeArray( skills );


        let q = `advanced_filter=${filterState.showFilter}&max=${max}&min=${min}&${queryString}&`;
        const fixCheckedToUrlEncode = ( data = [], field, especial ) => {
            let pairs = [];
            if( field === "skill_name" || field === "certificates" || field === "job_title" ) {
                data.map( item => {
                    pairs.push( `${ field }=` + encodeURIComponent( item.id ) );
                } );
            } else {
                data.map( ( value ) => {
                    if ( value.checked === true ) {
                    // console.log( value );
                        let val = value.value;
                        if ( especial ) {
                            val = val === "Yes" ? 1: 0;
                        }
                        pairs.push( `${ field }=` + encodeURIComponent( val ) );
                    }
                } );
            }
            q+= pairs.join( "&" ) + "&";
        };
        fixCheckedToUrlEncode( skills, 'skill_name' );
        fixCheckedToUrlEncode( certifications, 'certificates' );
        fixCheckedToUrlEncode( job_title, 'job_title' );
        fixCheckedToUrlEncode( countries, 'country' );
        fixCheckedToUrlEncode( resume, 'resume' );
        fixCheckedToUrlEncode( noticePeriod, 'noticePeriod' );
        fixCheckedToUrlEncode( states, 'state' );
        fixCheckedToUrlEncode( cities, 'city' );
        return `${util.url}/candidates/${query.page}/${query.pageSize}?orderby=${orderby}&direction=${direction}&search=${search}&${q}`;
    };
    const urlHandlerversion3 = ( query )  => {
        let orderby = ( query.orderBy ) ? query.orderBy.field:"createdAt";
        let direction = ( query.orderDirection ==="" ) ? "desc": query.orderDirection;
        let search = ( query.search === "" ) ? "": filterState.showFilter ? "": query.search;
        setSimpleSearch( search );
        const {
            resume,
            skills,
            certifications,
            job_title,
            countries,
            states,
            cities, max, min, noticePeriod } = filterState;
        console.log( skills );

        let q = `advanced_filter=${filterState.showFilter}&max=${max}&min=${min}&`;
        const fixCheckedToUrlEncode = ( data = [], field, especial ) => {
            let pairs = [];
            if( field === "skill_name" || field === "certificates" || field === "job_title" ) {
                data.map( item => {
                    pairs.push( `${ field }=` + encodeURIComponent( item.id ) );
                } );
            } else {
                data.map( ( value ) => {
                    if ( value.checked === true ) {
                    // console.log( value );
                        let val = value.value;
                        if ( especial ) {
                            val = val === "Yes" ? 1: 0;
                        }
                        pairs.push( `${ field }=` + encodeURIComponent( val ) );
                    }
                } );
            }
            q+= pairs.join( "&" ) + "&";
        };
        fixCheckedToUrlEncode( skills, 'skill_name' );
        fixCheckedToUrlEncode( certifications, 'certificates' );
        fixCheckedToUrlEncode( job_title, 'job_title' );
        fixCheckedToUrlEncode( countries, 'country' );
        fixCheckedToUrlEncode( resume, 'resume' );
        fixCheckedToUrlEncode( noticePeriod, 'noticePeriod' );
        fixCheckedToUrlEncode( states, 'state' );
        fixCheckedToUrlEncode( cities, 'city' );
        return `${util.url}/vendor-candidates/${query.page}/${query.pageSize}?orderby=${orderby}&direction=${direction}&search=${search}&${q}`;
    };
        
    const handleClose = () =>{
        setShowForm( false );
        ResumeStore.update( s => {
            s.data = {};
            s.file = null;
        } );
    };

    // useEffect( () => {
    // }, [loading] );

    return (
        {
            showForm, setShowForm, 
            confirmCloseModal, setConfirmCloseModal,
            simpleSearch, setSimpleSearch,
            fromResume,
            loading,
            closeAndReload,
            closeFormAndReloadList,
            closeModal,
            searchDataVersion2,
            // urlHandler,
            handleClose,filterState, setFilterState,urlHandlerversion2, urlHandlerversion3
        }
    );
}

export default useListOfCandidate;
