import React, { useEffect, useState } from 'react';
import util from '../../utils/miniUtils';
import { Card, CardContent } from '@material-ui/core';

const JobData = () => {
    const [ totalApplicants, setTotalApplicants ] = useState( [ ] );
    

    useEffect( () =>{
        getTalents(  );
    },[] );
    const getTalents = async (  ) => {
        try {
            const response = await util.axios.get(
                `new_dashboard/get_total_talent/`
            );  
            const { data, error, msg } = response.data;
            setTotalApplicants( data );
            
            if ( error ) throw msg;
        } catch ( e ) {
            util.handleError( e );
        }
    };

    return (
        <> 
            <Card style={{ width: "100%", height: "70%" }}>
                <CardContent>
                    
                    {totalApplicants.length >0 && (
                        <h2>{totalApplicants[0].total_positions}</h2> )}

                </CardContent>
            </Card>
           
            
            
        </>
    );
};

export default JobData;