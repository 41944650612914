import React, { useEffect, useState } from "react";
import { Chip, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Clear } from "@material-ui/icons";

const CertificationSelector = ({ getCertificationList, list, updateFilter, filteredCertifications = [], setCertificationList }) => {
    // console.log(filteredCertifications)
    const [certificationName, setCertificationName] = useState('');

    useEffect(() => {
        if (certificationName) {
            getCertificationList(certificationName);
        }
    }, [certificationName]);

    const handleChange = (e) => {
        setCertificationName(e.target.value);
    };

    const handleClick = (val) => {
        // console.log(val, "hi")
        // console.log(filteredCertifications)
        if (filteredCertifications && !filteredCertifications.some((item) => item.id === val.id)) {
            console.log(val)

            updateFilter("certification", val);
        }

    };

    const deleteCertification = (val) => {
        const updatedCertifications = filteredCertifications.filter(item => item.id !== val);
        updateFilter("certification_delete", updatedCertifications);
    };

    const handleReset = () => {
        setCertificationName('');
        setCertificationList([]);
    };

    return (
        <div>
            <div>
                {filteredCertifications && filteredCertifications.length > 0 && filteredCertifications.map(certification => (
                    <Chip
                        size="small"
                        name="certification"
                        label={certification.name}
                        key={certification.id}
                        onDelete={() => deleteCertification(certification.id)}
                        className="mr-1 mb-1"
                    />
                ))}
            </div>
            <div>
                <TextField
                    className="form-control mt-1"
                    variant="outlined"
                    size="small"
                    type="text"
                    value={certificationName}
                    onChange={handleChange}
                    label="Required Certifications"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleReset}>
                                    <Clear />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <ul className="skillsSelectorList">
                {list.length > 0 && list.map(certification => (
                    <li
                        className="skillsSelectorList-item"
                        name="certification"
                        key={certification.id}
                        onClick={() => handleClick(certification)}
                    >
                        {certification.name}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CertificationSelector;
