import { faFileExport, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MuiThemeProvider } from '@material-ui/core';
import MaterialTable from 'material-table';
import React, { Fragment, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import util from '../../../utils/miniUtils';
import ImageComponent from "../../../utils/ImageComponent";
import { RECRUITER_ROLE } from "../../../../constants";
import SidebarFilter from '../../shared/SidebarFilter';
// import useReport from './useReport';
import axios from "axios";

const NewRecuiterReport = () => {
    // const { totalsRow } = useReport();
    const [loading, setLoading] = useState( true );
    const [data, setData] = useState( [] );
    const [statusColumns, setStatusColumns] = useState( [] );
    const [parentStatusList, setParentStatusList] = useState( [] );
    const [total, setTotal] = useState( {} );
    const [filterData, setFilterData] = useState( {
        firstTime: true,
        recruiters:[],
        countries: [],
        clients:[],
        partners:[],
        sales_lead: [],
        startDate: new Date( new Date().setDate( new Date().getDate() - 0 ) ).toISOString().split( "T" )[0],
        endDate: new Date().toISOString().split( "T" )[0],
    } );

    // calculate total value for different status
    function getTotal( arr, statusList ) {
        const result = statusList.reduce( ( acc, status ) => {
            acc[status.jts_id] = [0, status.order];
            return acc;
        }, { submittedToday: 0 } );
      
        Object.keys( result ).forEach( ( key ) => {
            if ( key !== 'submittedToday' ) {
                result[key][0] = arr.reduce( ( sum, item ) => {
                    return sum + item[key];
                }, 0 );
            } else {
                result[key] = arr.reduce( ( sum, item ) => {
                    return sum + item[key];
                }, 0 );
            }
        } );
        addTotalRow( result );
        return result;
    }

    function addTotalRow ( total ) {
        if( data.length > 0 ) {
            const totalRow = {};
            for( let item in data[0] ){
                if( item === 'onBoardedTalents' ) {
                    totalRow[item] = [];
                } else {
                    totalRow[item] = '';
                }
            }
            for( let el in total ) {
                if( el === "submittedToday" ) {
                    totalRow[el] = total[el];
                } else {
                    totalRow[el] = total[el][0];
                }
            }
            setTotal( { ...totalRow } );
        }
    }

    

    const getFilterData = async () => {
        try {
            const request = await util.axios.get( `dashboard/filters_values` );
            const { error, msg, recruiters, countries, clients, partners, sales_lead } = request.data;
            if ( error ) throw  msg;
            setFilterData( { ...filterData, firstTime: false, recruiters, countries, clients, partners, sales_lead } );
        }catch ( e ) {
            util.handleError( e );
        }
    };

    const applyFilter = async ( type ) => {
        // Put only required data in dataObject to filter the table
        setLoading( true );
        setData( [] );
        setStatusColumns( [] );
        setTotal( {} );
        let dataObject = { 
            recruiters: filterData.recruiters.filter( item => { if ( item.checked === true ) return item.id;} ),
            country: filterData.countries.filter( item => { if ( item.checked === true ) return item.value;} ),
            partners: filterData.partners.filter( item => { if ( item.checked === true ) return item.id; } ),
            clients: filterData.clients.filter( item => { if ( item.checked === true ) return item.id; } ),
            sales_lead: filterData.sales_lead.filter( item => { if ( item.checked === true ) return item.id; } ),
            startDate: filterData.startDate + " 00:00:00",
            endDate: filterData.endDate + " 23:59:59",
        };
        // In the case that the user is a recruiter, will send only hes OWN ID in the recruiters property
        if ( util.user.role === RECRUITER_ROLE ){
            dataObject.recruiters = [{ id: util.user.id }];
        }

        try {
            let url = `dashboard/report_recruiter_by_status`;
            if ( type === "jobs" ) {
                url = `dashboard/report_jobs`;
                dataObject.sales_lead = dataObject.recruiters;
                delete dataObject.recruiters;
            }
            const request = await util.axios.post( url, dataObject );
            let { error, msg, result } = request.data;
            if ( error ) throw  msg;
            setData( result );
            getStatusList();
            if( data.length && parentStatusList.length ){
                getTotal( data, parentStatusList );
            }
            setLoading( false );
        }catch ( e ) {
            util.handleError( e );
            setLoading( false );
        }
    };
    const handleChange = ( e ) => {
        let { name, value } = e.target;
        setFilterData( { ...filterData, [name]: value } );
    };

    const updatedNewData = ( field, newData ) => {
        setFilterData( { ...filterData, [field]: newData } );
    };
    
    const getStatusList = async () => {
        try{
            const request = await util.axios.get( '/job_talent_status/list/parentStatus' );
            const { data, error, msg } = request.data;
            if( error ) throw msg;
            
            setStatusColumns( data.length > 0 ? data.map( el => ( { width:"5%", title: el.name, field: el.jts_id, hidden:false,
                hiddenByColumnsButton:false, render: d => {
                    return <div className="text-center">{d[el.jts_id]}</div>;
                } } ) ) : [] );
            setParentStatusList( data );
        } catch ( e ) {
            util.handleError( e );
        }
    };
    useEffect( () => {
        // Will run applyFilter() until the filterData is filled with API information      
        getStatusList();
        getFilterData();
    },[] );

    useEffect( () => {
        if( !filterData.firstTime ){
            applyFilter();  
        }
    },[ filterData ] );

    useEffect( () => {
        if( data.length && parentStatusList.length ){
            getTotal( data, parentStatusList );
        }

        if( !data.length ) {
            setTotal( {} );
        }
    },[data, parentStatusList] );

    return (
        <Fragment> 
            <div className="container-fluid mt-2">
                <div className="row">
                    <div className="col-md-2">
                        <Card elevation={1} className="p-2">
                            <SidebarFilter filterData={filterData} handleChange={handleChange} updatedNewData={updatedNewData} />
                        </Card>
                    </div>
                    <div className="col-md-10">
                        <Card elevation={1} className="p-2">
                            {util.LOADING_SCREEN( loading )}
                            { !loading && ( 
                                <MuiThemeProvider theme={util.defaultTableTheme}>
                                    {/* {data.length > 0 && statusColumns.length > 0 && ( */}
                                    <MaterialTable
                                        columns={
                                            [
                                                { width:"30%", title: 'Country', field: 'job_country', hidden:false,
                                                    hiddenByColumnsButton:false,
                                                    render: d => {
                                                        return d.job_country ? (
                                                            <div className="d-flex align-items-center">
                                                                <img width={25} src={util.countryFlag( d.job_country )} alt={d.job_country} className="mr-1"/>
                                                                {d.job_country}
                                                            </div>
                                                        ) : "";
                                                    } },
                                                { width:"5%", title: 'JobID', field: 'job_id', hidden:false,
                                                    hiddenByColumnsButton:false,
                                                    render: data => {
                                                        return data.job_id ? <a href={`/jobs/position/detail/${data.job_id}`} target="new">
                                                            {util.zeroPad( data.job_id, 5 )}
                                                        </a> : "TOTAL";
                                                    }
                                                },
                                                { width:"50%", title: 'Job Title', field: 'job_title', hidden:false,
                                                    hiddenByColumnsButton:false, },
                                                { width:"5%", title: 'Lead Recruiter', field: 'leadRecruiterName', hidden:false,
                                                    hiddenByColumnsButton:false, },
                                                { width:"5%", title: 'Sales Lead', field: 'salesLeadName', hidden:false,
                                                    hiddenByColumnsButton:false, },
                                           
                                                ...statusColumns,
                                                { width:"30%", title:'Onboarded Talents', field:'onBoardedTalents', hidden:false,
                                                    hiddenByColumnsButton:false,
                                                    render: d =>  {
                                                        return (
                                                            <ol className="text-left">
                                                                { 
                                                                d?.onBoardedTalents?.map( ( o, i ) => {
                                                                    return (
                                                                        <li key={i * Math.random() }>
                                                                            {o.name} 
                                                                        </li>
                                                                    );
                                                                } )
                                                                }
                                                            </ol>
                                                        );
                                                    } },
                                            ]
                                        }
                                        data={Object.keys( total ).length ? [ ...data, total] : data}
                                        // components={{
                                        //     Body: ( props ) => (
                                        //         <>
                                        //             <MTableBody {...props} />
                                        //             <TableFooter>
                                        //                 <TableRow>
                                        //                     <TableCell colSpan={1} className='text-dark text-center'><strong>Total:</strong></TableCell>
                                        //                     <TableCell colSpan={1} className='text-dark text-center'>{total?.submittedToday}</TableCell>
                                        //                     {totalStatusData()}
                                        //                 </TableRow>
                                        //             </TableFooter>
                                        //         </>
                                        //     ),
                                        // }}
                                        options={{
                                            searchFieldAlignment:"left",
                                            toolbarButtonAlignment:'left',
                                            emptyRowsWhenPaging: false,
                                            pageSizeOptions:[20,50,100],
                                            pageSize: 50,
                                            tableLayout:"auto",
                                            columnsButton:true,
                                            showTitle: false,
                                            headerStyle:{ position:'sticky',top:0 },
                                            maxBodyHeight:'525px',
                                        }}
                                        actions={[
                                            {
                                                icon: () => <div className={"btn btn-secondary jobButton activeBtn btn-sm"}>
                                                    <FontAwesomeIcon icon={faFileExport} className="mr-2" />
                                Export All
                                                </div>,
                                                tooltip: "Export All",
                                                isFreeAction: true,
                                                onClick: () => util.DownloadTableAsCsv( 'MuiTable-root', 1, "RecruiterReport" ),
                                            },
                                            {
                                                icon: () => <FontAwesomeIcon icon={faSync} style={{ width:'15px' }} />,
                                                tooltip: "Reload Data",
                                                isFreeAction: true,
                                                onClick: () => applyFilter( ),
                                            }
                                        ]}
                                    />
                                    {/* )} */}
                                </MuiThemeProvider>
                            )}
                        </Card>
                    </div>
                    {/* <div className="col-md-2">
                        <FunnelGraph totals={ funnelTotals( data ) } />
                    </div> */}
                </div>
            </div>
        </Fragment>
    );
};

export default NewRecuiterReport;