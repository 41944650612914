import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload,  faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import React, { Fragment } from "react";
import WorkHistoryPrintVersion from "./WorkHistoryPrintVersion";
import SkillsPrintVersion from "./SkillsPrintVersion";


export default function PrintVersion( { data, generatePDF,  continueEditing } ) {
	
    if ( data === null ) return "loading...";
	
    return (
        <Fragment>
            <div className="d-flex justify-content-between">
                <h5>Preview</h5>
                <div>
                    <button
                        onClick={()=>continueEditing()}
                        className="btn btn-sm btn-primary mr-2">
                        <FontAwesomeIcon icon={faPencilAlt} className="mr-1"/>
						Continue Editing
                    </button>
                    <button
                        onClick={()=>generatePDF()}
                        className="btn btn-sm btn-info">
                        <FontAwesomeIcon icon={faDownload} className="mr-1"/>
						Download Document
                    </button>
                </div>
            </div>
            <div id="testPDF">
                <table className="previewTable" >
                    <thead/>
                    <tbody>
                        <tr className="selectedStyle centeredText">
                            <th colSpan={2} className="text-center centeredText">{data.name}</th>
                        </tr>
                        <tr className="selectedStyle">
                            <th width="50%">Interview POC Name (Supplier)</th>
                            <th>Interview POC Contact # (Supplier)</th>
                        </tr>
                        <tr>
                            <td>{data["leadRecruiterName"]}</td>
                            <td>{data["leadRecruiterData"]}</td>
                        </tr>
                        <tr>
                            <th className="selectedStyle leftText">Current Location</th>
                            <td>
                                {data["talentLocation"]}
                            </td>
                        </tr>
                        <tr>
                            <th className="selectedStyle leftText">Willing to relocate? If so, please provide the preferred location:</th>
                            <td>
                                {data.relocation === 1 ? "Yes": "No"}
                                <br/>
                                {data.location}
                            </td>
                        </tr>
                        <tr>
                            <th className="selectedStyle leftText">Willing to attend in-person interview?</th>
                            <td>
                                {data.inPersonInterview ? data.inPersonInterview: "Yes"}
                            </td>
                        </tr>
                        <tr>
                            <th className="selectedStyle leftText">Availability to Start</th>
                            <td>
                                {data.AvailabilityToStart ? data.AvailabilityToStart: "0 Weeks"}
                            </td>
                        </tr>
					
                        <tr>
                            <th className="selectedStyle leftText">Notice Period/ LWD on last project</th>
                            <td>
                                {data.noticePeriod ? data.noticePeriod: "2 weeks"}
                            </td>
                        </tr>
					
                        <tr>
                            <th className="selectedStyle leftText">Interviews/ Offers in Pipeline</th>
                            <td>
                                {data.offersInPipeline ? data.offersInPipeline: "None"}
                            </td>
                        </tr>
					
                        <tr className="selectedStyle">
                            <th colSpan={2} className="text-center centeredText">
							LIST ALL EMPLOYMENT FOR THE PAST 7 YEARS
                            </th>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <table className="">
                                    <tbody>
                                        <tr className="selectedStyle">
                                            <th width="20%">
										Dates of Employment
										(Month/Year - Month/Year)
                                            </th>
                                            <th width="60%">
										Parent Employer Name /Project Company Name
                                                <br/>
										i.e. ABC Staffing / Project at XYZ Client Name
                                            </th>
                                            <th width="20%">Location</th>
                                        </tr>
								
								
                                        {
                                            data.workHistory.length === 0 ?
                                                "Nothing to display"
                                                :
                                                <Fragment>
											
                                                    {
                                                        data.workHistory.map( ( wh,i ) => {
                                                            return(
                                                                <Fragment key={i}>
                                                                    <WorkHistoryPrintVersion
                                                                        wh={wh}
                                                                    />
                                                                </Fragment>
                                                            );
                                                        } )
                                                    }
                                                </Fragment>
                                        }
                                    </tbody>
                                </table>
                            </td>
                        </tr>
					
					
					
                        <tr>
                            <th colSpan={3} className="text-center selectedStyle" >
							Supplier Synopsis
                            </th>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <table className="table">
                                    <tbody>
                                        <tr className="selectedStyle">
                                            <th width="20%">
										Mandatory Skills (As listed in JD)
                                            </th>
                                            <th width="10%"># of Years Experience</th>
                                            <th width="70%">Summary of Candidates relevant hands-on experience</th>
                                        </tr>
                                        {
                                            data["mandatorySkills"] && data["mandatorySkills"].length > 0 && data["mandatorySkills"].map( ( ps, i ) => {
                                                return(
                                                    <SkillsPrintVersion
                                                        key={i}
                                                        skill={ps}
                                                        index={i}
                                                    />
                                                );
                                            } )
                                        }
                                    </tbody>
                                </table>
                            </td>
                        </tr>
					
                        <tr>
                            <td colSpan={2}>
                                <table className="table">
                                    <tbody>
                                        <tr className="selectedStyle">
                                            <th width="20%">
										Nice to Have Skills (As listed in JD)
                                            </th>
                                            <th width="10%"># of Years Experience</th>
                                            <th width="70%">Summary of Candidates relevant hands-on experience</th>
                                        </tr>
                                        {
                                            data["niceToHaveSkills"] && data["niceToHaveSkills"].length > 0 && data["niceToHaveSkills"].map( ( ps, i ) => {
                                                return(
                                                    <SkillsPrintVersion
                                                        key={i}
                                                        skill={ps}
                                                        index={i}
                                                    />
                                                );
                                            } )
                                        }
                                    </tbody>
                                </table>
                            </td>
                        </tr>
					
                        <tr>
                            <th className="selectedStyle">Additional comments</th>
                            <td>
                                {data.additionalComments ? data.additionalComments: "None"}
                            </td>
                        </tr>
                        <tr>
                            <th className="selectedStyle">Certifications</th>
                            <td>
                                {data.certifications ? data.certifications: "None"}
                            </td>
                        </tr>
					
                    </tbody>
                </table>
            </div>
			
			
            <div className="d-flex justify-content-end mt-2">
                <button
                    onClick={()=>continueEditing()}
                    className="btn btn-sm btn-primary mr-2">
                    <FontAwesomeIcon icon={faPencilAlt} className="mr-1"/>
					Continue Editing
                </button>
                <button
                    onClick={()=>generatePDF()}
                    className="btn btn-sm btn-info">
                    <FontAwesomeIcon icon={faDownload} className="mr-1"/>
					Download Document
                </button>
            </div>
        </Fragment>
    );
	
}