import React, { useEffect, useState } from 'react';
import util from '../../utils/miniUtils';
import { Card, CardContent } from '@material-ui/core';

const ActiveEmployee = () => {
    const [ activeEmployee, setActiveEmployee ] = useState( 0 );
   
    useEffect( () =>{
        getActiveEmployee(  );
    },[] );

    const getActiveEmployee = async ( ) => {
        try {
            const response = await util.axios.get(
                `new_dashboard/get_active_Employee/`
            );  
            const { data, error, msg } = response.data;
            console.log( data );
            setActiveEmployee( data );
            
            if ( error ) throw msg;
        } catch ( e ) {
            util.handleError( e );
        }
    };
    
    return (
        <>
            <Card style={{ width: "100%", height: "70%" }}>
                <CardContent>
                    
                    <div>
                        {activeEmployee.length >0 && (
                            <h2 >{activeEmployee[0].count}</h2> )}
                    </div>

                </CardContent>
            </Card>
            
        </>
    );
};

export default ActiveEmployee;