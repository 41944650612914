import React, { Fragment, useState } from "react";
import util from "../../../../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";

export default function JobSettings({ markUp, billableDaysInYear, workingHoursInDay, averageBillableDaysInMonth, additionalDays, ctc, onChange }) {

    const [show, setShow] = useState(false);
    // console.log( ctc.additionalInputCost,ctc.workingHoursInDay );
    return (
        <Fragment>
            {ctc.employmentType === "Fulltime Employee" ?
                <div>
                    <span onClick={() => setShow(!show)} className="likeLink m-2"><FontAwesomeIcon icon={faCog} /> CTC-Parameter Settings for Fulltime Employee</span>
                    {
                        show ?

                            <Fragment>
                                <div className="row">
                                    <div className="col-md-12">
                                        <b style={{ padding: '5px' }}>Special Cases:</b>
                                    </div>
                                </div>
                                <div className="row pt-3" >
                                    <div className="col-md-6">
                                        <b style={{ padding: '5px' }}>Additional Vacation Days Provided to Talent:</b>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="number" className="form-control" style={{ padding: '5px' }} value={ctc.additionalDays} name="additionalDays" onChange={onChange} />
                                    </div>
                                </div>
                                <div className="row pt-3" >
                                    <div className="col-md-6">
                                        <b style={{ padding: '5px' }}>Additional Annual Costs Associated with Hiring the Talent, Including Visa and third-party Payroll costs:</b>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="number" className="form-control" style={{ padding: '5px' }} value={ctc.additionalInputCost} name="additionalInputCost" onChange={onChange} />
                                    </div>
                                </div>

                                <div>
                                    <div className="row text-center">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-12">Loading</div>
                                                <div className="col-md-12">{markUp}%</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-12">Billable Days in a Year</div>
                                                <div className="col-md-12">{billableDaysInYear}
                                                    {!util.noValues.includes(additionalDays) ? ` (-${additionalDays})` : null}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row text-center">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-12">Working Hours in Day</div>
                                                <div className="col-md-12">{workingHoursInDay}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-12">Avg. Billable Days in a Month</div>
                                                <div className="col-md-12">{averageBillableDaysInMonth}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row text-center">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-12">Days on input Cost</div>
                                                <div className="col-md-12">{!util.noValues.includes(ctc.additionalInputCost) ?
                                                    `(${(ctc.additionalInputCost / ctc.ctcPerDay).toFixed(2)})` :
                                                    null}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6"></div>
                                    </div>
                                </div>
                            </Fragment>

                            :
                            null
                    }
                </div> : ""}
        </Fragment>
    );
}