import React from 'react';
import { Modal } from 'react-bootstrap';

const JobStatusForm = ( { show, form, handleClose, handleSave, handleChange, isEdit } ) => {

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>{isEdit ? "Edit Talent Status" :"Add Job Talent Status"}</Modal.Header>
            <Modal.Body>
                <div className='form-group'>
                    <label>Name</label>
                    <input className='form-control' type="text" name="name" value={form.name} onChange={handleChange} />
                </div>
                <div className='form-group'>
                    <label>Description</label>
                    <input className='form-control' type="text" name="description" value={form.description} onChange={handleChange} />
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <div className="input-group-text">
                            <input name="active" 
                                checked={form.active === 1 ? true : false }
                                type="checkbox"
                                aria-label="active"
                                onChange={handleChange} />
                        </div>
                    </div>
                    <input
                        type="text" 
                        className="form-control" 
                        aria-label="Text input with checkbox" 
                        disabled value="Active"/>
                </div>
                <div className="form-group">
                    <label>Color</label>
                    <select className='form-control' name="color" value={form.color} onChange={handleChange}>
                        <option value={null}></option>
                        <option className="text-danger" value="danger">Red</option>
                        <option className="text-warning" value="warning">Yellow</option>
                        <option className="text-success" value="success">Green</option>
                    </select>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <span className='btn btn-sm btn-danger' onClick={handleClose}>Cancel</span>
                <span className='btn btn-sm btn-primary' onClick={() => handleSave( form )}>Save</span>
            </Modal.Footer>
        </Modal>
    );
};

export default JobStatusForm;