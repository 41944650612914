import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import util from '../../utils/miniUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Chip, MuiThemeProvider } from '@material-ui/core';
import SAlert from '../../microComponents/AlertSA';

const MemberList = ( { selectedEmployer: selectedEmployer } ) => {
    console.log( selectedEmployer );
    const [members, setMembers] = useState( [] );
    const [showDeleteModal, setShowDeleteModal] = useState( false );
    const [memberToDelete, setMemberToDelete] = useState( null );


  
    const handleDeleteClick = ( member ) => {
        setMemberToDelete( member );
        setShowDeleteModal( true );
    };

    // const handleDeleteMember = () => {
    //     const updatedMembers = members.filter( ( member ) => member.id !== memberToDelete.id );
    //     setMembers( updatedMembers );
    //     setShowDeleteModal( false );
    //     setMemberToDelete( null );
    // };
   

    
    const getEmployerMembers = async ( id ) => {
        try {
            const request = await util.axios.get( `/employers_subordinates/get_members/${id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setMembers( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };
   
   

    useEffect( () => {
        getEmployerMembers( selectedEmployer.employer_id );
    },[] );

    if( !selectedEmployer ) {
        return "";
    }
    const columns = [
        {
            title: "Name",
            field: "name",
            render: ( rowData ) => (
                <div>{rowData.name}</div>
            ),
        },
        {
            title: "Email",
            field: "email",
            render: ( rowData ) => (
                <a href={`mailto:${rowData.email}`} style={{ color: "blue" }}>
                    {rowData.email}
                </a>
            ),
        },
        {
            title: "Status",
            field: "status",
            render: ( rowData ) => (
                <Chip
                    size="small"
                    label={rowData.status === 0 ? "Invited" : "Accepted"}
                    className={`text-white ${rowData.status === 0 ? "bg-primary" : "bg-success"}`}
                />
            ),
        },
        {
            title: "Role",
            field: "role",
            render: ( rowData ) => <div>{rowData.roles}</div>,
        },
        {
            title: "Actions",
            sorting: false,
            render: ( rowData ) => (
                <div style={{ display: "flex", gap: "10px" }}>
                  
                    <FontAwesomeIcon
                        icon={faTrash}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDeleteClick( rowData )}
                    />
                </div>
            ),
        },
    ];
    const handleDeleteMember = async () => {
        try {
            const response = await util.axios.delete( `/employer_invitation/${memberToDelete.id}?email=${encodeURIComponent( memberToDelete.email )}` ); // Sending email as a query parameter
            const { error, msg } = response.data;
    
            if ( error ) throw msg;
    
            getEmployerMembers( selectedEmployer.employer_id );
            setShowDeleteModal( false );
            setMemberToDelete( null );
    
            util.toast().success( msg );
        } catch ( error ) {
            console.error( error );
            util.handleError( error );
        }
    };

    return (
        <>
            <MuiThemeProvider theme={util.defaultTableTheme}>
                <MaterialTable
                    columns={columns}
                    title="Employer's Members List"
                    data={members}
                    options={{
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50],
                        sorting: true,
                        search: true,
                        exportButton: true,
                        selection: true,
                        headerStyle: { backgroundColor: "#f5f5f5", fontWeight: "bold" },
                        maxBodyHeight: "60vh",
                    }}
                   
                />
            </MuiThemeProvider>

            
           
                    No Members available
            {showDeleteModal ?
                <SAlert
                    show={showDeleteModal}
                    msg={`Are you sure you want to delete this member: ${memberToDelete.email}?`}
                    hideAlert={() => setShowDeleteModal( false )}
                    onConfirm={handleDeleteMember}
                /> : ""}

            
        </>
    );
};

export default MemberList;

