import React, { Fragment, useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import util from "../../../utils/miniUtils";
import selector from "../../../utils/selector";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "../../../../assets/css/main.css";
import SAlert from "../../../microComponents/AlertSA";
import { LinkRounded } from "@material-ui/icons";

import ModalSelectPartnerClient from "./ModalSelectPartnerClient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPencilAlt, faCopy } from "@fortawesome/free-solid-svg-icons";
import { Paper, TextField, Tooltip } from "@material-ui/core";
// import LocationCX from "../../../shared/LocationCX";
import SkillSelector from "../../../shared/selector/SkillSelector";
import CertificateSelector from "../../../shared/selector/CertificationSelector";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ModalScreeningQuestions from "./ModalScreeningQuestions";
import LocationComponent from "../LocationComponent";

const classLabelsMUI = "MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled";

function JobEditForm( props ) {
    const [job, setJobData] = useState( props.job );
    const [backUpJob, setBackUp] = useState( job );
    const [isDisabled, setIsDisabled] = useState( props.job.status === 3 );
    const [marginShow, setMarginShow] = useState( false );
    const [modalVisible, setModalVisible] = useState( false );
    const [modalType, setModalType] = useState( false );
    const [marginLower, setMarginLower] = useState( false );
    const [isVisible, setIsVisible] = useState( true );
    const [recruiters, setRecruiters] = useState( [] );
    const [catalogJobDesc, setCatalogJobDesc] = useState( [] );
    const [disabledByOnboarding, setDisabledByOnboarding] = useState( false );
    const [skillsList, setSkillsList] = useState( [] );
    const [showScreeningQuestions, setShowScreeningQuestions] = useState( false );
    const [open, setOpen] = useState( false );
    const [certificationList, setCertificationList] = useState( [] );
    const [vendorList, setVendorList] = useState( [] );
    const [selectedVendors, setSelectedVendors] = useState( [] );


    // const [skills,setSkills] = useState( props.skills )
    const setShowJobEditForm = props.setShowJobEditForm;
    const PARTNER = 4;
    const CLIENT = 3;
    useEffect( () => {
        // Listen when update is pressed from accordion
        if ( props.updateChanges !== null ) {
            handleUpdateJobPosition();
        }
        // eslint-disable-next-line
    }, [props.updateChanges]);

    useEffect( () => {
        // Listen when cancel is pressed from accordion
        if ( props.cancelChanges !== null ) {
            setJobData( backUpJob );
        }
        // eslint-disable-next-line
    }, [props.cancelChanges]);

    useEffect( () => {
        // After the job is loaded, we need to update the UI of the deal type and hide partner if is direct client
        // This occurs only one time after load the job information
        if ( job.deal_type === "Direct Client" ) {
            setIsVisible( false );
        } else {
            setIsVisible( true );
        }
    }, [job] );

    useEffect( () => {
        getVendorList();
        getAssignedVendors();
        getRecruiters().then( null );
        getCatalog().then( null );
    }, [] );

    useEffect( () => {
        let disable = false;
        props.talents.forEach( d => {
            if ( parseInt( d.status ) === 13 ) {
                disable = true;
            }
        } );
        setDisabledByOnboarding( disable );
    }, [props.talents] );

    useEffect( () => {
        // Refresh al fields with the data of the selected job from the sidebar
        setIsDisabled( props.job.status === 3 );
        return () => { };
    }, [props.job.status] );

    async function getRecruiters() {
        try {
            if( !util.user.employer_id ){
                const request = await util.axios.get( `${util.url}/app_resources/users_by_role/1` );
                const { error, msg, users } = request.data;
                if ( error ) throw msg;
                setRecruiters( users );
            }else{
                const request = await util.axios.get(
                              `${util.url}/employers_subordinates/get_members/${util.user.employer_id}`
                );
                const { error, data } = request.data;
                        
                if ( error ) throw error;
                setRecruiters( data );
            }
        } catch ( error ) {
            util.handleError( error );
        }
    }

    const getListofSkills = async ( search ) => {
        try {
            search.replace( /\//g, "" );
            const encodedSearch = encodeURIComponent( search );
            const response = await util.axios.get( `catalog/catalog_skills_search/${encodedSearch}` );
            const { error, msg, data } = response.data;
            if ( error ) return util.handleError( msg );
            setSkillsList( data );
        } catch ( e ) {
            util.handleError( e );
        }
    };

    const getCertificationsList = async ( search = "" ) => {
        try {
            const response = await util.axios.get( `/talent/certification_search?search=${search}` );
            const { error, msg, data } = response['data'];
            if ( error ) throw new Error( msg );
            setCertificationList( data );
            // console.log("Fetched certifications:", data);
        } catch ( e ) {
            util.handleError( e );
        }
    };

    const getCatalog = async () => {
        // Make a request for catalog of job descriptions
        try {
            const response = await util.axios.get( `${util.url}/app_resources/job_descriptions` );
            const { error, msg, job_descriptions } = response["data"];
            if ( error ) return util.handleError( msg );
            setCatalogJobDesc( job_descriptions );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const getVendorList = async () => {
        try {
            const request = await util.axios.get( "/vendor" );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setVendorList( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const getAssignedVendors = async () => {
        try {
            const request = await util.axios.get( `/vendor/job/${job.id}` );
            const { data, error, msg } = request.data;
            if( error ) throw msg;
            setSelectedVendors( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    function handleChange( e ) {

        let { name, value } = e.target;

        if ( name === "deal_type" ) {
            if ( value === "Direct Client" ) {
                setIsVisible( false );
            } else {
                setIsVisible( true );
            }
        }
        setJobData( { ...job, [name]: value } );

    }

    function handleRichTextChange( e ) {
        const value = e.toString( "html" );
        setJobData( { ...job, 'job_description': value } );
    }

    function handleUpdateJobPosition() {
        if ( marginLower && job.margin < 20 ) {
            util.toast().warn( "You're using a lower margin tan 20%" );
            return;
        }
        util
            .axios
            .patch( `${util.url}/client_requirements/update/${job.id}`, { ...job, vendors: selectedVendors } )
            .then( response => {
                const { error, msg, job } = response.data;
                if ( error ) throw ( msg );
                props.handleChange( job );
                util.toast().success( msg );
                setBackUp( job );
                props.setPosition( job );
            } )
            .catch( err => util.toast().error( err ) );
        props.setShowJobEditForm( false );
    }

    const copyToClipboard = () => {
        const url = window.document.location.origin + `/careers?swe=${util.uuidv4()}&job=${job.id}&fs=true`;
        navigator.clipboard.writeText( url ).then( null );
        setOpen( true );
    };

    function onConfirmLowerMargin() {
        setMarginLower( true );
    }

    function selectAction( modalOption ) {
        setModalType( modalOption );
        setModalVisible( true );
    }

    function hideModal() {
        setModalType( PARTNER );
        setModalVisible( false );
    }

    function selectedData( opc, data ) {
        let jobTemp = job;
        if ( opc === PARTNER ) {
            jobTemp.partner = data;
        } else {
            jobTemp.client = data;
        }
        setJobData( Object.assign( {}, jobTemp ) );
    }

    function handleChangeAutoComplete( value ) {
        // let e ={target:{name: "job_title", value: value}}
        // handleChange(e);
        // must trigger job description
        const j = catalogJobDesc.filter( ( j ) => j.title === value )[0];
        // let e2 ={target:{name: "job_description", value: j.description}}
        // handleChange(e2);
        setJobData( { ...job, job_title: value, job_description: j.description } );
    }

    function handleSkillUpdate( name, value ) {
        // setJobData( { ...job, skills: [value] } );
        if ( name === "skills" ) {
            setJobData( ( prevJob ) => ( { ...prevJob, skills: [...prevJob.skills, value] } ) );
        }
        else {
            setJobData( ( prev ) => ( { ...prev, skills: [...value] } ) );
        }
    }
    function handleCertificationUpdate( name, value ) {
        // setJobData( { ...job, skills: [value] } );
        if ( name === "certification" ) {
            setJobData( ( prevJob ) => ( { ...prevJob, certification: [...prevJob.certification, value] } ) );
        }
        else {
            setJobData( ( prev ) => ( { ...prev, certification: [...value] } ) );
        }
    }

    return (
        <Fragment>
            <Paper elevation={1}>
                <div className="container-fluid p-3">
                    <div className="row mt-5">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-10" style={{ marginLeft: '3px' }}>
                                    <Tooltip title="Copy Job Link">
                                        <span className="likeLink" onClick={copyToClipboard}>
                                            <LinkRounded size={32} icon={faCopy} />
                                        </span>
                                    </Tooltip>
                                    <b style={{ marginLeft: "-2px" }}>Job Information</b>
                                    <Autocomplete
                                        freeSolo
                                        value={job.job_title}
                                        disableClearable
                                        disabled={isDisabled}
                                        onChange={( e, value ) => handleChangeAutoComplete( value )}
                                        options={catalogJobDesc.map( ( option ) => option.title )}
                                        renderInput={( params ) => (
                                            <TextField
                                                {...params}
                                                label="Job Title *"
                                                size="small"
                                                margin="normal"
                                                name="job_title"
                                                onChange={handleChange}
                                                variant="outlined"
                                                InputProps={{ ...params.InputProps, type: "search" }}
                                            />
                                        )}
                                    />
                                </div>
                                {/* <div className="col-md-10 ml-1">
                                    <util.RowComponent
                                        disabled={isDisabled}
                                        t={"Certification"}
                                        n={"certification"}
                                        m={handleChange}
                                        v={job.certification||""}
                                    />

                                </div> */}
                                <div className="col-md-10 ml-1 ">
                                    <CertificateSelector
                                        getCertificationList={getCertificationsList}
                                        list={certificationList}
                                        updateFilter={handleCertificationUpdate}
                                        filteredCertifications={job.certification}
                                        setCertificationList={setCertificationList}
                                    /></div>

                                <div className="col-md-10 ml-1">

                                    <SkillSelector
                                        getSkillList={getListofSkills}
                                        list={skillsList}
                                        updateFilter={handleSkillUpdate}
                                        filteredSkills={job.skills}
                                        setSkillsList={setSkillsList}
                                    />
                                </div>
                            </div>
                            { !util.user.employer_id &&  <div className="row ml-1 mr-1">
                                <util.RowComponent
                                    disabled={isDisabled}
                                    c={5}
                                    t={"Role"}
                                    n={"role"}
                                    m={handleChange}
                                    v={job.role || "Technician"}
                                    type={1}
                                    d={selector.role()}
                                />
                                <util.RowComponent
                                    disabled={isDisabled}
                                    c={5}
                                    t={"Scope"}
                                    n={"scope"}
                                    m={handleChange}
                                    v={job.scope || "Project Support"}
                                    type={1}
                                    d={selector.scope()}
                                />
                            </div>}
                            <div className="row ml-1 mr-1">
                                <util.RowComponent
                                    disabled={isDisabled}
                                    c={5}
                                    t={"Level"}
                                    n={"level"}
                                    m={handleChange}
                                    v={job.level || "Experienced (L2)"}
                                    type={1}
                                    d={selector.level()}
                                />
                                <util.RowComponent
                                    disabled={isDisabled}
                                    c={5}
                                    t={"Education"}
                                    n={"education"}
                                    m={handleChange}
                                    v={job.education || "University"}
                                    type={1}
                                    d={selector.education()}
                                />


                            </div>
                            <div className="paddingleft0 col-md-10 mt-2 ml-2">
                                <label className="text-muted textSmallLH">Job Description *</label>
                                {/* <Editor
                                    apiKey='co5tvvfh4ltli0l9urtqbvfbrevuswtkqdmu3lvf0t3lme9p'
                                    value={job.job_description || ""}
                                    disabled={isDisabled}
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        toolbar:
                        "formatselect | bold italic| alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat"
                                    }}
                                    onEditorChange={handleRichTextChange}
                                /> */}
                                <ReactQuill
                                    value={job.job_description || ""}
                                    onChange={handleRichTextChange}
                                    className="editJobDescription"
                                    modules={{
                                        toolbar: [
                                            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                            ['bold', 'italic', 'underline'],
                                            [{ 'align': [] }],
                                            ['clean']
                                        ],
                                    }}
                                    formats={[
                                        'header', 'font',
                                        'bold', 'italic', 'underline',
                                        'list', 'bullet',
                                        'align'
                                    ]}
                                />
                            </div>
                        </div>

                        <div className="col-md-6">
                            {!util.user.employer_id &&   <b style={{ marginLeft: "-2px" }}>Basic Information</b> }
                            {!util.user.employer_id &&   <div className="row">
                                <div className={`col-md-4 p-1 mt-3 `}>
                                    <TextField
                                        select
                                        size={"small"}
                                        variant="outlined"
                                        disabled={isDisabled}
                                        name="deal_type"
                                        label="Deal Type *"
                                        value={job.deal_type || "Channel Partner"}
                                        onChange={handleChange}
                                        InputLabelProps={{ shrink: true }}
                                        SelectProps={{
                                            native: true
                                        }}

                                    >
                                        {selector.dealType().map( option => (
                                            <option key={Math.random() * Math.random()} value={option["deal_type"]}>
                                                {option["deal_type"]}
                                            </option>
                                        ) )}
                                    </TextField>
                                </div>
                                {isVisible ?
                                    <div className={`col-md-3 col-xs-12 p-1 mt-3`}>
                                        <div className={classLabelsMUI}>Partner Information</div>
                                        {job.partner !== null ?
                                            <util.BoxSimple data={job.partner} editInfo={() => selectAction( PARTNER )} disabled={isDisabled} />
                                            : <button disabled={isDisabled} className="btn btn-default" onClick={() => selectAction( PARTNER )}>Select partner <FontAwesomeIcon icon={faPencilAlt} /></button>}

                                    </div> : ""}

                                <div className={`col-md-3 col-xs-12 p-1  mt-3`}>
                                    <div className={classLabelsMUI}>End Client</div>
                                    {job.client !== null ? <util.BoxSimple data={job.client} editInfo={() => selectAction( CLIENT )} disabled={isDisabled} /> : ""}
                                </div>
                                <div className={`col-md-3 col-xs-12 p-1`}>
                                    <div className="ml-2">Client Reference ID</div>
                                    <util.RowComponent
                                        disabled={isDisabled}
                                        n={"client_requirement_id"}
                                        m={handleChange}
                                        v={job.client_requirement_id || ""}
                                    />
                                </div>
                            </div> }

                            {!util.user.employer_id &&   <div className="row">
                                <div className="col-10" style={{ padding: "5px" }}>
                                    <Autocomplete
                                        multiple
                                        id="tags-outlined"
                                        options={vendorList}
                                        getOptionLabel={( option ) => option.vendor_name}
                                        onChange={( event, newInputValue ) => {
                                            setSelectedVendors( newInputValue );// Update input value on input change
                                        }}
                                        value={selectedVendors}
                                        renderInput={( params ) => (
                                            <TextField
                                                {...params}
                                                label="Select Vendor"
                                                placeholder="Vendor"
                                                variant="outlined"
                                                size="small"
                                            />
                                        )}
                                    />
                                </div>
                            </div> }

                            <div className="row">
                                <util.RowComponent
                                    disabled={isDisabled}
                                    c={5}
                                    t={"Employment Type"}
                                    n={"employment_type"}
                                    m={handleChange}
                                    v={job.employment_type || "Any"}
                                    type={1}
                                    d={selector.employment_type()}
                                />
                                <util.RowComponent
                                    disabled={isDisabled}
                                    c={5}
                                    t={"Restricted To"}
                                    n={"restricted_to"}
                                    m={handleChange}
                                    v={job.restricted_to}
                                    type={1}
                                    d={selector.restricted_to()}
                                /></div>
                            <div className="row">
                                <util.RowComponent
                                    disabled={isDisabled}
                                    c={5}
                                    t={"Duration in months *"}
                                    n={"duration"}
                                    m={handleChange}
                                    v={job.duration}
                                />

                                <util.RowComponent
                                    disabled={isDisabled}
                                    c={5}
                                    t={"Priority"}
                                    n={"priority"}
                                    m={handleChange}
                                    v={job.priority || "Mid"}
                                    type={1}
                                    d={selector.priority()}
                                />

                            </div>
                            <b>Compensation Range</b>
                            <div className="row">
                                <div className={`col-md-3 p-1 mt-2 align-self-center`}>
                                    <TextField
                                        size={"small"}
                                        className="w-100"
                                        disabled={isDisabled}
                                        InputLabelProps={{ shrink: true }}
                                        value={job.buy_from || ""}
                                        type="number"
                                        label="Minimum"
                                        fullWidth={true}
                                        InputProps={{
                                            startAdornment: util.symbolDependingCurrencySelected(
                                                job.country
                                            ),
                                        }}
                                        variant="outlined"
                                        name="buy_from"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className={`col-md-4 p-1 mt-2 align-self-center`}>
                                    <TextField
                                        size={"small"}
                                        className="w-100 mb-1"
                                        InputLabelProps={{ shrink: true }}
                                        value={job.buy_to || ""}
                                        type="number"
                                        label="Maximum"
                                        fullWidth={true}
                                        InputProps={{
                                            startAdornment: util.symbolDependingCurrencySelected(
                                                job.country
                                            ),
                                        }}
                                        variant="outlined"
                                        name="buy_to"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                {!util.user.employer_id &&    <div className={`col-md-5 p-1 mt-2 align-self-center`}>
                                    <TextField
                                        size={"small"}
                                        disabled={isDisabled}
                                        InputLabelProps={{ shrink: true }}
                                        value={job.sell_rate || ""}
                                        type="number"
                                        label="Job Sell Rate"
                                        fullWidth={true}
                                        InputProps={{
                                            startAdornment: util.symbolDependingCurrencySelected(
                                                job.country
                                            ),
                                        }}
                                        variant="outlined"
                                        name="sell_rate"
                                        onChange={handleChange}
                                    /> 
                                </div>   }

                                <util.RowComponent
                                    disabled={isDisabled}
                                    c={5}
                                    t={"Offer Type"}
                                    n={"sell_rate_type"}
                                    m={handleChange}
                                    v={job.sell_rate_type || "Per Hour"}
                                    type={1}
                                    d={selector.sell_rate_type3}
                                />
                            </div>

                            <b>Location</b>
                            <div className="row">
                                <LocationComponent job={job} setJob={setJobData} />
                            </div>
                            {job.country ? <div className="row">
                                <div className="col d-flex">{`${job.country}, ${job.state}`}</div>
                            </div> : ""}


                            <b>Onboarding Contacts</b>
                            {!util.user.employer_id &&    <div className="row mt-2">
                                <util.RowComponent
                                    disabled={isDisabled}
                                    c={5}
                                    t={"Reporting Manager"}
                                    n={"reporting_manager"}
                                    m={handleChange}
                                    v={job.reporting_manager || ""}
                                />
                                <util.RowComponent
                                    disabled={isDisabled}
                                    c={5}
                                    t={"Reporting Manager Phone"}
                                    n={"reporting_manager_phone"}
                                    m={handleChange}
                                    v={job.reporting_manager_phone || ""}
                                />
                            </div> }
                            {!util.user.employer_id &&   <div className="row mt-2">
                                <util.RowComponent
                                    disabled={isDisabled}
                                    c={5}
                                    t={"Reporting Manager Address"}
                                    n={"reporting_manager_address"}
                                    m={handleChange}
                                    v={job.reporting_manager_address || ""}
                                />

                                <TextField
                                    style={{ marginTop: '11px' }}
                                    size="small"
                                    select
                                    variant="outlined"
                                    disabled={disabledByOnboarding}
                                    name="sales_lead"
                                    className="col-md-5"
                                    label="Sales Lead *"
                                    value={job.sales_lead || "None"}
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }}
                                    SelectProps={{ native: true }}
                                >
                                    <option value=""> -- </option>
                                    {recruiters.map( ( option, index ) => (
                                        <option key={index} value={option.id}>
                                            {option.name}
                                        </option>
                                    ) )}
                                </TextField>
                            </div> }

                            <div className='row mt-3'>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    className="col-md-5"
                                    disabled={disabledByOnboarding}
                                    name="lead_recruiter"
                                    label={!util.user.employer_id?"Lead Recruiter *":"Primary Recruiter"}
                                    value={job.lead_recruiter || "None"}
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }}
                                    SelectProps={{ native: true }}
                                >
                                    <option value=""> -- </option>
                                    {recruiters.map( ( option, index ) => (
                                        <option key={index} value={option.id}>
                                            {option.name}
                                        </option>
                                    ) )}
                                </TextField>

                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    className="col-md-5 ml-1"
                                    name="secondary_recruiter"
                                    label="Secondary Recruiter"
                                    value={job.secondary_recruiter || "None"}
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }}
                                    SelectProps={{ native: true }}
                                >
                                    <option value=""> -- </option>
                                    {recruiters.map( ( option, index ) => (
                                        <option key={index} value={option.id}>
                                            {option.name}
                                        </option>
                                    ) )}
                                </TextField>
                                {job.questions && <div className='col-md-6 p-1 mt-2'>
                                    <span className="btn btn-sm btn-light" onClick={() => setShowScreeningQuestions( true )}>Screening Questions</span>
                                </div>}
                            </div>
                        </div>
                    </div>
                    {
                        isDisabled ?
                            ""
                            :
                            <Fragment>
                                <button
                                    onClick={handleUpdateJobPosition}
                                    className="float-right btn btn-primary btn-sm mt-5"
                                >
                                    <FontAwesomeIcon icon={faEdit} className="ml-2" />
                                    Update
                                </button>

                                <button
                                    onClick={() => {
                                        setJobData( backUpJob );
                                        setShowJobEditForm( false );

                                    }}
                                    className="float-right btn text-danger mr-2 mt-5"
                                >
                                    Cancel
                                </button>
                            </Fragment>

                    }
                </div>

                {marginShow ?
                    <SAlert
                        show={marginShow}
                        confirmText="Yes"
                        typeButton="warning"
                        msg={"Do you want to use a lower margin in this Job?"}
                        hideAlert={setMarginShow( false )}
                        onConfirm={onConfirmLowerMargin}
                        opc={0}
                    /> : ""}

                <ModalSelectPartnerClient
                    selectedData={selectedData}
                    hideModal={hideModal}
                    modalVisible={modalVisible}
                    modalType={modalType}
                />
                <ModalScreeningQuestions
                    show={showScreeningQuestions}
                    hideModal={() => setShowScreeningQuestions( false )}
                    questions={job.questions} />
            </Paper>

        </Fragment>
    );
}

export default JobEditForm;
