import { Card } from '@material-ui/core';
import React from 'react';
import util from '../../utils/miniUtils';

const WelcomeComponent = () => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    let greeting;

    if ( currentHour >= 5 && currentHour < 12 ) {
        greeting = 'Good Morning,';
    } else if ( currentHour >= 12 && currentHour < 18 ) {
        greeting = 'Good Afternoon,';
    } else {
        greeting = 'Good Evening,';
    }

    return (
        <Card style={{ height: "85%" }}>
            <h2>{greeting}</h2>
            <h3>{util.user.name}</h3>
        </Card>
    );
};

export default WelcomeComponent;
