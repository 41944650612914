import React, { useEffect, useState } from "react";
import {
    Card,   
    CardContent,
} from "@material-ui/core";
import util from "../../utils/miniUtils";
import ImageComponent from "../../utils/ImageComponent";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../../redux/actions/datefilter";
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";

const RecruiterDetails = () => {
    const [recruiterDetails, setRecruiterDetails] = useState( [] );
    const [selectedRecruiters, setSelectedRecruiters] = useState( [] );
    const [selectedCountries, setSelectedCountries] = useState( [] );
    const [recruiter, setRecruiter] = useState( [] );
    const [country, setCountry] = useState( [] );
    let { from, to, applyFilter } = useSelector( ( state ) => state.filter ) || {};
    const [originalRecruiterPerformance, setOriginalRecruiterPerformance] = useState( [] );

    const dispatch = useDispatch();
    
    useEffect( () =>{
        if( applyFilter ) {
            getRecruiterPerformance();
        }
    },[applyFilter] );

    const getRecruiterPerformance = async (  ) => {
        try {
            if( from === undefined ){
                const currentDate = new Date();
                from = currentDate.getFullYear() + '-' +
                  ( currentDate.getMonth() + 1 ) + '-' +
                  currentDate.getDate();
            }
            if( to === undefined ){
                const currentDate = new Date();
                to = currentDate.getFullYear() + '-' +
                ( currentDate.getMonth() + 1 ) + '-' +
                currentDate.getDate();
            }
            from = from ? util.convertToUTC( from ) : '';
            to = to ? util.convertToUTC( to ) : '';
            const response = await util.axios.get(
                `new_dashboard/get_recruiter_details?from=${from}&to=${to}`
            );

            const { data, error, msg } = response.data;

            setRecruiterDetails( data );
            setOriginalRecruiterPerformance( data );
            const uniqueRecruiters = [...new Set( data?.map( item => item.leadRecruiterName ) )];
            const uniqueCountries = [...new Set( data?.map( item => item.job_country ) )];

            // Convert to options format { label: "value", value: "value" }
            const recruiterOptions = uniqueRecruiters?.map( name => ( { label: name, value: name } ) );
            const countryOptions = uniqueCountries?.map( country => ( { label: country, value: country } ) );

            setCountry( countryOptions );
            setRecruiter( recruiterOptions );
            dispatch( setFilter( false ) );
            if ( error ) throw msg;
        } catch ( e ) {
            util.handleError( e );
        }
    };
    // const statusNoteParsing = ( item ) =>{
    //     let statusNote = "";
    //     let isStr = false;
    //     try {
    //         statusNote = JSON.parse( item?.statusNote.statusName );
    //     } catch ( ex ) {
    //         isStr = true;
    //         statusNote = item.statusNote;
    //     }
    // };               
    const statusNoteParsing = ( item ) => {
        let statusNote = "";
        let isStr = false;
        try {
            statusNote = JSON.parse( item?.statusNote );
         
        } catch ( ex ) {
            isStr = true;
            statusNote = item.statusNote;
        }
        return isStr ? statusNote : statusNote[0].statusName;
    };    

    const renderOnboardedTalent =( item ) => {

        return ( <>
            {item.onboardedTalent.map( ( el, index ) => {
            
                return ( <div key={index}>
                    <span>{index+1 + '. '}</span>
                    <span>{el}</span>
                </div> );
            } )}
        </> );
       
         
    };
    const handleCountryChange = ( selectedCountries ) => {
        setSelectedCountries( selectedCountries );
  
        let filteredRecruiter = originalRecruiterPerformance?.filter( item =>
            ( selectedRecruiters?.length === 0 || selectedRecruiters?.some( sel => sel.value === item.leadRecruiterName ) ) &&
                ( selectedCountries?.length === 0 || selectedCountries?.some( sel => sel.value === item.job_country ) )
        );
        setRecruiterDetails( [...filteredRecruiter] );
     
        
    };
    const handleRecruiterChange = ( selectedRecruiters ) =>{
        setSelectedRecruiters( selectedRecruiters );
        
        let filteredRecruiter = originalRecruiterPerformance?.filter( item =>
            ( selectedRecruiters?.length === 0 || selectedRecruiters?.some( sel => sel.value === item.leadRecruiterName ) ) &&
                ( selectedCountries?.length === 0 || selectedCountries?.some( sel => sel.value === item.job_country ) )
        );
        setRecruiterDetails( [...filteredRecruiter] );
       
        
    };

    const exportToCsv = () => {
        const headings = [
            "Job Title",
            "Lead Recruiter Name",
            "Job Id",
            "Client Name",
            "Partner Name",
            "Sales Lead Name",
            "Onboarded TalenT",
            "Country",

        ];
      
        const csvContent =
          "data:text/csv;charset=utf-8," +
          [headings.join( ',' ), ...recruiterDetails?.map( row =>
              [
                  row.job_title,
                  row.leadRecruiterName,
                  row.job_id,
                  row.clientName,
                  row.partnerName,
                  row.salesLeadName,
                  row.onboardedTalent,
                  row.job_country,
              ].join( ',' )
          )].join( '\n' );
      
        const encodedUri = encodeURI( csvContent );
        const link = document.createElement( "a" );
        link.setAttribute( "href", encodedUri );
        link.setAttribute( "download", "table-export.csv" );
        document.body.appendChild( link );
        link.click();
    };

    return (
        <Card style={{ width: "100%", height: "90%", overflow: 'hidden' }}>
            <CardContent style={{ height: "100%" }} >
                <div style={{ height: "100%", overflow: "auto" }}>
                    
                    <div className="recruiter-filter">
                        <Select
                            isMulti
                            placeholder="Select Lead recruiters..."
                            value={selectedRecruiters}
                            onChange={handleRecruiterChange}
                            options={recruiter}
                            className="mr-2 non-draggable dashboard-select"
                        />
                        <Select
                            isMulti
                            placeholder="Select countries..."
                            value={selectedCountries}
                            onChange={handleCountryChange}
                            options={country}
                            className="mr-2 non-draggable dashboard-select"
                        />
                        <div className={"btn btn-secondary jobButton activeBtn btn-sm export-onboarded non-draggable"} onClick={exportToCsv}>
                            <FontAwesomeIcon icon={faFileExport} className="mr-2" />
                                Export All
                        </div>
                        {/* <button className="m-2 btn btn-secondary sizeBtn activeBtn" onClick={handleApplyClick}>Apply</button> */}
                    </div>
                    {/* <table className="table table-bordered"> */}
                    <table className="custom-table-dashboard-recruiter">
                        <thead>
                            <tr>
                                <th>Job Title </th>
                                <th>Lead Recruiter Name</th>
                                <th>Job Id</th>
                                {/* <th>Last Change User</th> */}
                                <th>Client Name</th>
                                <th>Partner Name</th>
                                <th>Sales Lead Name</th>
                                <th>Onboarded Talent</th>
                                {/* <th>Total Talent Onboarded</th> */}
                                {/* <th>Status Note</th> */}
                                {/* <th>jobid</th> */}
                                <th>Country</th>
                                {/* <th>Submitted Today</th> */}
                            </tr>
                        </thead>
                        <tbody className="recruiter-report-body">
                            {recruiterDetails?.map( ( item,index ) => (
                                <tr key={index} className="recruiter-report-rows">
                                    {/* <td>{item.recruiter_name}</td> */}
                                    <td>{item.job_title}</td>
                                    <td>{item.leadRecruiterName}</td>
                                    <td>{item.job_id}</td>
                                    {/* <td>{item.lastChangeUser}</td> */}
                                    <td>{item.clientName}</td>
                                    <td>{item.partnerName}</td>
                                    <td>{item.salesLeadName}</td>
                                    {/* <td>{item.onboardedTalent ? item.onboardedTalent.join( ',' ):""}</td> */}
                                    <td>{item.onboardedTalent ? renderOnboardedTalent( item ):""}</td>
                                    {/* <td>{JSON.parse( item.statusNote )[0].statusName  }</td> */}
                                    {/* <td>{item.statusNote ? statusNoteParsing( item ):""}</td> */}
                                    {/* <td>{item.job_id}</td> */}
                                    <td>
                                        <img width={25} src={util.countryFlag( item.job_country )} alt={item.job_country} className="mr-1"/>
                                        {item.job_country}</td>
                                    {/* <td>{item.submittedToday}</td> */}
                                    {/* <td>{item.status}</td> */}
                                    
                                </tr>
                            ) )}
                        </tbody>
                    </table>
                    
                </div>
            </CardContent>
        </Card>
    );
};

export default RecruiterDetails;

