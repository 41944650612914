import React, { Fragment, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import util from "../../../utils/miniUtils";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { setCandidates, setCandidateStatus } from "../../../../redux/actions/JobCandidatesAction";
import { CircularProgress } from "@material-ui/core";

const Step2 = ( props ) => {
    const [isLoading, setIsLoading] = useState( false );
    const [recruiters, setRecruiters] = useState( [] );
	
	
    const [isDisabled, setIsDisabled] = useState(
        parseFloat( props.candidate.status ) > 2.5
    );
	
    useEffect( () => {
        getRecruiters().then( r => null );
    },[] );
	
    async function getRecruiters() {
        try {
            const request = await util.axios.get( `${util.url}/app_resources/users_by_role/1` );
            const { error, msg, users } = request.data;
            if ( error ) throw msg;
            setRecruiters( users );
        } catch ( error ) {
            util.handleError( error );
        }
    }
	
    const [interviewer, setInterviewer] = useState(
        props.candidate.internal_review !== null
            ? props.candidate.internal_review.interviewer
            : util.internalReview.interviewer
    );

    const [date, setDate] = useState(
        props.candidate.internal_review !== null && props.candidate.internal_review.date !== ""
            ? props.candidate.internal_review.date.split( "T" ).length === 1 ?
                props.candidate.internal_review.date + "T01:00":props.candidate.internal_review.date
            : util.internalReview.date
    );
    const [notes, setNotes] = useState(
        props.candidate.internal_review !== null ? props.candidate.internal_review.notes
            : util.internalReview.notes
    );
	
    function handleChanges( e ) {
        const { name, value } = e.target;
        if ( name === "interviewer" ) setInterviewer( value );
        if ( name === "date" ) setDate( value );
        if ( name === "notes" ) setNotes( value );
    }
	
    async function saveNote( option ) {
        try {
            setIsLoading( true );
            const candidate = props.candidate;
            if ( interviewer === "" ) throw new Error( "Select a interviewer" );
            if ( date === "" ) throw new Error( "Select a date" );
            if ( notes === "" ) throw new Error( "Write a note before clear this step" );
            const d = { interviewer,date,notes, option };
            const request = await util.axios.post( `${util.url}/job_position/internal_interviews/${candidate.position_id}/${candidate.candidate_id}`,d );
            const { error, msg } = request.data;
            if ( error ) return msg;
            util.toast().success( msg );
            if ( option === 0 ){
                props.setCandidateStatus( { id:candidate.candidate_id, status: 3, talent_desc_updated: "Internal interview Cleared" } );
                setIsDisabled( true );
            }else{
                props.setCandidateStatus( { id:candidate.candidate_id, status: 2.5, talent_desc_updated: "Internal interview Scheduled" } );
            }
			
            setIsLoading( false );
        } catch ( error ) {
            setIsLoading( false );
            util.handleError( error );
        }
    }
	
    if ( isLoading ){
        return <div className="text-center"><CircularProgress /></div>;
    }
	
    return (
        <Fragment>
            <div className="row">
                <div className="col-auto text-center flex-column d-none d-sm-flex">
                    <div className="row h-50">
                        <div className="col">&nbsp;</div>
                        <div className="col">&nbsp;</div>
                    </div>
                    <h5 className="m-2">
                        <span
	            className={`badge badge-pill ${
		            isDisabled ? "bg-success" : "bg-light"
	            } border `}
                        >
              &nbsp;
                        </span>
                    </h5>
                    <div className="row h-50">
                        <div className="col border-right">&nbsp;</div>
                        <div className="col">&nbsp;</div>
                    </div>
                </div>
                <div className="col py-2">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title text-muted">Internal Interview</h4>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>Interviewer</td>
                                        <td>
                                            <select
                                                value={interviewer}
                                                name="interviewer"
                                                onChange={handleChanges}
                                                className="form-control"
                                                disabled={isDisabled}
                                            >
                                                <option/>
                                                {recruiters.map( ( r, i ) => {
                                                    return (
                                                        <option key={i} value={r.name}>
                                                            {r.name}
                                                        </option>
                                                    );
                                                } )}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Date</td>
                                        <td>
                                            <input
                                                onChange={handleChanges}
                                                type="datetime-local"
                                                value={date}
                                                name="date"
                                                className="form-control"
                                                disabled={isDisabled}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Notes</td>
                                        <td>
                                            <textarea value={notes} onChange={handleChanges} name="notes" className="form-control" disabled={isDisabled}    />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {isDisabled ? (
                                ""
                            ) : (
                                <Fragment>
                                    <Button
                                        onClick={()=>saveNote( 0 )}
                                        variant="success"
                                        className="float-right"
                                    >
                                        <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
										Internal interview cleared
                                    </Button>
                                    <Button
                                        onClick={()=>saveNote( 1 )}
                                        variant="primary"
                                        className="float-right mr-2"
                                    >
                                        <FontAwesomeIcon icon={faCalendar} className="mr-2" />
										Schedule
                                    </Button>
                                </Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
function mapDispatchToProps( dispatch ) {
    return{
        setCandidates:( data ) => dispatch( setCandidates( data ) ),
        setCandidateStatus:( data ) => dispatch( setCandidateStatus( data ) ),
    };
}


export default connect( null,mapDispatchToProps )( Step2 );
