import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Form, Col, Row, Container, Card } from 'react-bootstrap';
import util from '../../utils/miniUtils';
import MemberList from './MemberList';


const VendorDetails = ( { onBackClick, selectedVendor } ) => {
    const [showMembersTable, setShowMembersTable] = useState( false );
    const [vendorDetails, setVendorDetails] = useState( {} );
    const [groupsList, setGroupsList] = useState( [] );
    const [loading, setLoading] = useState( false );
    // const [categories, setCategories] = useState( [] );

    const handleChange = ( e ) => {
        const { name, value } = e.target;
        setVendorDetails( prev => ( { ...prev, [name]: value } ) );
    };

    const toggleMembersView = () => {
        setShowMembersTable( !showMembersTable );
    };

    const handleSave = ( e ) => {
        e.preventDefault();
        if( vendorDetails.vendor_company_id ) {
            updateVendor( vendorDetails.vendor_company_id, vendorDetails );
        } else {
            addNewVendor( vendorDetails );
        }
    };

    const badgeTypes = [
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'danger',
        'warning',
        'danger',
        'success',
        'danger'
    ];

    const badgeTexts = [
        'Active',
        'Inactive',
        'Prospective',
        'Onboarding',
        'Suspended',
        'Closed',
        'At Risk',
        'Churned',
        'Renewed',
        'Blacklisted'
    ];

    const getBadgeText = ( number ) => {
        // Check if the input is "Active"
        const isActive = number?.toLowerCase() === 'active';
    
        // Filter the badgeTexts array based on the condition
        return badgeTexts.filter( text => {
            const lowercaseText = text.toLowerCase();
            const lowercaseNumber = number?.toLowerCase();
    
            // If the input is "Active", exclude "Inactive" from the result
            if ( isActive && lowercaseText.includes( 'inactive' ) ) {
                return false;
            }
    
            // Otherwise, include text that matches the input number
            return lowercaseText.includes( lowercaseNumber );
        } );
    };

    const getBadgeMarkup = ( badgeNumber ) => {
        // Determine the badge type based on the integer using modulus to cycle through array
        const index = badgeTexts.findIndex( text => text.toLowerCase().includes( badgeNumber?.toLowerCase() ) );

        const badgeType = badgeTypes[index];
      
        return (
            <div className="d-flex justify-content-end ">
                <span className={`badge badge-${badgeType}`}>
                    {getBadgeText( badgeNumber )}
                </span>
            </div>
        );
    };
    // API Requests
    const getGroupsList = async () => {
        try{
            setLoading( true );
            const request = await util.axios.get( `groups/simple-list` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setGroupsList( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    const getVendorDetails = async ( id ) => {
        try {
            const request = await util.axios.get( `/vendor/${id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setVendorDetails( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    // const getCategories = async ( group_id ) => {
    //     try{
    //         setLoading( true );
    //         const request = await util.axios.get( `/groups/get_categories/${group_id}` );
    //         const { error, msg, data } = request.data;
    //         if( error ) throw msg;
    //         setCategories( data.filter( el => el.active === 1 ) );
    //     } catch ( error ) {
    //         util.handleError( error );
    //     }
    //     setLoading( false );
    // };

    const addNewVendor = async ( vendor ) => {
        try {
            const request = await util.axios.post( "/vendor", { ...vendor } );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const updateVendor = async ( id, vendor ) => {
        try {
            const request = await util.axios.patch( `/vendor/${id}`, { ...vendor } );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    useEffect( () => {
        getGroupsList();
        if( selectedVendor && selectedVendor.vendor_company_id ) {
            getVendorDetails( selectedVendor.vendor_company_id );
        }
    },[] );

    if( loading ) {
        return util.LOADING_SCREEN( loading );
    }

    return (
        <>
            <div className="d-flex align-items-center mb-3">
                <Button variant="link" onClick={onBackClick} className="d-flex align-items-center mr-2">
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" /> Back
                </Button>
                
                {!selectedVendor ? "" : <Button className='btn activeBtn sizeBtn btn-secondary mr-2' onClick={toggleMembersView}>
                    {showMembersTable ? 'Info' : 'Members'}
                </Button>}
            </div>
            {showMembersTable ? <MemberList selectedVendor={selectedVendor} /> 
                : <div>
                    <Row style={{ marginLeft: '3%', marginTop: '20px' }}>
                        <Col xs={6} className="logo-placeholder">
                            {/* <VendorLogo vendorDetails={vendorDetails} /> */}
                        </Col>

                    </Row><Container>
                        <Card className='ml-4'>
                            <Card.Header as="h5">
                                <div className="d-flex justify-content-between align-items-center">
                                    <span>Vendor Details</span>
                                    <span>{getBadgeMarkup( vendorDetails.vendor_Status )}</span>
                                </div>
                            </Card.Header>
                            <Card.Body className='ml-4'>
                                <Form>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group as={Row}>
                                                <Form.Label column sm={6}>
                                                        Vendor Name
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Vendor Name"
                                                        name="vendor_name"
                                                        value={vendorDetails.vendor_name}
                                                        onChange={handleChange} />
                                                </Col>
                                            </Form.Group>
                                            {/* Assignment Group */}
                                            <Form.Group as={Row}>
                                                <Form.Label column sm={6}>
                                                        Assignment Group
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control as="select"
                                                        value={vendorDetails.vendor_group_id ? vendorDetails.vendor_group_id : ""}
                                                        name="vendor_group_id" onChange={handleChange}>
                                                        <option value=""> -- </option>
                                                        {groupsList.map( ( item ) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.itemName}
                                                            </option>
                                                        ) )}
                                                    </Form.Control>
                                                </Col>
                                            </Form.Group>

                                            {/* Category */}
                                            {/* <Form.Group as={Row}>
                                                <Form.Label column sm={6}>
                                                        Category
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control as="select"
                                                        value={vendorDetails.vendor_default_category}
                                                        name="vendor_default_category" onChange={handleChange}>
                                                        <option value=""> -- </option>
                                                        {categories.map( ( item ) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.name}
                                                            </option>
                                                        ) )}
                                                    </Form.Control>
                                                </Col>
                                            </Form.Group> */}

                                            <Form.Group as={Row}>
                                                <Form.Label column sm={6}>
                                                        HQ Address
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="HQ Address"
                                                        name="vendor_HQ_Address"
                                                        value={vendorDetails.vendor_HQ_Address}
                                                        onChange={handleChange} />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row}>
                                                <Form.Label column sm={6}>
                                                        Email Domain
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Vendor Email Domain"
                                                        name="vendor_domain"
                                                        value={vendorDetails.vendor_domain}
                                                        onChange={handleChange} />
                                                </Col>
                                            </Form.Group>


                                            <Form.Group as={Row}>
                                                <Form.Label column sm={6}>
                                                        Vendor Phone
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Vendor Phone"
                                                        name="vendor_Phone"
                                                        value={vendorDetails.vendor_Phone}
                                                        onChange={handleChange} />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row}>
                                                <Form.Label column sm={6}>
                                                        Revenue Size
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Revenue Size"
                                                        name="revenue_Size"
                                                        value={vendorDetails.revenue_Size}
                                                        onChange={handleChange} />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row}>
                                                <Form.Label column sm={6}>
                                                        Vendor Status
                                                </Form.Label>

                                                <Col sm={10}>
                                                    <select
                                                        className="form-control"
                                                        style={{ width: '100%' }}
                                                        name="vendor_Status"
                                                        value={vendorDetails.vendor_Status}
                                                        onChange={handleChange}
                                                    >
                                                        <option value=""></option>
                                                        <option value="Active">Active</option>
                                                        <option value="At Risk">At Risk</option>
                                                        <option value="Blacklisted">Blacklisted</option>
                                                        <option value="Churned">Churned</option>
                                                        <option value="Closed">Closed</option>
                                                        <option value="Inactive">InActive</option>
                                                        <option value="Onboarding">Onboarding</option>
                                                        <option value="Prospective">Prospective</option>
                                                        <option value="Renewed">Renewed</option>
                                                        <option value="Suspended">Suspended</option>
                                                    </select>
                                                </Col>

                                            </Form.Group>
                                            <Form.Group as={Row}>
                                                <Form.Label column sm={6}>
                                                       Social Media Link
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=" Social Media Link"
                                                        name="social_Media_Links"
                                                        value={vendorDetails.social_Media_Links}
                                                        onChange={handleChange} />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>

                                            <Form.Group as={Row}>
                                                <Form.Label column sm={6}>
                                                        Vendor Email
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Vendor Email"
                                                        name="vendor_Email"
                                                        value={vendorDetails.vendor_Email}
                                                        onChange={handleChange} />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row}>
                                                <Form.Label column sm={6}>
                                                        Employee Count
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Employee Count"
                                                        name="employee_Count"
                                                        value={vendorDetails.employee_Count}
                                                        onChange={handleChange} />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row}>
                                                <Form.Label column sm={6}>
                                                        Vendor Founded Year
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Vendor Founded Year"
                                                        name="vendor_Founded_Year"
                                                        value={vendorDetails.vendor_Founded_Year}
                                                        onChange={handleChange} />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row}>
                                                <Form.Label column sm={6}>
                                                        Vendor Website
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Vendor Website"
                                                        name="vendor_Website"
                                                        value={vendorDetails.vendor_Website}
                                                        onChange={handleChange} />
                                                </Col>
                                            </Form.Group>


                                            <Form.Group as={Row}>
                                                <Form.Label column sm={6}>
                                                        Vendor Industry
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Vendor Industry"
                                                        name="vendor_Description"
                                                        value={vendorDetails.vendor_Description}
                                                        onChange={handleChange} />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row}>
                                                <Form.Label column sm={6}>
                                                        Time Zone
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Time Zone"
                                                        name="time_Zone"
                                                        value={vendorDetails.time_Zone}
                                                        onChange={handleChange} />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row}>
                                                <Form.Label column sm={6}>
                                                        Country Code
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Country Code"
                                                        name="country_Code"
                                                        value={vendorDetails.country_Code}
                                                        onChange={handleChange} />
                                                </Col>
                                            </Form.Group>

                                        </Col>
                                    </Row>


                                    <Form.Group as={Row}>
                                        <Col sm={{ span: 5, offset: 0.5 }}>
                                            <Button type="submit" className='mt-3' onClick={handleSave}>
                                                    Save Vendor
                                            </Button>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                </div>}
        </>
    );
};

export default VendorDetails;