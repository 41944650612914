import React, { useEffect } from "react";
import { List, ListItem, ListItemText } from "@material-ui/core";
import TalentForm from "./talent_form/TalentForm";


export default function ListOfTalentsSelectedForTemplate( { talents, job } ) {
	
    const [selectedIndex, setSelectedIndex] = React.useState( null );
	
    useEffect( () => {
        // setSelectedIndex(0)
    },[] );
	
    return(
        <div className="container">
            <div className="row">
                <div className="col-md-3">
                    <List component="nav" aria-label="main mailbox folders">
                        {talents && talents.map( ( t,i )=>{
                            return (
                                <ListItem
                                    key={i}
                                    button
                                    selected={selectedIndex === i}
                                    onClick={() => setSelectedIndex( i )}
                                >
                                    <ListItemText primary={t.name}  color="primary"/>
                                </ListItem>
                            );
                        } )}
                    </List>
				
				
                </div>
                <div className="col-md-9">
                    {
                        // Only load the component when a talent is selected
                        selectedIndex !== null ?
                            <TalentForm
                                talent_id={talents[selectedIndex].id}
                                job_id={job.id}
                            />
                            :
                            "Select a talent to begin"
                    }
                </div>
            </div>
        </div>
    );
}