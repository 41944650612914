import React, { useEffect } from 'react';
import { Drawer } from "@material-ui/core";

const SqFilter = ( { show, filterState, setFilterState, handleClose, questions, setCandidates, candidates } ) => {

    function initialFilterState(){
        let initialState = {};
        questions.forEach( el => {
            if( el.type === 1 || el.type === 2 ) {
                initialState[el.id] = el.options.map( option => ( { value: option, checked: false } ) );
            } else if( el.type === 3 ) {
                initialState[el.id] = { start: null, end: null };
            }
        } );
        return initialState;
    }

    function handleType( type ) {
        switch( type ) {
            case 1:
                return "Multiple Choice";
            case 2:
                return "Single Choice";
            case 3:
                return "Numeric";
            case 4:
                return "Text";
        }
    }

    function handleChange( e, id ) {
        const { value, checked } = e.target;
        const temp = { ...filterState };

        temp[id] = temp[id].map( el => {
            
            if( el.value === value ) {
                return { ...el, checked };
            } else return { ...el };
        } );

        setFilterState( { ...temp } );
        filterCandidates( temp, candidates );
    }

    function handleInputChange( e, id ) {
        const { value, name } = e.target;
        const temp = { ...filterState };
        if( value ) {
            temp[id][name] = value;
        } else {
            temp[id][name] = null;
        }

        setFilterState( { ...temp } );
        filterCandidates( temp, candidates );
    }

    function isJSON( value ) {
        try {
            JSON.parse( value );
            return true;
        } catch ( e ) {
            return false;
        }
    }
    

    function filterCandidates( filterResponse, candidates ) {
        const finalCandidates = [];
      
        for ( const candidate of candidates ) {
            const { response } = candidate;
            const candidateResponse = {};
      
            for ( const el of response ) {
                if ( el.type === 1 || el.type === 2 ) {
                    candidateResponse[el.id] = isJSON( el.response ) ? JSON.parse( el.response ) : el.response;
                } else if ( el.type === 3 ) {
                    candidateResponse[el.id] = parseFloat( el.response );
                } else {
                    candidateResponse[el.id] = el.response;
                }
            }

            const candidateMatchesFilter = Object.keys( filterResponse ).every( ( questionId ) => {
                const filter = filterResponse[questionId];
                const candidateValue = candidateResponse[questionId];
      
                if ( Array.isArray( filter ) ) {
                    const filteredOptions = filter.filter( ( el ) => el.checked ).map( ( el ) => el.value );
                    return compareArrays( filteredOptions, candidateValue );
                } else if ( filter && ( filter.start !== null || filter.end !== null ) ) {
                    // Handle numeric range filtering (type 3)
                    const { start, end } = filter;
                    
                    if ( start !== null && end !== null ) {
                        return candidateValue >= start && candidateValue <= end;
                    } else if ( start !== null ) {
                        return candidateValue >= start;
                    } else if ( end !== null ) {
                        return candidateValue <= end;
                    }
                }
                return true;
            } );
      
            if ( candidateMatchesFilter ) {
                finalCandidates.push( candidate.id );
            }
        }
    
        setCandidates( filterArrayById( candidates, finalCandidates ) );
    }
    

    function filterArrayById( array1, array2 ) {
        return array1.filter( item => array2.includes( item.id ) );
    }
      
    function compareArrays( array1, array2 ) {
        const set2 = new Set( array2 );
        return array1.every( ( item ) => set2.has( item ) );
    }

    function handleOptionsAndFilter( el ) {
        switch( el.type ) {
            case 1:
                return el.options ? <div className="ml-3">       
                    {el.options.map( ( option, index ) => <div className="border rounded d-flex p-2 mb-1 bg-light" key={`option-${index}`}>                     
                        <input
                            type="checkbox"
                            id={el.id + option}
                            onChange={( e ) => handleChange( e, el.id, index )}
                            value={option}
                            checked={filterState[el.id].find( el => el.value == option ).checked}
                        />
                        <label htmlFor={el.id + option} className="ml-2 w-75 w-100 mb-0">{option}</label>
                    </div> )
                    }
                </div> : "";
            case 2: 
                return el.options ? <div className="ml-3">       
                    {el.options.map( ( option, index ) => <div className="border rounded d-flex p-2 mb-1 bg-light" key={`option-${index}`}>                     
                        <input
                            type="checkbox"
                            id={el.id + option}
                            onChange={( e ) => handleChange( e, el.id, index )}
                            value={option}
                            checked={filterState[el.id].find( el => el.value == option ).checked}
                        />
                        <label htmlFor={el.id + option} className="ml-2 w-75 w-100 mb-0">{option}</label>
                    </div> )
                    }
                </div> : "";
            case 3:
                return <div className='ml-3'>
                    <div className='d-flex align-items-center'>
                        <input id={`screening-questions-filter-more-than-${el.id}`} className='form-control' name="start" onChange={( e ) => handleInputChange( e, el.id )} />
                        <div className='mx-2'>to</div>
                        <input id={`screening-questions-filter-less-than-${el.id}`} className='form-control' name="end"  onChange={( e ) => handleInputChange( e, el.id )} />
                    </div>
                </div>;
        }
    }

    function handleReset() {
        setCandidates( candidates );
        setFilterState( initialFilterState() );
    }

    useEffect( () => {
        setFilterState( initialFilterState() );
    },[] );
    
    if( !show ) {
        return null;
    }
    return (
        <Drawer anchor="right" open={show} onClose={handleClose}>
            <div className="collab-container container pt-2" style={{ width: .45 * window.innerWidth }}>
                {/* {questions.filter( question => question.type !== 3 && question.type!==4 ).map( ( el, index ) => ( */}
                <div className='text-right'>
                    <button className='btn btn-sm btn-secondary' type="button" onClick={handleReset}>Reset</button>
                </div>
                {questions && questions.map( ( el, index ) => (
                    <div key={index}>
                        {/* Questions */}
                        <div>
                            <strong>
                                <span className="mr-1">{index + 1}.</span>
                                <span>{el.question}</span>
                            </strong>
                        </div>
                        {/* Type */}
                        <div className='ml-3'>
                            <span className='mr-1'>
                                <strong>Type:</strong>
                            </span>
                            {handleType( el.type )}
                        </div>
                        {/* Options */}
                        {/* {el.options ? <div className="ml-3">       
                            {el.options.map( ( option, index ) => <div className="border rounded d-flex p-2 mb-1 bg-light" key={`option-${index}`}>                     
                                <input
                                    type="checkbox"
                                    id={el.id + option}
                                    onChange={( e ) => handleChange( e, el.id, index )}
                                    value={option}
                                    checked={filterState[el.id].find( el => el.value == option ).checked}
                                />
                                <label htmlFor={el.id + option} className="ml-2 w-75 w-100 mb-0">{option}</label>
                            </div> )
                            }
                        </div> : ""} */}
                        {handleOptionsAndFilter( el )}
                    </div> ) 
                )}
            </div>
        </Drawer>
    );
};

export default SqFilter;