import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import util from '../../../../utils/miniUtils';
import SweetAlert from 'react-bootstrap-sweetalert';

export default function MultipleTerminateEmployees( { show, data, handleClose } ) {
    const [terminationDate, setTerminationDate] = useState( new Date() );
    const [showConfirmation, setShowConfirmation] = useState( false );

    const handleTerminateClick = () => {
        setShowConfirmation( true );
    };

    const terminateEmployees = async () => {
        try {
            const selectedIds = data.map( row => row.employeeId );
            const request = {
                termination_date: terminationDate,
                ids: selectedIds
            };
            const response = await util.axios.put( '/employee/terminate_multiple',request );
            // Add termination logic here
            const { error, msg } = response.data;
            util.toast().success( msg );
            if ( error ) throw msg;
            setShowConfirmation( false );
            setTerminationDate( new Date() );
            handleClose();
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const handleChange = ( e ) => {
        const value = e.target.value;
        setTerminationDate( value );
    };

    return (
        <>
            <Modal show={show} onHide={() => handleClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Termination</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <label className="w-100">
                            <strong>Pick a Termination Date</strong> <br />
                            <input
                                className="form-control"
                                value={terminationDate}
                                type="date"
                                onChange={handleChange}
                                id="startDate"
                                name="startDate"
                            />
                        </label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose()}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleTerminateClick}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
            <SweetAlert
                show={showConfirmation}
                warning
                showCancel
                confirmBtnText="Yes, remove it!"
                confirmBtnBsStyle="danger"
                title="Are you sure?"
                onConfirm={() => terminateEmployees()}
                onCancel={() => setShowConfirmation( false )}
                focusCancelBtn
            />
        </>
    );
}
