import up from "../../shared/checkboxSelector/images/up.png";
import down from "../../shared/checkboxSelector/images/down.png";
import React, { useEffect, useState } from "react";
// import CheckBoxStatusSelector from "../../../AppraisalSystem/shared/CheckboxStatusSelector";
import CheckBoxSelector from "../../shared/checkboxSelector";
import util from "../../utils/miniUtils";
import { Collapse } from "react-bootstrap";
import { Chip } from "@material-ui/core";
import countryList from '../../utils/location/country.json';
// import CustomSlider from "../../talents/components/listComponents/CustomSlider";
import CheckBoxSelectorTwo from "../../shared/checkboxSelector/CheckBoxSelectorTwo";
const countryListFixed = util.fixCountryList();
const employmentTypeList=util.fixEmploymentTypeList();

//Reusing the checkBoxSelector component instead of fieldSelector

export default function SideBarFilter( { reloadData } ) {
    // Data for filters
    const [filter, setFilter] = useState( {
        ready: false,
        loading: false,
        status: [],
        employmentType: employmentTypeList,
        country: countryListFixed,
        state: [],
       
    } );
    
    const [statusList, setStatusList] = useState( [] );

    
    useEffect( () => {
        getStatusList(); // Fetch status list when component mounts
    }, [] );
    const getStatusList = async () => {
        try{
            const request = await util.axios.get( 'job_talent_status/list' );
            const { data, error, msg } = request.data;
            if( error ) throw msg;
            console.log( "Status List:", data );
            setStatusList( data );
        } catch ( e ) {
            util.handleError( e );
        }
    };
    
   
    // Get states list from country name
    const getStatesList = ( countryName ) =>{
        let states= {};
        countryList.forEach( ( item )=>{

            if( item.name === countryName ){
                states[countryName]= item.states ;
            }} );
        return states;
    };

    useEffect( () => {
        // Every time country is changed, will retrieve the list of states
        let countries=filter.country.filter( ( item )=>item.checked );
        let states = {};
        for( let i=0;i<countries.length;i++ ){
            states = { ...states, ...( getStatesList( countries[i].value ) ) };
        }

        let temp = [];
        Object.keys( states ).forEach( el => temp.push( ...states[el] ) );
        temp = temp.map( ( el,i ) => ( {
            value: el.name,
            id: i + 1,
            label: el.name
        } ) );
        setFilter( ( prev )=>( { ...prev, state: [...temp] } ) );
    },[filter.country] );

    // Trigger in every change of filter
    useEffect( () => {
        if ( filter.ready ) {
            // console.log( 'query new data due changes' );
            reloadData( filter );
        }
    }, [filter] );
    
    if ( filter.loading ) {
        return util.LOADING_SCREEN( filter.loading );
    }
    // console.log( filter );

    return (
        <div className="container-fluid fontSize13">
            <div className="row sideBarFilter">
                <div className="col-md-12 p-sm-0 m-sm-0">
                   
                    
                   
                    
                    <CheckBoxSelector
                        isOpenByDeafult={true}
                        dataArray={filter.country}
                        title="Country"
                        updateData={( data ) =>
                            setFilter( { ...filter, country: data, ready: true } )
                        }
                    />

                    {filter.state.length > 0 ? <CheckBoxSelector
                        dataArray={filter.state}
                        title="States"
                        updateData={( data ) =>
                            setFilter( { ...filter, state: data, ready: true } )
                        }
                    /> : ""}
                    
                    
                    <CheckBoxSelector
                        dataArray={filter.employmentType}
                        title="Employment Type"
                        updateData={( data ) =>
                            setFilter( { ...filter, employmentType: data, ready: true } )
                        }
                    />
                    {statusList.length > 0 && ( // Render status list if available
                        <CheckBoxSelectorTwo
                            dataArray={statusList}
                            title="Status"
                            updateData={( data ) =>
                                setFilter( { ...filter, status: data, ready: true } )
                            }
                        />
                    )}
                   
                   
                   

                   
                </div>
            </div>
        </div>
    );
}


