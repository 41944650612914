import { Box, Card, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import * as React from 'react';
import { TalentAddedByYear, TalentAddedPerDay, TalentAddedPerMonth } from './ChartList';
import { useDispatch } from 'react-redux';
import { setFilter } from '../../../redux/actions/datefilter';


export default function TalentDaysTab() {
    const [value, setValue] = React.useState( '1' );
    const dispatch = useDispatch();

    const handleChange = ( event, newValue ) => {
        dispatch( setFilter( true ) );
        setValue( newValue );
    };

    return (
        <Card className='days-tab'>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" indicatorColor='primary'>
                            <Tab label="Years" value="1" className="non-draggable" />
                            <Tab label="Months" value="2" className="non-draggable" />
                            <Tab label="Days" value="3" className="non-draggable"/>
                        </TabList>
                    </Box>
                    <TabPanel value="1"><TalentAddedByYear /></TabPanel>
                    <TabPanel value="2"><TalentAddedPerMonth /></TabPanel>
                    <TabPanel value="3"><TalentAddedPerDay /></TabPanel>
                </TabContext>
            </Box>
        </Card>
    );
}