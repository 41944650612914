import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import util from '../../utils/miniUtils';
import WorkflowForm from './WorkflowForm';

const WorkflowList = ( { category } ) => {
    const [list, setList] = useState( [] );
    const [show, setShow] = useState( false );
    const [groupList, setGroupList] = useState( [] );
    const [workflow, setWorkflow] = useState( {} );
    const [isNew, setIsNew] = useState( true );

    const getWorkflowList = async () => {
        try{
            const request = await util.axios.get( `workflow?orderBy=createdAt&direction=desc&category=${category}` );
            const { data, error, msg } = request.data;
            if( error ) throw msg;
            setList( data );
        } catch( error ) {
            util.handleError( error );
        }
    };

    const getGroupList = async () => {
        try {
            const request = await util.axios.get( 'groups/simple-list' ); 
            const { data, error, msg } = request.data;
            if( error ) throw msg;
            setGroupList( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const createWorkFlow = async ( workflow ) => {
        try {
            const request = await util.axios.post( 'workflow', workflow );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
        } catch ( error ) {
            util.handleError( error );
        }
        getWorkflowList();
    };

    const updateWorkflow = async ( id, workflow ) => {
        try {
            const request = await util.axios.patch( `workflow/${id}`, workflow );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
        } catch ( error ) {
            util.handleError( error );
        }
        getWorkflowList();
        setIsNew( true );
    };


    const deleteWorkflow = async ( id ) => {
        try {
            const request = await util.axios.delete( `workflow/${id}` );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const handleEdit = ( item ) => {
        setShow( true );
        setIsNew( false );
        setWorkflow( item );

    };

    const deleteItem = ( id ) => {
        deleteWorkflow( id );
        getWorkflowList();
    };

    const handleClose = () => {
        setShow( false );
        setWorkflow( {} );
    };

    useEffect( () => {
        getWorkflowList();
        getGroupList();
    },[category] );

    return ( 
        <>
            <div className="listContainer p-1">
                <div className="d-flex justify-content-between align-items-center mt-1 mb-2">
                    <b>Workflow</b>
                    <FontAwesomeIcon
                        className="isCursor text-primary"
                        icon={faPlus}
                        onClick={() => setShow( true )}
                    />
                </div>
                <div className='d-flex flex-column'>
                    {list && list.map( ( el ) => (
                        <span
                            className="d-flex justify-content-between border p-1 pl-2 m-1 align-items-center"
                            key={`${el.field_id}${el.wl_category_id}`}>
                            <ItemCard item={el} />
                            <button
                                className="btn btn-sm p-1 btn-outline-secondary border-0"
                                onClick={( e ) => {
                                    e.stopPropagation();
                                    handleEdit( el );
                                }}>
                                <FontAwesomeIcon icon={faEdit} title="Edit"/>
                            </button>
                            <button
                                className="btn btn-sm p-1 btn-outline-danger border-0"
                                onClick={( e ) => {
                                    e.stopPropagation();
                                    deleteItem( el.id );
                                }}>
                                <FontAwesomeIcon icon={faTrash} title="Delete"/>
                            </button>
                        </span> )
                    )}
                </div>
            </div>
            {show ? <WorkflowForm
                workflow={workflow}
                isNew={isNew}
                show={show} 
                category={category}
                handleClose={handleClose} 
                createWorkflow={createWorkFlow} 
                updateWorkflow={updateWorkflow}
                groupList={groupList} /> : ""}
        </>
    );
};

export default WorkflowList;

const ItemCard = ( { item } ) => {
    return <div className="w-100">
        <div className="d-flex align-items-center justify-content-between">
            <b className="text-dark">{item.name}</b>
        </div>
        <div className='xs-fontSize'>{`${item.approvals_required} Approval required`}</div>
    </div>;
};