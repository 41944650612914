import util from "../../../../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import React from "react";

export default function Skills( { handleChange, skill, index, section, deleteSkill } ) {
    return(
        <tr>
            <td>
                <util.InputSlim
                    name="skill"
                    onChange={( e )=>handleChange( e,index, section )}
                    value={skill.skill ? skill.skill: ""}
                />
            </td>
            <td>
                <util.InputSlim
                    name="yearsOfExperience"
                    onChange={( e )=>handleChange( e,index, section )}
                    type="number"
                    value={skill.yearsOfExperience ? skill.yearsOfExperience: 0}
                />
            </td>
            <td className="d-flex justify-content-around">
                <util.InputSlim
                    name="desc"
                    onChange={( e )=>handleChange( e,index, section )}
                    value={skill.desc ? skill.desc: ""}
                />
                <button onClick={()=>deleteSkill( index, section )} className="btn btn-sm text-danger">
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </td>
        </tr>
    );
}