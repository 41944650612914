import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Chip, MuiThemeProvider } from "@material-ui/core";
import { Modal, Button, Form } from "react-bootstrap";
import util from "../../../utils/miniUtils";
import InviteModal from "./InviteModal";
import SAlert from "../../../microComponents/AlertSA";

const Subordinate = () => {
    // console.log( util.user );
    const [members, setMembers] = useState( [] );
    const [showModal, setShowModal] = useState( false );
    const [emails, setEmails] = useState( "" );
    const [error, setError] = useState( "" );
    const [showDeleteModal, setShowDeleteModal] = useState( false );
    const [memberToDelete, setMemberToDelete] = useState( null );
    const [showRoleModal, setShowRoleModal] = useState( false ); 
    const [roleToUpdate, setRoleToUpdate] = useState( null ); 
    const [selectedRole, setSelectedRole] = useState( "" ); 
    const [accountSettings, setAccountSettings] = useState( {} ); 

    // Fetch members data
    const fetchMembers = async () => {
        try {
            const employerId = util.user.employer_id;
            const response = await util.axios.get( `/employers_subordinates/get_members/${employerId}` );
            const { error, data } = response.data;

            if ( error ) {
                throw new Error( "Failed to fetch subordinate data" );
            }

            setMembers( data );
        } catch ( err ) {
            setError( "An error occurred while fetching members." );
            console.error( err );
        }
    };

    const fetchSettings = async () => {
        try {
            const employerId = util.user.employer_id;
            const response = await util.axios.get( `/employers/get_account_settings/${employerId}` );
            const { error, data } = response.data;
            if ( error ) {
                throw new Error( "Failed to fetch account settings" );
            }
            setAccountSettings( data ); // Set account settings
        } catch ( err ) {
            console.error( err );
        }
    };

    useEffect( () => {
        fetchMembers();
        fetchSettings(); // Call fetchSettings to get account settings
    }, [] );

    const handleModalClose = () => {
        setShowModal( false );
        setEmails( "" );
        setError( "" );
    };

    const handleAddMembers = async () => {
        const emailArray = emails.split( "," ).map( ( email ) => email.trim() );
        const employerDomain = util.user.email.split( "@" )[1]; 
        
        if ( emailArray.length + ( accountSettings.employee_count || 0 ) > ( accountSettings.employee_limit || 0 ) ) {
            setError( "Too many invites, reduce the members to the maximum on your account" );
            return;
        }
        if ( emailArray.length > 5 ) {
            setError( "You can only add up to 5 emails at a time." );
            return;
        }

        for ( const email of emailArray ) {
            if ( !/\S+@\S+\.\S+/.test( email ) ) {
                setError( `Invalid email format: ${email}` );
                return;
            }

            const emailDomain = email.split( "@" )[1];
            if ( emailDomain !== employerDomain ) {
                setError( `Please use your corporate email address (${employerDomain}) instead of a third-party email like ${email}.` );
                return;
            }

            if ( members.some( ( member ) => member.email.toLowerCase() === email.toLowerCase() ) ) {
                setError( `This email is already in use: ${email}` );
                return;
            }
        }

        const userEmails = emailArray.map( ( email ) => ( { email } ) );

        try {
            const response = await util.axios.post( '/employer_invitation/sendInvite', {
                employer_id: util.user.employer_id,
                user_email: userEmails,
            } );

            const { error, msg } = response.data;

            if ( error ) throw new Error( msg );

            util.toast().success( msg );
            fetchMembers();
            handleModalClose();
        } catch ( error ) {
            setError( "An error occurred while adding members." );
            console.error( error );
            util.handleError( error );
        }
    };

    const handleDeleteClick = ( member ) => {

        if ( util.user.role !== 18 ) {
            setMemberToDelete( member );
            setShowDeleteModal( true );
        }
    };
    

    const handleDeleteMember = async () => {
        try {
            const response = await util.axios.delete( `/employer_invitation/${memberToDelete.id}?email=${encodeURIComponent( memberToDelete.email )}` );
            const { error, msg } = response.data;

            if ( error ) throw msg;

            fetchMembers();
            setShowDeleteModal( false );
            setMemberToDelete( null );

            util.toast().success( msg );
        } catch ( error ) {
            setError( "An error occurred while deleting the member." );
            console.error( error );
            util.handleError( error );
        }
    };

    // New function to open the role update modal
    const handleRoleUpdateClick = ( member ) => {
        if ( util.user.role !== 18  ) {
            setRoleToUpdate( member );
            setSelectedRole( member.roles );
            setShowRoleModal( true );
        }
    };
    

    // Function to handle role update
    const handleRoleUpdate = async () => {
        try {
            const response = await util.axios.put( `/employer_invitation/${roleToUpdate.id}`, {
                role_id: selectedRole, 
            } );
    
            const { error, msg } = response.data;
    
            if ( error ) throw msg;
    
            
            fetchMembers(); 
    
            setShowRoleModal( false ); 
            setRoleToUpdate( null ); 
            util.toast().success( msg ); 
        } catch ( error ) {
            setError( "An error occurred while updating the role." ); 
            console.error( error );
            util.handleError( error );
        }
    };
    

    const columns = [
        {
            title: "Name",
            field: "name",
            render: ( rowData ) => (
                <span
                    style={{ cursor: "pointer", color: rowData.roles !== "Employer" ? "#007bff" : "inherit" }}
                    onClick={() => rowData.roles !== "Employer" && handleRoleUpdateClick( rowData )}
                >
                    {rowData.name}
                </span>
            ),
        },
        {
            title: "Email",
            field: "email",
        },
        {
            title: "Status",
            field: "status",
            render: ( rowData ) => (
                <Chip
                    size="small"
                    label={rowData.status === 0 ? "Invited" : "Accepted"}
                    className={`text-white ${rowData.status === 0 ? "bg-primary" : "bg-success"}`}
                />
            ),
        },
        {
            title: "Roles",
            field: "roles",
        },
        {
            title: "Actions",
            sorting: false,
            hidden:util.user.role === 18,
            render: ( rowData ) =>
                <div style={{ display: "flex", gap: "10px" }}>
                    <FontAwesomeIcon
                        icon={faTrash}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDeleteClick( rowData )}
                    />
                </div>
        },
    ];
    const tableTitle = `Employer's Members List (${accountSettings.employee_count || 0} / ${accountSettings.employee_limit || 0})`;


    return (
        <>
            <MuiThemeProvider theme={util.defaultTableTheme}>
                <MaterialTable
                    columns={columns}
                    title={tableTitle} // Use the updated title
                    data={members}
                    options={{
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50],
                        sorting: true,
                        search: true,
                        // exportButton: true,
                        selection: true,
                        headerStyle: { backgroundColor: "#f5f5f5", fontWeight: "bold" },
                        maxBodyHeight: "60vh",
                    }}
                    actions={
                        util.user.role !== 18
                            ? [
                                {
                                    icon: () => (
                                        <div className="btn btn-secondary btn-sm activeBtn sizeBtn">
                                            <FontAwesomeIcon icon={faPlusCircle} /> Invite Member
                                        </div>
                                    ),
                                    tooltip: "Invite Members",
                                    isFreeAction: true,
                                    onClick: () => setShowModal( true ),
                                },
                            ]
                            : ""
                    }
                />
            </MuiThemeProvider>

            <InviteModal
                showModal={showModal}
                handleAddMembers={handleAddMembers}
                handleModalClose={handleModalClose}
                emails={emails}
                setEmails={setEmails}
                error={error}
            />

            {showDeleteModal && (
                <SAlert
                    show={showDeleteModal}
                    msg={`Are you sure you want to delete this member: ${memberToDelete.email}?`}
                    hideAlert={() => setShowDeleteModal( false )}
                    onConfirm={handleDeleteMember}
                />
            )}

            {/* Role Update Modal */}
            <Modal show={showRoleModal} onHide={() => setShowRoleModal( false )}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="memberEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                value={roleToUpdate?.email || ""}
                                disabled
                            />
                        </Form.Group>
                        <Form.Group controlId="roleSelect">
                            <Form.Label>Select Role</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedRole}
                                onChange={( e ) => setSelectedRole( e.target.value )}
                            >
                                <option value="Employer Admin">Employer Admin</option>
                                <option value="Employer ">Employer</option>
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowRoleModal( false )}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleRoleUpdate}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Subordinate;
