import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import util from "../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, } from "@fortawesome/free-solid-svg-icons";
import Question from "./Question";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Tooltip from "@material-ui/core/Tooltip";

export  default function TestModal( { show, hideModal, id, refreshTestList } ) {
	
    const [state, setState] = useState( {
        data: {
            id: id,
            name: "",
            desc:"",
            questions: [],
            time: 15
        },
        id:id,
        loading: false,
    } );
	
    useEffect( () => {
        // console.log( id );
        if ( !util.noValues.includes( id ) ){
            getTestInformation().then( null );
        }else{
            setState( {
                data: {
                    id: null,
                    name: "",
                    desc:"",
                    questions: [],
                    time: 0
                },
                id:null,
                loading: false,
            } );
        }
        // eslint-disable-next-line
    },[id] );
	
    // Handle changes on "Test Information" section
    const onChangeHandler = ( e ) =>{
        const { name, value } = e.target;
        let t = { ...state };
        t.data[name] = value;
        setState( t );
    };
	
    // Handle changes on test information (Description due is a rich text field)
    const handleChange = ( value ) =>{
        let t = { ...state };
        t.data.desc = value;
        setState( t );
    };
	
    // Handle changes inside each question
    const handleChangeX = ( e, index ) =>{
        const { name, value } = e.target;
        let t = { ...state };
        // console.log( name, value );
        t.data.questions[index][name] = value;
        setState( t );
    };
	
    // Handle changes of image in each question
    const onChangeHandlerX = ( event, index ) =>{
        const fileSize = ( event.target.files[0].size / 1024 / 1024 ).toString();
        if ( fileSize.split( "." )[0] > 2 ) {
            toast.error( "File size is exceeded, max 2 MB" );
            let t = { ...state };
            t.data.questions[index].filename = null;
            setState( t );
        } else {
            let reader = new FileReader();
            if( event.target.files[0] ) {
                reader.onload = () => {
                    let imgBase64 = reader.result;
                    let t = { ...state };
                    t.data.questions[index].filename = imgBase64;
                    setState( t );
                };
                reader.readAsDataURL( event.target.files[0] );
            }
        }
    };
	
    const cancelImage = ( index ) =>{
        let t = { ...state };
        t.data.questions[index].filename = null;
        setState( Object.assign( {}, t ) );
    };
	
    // Make a request to obtain information about the test
    const getTestInformation  = async() => {
        try {
            const request = await util.axios.get( `${util.url}/tests/${id}`, );
            let { error, msg, test } = request.data;
            if ( error ) throw msg;
            setState( { ...state, data: Object.assign( {}, test ) } );
            setState( { ...state, data: test } );
        }catch ( e ) {
            util.handleError( e );
        }
    };
	
    // Create a test in the server, this is always the first step to enable add questions
    const createTest  = async() => {
        try {
            // Validate data before save it
            if ( state.data.name === "" ) return util.handleError( "Enter Name for your test" );
            if ( state.data.desc === "" ) return util.handleError( "Enter Description" );
            if ( state.data.time === "" || state.data.time === 0 ) return util.handleError( "Enter valid Time" );
			
            const request = await util.axios.post( `${util.url}/tests/create`, { test: state.data } );
            let { error, msg, newTest } = request.data;
            if ( error ) throw msg;
            setState( { ...state, data: newTest, id: newTest.id } );
            util.toast().success( msg );
            refreshTestList();
        }catch ( e ) {
            util.handleError( e );
        }
    };
	
    // Update all the test, including the questions
    const updateTest  = async() => {
        try {
            if ( state.data.name === "" ) return util.handleError( "Enter Name for your test" );
            if ( state.data.desc === "" || state.data.desc === "<p><br></p>" ) return util.handleError( "Enter Description" );
            if ( state.data.time === "" || state.data.time === 0 ) return util.handleError( "Enter valid Time" );
			
            const request = await util.axios.put( `${util.url}/tests/update/${state.id}`, { test: state.data } );
            let { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
        }catch ( e ) {
            util.handleError( e );
        }
    };
	
    // Every time we add a question, we create a record that belongs to this test
    const addQuestion = async () =>{
        try {
            const request = await util.axios.post( `${util.url}/tests/create_question/${state.id}` );
            let { error, msg, question } = request.data;
            if ( error ) throw msg;
            // Add new question to the test array
            let t = [...state.data.questions];
            t.push( question );
            let tt = { ...state };
            tt.data.questions = t;
            setState( tt );
        }catch ( e ){
            util.handleError( e );
        }
    };
	
    // When remove a question, you also delete from the db
    const removeQuestion = async ( index ) =>{
        try {
            const idByIndex = state.data.questions[index].id;
            const request = await util.axios.delete( `${util.url}/tests/delete_question/${idByIndex}` );
            let { error, msg, questions } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
            // Fill the array with the update list of remaining questions
            let tt = { ...state };
            tt.data.questions = [];
            setState( Object.assign( {}, tt ) );
            tt.data.questions = questions;
            setState( Object.assign( {}, tt ) );
        }catch ( e ){
            util.handleError( e );
        }
    };
	
    return(
        <Fragment>
            <Modal
                size={"lg"}
                show={show}
                onHide={hideModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{state.data.id !== null ? "Edit test":"Create test" }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs defaultActiveKey="test_information" id="tabsContainer">
                        <Tab eventKey="test_information" title="Test Information">
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <th className="text-right">Name</th>
                                        <td><input className="form-control" name="name" value={state.data.name} onChange={onChangeHandler}/></td>
                                    </tr>
                                    <tr>
                                        <th className="text-right">Description</th>
                                        <td>
                                            <ReactQuill
                                                id="testDesc"
                                                value={state.data.desc}
                                                onChange={handleChange}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-right">Time</th>
                                        <td>
                                            <Tooltip title={"Time in minutes"}>
                                                <span>
                                                    <input
                                                        className="form-control"
                                                        style={{ width:150 }}
                                                        name="time"
                                                        type="number"
                                                        value={state.data.time}
                                                        onChange={onChangeHandler}
                                                        placeholder="Time in minutes"
                                                    />
                                                </span>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {state.data.id === null ?
                                <div className="text-center" >
                                    <button id="btnCreateTest" onClick={createTest} className="btn btn-secondary activeBtn sizeBtn">
										Create Test
                                    </button>
                                </div>
                                :
                                <div className="text-center">
                                    <button id="btnUpdateTest" onClick={updateTest} className="btn btn-secondary activeBtn sizeBtn">
										Update Test
                                    </button>
                                </div>
                            }
                        </Tab>
                        <Tab eventKey="questions" title="Questions" disabled={state.data.id === null}>
                            {state.data.id !== null ?
                                <Fragment>
                                    <h5 className="p-2">Questions</h5>
                                    <table className="table">
                                        <tbody>
                                            {state.data?.questions?.map( ( q, i ) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <Question
                                                                q={q}
                                                                index={i}
                                                                onChangeHandlerX={onChangeHandlerX}
                                                                handleChangeX={handleChangeX}
                                                                removeQuestion={removeQuestion}
                                                                cancelImage={cancelImage}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            } )}
                                            <tr>
                                                <td colSpan={3} className="text-right">
                                                    <button id="btnAddQuestion" onClick={addQuestion} className="btn btn-primary activeBtn sizeBtn mt-2">
                                                        <FontAwesomeIcon icon={faPlus} className="mr-2" />
													Add
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Fragment>
                                :
                                ""
                            }
                        </Tab>
                    </Tabs>
					
                </Modal.Body>
            </Modal>
        </Fragment>
    );
}

