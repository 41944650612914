import React from 'react';

const SubCatalogList = ( { data, onEdit, onSelect, active, onDelete } ) => {
   
    const { id, name, type} = data;

    return (
        <div className={`d-flex justify-content-between align-items-center p-2
        border rounded mt-1 mb-1 department_item ${active === type ? "activeBlock" : ""} isCursor`}
        onClick={( e ) => onSelect( e, type,name,id )}>
            <div className="isCursor">
                <div>
                    <b className='text-dark'>{name}</b>
                </div>
                <div className="leadName">
                    <span>{name} list</span>
                </div>
            </div>
           
        </div>
    );
};

export default SubCatalogList;
