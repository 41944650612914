import React, { Fragment } from "react";
import { OFFER_TYPE, OFFER_TYPE_ESTIMATE, TALENT_ENGAGEMENT_TYPE } from "./constants";
import util from "../../../../../utils/miniUtils";

export default function SelectorCTC( { typeSelector, title, name, value, onChange, isVendor } ) {
    const items = () => {
        switch ( typeSelector ) {
            case TALENT_ENGAGEMENT_TYPE:
                return ["Fulltime Employee", "Contractor", "Corp to Corp"];
            case OFFER_TYPE:
                return ["Hour", "Day", "Month"];
            case OFFER_TYPE_ESTIMATE:
                return ["Hour", "Day", "Month"];
            default:
                return [];
        }
    };
    
    return (
        <Fragment>
            <div className="row d-flex align-content-center align-items-center">
                <div className="col-md-6"><b>{title}</b></div>
                <div className="col-md-6">
                    <select className="form-control" name={name} value={value} onChange={onChange}
                        disabled={( util.user.role ===15 && typeSelector===TALENT_ENGAGEMENT_TYPE ) || ( isVendor && util.user.role !==15 ) } >
                        {items().map( ( item ) => {
                            return (
                                <option key={Math.random()} value={item}>
                                    { typeSelector !== TALENT_ENGAGEMENT_TYPE ? "Per ": null}{item}
                                </option>
                            );
                        } )}
                    </select>
                </div>
            </div>
            <hr className="m-1" />
        </Fragment>
    );
}
